<div class="main main-full px-3">

    <div *ngFor="let stat of devStats">
        <b>{{stat.title}}:</b> {{stat.stat}}
    </div>

    <button (click)="integrity()">
        Integrity
    </button>

</div>
