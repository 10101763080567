import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[autoFocus]'
})
export class AutoFocusDirective implements OnInit {
	@Input() hasDelay: boolean = true;
	constructor(private elRef: ElementRef) { }
	ngOnInit(): void {
		if (this.hasDelay) {
			setTimeout(() =>
				this.elRef.nativeElement.focus()
				, 500)
		} else {
			this.elRef.nativeElement.focus()
		}
	}
}