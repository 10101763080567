<div class="row mr-0 config-background-3">
	<div #heroBanner id="hero-container" class="col-12 hero-container px-0">
		<img class="w-100 d-none d-md-block" src="{{seriesView && heroWeb ? heroWeb : heroWebImage}}" alt="Hero Image" />
		<img class="w-100 d-md-none" src="{{seriesView && heroMobile ? heroMobile: heroMobileImage}}" alt="Hero Image" />
		<div class="hero-overlay col-12" [class.series-view-v2-overlay]="seriesView">
			<div class="col-11 col-md-5 col-xxl-4 ml-2 ml-md-5">
                <img *ngIf="newVideo && !watched && showNew" class="thumb-new mb-2" src="{{config.remoteConfig.new_badge}}" height="30px" alt="New Video" />
                <img *ngIf="!newVideo && watched && showNew" class="thumb-new mb-2" src="{{config.remoteConfig.watched}}" height="30px" alt="New Video" />
				<img *ngIf="!seriesView && media.channelImageUrl" class="channelImage d-block d-lg-block"
					src="{{media.channelImageUrl}}" alt="Channel Logo" /> <!-- TODO: ACCESIBILITY -->
				<img *ngIf="seriesView && logo" class="series-view-v2-channelImage d-block"
					src="{{logo}}" alt="Channel Logo" />
				<h4 class="text-white mt-4" *ngIf="!seriesView" [class.series-view-v2-text]="seriesView">{{media.title}}</h4>
				<!--
            <hr />
                <p class="d-none d-sm-block d-md-none d-xl-block"></p>
                <hr />
                <div>
                    <app-rate [mediaId]="media.mediaId"></app-rate>
                </div>
            -->
				<div class="mt-2 mt-md-4" *ngIf="!seriesView">
					<a class="btn btn-outline-light px-5 mr-3" [routerLink]="['/video', media.mediaId]">
						<b class="align-middle">PLAY</b>
					</a>
					<button *ngIf="!existsInPlaylist(media.mediaId) && isAuthenticated()"
						class="btn btn-outline-light px-5 d-none d-lg-inline-block" (click)="addToPlaylist(media)">
						<b *ngIf="!addToPlaylistLoading" class="align-middle">ADD TO WATCHLIST</b>
						<b *ngIf="addToPlaylistLoading" class="align-middle">REMOVING</b>
					</button>
					<button *ngIf="existsInPlaylist(media.mediaId) && isAuthenticated()"
						class="btn btn-outline-light px-5 d-none d-lg-inline-block"
						(click)="deleteFromPlaylist(media)">
						<b *ngIf="!addToPlaylistLoading" class="align-middle">REMOVE FROM WATCHLIST</b>
						<b *ngIf="addToPlaylistLoading" class="align-middle">ADDING</b>
					</button>
				</div>
			</div>
<!--			<div *ngIf="seriesView">-->
<!--				<div class="carousel-buttons">-->
<!--					<button class="btn btn-light watch-now-button p-2"-->
<!--						[routerLink]="['/video', media.mediaId]">Watch</button>-->
<!--				</div>-->
<!--				<div class="series-view-add-to-watchlist d-md-none d-lg-none"-->
<!--					*ngIf="isAuthenticated() && !existsInPlaylist(media.mediaId)" (click)="addToPlaylist(media.mediaId)">-->
<!--					+ Watchlist-->
<!--				</div>-->
<!--				<div class="series-view-add-to-watchlist d-md-none d-lg-none"-->
<!--					*ngIf="isAuthenticated() && existsInPlaylist(media.mediaId)" (click)="deleteFromPlaylist(media.mediaId)">-->
<!--					- Watchlist-->
<!--				</div>-->
<!--			</div>-->
		</div>
	</div>
</div>
