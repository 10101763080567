import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RemoteConfigService } from '../services/remote-config.service';

@Injectable({
    providedIn: 'root'
})
export class UnderConstructionGuard implements CanActivate {

    ignore = [
        'localhost', 'privacy', 'privacy-policy', 'terms', 'terms-and-conditions', 'legaldocuments', 'refund', 'refund-policy'
    ]

    constructor(private config: RemoteConfigService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.ignore.some(x => location.href.includes(x))) {
            if (this.isPath(route)) {
                return this.router.createUrlTree(['/']);
            }

            return true;
        }

        if (this.config.remoteConfig.under_construction && !this.isPath(route)) {
            return this.router.createUrlTree(['/under-construction']);
        }

        if (!this.config.remoteConfig.under_construction && this.isPath(route)) {
            return this.router.createUrlTree(['/']);
        }
        return true;
    }

    private isPath(route: ActivatedRouteSnapshot) {
        return route.url.some(x => x.path === 'under-construction');
    }

}
