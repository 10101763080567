import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-makersplace',
    templateUrl: './makersplace.component.html',
    styleUrls: ['./makersplace.component.css']
})
export class MakersplaceComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
