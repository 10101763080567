import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ChannelResponse, SeriesViewSeason } from '../../models';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChannelsService } from '../../services/channels.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { WatchHistoryService } from '../../services/watch-history.service';

@Component({
    selector: 'app-series-view-v2',
    templateUrl: './series-view-v2.component.html',
    styleUrls: ['./series-view-v2.component.scss'],
})
export class SeriesViewV2Component implements OnInit, OnDestroy {

    @Input() channel: ChannelResponse;

    selectedTab = 1;
    sortByOpen = false;
    seasonsOpen = false;

    imageWeb: string;
    imageMobile: string;
    logo: string;

    firstEpisodeTitle: string;
    firstEpisodeId: number;
    seasonId: number;

    sortByOption = 'Oldest';

    sortOptions = [{
        title: 'Oldest',
        cb: this.sortByOldest.bind(this),
        sort: 'asc'
    }, {
        title: 'Newest',
        cb: this.sortByNewest.bind(this),
        sort: 'desc'
    }];

    selectedSeason: string;

    constructor(private service: ChannelsService,
                private ui: UiService,
                private route: ActivatedRoute,
                private titleService: Title,
                private router: Router,
                private channelService: ChannelsService,
                private watchHistory: WatchHistoryService) {
    }

    async ngOnInit(): Promise<void> {
        this.ui.startLoading();
        this.seasonId = +this.route.snapshot.paramMap.get('seasonId');
        if (!this.channel && this.seasonId) {
            this.channel = await lastValueFrom(this.service.getChannelBySeasonId(this.seasonId));
            this.titleService.setTitle(this.channel.name);
        }

        this.setUpMedia();

        if (this.channel.seasonId !== undefined) {
            this.selectedSeason =
                this.channel.series?.seasons.find(x => x.seasonId === this.channel.seasonId)?.name
                ?? this.channel.series?.seasons[0].name;
        } else {
            this.selectedSeason = this.channel.series?.seasons[0].name;
        }

        const userChannels = await firstValueFrom(this.channelService.userChannels());
        if (!userChannels) {
            this.channelService.refreshUserChannels();
        }

        this.watchHistory.refreshWatchHistory();

        this.firstEpisodeTitle = this.channel.media[0]?.title;
        this.firstEpisodeId = this.channel.media[0]?.mediaId;

        this.sortByOption = this.sortOptions.find(x => x.sort === this.channel.displayOrder)?.title ?? 'Oldest';
        this.ui.stopLoading();
    }

    ngOnDestroy() {
        this.titleService.setTitle(environment.name);
    }

    async optionClicked(event, season: SeriesViewSeason) {
        event.stopPropagation();
        await this.router.navigate(['/season', season.seasonId]);
        this.imageWeb = '';
        this.imageMobile = '';

        this.selectedSeason = season.name;
        this.ui.startLoading();
        try {
            this.channel = await lastValueFrom(this.service.getChannelBySeasonId(season.seasonId));
            this.setUpMedia();

            this.firstEpisodeTitle = this.channel.media[0].title;
            this.firstEpisodeId = this.channel.media[0].mediaId;
        } catch (e) {

        } finally {
            this.ui.stopLoading();
            this.closeDropdowns();
        }
    }

    setUpMedia() {
        if (this.channel.media[0]) {
            this.channel.media[0].channelImageUrl = this.channel.images?.find(f => f.type === 'standard')?.url;
        }
        this.imageWeb = this.channel.images?.find(x => x.type === 'hero-web-slim')?.url ?? this.channel.images?.find(x => x.type === 'hero-web')?.url;
        this.imageMobile = this.channel.images?.find(x => x.type === 'hero-mobile')?.url;
        this.logo = this.channel.images?.find(x => x.type === 'logo')?.url;
    }

    showHasMultipleSeasons() {
        return this.channel.series?.seasons && this.channel.series?.seasons.length > 1;
    }

    openSortBy(event) {
        if (!this.sortByOpen) {
            event.stopPropagation();
        }
        this.closeDropdowns();
        this.sortByOpen = true;
    }

    openSeasons(event) {
        if (!this.seasonsOpen) {
            event.stopPropagation();
        }
        this.closeDropdowns();
        this.seasonsOpen = true;
    }

    sortByNewest(event?) {

        event?.stopPropagation();
        if (this.sortByOption === 'Oldest') {
            this.channel.media.reverse();
        }
        this.sortByOption = 'Newest';
        this.closeDropdowns();
    }

    sortByOldest(event?) {
        event?.stopPropagation();
        if (this.sortByOption === 'Newest') {
            this.channel.media.reverse();
        }
        this.sortByOption = 'Oldest';

        this.closeDropdowns();
    }

    @HostListener('click')
    closeDropdowns() {
        this.seasonsOpen = this.sortByOpen = false;
    }

}
