<form (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
    <div class="form-group text-left">
        <input [disabled]="loading" type="text" name="code" [(ngModel)]="code"
               class="form-control" placeholder="{{ 'account-enter-voucher-code' | translate }}" required #voucherCode>
        <p class="text-danger" *ngIf="error">{{error}}</p>
    </div>

    <div class="form-group">
        <button type="submit" class="btn btn-outline-primary" [class.btn-slim]="slim">{{ 'account-voucher-submit' | translate }}</button>
    </div>
</form>
