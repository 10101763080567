export interface PointsResponse {
    points: UserPointsModel[];
    pointsTotal: number;
    pointsSpent: number;
    pointsRemaining: number;
}

export interface UserPointsModel {
    amount: number;
    date: string;
    source: string;
}
