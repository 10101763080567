import { Pipe, PipeTransform } from '@angular/core';
import { LocalisationService } from '../services/localisation.service';

@Pipe({
	name: 'translate'
})
export class TranslatePipe implements PipeTransform {

	constructor(private localisationService: LocalisationService) {}

	transform(key: string, ...args: string[]): string {
        if (args && args.length > 0) {
            return this.localisationService.getTranslationsParams(key, ...args);
        }

		return this.localisationService.getTranslation(key);
	}

}
