<div *ngIf="preferences$ | async">
    <h4>{{ "email-preferences-header" | translate }}</h4>
    <div class="mb-3">
        <label class="form-switch w-100">
            <span class="switch-text">{{ "email-preferences-video-notifications" | translate }} <small class="beta">BETA</small></span>
            <input type="checkbox" [(ngModel)]="videoNotificationEmails">
            <i class="float-right"></i>
            <br />
            <small>{{ "email-preferences-video-notifications-description" | translate }}</small>
        </label>
    </div>
    <div class="mb-3">
        <label class="form-switch w-100">
            <span class="switch-text">{{ "email-preferences-live-stream-emails" | translate }}</span>
            <input type="checkbox" [(ngModel)]="liveStreamEmails">
            <i class="float-right"></i>
            <br />
            <small>{{ "email-preferences-live-stream-emails-description" | translate }}</small>
        </label>
    </div>
    <div class="mb-3">
        <label class="form-switch w-100">
            <span class="switch-text">{{ "email-preferences-corridor-news-emails" | translate }}</span>
            <input type="checkbox" [(ngModel)]="corridorNewsEmails">
            <i class="float-right"></i>
            <br />
            <small>{{ "email-preferences-corridor-news-emails-description" | translate }}</small>
        </label>
    </div>
    <div class="mb-3">
        <label class="form-switch w-100">
            <span class="switch-text">{{ "email-preferences-corridor-store-emails" | translate }}</span>
            <input type="checkbox" [(ngModel)]="corridorStoreEmails">
            <i class="float-right"></i>
            <br />
            <small>{{ "email-preferences-corridor-store-emails-description" | translate }}</small>
        </label>
    </div>
    <div class="mb-3">
        <label class="form-switch w-100">
            <span class="switch-text">{{ "email-preferences-corridor-subscription-emails" | translate }} <small class="beta">{{ "required" | translate }}</small></span>
            <input type="checkbox" checked="checked" disabled>
            <i class="float-right"></i>
            <br />
            <small>{{ "email-preferences-corridor-subscription-emails-description" | translate }}</small>
        </label>
    </div>

    <div class="mb-3">
        <small>{{ "email-preferences-bottom-text" | translate }}</small>
    </div>

    <div class="form-group">
        <button type="submit" tabindex="3" class="btn btn-outline-primary btn-slim" (click)="onSave()">{{ "email-preferences-save" | translate }}</button>
    </div>
</div>
