export interface MetadataResponseV2 {
    images: MediaImageResponseV2[];
    metadata: MetadataDataResponseV2;
}

export interface MediaImageResponseV2 {
    id: number;
    mediaId: number;
    url: string;
    width: number;
    height: number;
    dateCreated: string;
}

export interface MetadataDataResponseV2 {
    id: number;
    mediaId: number;
    title: string;
    description: string;
    shortDescription: string;
    datePublished: string;
    audioOnlyOption: boolean;
}
