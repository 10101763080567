import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PointsService } from '../../services/points.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { RemoteConfigService } from '../../services/remote-config.service';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { UserInfoService } from '../../services/user-info.service';
import { UiService } from '../../services/ui.service';


@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements AfterViewInit, OnDestroy {

    activeUrl;
    showSearch: boolean = false;
    searchTerm: string = '';

    notifications: any[];
    notificationsNotRead = false;
    notificationsLoading = false;
    pointsSet: boolean = false;

    points$: BehaviorSubject<number>;
    destroy$: Subject<void> = new Subject<void>();
    mobileMenuOpen$ = new BehaviorSubject<boolean>(false);
    showDesktopDropdown$ = new BehaviorSubject<boolean>(false)

    @ViewChild('left') leftSide: ElementRef<HTMLDivElement>;
    @ViewChild('right') rightSide: ElementRef<HTMLDivElement>;
    @ViewChild('center') center: ElementRef<HTMLDivElement>;

    get showDesktopDropdown(): boolean {
        return this.showDesktopDropdown$.value;
    }
    set showDesktopDropdown(val) {
        this.showDesktopDropdown$.next(val);
    }

    get mobileMenuOpen(): boolean  {
        return this.mobileMenuOpen$.value;
    }
    set mobileMenuOpen(val) {
        this.mobileMenuOpen$.next(val);
    }

    constructor(private router: Router,
                private accountService: AccountService,
                private notificationService: NotificationService,
                private pointsService: PointsService,
                public config: RemoteConfigService,
                private ui: UiService,
                private userInfoService: UserInfoService
    ) {
        let lastScreenSize: ScreenSizeEnum;
        this.ui.breakpointObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((screenSize: ScreenSizeEnum) => {
                if ((lastScreenSize === ScreenSizeEnum.Medium && screenSize === ScreenSizeEnum.Large) ||
                    (lastScreenSize === ScreenSizeEnum.Large && screenSize === ScreenSizeEnum.Medium)) {
                    this.mobileMenuOpen = false;
                    this.showDesktopDropdown = false;
                }

                lastScreenSize = screenSize;
            });

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                takeUntil(this.destroy$)
            )
            .subscribe(
                (event: any) => {
                    this.mobileMenuOpen = false;
                    this.showDesktopDropdown = false;
                }
            );

        this.activeUrl = this.router.url;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            this.activeUrl = (event as any).url;
        });

        if (this.isAuthenticated()) {
            this.openNotifications();
        }

        this.points$ = this.pointsService.points$;
    }

    ngAfterViewInit() {
        this.adjustSearchMargins()
        setTimeout(() => this.adjustSearchMargins(), 100)
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.adjustSearchMargins(true)
    }

    adjustSearchMargins(resize = false) {
        if (!this.leftSide?.nativeElement || !this.rightSide?.nativeElement || !this.center?.nativeElement) {
            return;
        }

        const leftWidth: number = this.leftSide.nativeElement.offsetWidth;
        const rightWidth: number = this.rightSide.nativeElement.offsetWidth;
        let diff = Math.abs(leftWidth - rightWidth);

        const windowWidth = document.body.clientWidth;
        const searchBarWidth = 400;

        const paddingOffset = (windowWidth - searchBarWidth - leftWidth - rightWidth - diff);

        diff += paddingOffset > 0 ? 0 : paddingOffset;

        if (leftWidth > rightWidth) {
            this.center.nativeElement.style.paddingRight = diff + 'px';
        }
        if (rightWidth > leftWidth) {
            this.center.nativeElement.style.paddingLeft = diff + 'px';
        }
    }

    doSearch(): void {
        this.router.navigate([`/search`], { queryParams: { search: this.searchTerm } });
        this.showSearch = false;
        this.searchTerm = '';
    }

    login() {
        this.accountService.navigateToAccessPage('login');
    }

    register() {
        this.accountService.navigateToAccessPage('signup');
    }

    signout() {
        this.accountService.logoutUser();
        this.pointsSet = false;
    }

    isAuthenticated() {
        return this.accountService.isAuthenticated();
    }

    showLearnMore() {
        return !this.accountService.isAuthenticated() && this.config.remoteConfig.learn_more_enabled;
    }

    hasSubscription() {
        return this.userInfoService.hasSubscription;
    }

    getAvatarUrl() {
        if (!this.accountService.isAuthenticated()) {
            return this.config.remoteConfig.hamburger;
        }
        return this.userInfoService.avatarUrl ?? 'assets/avatar-default.svg';
    }

    userInfo() {
        return this.userInfoService;
    }

    openNotifications() {
        this.accountService.updateToken().then(
            () => {
                this.notificationsLoading = true;
                this.notificationService.getNotifications().subscribe(res => {
                    this.notifications = res;
                    this.notificationsNotRead = res.filter(s => !s.read).length > 0;
                    this.notificationsLoading = false;
                });
            },
            error => {
            });
    }

    trialString() {
        let timeLeft = this.userInfo().streamsRemaining;

        if (timeLeft >= 24) {
            return Math.round(timeLeft / 24) + ' days left';
        } else {
            return timeLeft + ' hours left';
        }
    }

    closeNav() {
        if (this.showDesktopDropdown) {
            this.showDesktopDropdown = false;
        }
    }
}
