<section class="main-full">
    <div id="watchlist-titles" class="mb-3">
        <h1>{{'watchlist' | translate}}</h1>
    </div>
    <div class="container-fluid media-container">
        <div class="row ml-2 mr-2 mb-3">
            <div class="col-12 col-sm-4 col-md-3 col-xxl-2 p-1 m-0" *ngFor='let m of media'>
                <app-video-thumb-v2 [media]="m" [hoverOnSmall]="true" [fromWatchList]="true"></app-video-thumb-v2>
            </div>
            <div *ngIf="(!media || !media.length) && !isLoading" class="empty-watchlist">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     x="0px"
                     y="0px"
                     viewBox="0 0 489.8 489.8" style="enable-background:new 0 0 489.8 489.8;" xml:space="preserve">
<g>
	<g>
		<path d="M438.2,0H51.6C23.1,0,0,23.2,0,51.6v386.6c0,28.5,23.2,51.6,51.6,51.6h386.6c28.5,0,51.6-23.2,51.6-51.6V51.6
			C489.8,23.2,466.6,0,438.2,0z M465.3,438.2c0,14.9-12.2,27.1-27.1,27.1H51.6c-14.9,0-27.1-12.2-27.1-27.1V51.6
			c0-14.9,12.2-27.1,27.1-27.1h386.6c14.9,0,27.1,12.2,27.1,27.1V438.2z"/>
        <path d="M337.4,232.7h-80.3v-80.3c0-6.8-5.5-12.3-12.3-12.3s-12.3,5.5-12.3,12.3v80.3h-80.3c-6.8,0-12.3,5.5-12.3,12.2
			c0,6.8,5.5,12.3,12.3,12.3h80.3v80.3c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-80.3h80.3c6.8,0,12.3-5.5,12.3-12.3
			C349.7,238.1,344.2,232.7,337.4,232.7z"/>
	</g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
</svg>

                <h3 class="mb-3">Your watchlist is empty</h3>
                <p>Content you add to your watchlist will appear here.</p>
            </div>
        </div>
    </div>
</section>

