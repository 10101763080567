<a #seasonBanner class="season-banner" [routerLink]="['/season', channel.seasonId]">
    <div class="img-wrapper" [class.first]="isFirst">
        <img class="season-logo" [src]="logo">
        <img class="season-background" [src]="background">
    </div>
</a>
<div #slider class="slider" [class.large]="channel.thumbnailType === 'large'" [class.vertical]="channel.thumbnailType === 'vertical'">
    <app-image-slider [type]="'season-banner'" [items]="channel.media" *ngIf="channel && channel.media" [thumbnailType]="isMobileView ? channel.thumbnailTypeResponsive ?? channel.thumbnailType : channel.thumbnailType"></app-image-slider>
</div>


