import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LegalDocument } from 'src/app/models/legal-document';
import { LegalDocumentsService } from '../legal-documents.service';

@Injectable({
	providedIn: 'root'
})
export class LegalDocumentsResolver implements Resolve<any> {

	constructor(private legalDocumentsService: LegalDocumentsService) { }

	resolve(): Observable<LegalDocument[]> {
		return this.legalDocumentsService.getLatestLegalDocuments();
	}
}
