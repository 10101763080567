import { MediaSummary } from './media-summary';
import { ImageModel } from './image-model';
import { TextModel } from './text-model';

export interface HeroResponse {
    autoPlay: boolean;
    autoPlayTime: number;
    heroes: HeroV2[];
}

export interface HeroV2 {
    name?: string;
    type: string;
    images?: ImageModel[];
    text?: TextModel[];
    channelId?: number | null;
    seasonId?: number | null;
    mediaId?: number | null;
    media?: MediaSummary;
    redirectUrl?: string;
}
