import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';
import { LivestreamChat } from '../../models/socket-messages/livestream-chat';
import { EnvironmentService } from '../../services/environment.service';
import { BehaviorSubject } from 'rxjs';
import { AccountType } from '../../models/enums/account-type.enum';

@Component({
    selector: 'app-chat-read',
    templateUrl: './chat-read.component.html',
    styleUrls: ['./chat-read.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatReadComponent implements OnInit {

    deleted$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    protected readonly AccountType = AccountType;

    @Input() comment: LivestreamChat;

    constructor(private chatService: ChatService,
                public env: EnvironmentService) {
    }

    ngOnInit() {
    }

    report() {
        if (confirm('Are you sure you want to report this chat message?')) {
            this.chatService.reportMessage('live', this.comment.chatId.toString()).subscribe(
                res => {
                    this.deleted$.next(true);
                }
            );
        }
    }

    logoUrl() {
        return environment.assets.logo;
    }
}
