import { Component, Input, OnDestroy } from '@angular/core';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { VideoService } from 'src/app/services/video.service';
import { StreamReport } from 'src/app/models/stream-report';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import { UserInfo } from '../../models';
import { UserInfoService } from '../../services/user-info.service';

declare var player: any;
declare var videojs: any;

@Component({
    selector: 'videojs',
    templateUrl: './videojs.component.html',
    styleUrls: ['./videojs.component.css']
})
export class VideojsComponent implements OnDestroy {

    @Input() videoId: number;
    @Input() url: string;
    @Input() poster: string;
    nextVideoId;
    @Input() type: string;
    @Input() autoplay: boolean;
    @Input() audioOnlyOption: boolean;
    @Input() startTimeMs: number;
    @Input() preload: boolean = true;

    uid: string;
    loaded: boolean = false;
    audioPlayback: boolean = false;
    lastReportedTime = 0;
    frequency = 10;
    timeLeft: number;
    autoplayNextVideo: boolean = true;
    isSafari: boolean = false;

    constructor(
        private app: AppComponent,
        private router: Router,
        private storageManager: StorageManagerService,
        private videoService: VideoService,
        private userInfo: UserInfoService) {
        this.generateUID();

        this.audioPlayback = JSON.parse(localStorage.getItem('audioPlayback'));
        if (!this.audioPlayback) {
            this.audioPlayback = false;
        }

        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    ngOnDestroy() {
        player.pause();
        player.dispose();
    }

    generateUID() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        // then to call it, plus stitch in '4' in the third group
        this.uid = (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
    }

    ngAfterViewInit() {
        if (localStorage.getItem('autoplay')) {
            this.autoplayNextVideo = JSON.parse(localStorage.getItem('autoplay'));
        }

        videojs.options.hls.overrideNative = this.isSafari;

        videojs.Hls.xhr.beforeRequest = (options) => {
            if (options.uri.includes('kms')) {
                options.uri = options.uri + '?uid=' + this.uid + '&platform=Web';
                options.headers = {
                    'Authorization': 'bearer ' + this.storageManager.getToken()
                };
            }
            return options;
        };

        let vjs = 'video';

        var options = {}; // Nuevo plugin options

        player = videojs(vjs, {techOrder: ['chromecast', 'html5']});
        player.nuevo(options);
        player.chromecast({button: 'controlbar', receiverAppID: 'FD667DD2'});

        let volume = localStorage.getItem('volume');
        if (volume) {
            player.volume(volume);
        }

        player.hotkeys({
            volumeStep: 0.1,
            seekStep: 5,
            enableVolumeScroll: false,
            alwaysCaptureHotkeys: true
        });

        player.on('error', () => {
            console.log('error');
        });

        player.on('ended', () => {
            if (localStorage.getItem('nextVideoId')) {
                if (localStorage.getItem('autoplay')) {
                    this.autoplayNextVideo = JSON.parse(localStorage.getItem('autoplay'));
                }
                if (this.autoplayNextVideo) {
                    try {
                        videojs(player).dispose();
                    } catch {
                    }
                    this.router.navigate(['/video', localStorage.getItem('nextVideoId')]);
                }
            }
        });

        player.on('volumechange', () => {
            localStorage.setItem('volume', player.volume());
        });

        player.on('loadeddata', () => {
            this.loaded = true;

            if (player.duration() - (this.startTimeMs / 1000) < 10) {
                this.startTimeMs = 0;
            }

            if (this.startTimeMs) {
                player.currentTime(this.startTimeMs / 1000);
            }

            if (!this.userInfo.hasSubscription && !this.userInfo.hasPatreon) {
                this.app.getUserInfo();
            }
        });

        player.on('timeupdate', () => {
            let time = player.currentTime();
            let intTime = (time | 0);
            if (time > 0 && intTime % this.frequency == 0 && intTime > this.lastReportedTime) {
                this.lastReportedTime = intTime;
                this.videoService.reportStreamProgress(
                    this.uid,
                    this.videoId,
                    new StreamReport(player.currentTime() * 1000, 0, 'StreamProgress')
                ).subscribe(res => {
                });
            }

            this.timeLeft = player.duration() - time;
        });

        if (this.audioOnlyOption === false || !this.audioPlayback) {
            player.src({
                src: this.url,
                type: 'application/x-mpegURL',
                withCredentials: false,
                overrideNative: this.isSafari,
                cacheEncryptionKeys: true
            });
        } else {
            player.src({
                src: this.url.replace('playlist.m3u8', 'eng.m3u8').replace('master.m3u8', 'eng.m3u8'),
                type: 'application/x-mpegURL',
                withCredentials: false,
                overrideNative: this.isSafari,
                cacheEncryptionKeys: true
            });
        }
    }

    toggleVideo() {
        this.audioPlayback = this.audioPlayback === false ? true : false;
        localStorage.setItem('audioPlayback', JSON.stringify(this.audioPlayback));
        var currentPos = player.currentTime();
        if (this.audioOnlyOption === false || !this.audioPlayback) {
            player.src({
                src: this.url,
                type: 'application/x-mpegURL',
                withCredentials: false,
                overrideNative: this.isSafari,
                cacheEncryptionKeys: true
            });
        } else {
            player.src({
                src: this.url.replace('playlist.m3u8', 'eng.m3u8'),
                type: 'application/x-mpegURL',
                withCredentials: false,
                overrideNative: this.isSafari,
                cacheEncryptionKeys: true
            });
        }
        player.currentTime(currentPos);
    }
}
