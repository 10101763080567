export interface PurchaseHistory {
    id: number;
    date: string;
    productName: string;
    transactionId: string;
    voucherCode: string;
    voucherUsed: boolean;
    amount: number;
    currency: string;
}
