import { Component, OnInit } from '@angular/core';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

    constructor(private ui: UiService) {
    }

    ngOnInit(): void {
        this.ui.stopLoading();
    }

}
