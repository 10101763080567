import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PaymentsService } from '../../../services/payments.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { AccountService } from '../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';

@Component({
    selector: 'app-enter-voucher-code-v2',
    templateUrl: './enter-voucher-code-v2.component.html',
    styleUrls: ['./enter-voucher-code-v2.component.scss']
})
export class EnterVoucherCodeV2Component extends AuthenticationOverride implements OnInit, AfterViewInit {
    loading: boolean = false;
    code: string = '';
    error: string;

    @Input() slim = false;

    @Output() complete = new EventEmitter<void>();
    @Output() loadingEvent = new EventEmitter<boolean>();

    @ViewChild('voucherCode') voucherCodeInput;

    constructor(private paymentService: PaymentsService,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router) {
        super(route, storage, router, account);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.voucherCodeInput.nativeElement.focus();
        })

    }

    setLoading(isLoading: boolean) {
        this.loading = isLoading;
        this.loadingEvent.emit(isLoading);
    }

    async submit() {
        this.setLoading(true);
        this.error = undefined;
        try {
            await lastValueFrom(this.paymentService.redeemVoucher(this.code));
            this.complete.emit();
        } catch (e) {
            if (e.status === 400) {
                this.error = e.error;
            } else {
                this.error = 'An unexpected error occurred';
            }
        } finally {
            this.setLoading(false);
        }
    }

}
