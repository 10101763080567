import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-simple-shaka',
  templateUrl: './modal-simple-shaka.component.html',
  styleUrls: ['./modal-simple-shaka.component.css']
})
export class ModalSimpleShakaComponent implements OnInit {

  @Input() url: string

  @Output() closeEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.closeEvent.emit(true)
  }

  nothing(event: Event) {
    event.stopPropagation()
  }
}
