import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { PointsResponse } from '../models';


@Injectable({
    providedIn: 'root'
})
export class PointsService {
    public points$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    public get producerPoints() {
        return this.points$.value;
    }

    public set producerPoints(points: number) {
        this.points$.next(points);
    }

    getPoints(): Observable<PointsResponse> {
        return this.http
            .get<PointsResponse>(this.envService.userHost + '/v1/points/')
    }

    recalculatePoints(): Observable<void> {
        return this.http.post<void>(this.envService.userHost + '/v1/points/recalculate', {})
    }
}
