import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PaymentsService } from '../../services/payments.service';

@Component({
    selector: 'app-enter-voucher-code',
    templateUrl: './enter-voucher-code.component.html',
    styleUrls: ['./enter-voucher-code.component.scss']
})
export class EnterVoucherCodeComponent implements OnInit, AfterViewInit {
    loading: boolean = false;
    code: string = ''
    error: string;

    @Input() slim = false;

    @Output() complete = new EventEmitter<void>();
    @Output() loadingEvent = new EventEmitter<boolean>();

    @ViewChild('voucherCode') voucherCodeInput;

    constructor(private paymentService: PaymentsService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.voucherCodeInput.nativeElement.focus();
    }

    setLoading(isLoading: boolean) {
        this.loading = isLoading;
        this.loadingEvent.emit(isLoading);
    }

    async submit() {
        this.setLoading(true);
        this.error = undefined;
        try {
            await lastValueFrom(this.paymentService.redeemVoucher(this.code));
            this.complete.emit();
        } catch (e) {
            if (e.status === 400) {
                this.error = e.error;
            } else {
                this.error = 'An unexpected error occurred';
            }
        } finally {
            this.setLoading(false);
        }
    }

}
