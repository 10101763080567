<div class="main main-full main-responsive">
	<app-title [title]="'account-enter-voucher-code' | translate "></app-title>
	<div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
		<span class="sr-only">{{ "loading" | translate }}</span>
	</div>

	<app-enter-voucher-code-v2 (complete)="navigateComplete()" (loadingEvent)="loading = $event" [slim]="true">
	</app-enter-voucher-code-v2>

    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>
