import { Component, OnInit } from '@angular/core';
import { Auth, confirmPasswordReset } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { UiService } from '../../../services/ui.service';
import { RemoteConfigService } from '../../../services/remote-config.service';

@Component({
    selector: 'app-forgotten-password-recovery',
    templateUrl: './forgotten-password-recovery.component.html',
    styleUrls: ['./forgotten-password-recovery.component.scss']
})
export class ForgottenPasswordRecoveryComponent implements OnInit {

    viewModel: any = {};
    errorMessage: string;

    constructor(
        private afAuth: Auth,
        private ui: UiService,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private router: Router,
        private config: RemoteConfigService) {
        this.route.queryParams.subscribe(params => {
            this.viewModel.code = params['oobCode'] ?? params['c'];
            this.viewModel.email = params['e'];
        });
    }

    ngOnInit(): void {
        if (this.accountService.isAuthenticated() && this.config.remoteConfig.login_version == 1) {
            this.router.navigate(['/account/password'])
        }
    }

    resetPassword() {
        if (this.viewModel.password !== this.viewModel.confirm) {
            this.errorMessage = 'Passwords do not match.';
            return;
        }

        this.ui.startLoading();
        if (this.config.remoteConfig.login_version == 1) {
            this.resetPasswordV1()
        }
        if (this.config.remoteConfig.login_version == 2) {
            this.resetPasswordV2()
        }
    }

    resetPasswordV1() {
        confirmPasswordReset(this.afAuth, this.viewModel.code, this.viewModel.password)
            .then(() => {
                this.ui.stopLoading();
                this.accountService.navigateToAccessPage('login');
            })
            .catch(error => {
                this.errorMessage = error.message;
                this.ui.stopLoading();
            });
    }

    resetPasswordV2() {
        this.accountService.forgottenPasswordChangeV2(this.viewModel.code, this.viewModel.password, this.viewModel.email).subscribe({
            next: () => {
                this.ui.stopLoading();
                this.accountService.navigateToAccessPage('login');
            },
            error: error => {
                this.errorMessage = error.error;
                this.ui.stopLoading();
            }
        });
    }


}
