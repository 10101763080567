import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {RemoteConfigService} from '../../services/remote-config.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-under-construction',
    templateUrl: './under-construction.component.html',
    styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {

    entryForm: FormGroup;
    private readonly passCode: string = 'sliced';

    constructor(
        private fb: FormBuilder,
        private config: RemoteConfigService,
        private router: Router) {
    }

    ngOnInit() {
        this.entryForm = this.fb.group({
            entryCode: ['', Validators.required]
        });
    }

    onSubmit() {
        if (this.entryForm.value.entryCode === this.passCode) {
            this.config.remoteConfig.under_construction = false;
            this.router.navigate(['/']);
        } else {
            this.entryForm.reset();
        }
    }

}
