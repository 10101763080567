<section class="main main-full" id="forgot-password-section">
    <app-title [title]="'forgotten-password' | translate"
               [subtitle]="'Just type in your new password and you\'re all set!'"
    ></app-title>

    <form novalidate>
        <app-password-field [(password)]="viewModel.password" [placeholder]="'New Password'"></app-password-field>

        <app-password-field class="d-block mt-2" [(password)]="viewModel.confirm" [placeholder]="'Confirm Password'"></app-password-field>


        <div class="form-group block error">
            <span *ngIf="errorMessage">{{errorMessage}}</span>
        </div>

        <button class="btn btn-primary btn-block" tabindex="3" (click)="resetPassword()">Reset
            Password
        </button>
    </form>
</section>
