<div #videoUI id="videoUI" data-shaka-player-container>
    <video #videoPlayer data-shaka-player id="video" style="width:100%; height: 100%;"></video>
</div>

<div class="cover-image" *ngIf="poster" (click)="posterClicked()" [class.hide]="(playing$ | async) && !isMobile">
    <img class="play-greenlight pointer" src="https://cdn.watchcorridor.com/assets/play.svg"
         alt="Play Greenlight Video" *ngIf="(canPlay$ | async) || isMobile" />
    <app-loading-spinner [widthHeightPx]="(isMobileView$ | async) ? 50 : 160" *ngIf="!(canPlay$ | async) && !isMobile"></app-loading-spinner>
    <img [src]="poster" class="w-100 h-100 poster">
</div>
