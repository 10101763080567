import { Component, OnInit } from '@angular/core';
import { PointsService } from 'src/app/services/points.service';
import { AccountService } from '../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';

@Component({
  selector: 'app-point-history-v2',
  templateUrl: './point-history-v2.component.html',
  styleUrls: ['./point-history-v2.component.scss']
})
export class PointHistoryV2Component extends AuthenticationOverride implements OnInit {

	loading: boolean;
    pointHistory: any;

    constructor(private pointsService: PointsService,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loading = true;
        this.pointsService.getPoints().subscribe(res => {
            this.pointHistory = res;
            this.loading = false;
        });
    }

}
