import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-video-end-card',
  templateUrl: './video-end-card.component.html',
  styleUrls: ['./video-end-card.component.scss']
})
export class VideoEndCardComponent implements OnInit {

  @Input() endOfVideoTime;
  @Input() cancelled = false;

  @Output() upNextCancelled = new EventEmitter<void>()
  @Output() playNextVideo = new EventEmitter<void>()
  @Output() watchAgain = new EventEmitter<void>()

  endOfVideoImage = '';
  endOfVideoTitle = '';
  endOfVideoDescription = '';

  constructor() { }

  ngOnInit(): void {
    this.endOfVideoImage = localStorage.getItem('nextVideoImage');
    this.endOfVideoTitle = localStorage.getItem('nextVideoTitle');
    this.endOfVideoDescription = localStorage.getItem('nextVideoDescription');
  }
}
