import { Component, OnInit } from '@angular/core';
import { MessagingService } from 'src/app/services/messaging.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-push-notification',
    templateUrl: './push-notification.component.html',
    styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {

    data: any = null;
    notification: any = null;

    interval;

    constructor(
        private messagingService: MessagingService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.messagingService.currentMessage.subscribe(payload => {
            if (payload) {
                this.notification = payload.notification;
                this.data = payload.data;

                this.interval = setInterval(() => {
                    this.notification = null;
                    this.data = null;
                    clearInterval(this.interval);
                }, 7500);
            }
        });
    }

    navigateToVideo() {
        if (this.data.MediaId == 'live') {
            this.router.navigate(['/live']);
        } else {
            this.router.navigate(['/video', this.data.MediaId]);
        }
        this.notification = null;
        this.data = null;
    }
}
