import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-smush-brethren',
    templateUrl: './smush-brethren.component.html',
    styleUrls: ['./smush-brethren.component.css']
})
export class SmushBrethrenComponent implements OnInit {

    smush: any;
    showModal: boolean = false;
    videoUrl: string = 'https://cdn.watchcorridor.com/videos/clear/smush/manifest.mpd';

    section1 = {
        text: [
            {text: 'our very own version!', type: 'title'},
            {
                text: 'That’s right.  We made “Smush Brethren” with every character from the Corridor Crew.  Be advised: if you want to play Smash Bros, you should play Smash Bros.  This is NOT that.',
                type: 'text'
            }
        ],
        images: [
            {url: 'https://cdn.watchcorridor.com/smush/images/banner-1.jpg', orientation: 'vertical'},
            {url: 'https://cdn.watchcorridor.com/smush/images/banner-1.jpg', orientation: 'horizontal'},
        ]
    };

    section2 = {
        text: [
            {text: 'EVEN JAKE LIKES IT!', type: 'title'},
            {
                text: 'Bossman getting you down?  Don’t worry!  Enjoy a round of Smush and you’ll be back at it in no time, with a fresh perspective on life not even the saltiest boss can touch.',
                type: 'text'
            }
        ],
        images: [
            {url: 'https://cdn.watchcorridor.com/smush/images/banner-2.jpg', orientation: 'vertical'},
            {url: 'https://cdn.watchcorridor.com/smush/images/banner-2.jpg', orientation: 'horizontal'},
        ]
    };

    section3 = {
        text: [
            {text: 'MADE BY PETER HIMSELF', type: 'title'},
            {
                text: 'That’s right, our shining boy, Peter France.  This was all him.   When not being bullied by Beeple, he made this amazing game!  Good job, Peter.  We love you. ',
                type: 'text'
            }
        ],
        images: [
            {url: 'https://cdn.watchcorridor.com/smush/images/banner-3.jpg', orientation: 'vertical'},
            {url: 'https://cdn.watchcorridor.com/smush/images/banner-3.jpg', orientation: 'horizontal'},
        ]
    };


    constructor(
        private account: AccountService,
        private router: Router) {
    }

    ngOnInit(): void {
    }

    isAuthed() {
        return this.account.isAuthenticated();
    }

    openVideo() {
        this.showModal = true;
    }

    closeVideo() {
        this.showModal = false;
    }

    getSmush() {
        this.router.navigate(['smush-download']);
    }

    register() {
        this.router.navigate(['signup']);
    }
}
