<div class="main main-full config-background-2">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12">
                <div class="entry-content">
                    <h2 class="form-title">Update Successful!</h2>
                    <p>Congratulations! Your card details have been updated successfully!</p>
                    <a routerLink="/">Click here to start watching!</a>
                </div>
            </div>
        </div>
    </div>
</div>
