<div class="under-construction-container">
    <div class="image-container pb-4">
        <img width="600" src="https://assets.fairway.tv/logo_long.svg" alt="Under Construction Image"
             class="centered-image">
    </div>
    <h1 class="astro-font pb-4">COMING SOON</h1>
    <form [formGroup]="entryForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
        <div class="form-group">Entry Code:
            <input class="entry-code" type="text" formControlName="entryCode" required>
        </div>
        <button type="submit" class="btn btn-primary btn-slim">Enter</button>
    </form>
</div>
