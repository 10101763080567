<div class="main main-full mocap-bg-grad">
    <div class="container account-management wide-content">
        <h2>{{ "account-email-preferences" | translate }}</h2>
        <div class="row border-top py-3 my-3">
            <div class="col-12">
                <app-email-preferences></app-email-preferences>
            </div>
        </div>
    </div>
</div>
