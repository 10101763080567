<div class="subscription-row">
    <div *ngIf="!subscription || loading" class="text-center">
        <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="d-flex justify-content-between" *ngIf="subscription && !loading">
        <div>
            <div>
                <div class="tt" *ngIf="subscription.status.toLowerCase() === 'unpaid'">
                    <img src="assets/subscriptions/unpaid.svg" height="28px" />
                    <span class="tttext">Unpaid</span>
                </div>
                <div class="tt" *ngIf="subscription.status.toLowerCase() === 'cancelled'">
                    <img src="assets/subscriptions/cancelled.svg" height="28px" />
                    <span class="tttext">Cancelled</span>
                </div>
                <div class="tt" *ngIf="subscription.status.toLowerCase() === 'active'">
                    <img src="assets/subscriptions/active.svg" height="28px" />
                    <span class="tttext">Active</span>
                </div>

                <b class="mx-2">Subscription</b> {{subscription.subscriptionId}}
            </div>
            <div>
                <div class="mt-2">Bills {{subscription.renewPeriod}}</div>
                <div *ngIf="subscription.status.toLowerCase() !== 'cancelled' && subscription.type !== 'Patreon'">
                    Next payment due {{subscription.renewalDate | date}}
                </div>
                <div *ngIf="subscription.status.toLowerCase() !== 'cancelled' && subscription.type === 'Patreon'">
                    Subscription ends {{subscription.renewalDate | date}}
                </div>
                <div *ngIf="subscription.status.toLowerCase() === 'cancelled' && subscription.canResume === true">
                    Subscription ends {{subscription.endDate | date}}
                </div>
                <div *ngIf="subscription.status.toLowerCase() === 'cancelled' && subscription.canResume === false">
                    Subscription ended {{subscription.endDate | date}}
                </div>
            </div>
        </div>
        <div>
            <img *ngIf="subscription.type === 'Patreon'" src="assets/subscriptions/patreon.svg" height="28px" />
            <img *ngIf="subscription.type === 'Apple'" src="assets/subscriptions/apple.svg" height="28px" />
            <img *ngIf="subscription.type === 'Stripe'" src="assets/subscriptions/stripe.svg" height="28px" />
            <img *ngIf="subscription.type === 'PayPal'" src="assets/subscriptions/paypal.svg" height="28px" />
            <div class="tt pointer ml-2" (click)="refresh()">
                <img src="assets/subscriptions/refresh.svg" height="28px" alt="Refresh Data" />
                <span class="tttext">Refresh</span>
            </div>
        </div>
    </div>
    <div class="mt-2">
        <ng-container *ngIf="subscription.canResume">
            <button
                    *ngIf="(subscription.type === 'Stripe' || subscription.type === 'PayPal') && subscription.status.toLowerCase() !== 'cancelled'"
                    class="btn btn-slim btn-outline-danger mr-3" (click)="cancel()">Cancel</button>
            <button
                    *ngIf="(subscription.type === 'Stripe' || subscription.type === 'PayPal') && subscription.status.toLowerCase() === 'cancelled'"
                    class="btn btn-slim btn-outline-primary mr-3" (click)="resume()">Resume</button>
            <div *ngIf="error" class="text-danger">{{error}}</div>
        </ng-container>
        <ng-container *ngIf="subscription.type ==='Patreon' && showConvert">
            <button class="btn btn-slim btn-outline-primary mr-3" [routerLink]="['/payment']">{{'convert-to-other-payment' | translate}}</button>
        </ng-container>
    </div>
</div>
