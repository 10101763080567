import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
    selector: 'app-cancel-membership',
    templateUrl: './cancel-membership.component.html',
    styleUrls: ['./cancel-membership.component.scss']
})
export class CancelMembershipComponent extends AuthenticationOverride implements OnInit {

    error: String;
    loading: boolean;
    appleSubscriptionType: boolean;
    patreonSubscriptionType: boolean;
    subscriptionEndDate: string;

    constructor(
        private userInfo: UserInfoService,
        router: Router,
        route: ActivatedRoute,
        storage: StorageManagerService,
        account: AccountService) {
        super(route, storage, router, account);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.loading = true;
        this.userInfo.getUserInfoQuery('subscription').subscribe(res => {
            this.subscriptionEndDate = res.subscriptionEndDate;
            if (res.subscriptionType === 'AppleIAP-Production' || res.subscriptionType === 'Apple') {
                this.appleSubscriptionType = true;
            }
            if (res.subscriptionType === 'Patreon') {
                this.patreonSubscriptionType = true;
            }
            this.loading = false;
        });
    }


}
