<div class="main-full">
    <app-video-player [mediaId]="mediaId" [autoPlay]="autoPlay" (safariHls)="safariHls = $event" (videoLoaded)="videoLoaded = $event"></app-video-player>

    <div class="video-page-container video-page-lower">
        <div class="w-100">
            <div class="mb-3">
                <app-video-description
                        [mediaId]="mediaId"
                        [videoLoaded]="videoLoaded"
                        [safariHls]="safariHls"
                        (reportClicked)="showReportReasons = true"
                ></app-video-description>
            </div>
            <app-video-comments [mediaId]="mediaId"></app-video-comments>
        </div>
        <div class="recommended-list">
            <app-recommended-list [mediaId]="mediaId"></app-recommended-list>
        </div>

    </div>
</div>

<app-modal-media-report *ngIf="showReportReasons" (close)="showReportReasons = false" [mediaId]="mediaId">
</app-modal-media-report>
