<div class="main-full container-fluid py-5 text-center">
    <div>
        <h2>404 Not Found</h2>
        <p>Let us know what you were looking for:</p>
        <a routerLink="/home/feedback" [queryParams]="{ref:'menu'}"
            class="btn btn-slim btn-outline-light btn-spacing my-2" type="submit">
            Contact Support
        </a>
    </div>
</div>
