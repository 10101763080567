import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SubscriptionLineV3 } from '../../../models/subscription-line-v3';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { AccountService } from '../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { EnvironmentService } from '../../../services/environment.service';

declare function Stripe(pk): any

@Component({
	selector: 'app-payment-method-v2',
	templateUrl: './payment-method-v2.component.html',
	styleUrls: ['./payment-method-v2.component.scss']
})
export class PaymentMethodV2Component extends AuthenticationOverride implements OnInit {

	cardsLoading: boolean;
	loading: boolean;

	cards: any;
	subscriptions: SubscriptionLineV3[] = [];

	subscriptionId: string;
	type: string;
	status: string;
	renewalDate: string | null;
	renewPeriod: string;
	endDate: string | null;
	canResume: boolean;

	constructor(private subscriptionService: SubscriptionService,
                private environmentService: EnvironmentService,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router) {
        super(route, storage, router, account);
	}

	ngOnInit(): void {
        super.ngOnInit();
		this.loading = true;
		this.subscriptionService.getCards().subscribe(res => {
			this.cards = res;
			this.loading = false;
		});
		this.subscriptionService.getSubscriptions().subscribe(res => {
			this.subscriptions = res;
			this.loading = false;
		});
	}

	addCard() {
		this.subscriptionService.getCardChangeSession().subscribe(res => {
            this.subscriptionService.getCardChangeSession().subscribe(res => {
                const stripe = Stripe(this.environmentService.stripePk);

                stripe.redirectToCheckout({
                    sessionId: res.id
                }).then(function (result) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                });
            });
		});
	}

	onCardRemoved(removed: boolean) {
		this.cardsLoading = true;
		this.subscriptionService.getCards().subscribe(res => {
			this.cards = res;
			this.cardsLoading = false;
		});
	}

	showConvert() {
		return !this.subscriptions.some(x => x.type !== 'Patreon');
	}
}
