import { Component } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {

    constructor(
        public userInfo: UserInfoService,
        app: AppComponent,
        storageManager: StorageManagerService) {
        app.stopLoading();
        storageManager.removeString('recommended-list');
    }

}
