import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-paypal-button-v2',
    templateUrl: './paypal-button-v2.component.html',
    styleUrls: ['./paypal-button-v2.component.scss']
})
export class PaypalButtonV2Component implements OnInit, OnDestroy {

    @Input() disableBtn: boolean = false;


    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

}
