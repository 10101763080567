<div class="main main-full main-responsive">
	<app-title [title]="'account-change-email' | translate"></app-title>
	<div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
		<span class="sr-only">{{ "loading" | translate }}</span>
	</div>
	<form id="signup-form" class="signup-form needs-validation" (ngSubmit)="submit()" #f="ngForm" novalidate>
		<div class="form-group">
			<label for="email">{{ "email" | translate }}</label>
			<input [disabled]="loading" type="text" name="email" [(ngModel)]="viewModel.email" autoFocus
				class="form-control" placeholder="email@example.com" required>
			<p class="text-danger" *ngIf="error">{{error}}</p>
		</div>

		<div class="mb-3" *ngIf="config.remoteConfig.login_version == 1">
			<small>{{ "account-change-email-success" | translate }}</small>
		</div>

		<div class="form-group">
			<button type="submit" class="btn btn-outline-primary btn-slim">{{ "account-change-email" | translate }}</button>
		</div>
	</form>

    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>
