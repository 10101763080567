<div class="thumb" #thumb>
    <img *ngIf="newVideo && !watched" class="thumb-new" src="{{config.remoteConfig.new_badge}}" alt="New Video" />
    <img *ngIf="watched" class="thumb-watched" src="{{config.remoteConfig.watched}}" alt="New Video" />
    <div class="thumb-container p-1 p-md-2">
        <a class="thumb-link" [routerLink]="['/video', media.mediaId]" [queryParams]="{seasonId:seasonId}">
            <picture (deferLoad)="load()">
                <source *ngIf="loadImages && media && media.image" srcset="{{media.image + '?w=' + imageWidth}}">
                <img class="thumb-image h-100 w-100" src="{{config.remoteConfig.loading_packshot}}"
                    alt="Loading Image" />
            </picture>

            <div class="media-duration" *ngIf="media.duration && showDuration"
                [ngClass]="{ 'media-padding': progress > 0 }">
                {{media.duration}}
            </div>

            <div class="play-button" *ngIf="showPlayButton">
                <img class="thumb-icon" src="assets/thumb-play.svg" alt="Play video" />
            </div>
        </a>
        <div class="media-progress" *ngIf="progress > 0">
            <ngb-progressbar class="watch_progress_colour" type="primary" [value]="progress" [max]="100" height="4px">
            </ngb-progressbar>
        </div>
    </div>

    <div class="thumb-hover p-1 p-md-2">
        <a class="a-route" [routerLink]="['/video', media.mediaId]" [queryParams]="{seasonId:seasonId}">
            <picture (deferLoad)="load()">
                <source *ngIf="loadImages && media && media.previewImage" srcset="{{media.previewImage}}"
                    type="image/webp">
                <source *ngIf="loadImages && media && media.image" srcset="{{media.image + '?w=' + imageWidth}}">
                <img class="thumb-image h-100 w-100" src="{{config.remoteConfig.loading_packshot}}"
                    alt="{{media.title}} Image" />
            </picture>
        </a>
    </div>
</div>
