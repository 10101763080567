import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VideoService } from '../../services/video.service';
import { Subject, takeUntil } from 'rxjs';
import { AppComponent } from '../../app.component';
import { LocalisationService } from '../../services/localisation.service';

@Component({
    selector: 'app-modal-media-report',
    templateUrl: './modal-media-report.component.html',
    styleUrls: ['./modal-media-report.component.scss']
})
export class ModalMediaReportComponent implements OnInit, OnDestroy {

    @Input() mediaId: number = 0;

    @Output() close = new EventEmitter<void>();

    reasons: { [key: string]: string; };

    get keys() {
        return Object.keys(this.reasons);
    }

    destroy$ = new Subject<void>();
    selectedReason: string;
    loading: boolean = true;

    constructor(private videoService: VideoService,
                private app: AppComponent,
                private translateService: LocalisationService) {
    }

    ngOnInit(): void {
        this.videoService.getReportReasons(this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: res => {
                    this.reasons = res;
                    this.loading = false;
                },
                error: () => this.loading = false,
                complete: () => this.loading = false
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    cancelEvent(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    cancel() {
        this.close.emit();
    }

    submitReport() {
        this.loading = true;
        this.videoService.reportMedia(this.mediaId, {reason: this.selectedReason})
            .subscribe({
                next: x => {
                    this.loading = false;
                    this.close.emit();
                    this.app.showSimpleAlert({
                        title: this.translateService.getTranslation('report-video-playback'),
                        body: `${this.translateService.getTranslation('report-video-playback-success')} ${this.translateService.getTranslation(`video-report-${this.selectedReason}`)}`
                    });
                },
                error: error => {
                    this.loading = false;
                },
                complete: () => {
                    this.loading = false;
                }
            });
    }
}
