import { Component, Input, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
    selector: 'app-simple-alert',
    templateUrl: './simple-alert.component.html',
    styleUrls: ['./simple-alert.component.css']
})
export class SimpleAlertComponent implements OnInit {

    interval;
    @Input() timeLeft: number = 7000;

    @Input() simpleAlert: any;

    constructor(private app: AppComponent) {
    }

    ngOnInit(): void {
        this.show();
    }

    show() {
        this.timeLeft = 7000;
        this.interval = setInterval(() => {
            if (this.timeLeft <= 0) {
                this.close();
                clearInterval(this.interval);
            }
            this.timeLeft -= 1000;
        }, 1000);

    }

    close() {
        this.app.closeSimpleAlert();
        this.simpleAlert = null;
    }
}
