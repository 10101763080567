import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { RemoteConfigService } from '../../services/remote-config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    year = new Date().getFullYear();

    constructor(private app: AppComponent,
                public config: RemoteConfigService) {
    }

    ngOnInit() {
    }

    version() {
        return this.app.version;
    }
}
