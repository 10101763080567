import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { Utils } from 'src/app/services/utils.service';
import { Comment } from '../../models';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from 'src/app/services/environment.service';
import { RemoteConfigService } from 'src/app/services/remote-config.service';
import { SocketService } from 'src/app/services/socket.service';
import { UserInfoService } from '../../services/user-info.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AccountType } from '../../models/enums/account-type.enum';

@Component({
    selector: 'app-comment-read',
    templateUrl: './comment-read.component.html',
    styleUrls: ['./comment-read.component.scss']
})
export class CommentReadComponent implements OnInit, OnDestroy {

    deleted: boolean = false;

    likeLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    destroy$: Subject<void> = new Subject<void>();

    @Input() comment: Comment;
    @Input() mobileTopComment: boolean = false;
    @Input() mediaId: number;
    @Input() subComment: boolean;
    @Input() originalCommentId: string;

    @Output() replyClicked: EventEmitter<Comment> = new EventEmitter<Comment>();

    protected readonly AccountType = AccountType;

    get replies(): Comment[] {
        if (this.config.remoteConfig?.comments_version === '1') {
            return this.comment.replies;
        } else {
            return this.comment.comments;
        }
    }

    constructor(
        public chatService: ChatService,
        private socketService: SocketService,
        public config: RemoteConfigService,
        private userInfoService: UserInfoService,
        public env: EnvironmentService) {
    }

    ngOnInit() {
        this.socketService.onMessage('comment-' + this.mediaId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            const parsed = JSON.parse(data);

            if (parsed.commentId !== this.comment.id) {
                return;
            }

            if (parsed.type === 'add-like') {
                if (this.comment.liked && parsed.commentUserId == this.userInfoService.userId) {
                    this.likeLoading$.next(false);
                    return;
                }
                this.comment.likes++;
                this.comment.liked = parsed.commentUserId == this.userInfoService.userId
            } else if (parsed.type === 'remove-like') {
                if (!this.comment.liked && parsed.commentUserId == this.userInfoService.userId) {
                    this.likeLoading$.next(false);
                    return;
                }
                this.comment.likes--;
                if (parsed.commentUserId == this.userInfoService.userId) {
                    this.comment.liked = false;
                }
            }

            if (parsed.commentUserId == this.userInfoService.userId) {
                this.likeLoading$.next(false);
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    showBadges() {
        return this.config.remoteConfig.badges_enabled;
    }

    deleteChat() {
        if (this.config.remoteConfig.comments_version === '1') {
            if (this.originalCommentId) {
                this.chatService.deleteMessage(this.mediaId.toString(), this.originalCommentId, this.comment.id).subscribe(
                    () => {
                        this.deleted = true;
                    }
                );
            } else {
                this.chatService.deleteMessage(this.mediaId.toString(), this.comment.id, null).subscribe(
                    () => {
                        this.deleted = true;
                    }
                );
            }
        }
        else {
            this.socketService.deleteMessage('comment', this.mediaId, +this.comment.id);
        }
    }

    reportChat() {
        if (confirm('Are you sure you want to report this comment?')) {
            this.chatService.reportMessage(this.mediaId.toString(), this.comment.id).subscribe(
                () => {
                    this.deleted = true;
                }
            );
        }
    }

    replyChat(comment: Comment) {
        this.replyClicked.emit(comment);
        if (!this.subComment) {
            this.chatService.replyingComment = comment;
        }
    }

    closeReply() {
        this.chatService.replyingComment = null;
    }

    emoji(comment: Comment) {
        this.replyClicked.emit(comment);
        if (!this.subComment) {
            this.chatService.replyingComment = comment;
        }
        this.chatService.emoji = true;
    }

    ownComment() {
        if (this.userInfoService.uid) {
            return (this.userInfoService.uid === this.comment.uid || this.userInfoService.hash === this.comment.hash);
        }
        return false;
    }

    getTime() {
        return Utils.getTime(this.comment.dateCreated ?? this.comment.created);
    }

    toggleLike() {
        this.likeLoading$.next(true);
        if (this.comment.liked) {
            this.socketService.removeLike(+this.comment.id, this.mediaId);
            return;
        }

        this.socketService.addLike(+this.comment.id, this.mediaId)
    }
}
