<div class="pb-2">
    <div class="section">
        <img class="w-100" src="https://cdn.watchcorridor.com/smush/images/top-banner2.jpg" />
        <div class="text-center text-black bg-white pb-5">
            <img class="smush-logo mb-4" src="https://cdn.watchcorridor.com/smush/images/logo3.png" alt="SMUSH" />
            <h5 class="w-25 mx-auto mb-4">Looking to give it a shot? Good luck trying to figure out how to play it. No
                promises!</h5>

            <div *ngIf="isAuthed()">
                <button class="btn btn-primary mr-4" (click)="getSmush()"><b>DOWNLOAD THE GAME</b></button>

                <a [routerLink]="['/video', 1895]" class="btn btn-outline-secondary">
                    <b>WATCH THE MAKING OF</b>
                </a>
            </div>

            <div *ngIf="!isAuthed()">
                <button class="btn btn-primary" (click)="register()"><b>REGISTER TO DOWNLOAD</b></button>
            </div>
        </div>
    </div>
    <div class="section">
        <img class="w-100" src="https://cdn.watchcorridor.com/smush/images/video-bg.jpg" />
        <img *ngIf="videoUrl" class="play-greenlight pointer" (click)="openVideo()"
            src="https://cdn.watchcorridor.com/assets/play.svg" alt="Play Smush Video" />
    </div>
    <app-section-left-text [section]="section1"></app-section-left-text>
    <app-section-right-text [section]="section2"></app-section-right-text>
    <app-section-left-text [section]="section3"></app-section-left-text>
</div>
<app-modal-simple-shaka *ngIf="showModal && videoUrl" (closeEvent)="closeVideo()" [url]="videoUrl">
</app-modal-simple-shaka>