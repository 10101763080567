<div class="thumb-container">
    <img *ngIf="newVideo && !watched" class="thumb-new" src="{{config.remoteConfig.new_badge}}" alt="New Video" />
    <img *ngIf="watched" class="thumb-watched" src="{{config.remoteConfig.watched}}" alt="New Video" />
    <div class="thumb-image-container">

        <a class="thumb-image">
            <a class="thumb-link" [routerLink]="['/video', media.mediaId]">
                <picture (deferLoad)="load()">
                    <source *ngIf="loadImages && this.media && this.media.image" srcset="{{this.media.image + '?w=400'}}">
                    <img class="thumb-image h-100 w-100" src="{{config.remoteConfig.loading_packshot}}"
                         alt="Loading Image"/>
                </picture>
            </a>
        </a>

        <a class="thumb-hover-image" [routerLink]="['/video', media.mediaId]">
            <picture (deferLoad)="load()">
                <source *ngIf="loadImages && this.media && this.media.previewImage" srcset="{{this.media.previewImage}}"
                        type="image/webp">
                <source *ngIf="loadImages && this.media && this.media.image" srcset="{{this.media.image + '?w=400'}}">
                <img class="thumb-image h-100 w-100" src="{{config.remoteConfig.loading_packshot}}"
                     alt="{{media.title}} Image"/>
            </picture>
        </a>

    </div>

    <ngb-progressbar type="primary" [value]="progress" [max]="100" height="2px" class="thumb-open-progress-bar watch_progress_colour"></ngb-progressbar>

    <div class="thumb-lower-container">
        <div class="thumb-text-container">
            <div class="thumb-text-badge mr-2">
                <img class="channelBadge" *ngIf="media.channelBadgeUrl"
                     src="{{media.channelBadgeUrl}}" alt="Channel Badge"/> <!-- TODO: ACCESIBILITY -->
            </div>
            <div class="thumb-text">
                {{media.title}}
            </div>
        </div>
        <div class="thumb-duration" *ngIf="media.duration">
            {{media.duration}}
        </div>
    </div>

</div>
