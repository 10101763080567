<div class="title-and-sort">
    <h2 class="page-title">Creators</h2>
    <button (click)="toggleSort()">Sort<img src="assets/chevron-down.svg" [class.flipped]="!(reverse$ | async)"></button>
</div>
<div class="main-full creator-grid">
    <ng-container *ngIf="creatorTeams$ | async as creatorTeams">
        <div *ngFor="let team of creatorTeams" class="creator-grid-item">

            <a class="thumbnail-container" [routerLink]="['/creators', team.id]">
                <img class="thumbnail-image" [src]="team.image">
            </a>


            <div class="creator-title">
                <div class="creator-display-name">{{team.displayName}}</div>
            </div>

            <div class="creator-description">
                {{team.shortDescription}}
            </div>

            <div class="creator-follow">
                <button class="proto-button" (click)="viewProfile(team.id)">View Profile</button>
                <app-follow-btn [creatorTeamId]="team.id" style="font-size: 20px"></app-follow-btn>
            </div>

        </div>
    </ng-container>
</div>
