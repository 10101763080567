import { Component, OnInit } from '@angular/core';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { UserPreferencesService } from '../../../services/user-preferences.service';
import { BehaviorSubject, tap } from 'rxjs';
import { UserPreferenceDefaults, UserPreferenceKey } from '../../../models/enums/user-preferences.enum';
import { AppComponent } from '../../../app.component';

@Component({
    selector: 'app-video-quality',
    templateUrl: './video-quality.component.html',
    styleUrls: ['./video-quality.component.scss']
})
export class VideoQualityComponent extends AuthenticationOverride implements OnInit {

    readonly videoQualityOptionsMap: {quality: string, bitrate: number}[] = [
        {quality: '180p', bitrate: 500000},
        {quality: '360p', bitrate: 1000000},
        {quality: '540p', bitrate: 2000000},
        {quality: '720p', bitrate: 4000000},
        {quality: '1080p', bitrate: 6000000},
        {quality: '1440p', bitrate: 8400000},
        {quality: '2160p', bitrate: 12000000},
    ];

    selections = {
        mobileData: { key: UserPreferenceKey.VIDEOQUALITY_MobileData, value: UserPreferenceDefaults.VIDEOQUALITY_MobileData },
        mobileWifi: { key: UserPreferenceKey.VIDEOQUALITY_MobileWifi, value: UserPreferenceDefaults.VIDEOQUALITY_MobileWifi },
        other: { key: UserPreferenceKey.VIDEOQUALITY_Other, value: UserPreferenceDefaults.VIDEOQUALITY_Other }
    }

    saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    preferences$ = this.userPreferencesService.get().pipe(tap(response => {
        const data = response.find(x => x.key === this.selections.mobileData.key)?.value
        this.selections.mobileData.value = data !== undefined ? +data : this.selections.mobileData.value;

        const wifi = response.find(x => x.key === this.selections.mobileWifi.key)?.value
        this.selections.mobileWifi.value = wifi !== undefined ? +wifi : this.selections.mobileWifi.value;

        const other = response.find(x => x.key === this.selections.other.key)?.value
        this.selections.other.value = other !== undefined ? +other : this.selections.other.value;
    }));

    constructor(router: Router,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                private userPreferencesService: UserPreferencesService,
                private app: AppComponent) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    save() {
        this.saving$.next(true);
        this.userPreferencesService.setMulti([this.selections.mobileData, this.selections.mobileWifi, this.selections.other])
            .subscribe(() => {
                this.saving$.next(false);
                this.app.showSimpleAlert({
                    title: 'Success!',
                    body: 'Video quality settings saved!'
                });
            });
    }

}
