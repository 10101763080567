
export class MediaMetadata {
    constructor(
        public mediaId: string,
        public title: string,
        public description: string,
        public shortDescription: string,
        public datePublished: Date,
        public mediaLength: string,
        public rating: string) {
    }
}