<div class="main main-full main-responsive">
    <p class="mb-0">A PayPal window should have opened, please make your payment there.</p>
    <div class="loader-container">
        <div class="div-loader"></div>
    </div>
    <div class="mb-3">
        Waiting for a response from PayPal
    </div>

    <p>Do not close this page. You will be redirected automatically.</p>

    <p *ngIf="paypalUrl">
        If the window did not open, please use this URL:<br>
        <a [href]="paypalUrl" target="_blank">{{paypalUrl}}</a>
    </p>
</div>
