import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-show-thumb',
    templateUrl: './show-thumb.component.html',
    styleUrls: ['./show-thumb.component.scss']
})
export class ShowThumbComponent implements OnInit {

    @Input() item: any;
    routerLink: string[];

    constructor() {
    }

    ngOnInit(): void {
        this.routerLink = this.getRouterLink();
    }

    getRouterLink(): string[] {
        if (this.item.slug === 'Latest Videos') {
            return ['/latest-videos'];
        }

        if (this.item.seasonId) {
            return ['/season', this.item.seasonId];
        }
        return ['/shows', this.item.slug];
    }

}
