import { Component, OnInit } from '@angular/core';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { AccountService } from '../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';

@Component({
    selector: 'app-discount-code',
    templateUrl: './discount-code.component.html',
    styleUrls: ['./discount-code.component.scss']
})
export class DiscountCodeComponent extends AuthenticationOverride implements OnInit {

    loading: boolean;
    promo: any;

    constructor(
        private promoCodeService: PromoCodeService,
        account: AccountService,
        route: ActivatedRoute,
        storage: StorageManagerService,
        router: Router) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loading = true;
        this.promoCodeService.getPromo().subscribe(res => {
            this.promo = res;
            this.loading = false;
        });
    }

}
