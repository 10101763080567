import { Component, Input, OnInit } from '@angular/core';
import { MediaSummary } from '../../models';

@Component({
    selector: 'app-hero-banner-static-image',
    templateUrl: './hero-banner-static-image.component.html',
    styleUrls: ['./hero-banner-static-image.component.scss']
})
export class HeroBannerStaticImageComponent implements OnInit {

    @Input() hero: MediaSummary;
    @Input() roundedBorder: boolean = false;

    heroMobileImage: string;
    heroWebImage: string;

    constructor() {
    }

    ngOnInit(): void {
        this.heroMobileImage = this.hero.images.find(x => x.type === 'hero-mobile')?.url;
        this.heroWebImage = this.hero.images.find(x => x.type === 'hero-web')?.url;
    }

}
