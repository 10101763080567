export enum SubtitleOption {
    Font = 'Font',
    Shadow = 'Shadow',
    Background = 'Background',
    Window = 'Window',
    TextSize = 'TextSize',
    Colour = 'Colour',
    ShadowColour = 'ShadowColour'
}

export enum SubtitleOptionFont {
    Typewriter = 'Typewriter',
    Print = 'Print',
    Console = 'Console',
    Block = 'Block',
    Casual = 'Casual',
    Cursive = 'Cursive',
    SmallCaps = 'SmallCaps'
}

export enum SubtitleOptionTextSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export enum SubtitleOptionShadow {
    None = 'None',
    Raised = 'Raised',
    Depressed = 'Depressed',
    Uniform = 'Uniform',
    DropShadow = 'DropShadow'
}

export enum SubtitleOptionColour {
    Transparent = 'Transparent',
    White = 'White',
    Black = 'Black',
    Red = 'Red',
    Green = 'Green',
    Blue = 'Blue',
    Yellow = 'Yellow',
    Magenta = 'Magenta',
    LightBlue = 'LightBlue'
}

export const SubtitleOptionColourMap = new Map<SubtitleOptionColour, string>([
    [SubtitleOptionColour.Transparent, 'transparent'],
    [SubtitleOptionColour.White, 'white'],
    [SubtitleOptionColour.Black, 'black'],
    [SubtitleOptionColour.Red, 'red'],
    [SubtitleOptionColour.Green, 'green'],
    [SubtitleOptionColour.Blue, 'blue'],
    [SubtitleOptionColour.Yellow, 'yellow'],
    [SubtitleOptionColour.Magenta, 'magenta'],
    [SubtitleOptionColour.LightBlue, 'lightblue'],
]);

export const SubtitleOptionShadowMap = new Map<SubtitleOptionShadow, string>([
    [SubtitleOptionShadow.None, 'no-shadow'],
    [SubtitleOptionShadow.Raised, 'raised'],
    [SubtitleOptionShadow.Depressed, 'depressed'],
    [SubtitleOptionShadow.Uniform, 'uniform'],
    [SubtitleOptionShadow.DropShadow, 'drop-shadow'],
]);

export const SubtitleOptionFontMap = new Map<SubtitleOptionFont, string>([
    [SubtitleOptionFont.Typewriter, 'typewriter'],
    [SubtitleOptionFont.Print, 'print'],
    [SubtitleOptionFont.Console, 'console'],
    [SubtitleOptionFont.Block, 'block'],
    [SubtitleOptionFont.Casual, 'casual'],
    [SubtitleOptionFont.Cursive, 'cursive'],
    [SubtitleOptionFont.SmallCaps, 'small-caps'],
]);

export const SubtitleOptionTextSizeMap = new Map<SubtitleOptionTextSize, string>([
    [SubtitleOptionTextSize.Small, 'font-size-small'],
    [SubtitleOptionTextSize.Medium, 'font-size-medium'],
    [SubtitleOptionTextSize.Large, 'font-size-large'],
]);

export interface SubtitleOptions {
    key: SubtitleOption,
    value: string
}
