import { Component, OnDestroy, OnInit } from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import {LiveService} from 'src/app/services/live.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit, OnDestroy {

    url: string;
    livestreamId: number

    closeSub$: Subject<void> = new Subject<void>();

    constructor(private liveService: LiveService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        const streamId = this.route.snapshot.paramMap.get('id');
        if (streamId) {
            this.livestreamId = +streamId;
        }

        this.liveService.status$.pipe(takeUntil(this.closeSub$)).subscribe(status => {
            if (!status) {
                return;
            }

            if (status.streams && status.streams.length > 0) {
                this.url = status.streams.find(x => x.livestreamId === this.livestreamId)?.url;
                if (!this.url) {
                    this.url = status.streams[0].url;
                }
                this.closeSub$.next();
            }
        });
    }

    ngOnDestroy() {
        this.closeSub$.next();
        this.closeSub$.complete();
    }

}
