import { Component, Input, OnInit } from '@angular/core';
import { PlaylistService } from 'src/app/services/playlist.service';
import { ChannelsService } from 'src/app/services/channels.service';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-user-playlist',
    templateUrl: './user-playlist.component.html',
    styleUrls: ['./user-playlist.component.css']
})
export class UserPlaylistComponent implements OnInit {

    mediaList: any[] = null;
    mediaList2: any[] = [{}, {}, {}, {}, {}, {}, {}];

    loaded: boolean = false;

    @Input() type: string;

    constructor(
        private channelService: ChannelsService,
        private playlistService: PlaylistService,
        private account: AccountService) {
    }

    ngOnInit(): void {
        this.account.updateToken().then(
            () => {
                if (!this.loaded && this.type) {
                    this.loaded = true;

                    this.channelService.userChannels().subscribe(res => {
                        if (res) {
                            let list = res.find(f => f.playlistId === this.type)?.media;

                            if (list && list.length > 0) {
                                this.playlistService.mediaList = list;
                                if (this.mediaList) {
                                    this.mediaList2 = list;
                                    this.mediaList = null;
                                } else {
                                    this.mediaList = list;
                                    this.mediaList2 = null;
                                }
                            } else {
                                this.playlistService.mediaList = [];
                            }

                            if (!list || list.length === 0) {
                                this.mediaList = [];
                                this.mediaList2 = null;
                                this.mediaList.push({
                                    title: 'Add a video to your watchlist!'
                                });
                            }
                        }
                    });

                    /*this.playlistService.getPlaylist(this.type.replace("playlist-", "")).subscribe(res => {
                      this.mediaList = res
                      this.mediaList2 = null
                      this.playlistService.mediaList = res

                      if (this.mediaList.length === 0) {
                        this.mediaList.push({
                          title: "Add a video to your watchlist!"
                        })
                      }
                    })*/
                }
            },
            () => {
            });
    }
}
