import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { SmartTVCode, SmartTVDevice } from '../models';

@Injectable({
    providedIn: 'root'
})
export class SmartTvService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) { }

    getLoginCode(): Observable<SmartTVCode> {
        return this.http
            .get<SmartTVCode>(this.envService.userHost + '/v1/smartlogin')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('SmartTvService::Error::GET', error);
                return throwError(error);
            }))
    }

    getLoginCodeResponse(code: string): Observable<SmartTVDevice> {
        return this.http
            .get<SmartTVDevice>(this.envService.userHost + '/v1/smartlogin/' + code)
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('SmartTvService::Error::GET', error);
                return throwError(error);
            }))
    }

    confirm(hash: string): Observable<any> {
        return this.http
            .patch<any>(this.envService.userHost + '/v1/smartlogin/' + hash, {})
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('SmartTvService::Error::GET', error);
                return throwError(error);
            }))
    }

    reject(hash: string): Observable<any> {
        return this.http
            .delete<any>(this.envService.userHost + '/v1/smartlogin/' + hash)
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('SmartTvService::Error::GET', error);
                return throwError(error);
            }))
    }

    sendCodeV2(code: string): Observable<HttpResponse<void>> {
        return this.http.post<void>(`${this.envService.userHost}/v2/smartlogin/${code}`, {}, {observe: 'response'});
    }
}
