import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {

    constructor(private envService: EnvironmentService,
                private http: HttpClient) {
    }

    get(): Observable<{key: string, value: string}[]> {
        return this.http.get<{key: string, value: string}[]>(this.envService.userHost + '/v1/preferences');
    }

    getOne(key: string): Observable<{key: string, value: string}> {
        return this.http.get<{key: string, value: string}>(this.envService.userHost + '/v1/preferences/' + key);
    }

    set(key: string, value: any) {
        return this.http.post(this.envService.userHost + '/v1/preferences', {key, value});
    }

    setMulti(keyValues: {key: string, value: any}[]) {
        // to prevent updating of passed in values
        const copy = JSON.parse(JSON.stringify(keyValues));
        const save = copy.map(x => {
            x.value = x.value.toString();
            return x;
        })
        return this.http.post(this.envService.userHost + '/v1/preferences/multi', save);
    }
}
