import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { AppComponent } from 'src/app/app.component';
import { Subscription } from 'src/app/models/account/subscription';

@Component({
    selector: 'app-subscription-row',
    templateUrl: './subscription-row.component.html',
    styleUrls: ['./subscription-row.component.css']
})
export class SubscriptionRowComponent implements OnInit {

    @Input() subscription: Subscription;

    now: Date = new Date();
    loading: boolean = false;
    endDate: Date;

    constructor(
        private app: AppComponent,
        private subscriptionService: SubscriptionService) {
    }

    ngOnInit() {
        this.endDate = new Date(this.subscription.endDate);
    }

    isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

    parseIsoDateStrToDate(value) {
        if (typeof value === 'string' && this.isoDateFormat.test(value)) {
            return new Date(value);
        }
        return value;
    }

    cancel() {
        this.app.startLoading();
        this.subscriptionService.cancelSubscription(this.subscription.subscriptionId).subscribe({
            next: res => {
                this.subscription = res;
                this.app.stopLoading();
            },
            error: () => {
                this.app.stopLoading();
            }
        });
    }

    resume() {
        this.app.startLoading();
        this.subscriptionService.resumeSubscription(this.subscription.subscriptionId).subscribe({
            next: res => {
                this.subscription = res;
                this.app.stopLoading();
            },
            error: () => {
                this.app.stopLoading();
            }
        });
    }

    getSubscriptionType() {
        switch (this.subscription.type) {
            case 'Stripe':
                return 'Credit/Debit Card';
            case 'PayPal':
                return 'PayPal';
            case 'Patreon':
                return 'Patreon';
            case 'AppleIAP-Sandbox':
                return 'Apple IAP';
            case 'AppleIAP-Production':
                return 'Apple IAP';
        }
    }
}
