<div class="main main-full d-flex justify-content-center py-3">

    <div class="checkout-container px-3">
        <h3 class="pick-sub">{{'pick-your-subscription' | translate}}</h3>
        <p *ngIf="!hasPreviousSub">{{'payment-type-access-sub-text-1' | translate}}</p>
        <p *ngIf="hasPreviousSub">{{'choose-subscription' | translate}}.</p>

        <div class="currency-container">
            <select id="currency-select" [(ngModel)]="currency" (change)="updatePrices()" *ngIf="currencies.length > 1">
                <option value="{{c}}" *ngFor="let c of currencies">{{getCurrencySymbol(c)}} {{c | uppercase}}</option>
            </select>
        </div>

        <div class="d-flex justify-content-between" *ngIf="!loading">
            <div class="plan-button" [class.active]="product === 'month'" (click)="monthly()" [class.half]="annualPrice">
                <div>
                    <div>{{'monthly' | translate}}</div>
                    <div>{{monthlyPrice | currency : currency.toUpperCase()}}</div>
                </div>
                <div class="check-mark">
                </div>
            </div>
            <div class="plan-button half" [class.active]="product === 'year'" (click)="annual()" *ngIf="annualPrice">
                <div>
                    <div>{{'annual' | translate}}</div>
                    <div>{{annualPrice | currency : currency.toUpperCase()}}</div>
                </div>
                <div class="check-mark">
                </div>
            </div>
        </div>

        <div class="pp-container" *ngIf="config.remoteConfig.points_enabled">
            <span *ngIf="product === 'month'">
                {{'payment-type-access-sub-text-monthly-text-1' | translate}}
                <img src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points" class="icon-small">
                {{'payment-type-access-sub-text-monthly-text-2' | translate}}.
            </span>
            <span *ngIf="product === 'year'">
                {{'payment-type-access-sub-text-annual-text-1' | translate}}
                <img src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points" class="icon-small">
                {{'payment-type-access-sub-text-annual-text-2' | translate}}
            </span>
        </div>

        <p *ngIf="!hasPreviousSub || hasPatreon">
            {{ (hasPatreon ? 'next-billing-date' : 'free-trial-end') | translate }} {{trialEndDate | date:'d MMMM yyyy'}}.
        </p>
        <form id="payment-form" (ngSubmit)="confirmStripeSetup()" [class.d-none]="!stripeElements">
            <div id="payment-container">
                <!-- Elements will create card inputs here -->
                <div id="payment-element">

                </div>
            </div>
            <div class="checkbox-container my-3" [class.active]="refundAccepted"
                (click)="refundAccepted = !refundAccepted">
                <div class="checkbox"></div>
                <div class="checkbox-label">
                    {{ "i-have-read-and-accept-the" | translate }}
                    <a (click)="viewDocument('refund-policy', $event)">{{'Refund Policy'}}</a>
                </div>
            </div>
            <div *ngIf="hasPatreon" class="small">
                {{ 'patreon-warning' | translate }}
            </div>
            <button type="submit" class="mt-3 btn btn-lg btn-primary btn-block submit-button"
                [disabled]="!refundAccepted">
                <span *ngIf="!hasPreviousSub">
                    {{ 'start-free-trial' | translate | uppercase}}
                </span>
                <span *ngIf="hasPreviousSub">
                    <ng-container *ngIf="!hasPatreon">
                        {{'pay' | translate | uppercase}} {{ selectedPrice | currency : currency.toUpperCase() }}
                    </ng-container>
                    <ng-container *ngIf="hasPatreon">
                        {{'convert-patreon' | translate}}
                    </ng-container>
                </span>
            </button>

            <div class="error-message text-danger font-weight-bold">
                {{ errorMessage }}
            </div>
        </form>

        <or-block *ngIf="config.remoteConfig.vouchers_enabled"></or-block>

        <div class="form-group mt-3 d-flex align-items-center" *ngIf="config.remoteConfig.vouchers_enabled">
            <input type="text" name="voucherCode" class="form-control" autoFocus [(ngModel)]="voucherCode"
                placeholder="{{ 'account-enter-voucher-code' | translate }}">
            <button [disabled]="voucherCode?.length < 1"
                class="btn btn-primary btn-slim no-wrap submit-button voucher ml-3" (click)="redeemVoucher()">
                {{ 'account-voucher-submit' | translate | uppercase }}
            </button>
        </div>
        <div class="error-message text-danger font-weight-bold">
            {{voucherError}}
        </div>

        <ng-container *ngIf="!isMobile && !hideMenus && config.remoteConfig.paypal_enabled">
            <or-block></or-block>
            <div class="checkbox-container my-3" [class.active]="refundAccepted"
                (click)="refundAccepted = !refundAccepted">
                <div class="checkbox"></div>
                <div class="checkbox-label">
                    {{ "i-have-read-and-accept-the" | translate }}
                    <a (click)="viewDocument('refund-policy', $event)">{{'Refund Policy'}}</a>
                </div>
            </div>
            <app-paypal-button-v2 [disableBtn]="!refundAccepted" (click)="payPal()"></app-paypal-button-v2>
        </ng-container>

        <div class="test-card" *ngIf="isStaging()">
            <div>
                <div>Test Card:</div>
                <div>4242 4242 4242 4242</div>
                <div class="d-inline-block mr-3">
                    <div>Expiry:</div>
                    <div>12/35</div>
                </div>
                <div class="d-inline-block">
                    <div>CVC:</div>
                    <div>123</div>
                </div>
            </div>
        </div>
    </div>
</div>
