<div class="main main-full">
    <div class="page-container">
        <p class="subheading subheading-1">Choose Your Badges</p>

        <div class="image-gallery ig-1">
            <div class="badge-box" *ngFor='let badge of unorderedBadges' (click)="toggleBadge(badge)">
                <img class="chat-badge-large center" [ngClass]="{'selected-badge': isBadgeSelected(badge)}"
                    alt="{{badge.type}}" title="{{badge.type}}"
                    src="https://cdn.watchcorridor.com/badges/{{badge.type}}.png"
                    *ngIf="config.remoteConfig.comments_version == '1'" />
                <img class="chat-badge-large center" [ngClass]="{'selected-badge': isBadgeSelected(badge)}"
                    alt="{{badge.type}}" title="{{badge.type}}"
                    [src]="env.imageHost +  '/badges/' + badge.type + '.png'"
                    *ngIf="config.remoteConfig.comments_version == '2'" />
            </div>
        </div>

        <p class="subheading subheading-2">Order</p>

        <div class="image-gallery">
            <div class="badge-box" *ngFor='let badge of orderedBadges' (click)="toggleBadge(badge)">
                <img class="chat-badge-large center" alt="{{badge.type}}" title="{{badge.type}}"
                    src="https://cdn.watchcorridor.com/badges/{{badge.type}}.png"
                    *ngIf="config.remoteConfig.comments_version == '1'" />
                <img class="chat-badge-large center" alt="{{badge.type}}" title="{{badge.type}}"
                    [src]="env.imageHost +  '/badges/' + badge.type + '.png'"
                    *ngIf="config.remoteConfig.comments_version == '2'" />
            </div>
            <div class="badge-box" *ngIf="!orderedBadges || orderedBadges.length == 0">
                <div class="placeholder-badge"></div>
            </div>
            <div class="badge-box" *ngIf="!orderedBadges || orderedBadges.length < 2">
                <div class="placeholder-badge"></div>
            </div>
            <div class="badge-box" *ngIf="!orderedBadges || orderedBadges.length < 3">
                <div class="placeholder-badge"></div>
            </div>
        </div>

        <div class="image-gallery placeholders">
        </div>

        <div class="form-group">
            <button type="submit" class="btn btn-outline-primary button-width" (click)="submit()">{{
                "account-badges-update"
                | translate}}</button>
        </div>

        <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3 button-width">{{ "account-back" |
            translate}}</a>
    </div>
</div>