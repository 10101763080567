export class Register {

    constructor(
      public name?: string,
      public email?: string,
      public password?: string,
      public confirmPassword?: string,
      public privacyPolicy?: string,
      public optIn?:boolean,
      public termsAndConditions?: string) { }
  }
  