import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    currentMessage = new BehaviorSubject(null);

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private notificationService: NotificationService,
        private account: AccountService) {
        /*this.angularFireMessaging.messages.subscribe(
          (_messaging) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
          }
        )*/
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                if (token) {
                    this.account.updateToken().then(
                        () => {
                            this.notificationService.sendPushDeviceId(token).subscribe();
                        },
                        () => {
                        });
                }
            }
        );
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (messaging: any) => {
                messaging.onMessageCallback = (payload: any) => {
                    this.currentMessage.next(payload);
                };
            }
        );
    }
}
