import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { get } from 'scriptjs';

declare var registerIVSTech: any;
declare var videojs: any;

@Component({
    selector: 'ivs-live',
    templateUrl: './ivs-live.component.html',
    styleUrls: ['./ivs-live.component.css']
})
export class IvsLiveComponent implements OnInit, OnDestroy {

    player: any;
    error: boolean = false;

    @Input() url: string;

    constructor() {
    }

    ngOnInit(): void {
        get('https://player.live-video.net/1.3.1/amazon-ivs-videojs-tech.min.js', () => {
            registerIVSTech(videojs);

            this.player = videojs('ivsPlayer', {
                techOrder: ['AmazonIVS'],
                controlBar: {
                    pictureInPictureToggle: false
                }
            }, () => {
                this.player.src(this.url);
            });
            document.getElementsByClassName('vjs-subs-caps-button')[0].classList.add('disabled', 'd-none');

            let playerEvent = this.player.getIVSEvents().PlayerEventType;
            this.player.getIVSPlayer().addEventListener(playerEvent.ERROR, () => {
                console.log('ivs error');
                this.error = true;
            });
        });
    }

    refresh() {
        this.error = false;
        this.player.src(this.url);
    }

    ngOnDestroy() {
        this.player.dispose();
    }

}
