import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy, Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from 'src/app/app.component';
import {StreamReport} from 'src/app/models/stream-report';
import {VideoService} from 'src/app/services/video.service';
import {StorageManagerService} from '../../services/storage-manager.service';
import {AccountService} from '../../services/account.service';
import { fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import {ScreenSizeEnum} from '../../models/screen-size.enum';
import { UiService } from '../../services/ui.service';
import { SubtitleService } from '../../services/subtitle.service';

declare function initSeekPreview();

declare function loadVtt(url);

declare function unloadVtt();

//declare function timestampClicked();

declare var shaka: any;

@Component({
    selector: 'shaka-player',
    templateUrl: './shaka-player.component.html',
    styleUrls: ['./shaka-player.component.scss']
})
export class ShakaPlayerComponent implements AfterViewInit, OnChanges, OnDestroy {

    @ViewChild('videoPlayer') videoElementRef: ElementRef;
    videoElement: HTMLVideoElement;
    @ViewChild('videoUI') videoUIElementRef: ElementRef;
    videoUIElement: HTMLDivElement;

    @Input() poster: string;
    @Input() startTimeMs: number;
    @Input() uid: string;
    @Input() url: string;
    @Input() widevineUrl: string;
    @Input() fairplayUrl: string;
    @Input() fpsHlsUrl: string;
    @Input() videoId: number;
    @Input() previewsUrl: string;
    @Input() textTracks: any;
    @Input() maxBitrate: number = 12000000;

    @Output() reportMedia = new EventEmitter<void>();
    @Output() theatre = new EventEmitter<boolean>()

    autoplay: boolean;
    frequency = 60;
    lastReportedTime = 0;
    currentProgress = 0;
    timeLeft: number;
    player: any;
    pip: boolean = false;
    pipClosed: boolean = false;
    endOfVideo = false;
    endOfVideoCounterId;
    endOfVideoTime = 10;
    cancelled = false;
    endOfVideoImage = '';
    videoLoaded: boolean = false;

    subtitleClass$: Observable<string>;

    private _isTheatre: boolean = false;

    get isTheatre() {
        return this._isTheatre;
    }

    set isTheatre(value: boolean) {
        this._isTheatre = value;
        this.theatre.emit(value);
        if (value) {
            this.storage.setPlayerSize('theatre');
        } else {
            if (!this.isFullScreen) {
                this.storage.removePlayerSize();
            }
        }
    }

    destroy$ = new Subject<void>();

    get isFullScreen(): boolean {
        return document.fullscreenElement !== null;
    }

    constructor(
        private app: AppComponent,
        private router: Router,
        private route: ActivatedRoute,
        private videoService: VideoService,
        private storage: StorageManagerService,
        private account: AccountService,
        private ui: UiService,
        private subtitleService: SubtitleService) {
        if (this.account.isAuthenticated()) {
            this.subtitleClass$ = this.subtitleService.subtitleClass();
            this.subtitleService.getSubtitleOptions().subscribe();
        }

        const screenSiteSetting = this.storage.getPlayerSize();
        switch (screenSiteSetting) {
            case 'theatre':
                this.toggleTheatre(true);
                break;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.autoplay) {
            this.cancelled = !changes.autoplay.currentValue;
        }
    }

    ngAfterViewInit(): void {

        this.cancelled = !this.autoplay;

        if (localStorage.getItem('nextVideoImage')) {
            this.endOfVideoImage = localStorage.getItem('nextVideoImage');
        }

        // Install built-in polyfills to patch browser incompatibilities.
        shaka.polyfill.installAll();

        // Check to see if the browser supports the basic APIs Shaka needs.
        if (shaka.Player.isBrowserSupported()) {
            // Everything looks good!
            this.videoElement = this.videoElementRef.nativeElement;
            this.videoUIElement = this.videoUIElementRef.nativeElement;

            this.initPlayer();

            if (this.previewsUrl) {
                loadVtt(this.previewsUrl);
            } else {
                unloadVtt();
            }

            fromEvent(document.getElementById('theatre'), 'click')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.toggleTheatre());

            fromEvent(document.getElementById('captions'), 'click')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.toggleCaptions());

            fromEvent(document.getElementById('report'), 'click')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.reportMedia.emit();
                    this.videoElement.pause();
                });

            this.ui.breakpointObservable
                .pipe(takeUntil(this.destroy$))
                .subscribe((screenSize: ScreenSizeEnum) => {
                    const el = document.getElementById('theatre');
                    if (!el) {
                        return;
                    }
                    if (screenSize >= ScreenSizeEnum.Small) {
                        el.style.display = 'block';
                    } else {
                        el.style.display = 'none';
                    }
                });

            if (!this.textTracks || Object.keys(this.textTracks).length === 0) {
                document.getElementById('captions').style.display = 'none';
            }
        } else {
            // This browser does not have the minimum set of APIs we need.
            console.error('Browser not supported!');
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

        if (this.endOfVideoCounterId) {
            clearInterval(this.endOfVideoCounterId);
        }

        if (this.account.isAuthenticated()) {
            this.reportStreamProgress();
        }

        if (this.player) {
            this.player.destroy();
        }
    }

    updateAutoPlay(autoplay: boolean): void {
        this.autoplay = autoplay;
        this.cancelled = !autoplay;
        console.log('updateAutoPlay', autoplay)
    }


    private initPlayer() {

        let volume = +localStorage.getItem('volume');
        if (volume) {
            this.videoElement.volume = volume;
        }

        let localPlayer = new shaka.Player(this.videoElement);
        const ui = new shaka.ui.Overlay(localPlayer, this.videoUIElement, this.videoElement);

        const controls = ui.getControls();

        this.player = ui.getControls().getPlayer();

        ui.configure({
            'castReceiverAppId': 'F70D4520',
            'overflowMenuButtons': ['cast', 'playback_rate', 'quality', 'captions'],
            'seekBarColors': {
                base: 'rgba(255, 255, 255, 0.3)',
                buffered: 'var(--player_buffered)',
                played: 'var(--player_played)',
            },
            'controlPanelElements': [
                'play_pause',
                'time_and_duration',
                'spacer',
                'mute',
                'volume',
                'theatre',
                'fullscreen',
                'captions',
                'report',
                'overflow_menu'],
            'addBigPlayButton': true
        });

        controls.addEventListener('caststatuschanged', this.onCastStatusChanged);

        this.configurePlayer();
        this.player.addEventListener('error', (e) => {
            this.onError(e.detail);
        });
        this.player.addEventListener('adaptation', () => {
            this.player.getVariantTracks().forEach(function(track) {
                if (track.active) {
                    let autoElement = document.getElementsByClassName('shaka-resolution-button')[0].children[1].children[1];
                    if (autoElement) {
                        autoElement.innerHTML = 'Auto (' + track.height + 'p)';
                    }
                }
            });
        });

        this.videoElement.addEventListener('volumechange', () => {
            localStorage.setItem('volume', this.videoElement.volume + '');
        });

        this.videoElement.addEventListener('canplay', () => {
            this.videoLoaded = true;
            console.log('videoloaded');
        });

        this.videoElement.addEventListener('seeked', () => {
            console.log('Seeking ended. New time:', this.videoElement.currentTime);
            this.reportStreamProgress();
        });

        this.videoElement.addEventListener('ended', () => {
            if (localStorage.getItem('nextVideoImage')) {
                this.endOfVideoImage = localStorage.getItem('nextVideoImage');
            }
            this.endOfVideo = true;
            if (!this.cancelled) {
                this.endOfVideoCounterId = setInterval(() => {
                    this.endOfVideoTime = this.endOfVideoTime - 1;
                    if (this.endOfVideoTime < 0) {
                        if (!this.cancelled) {
                            if (localStorage.getItem('nextVideoId')) {
                                this.playNextVideo();
                            }
                        }
                        clearInterval(this.endOfVideoCounterId);
                    }
                }, 1000);
            }
        }, {once: true});

        this.videoElement.addEventListener('timeupdate', () => {
            let time = this.videoElement.currentTime;
            if (this.account.isAuthenticated()) {
                let intTime = (time | 0);
                if (time > 0 && intTime % this.frequency == 0 && intTime > this.lastReportedTime) {
                    this.reportStreamProgress();
                }
            }
            this.timeLeft = this.videoElement.duration - time;
            this.currentProgress = time;
        });

        this.player.addEventListener('textchanged', () => {
            const lang = this.player.getTextTracks().find(x => x.active)?.language ?? 'null';
            this.storage.setString('caption-language', lang);
        });

        this.player.addEventListener('texttrackvisibility', () => {
            const currentLang = this.storage.getString('caption-language');
            const tracks = this.player.getTextTracks();
            if (currentLang !== null && currentLang !== undefined && currentLang !== 'null') {
                if (tracks.findIndex(x => x.language === currentLang) < 0 || tracks.length === 0) {
                    return;
                }
            }

            const visible = this.player.getTextTracks().findIndex(x => x.active) > -1;
            if (!visible) {
                this.storage.setString('caption-language', null);
            }

            this.initCheckCaptionImage();
        });

        if (this.url === undefined) {
            this.url = this.fpsHlsUrl;
        }

        this.player.load(
            this.url
        ).then(() => {


            this.setMaxVideoBandwidth();

            initSeekPreview();
            this.addTextTracks().then(() => {
                const captionLang = this.storage.getString('caption-language');
                if (captionLang !== undefined && captionLang !== null && captionLang !== 'null') {
                    const tracks = this.player.getTextTracks();
                    const track = tracks.find((x) => x.language === captionLang);
                    this.player.setTextTrackVisibility(true);
                    this.player.selectTextTrack(track);
                }
                this.initCheckCaptionImage();
            });

            if (this.startTimeMs > 0) {
                let startTimeS = this.startTimeMs / 1000;
                if (this.videoElement.duration - startTimeS < 30) {
                    startTimeS = 0;
                }
                if (startTimeS < 10) {
                    startTimeS = 0;
                }
                this.videoElement.currentTime = startTimeS;
            }
        }).catch((e) => {
            this.onError(e);
        });
    }

    async addTextTracks() {
        for (const lang of Object.keys(this.textTracks)) {
            await this.player.addTextTrackAsync(this.textTracks[lang], lang, 'subtitle', 'text/vtt');
        }
    }

    reportStreamProgress() {
        if (!this.videoElement) {
            return;
        }

        let intTime = (Math.floor(this.videoElement.currentTime) | 0);
        if (Math.abs(intTime - this.lastReportedTime) > this.frequency) {
            this.lastReportedTime = intTime;
            this.videoService.reportStreamProgress(
                this.uid,
                this.videoId,
                new StreamReport(intTime, this.videoElement.duration * 1000, 'StreamProgress')
            ).subscribe();
        }
    }

    playNextVideo() {
        this.playVideo(localStorage.getItem('nextVideoId'));
    }

    cancelNextVideoTimer() {
        clearInterval(this.endOfVideoCounterId);
        this.endOfVideoTime = -1;
        this.cancelled = true;
    }

    @HostListener('window:keyup', ['$event'])
    keyEventUp(event: any) {
        if (event.target.className.indexOf('text-input-dark') === -1 && !event.target.matches('input')) {
            switch (event.key) {
                case 'f':
                    event.preventDefault();
                    this.toggleFullScreen();
                    break;
                case 'p':
                    event.preventDefault();
                    this.togglePausePlay();
                    break;
                case 't':
                    event.preventDefault();
                    this.toggleTheatre();
                    break;
                case 'c':
                    event.preventDefault();
                    this.toggleCaptions();
                    break;
                case ' ':
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                    this.togglePausePlay();
                    return false;
            }
        }
    }

    @HostListener('window:keydown', ['$event'])
    keyEventDown(event: any) {
        if (event.target.className.indexOf('text-input-dark') === -1 && !event.target.matches('input')) {
            switch (event.key) {
                case ' ':
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                    break;
                case 'ArrowRight':
                    this.skipForward();
                    return;
                case 'ArrowLeft':
                    this.skipBackward();
                    return;
            }
            return;
        }
    }

    toggleTheatre(set: boolean = undefined) {
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                this.isTheatre = true;
                return;
            }
        }

        if (set !== undefined) {
            this.isTheatre = set;
            return;
        }
        this.isTheatre = !this.isTheatre;
    }

    toggleCaptions() {
        const textTracks = this.player.getTextTracks();
        const showingTracks = textTracks.filter(track => track.active);
        const active = !showingTracks.length;
        this.player.setTextTrackVisibility(active);
        const captionImg: any = document.getElementById('captions');
        if (captionImg) {
            captionImg.src = active
                ? 'https://cdn.watchcorridor.com/assets/cc-on.svg'
                : 'https://cdn.watchcorridor.com/assets/cc-off.svg';
        }
    }

    initCheckCaptionImage() {
        const textTracks = this.player.getTextTracks();
        const showingTracks = textTracks.filter(track => track.active);
        const active = showingTracks.length;
        const captionImg: any = document.getElementById('captions');
        if (captionImg) {
            captionImg.src = active
                ? 'https://cdn.watchcorridor.com/assets/cc-on.svg'
                : 'https://cdn.watchcorridor.com/assets/cc-off.svg';
        }
    }

    togglePausePlay() {
        if (this.videoElement.paused) {
            this.videoElement.play();
        } else {
            this.videoElement.pause();
        }
    }

    toggleFullScreen() {

        if (!document.fullscreenElement) {
            this.videoElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll() {
        if (window.scrollY === 0) {
            this.pipClosed = false;
        }

        this.pip = window.scrollY > 300 && !this.pipClosed;
    }

    closePip() {
        this.pip = false;
        this.pipClosed = true;
    }

    private onCastStatusChanged(event) {
        const newCastStatus = event['newStatus'];
        if (newCastStatus) {
            // Handle cast status change
            console.log('The new cast status is: ' + newCastStatus);
        }
    }

    private onError(error) {
        if (error.code === 6002 && (navigator as any).brave) {
            console.log('Brave no Widevine CDM detected.');
            this.app.showSimpleAlert({
                title: 'Widevine CDM Required',
                buttonText: 'Brave Widevine Support',
                buttonUrl: 'https://support.brave.com/hc/en-us/articles/360023851591-How-do-I-view-DRM-protected-content-',
                body: 'For playback we require Widevine to be installed in your browser.<br/>Click below to see the Brave support article for Widevine.'
            });
        }
        // Log the error.
        console.error('Error code', error.code, 'object', error);
    }

    onResize() {
        this.configurePlayer();
    }

    configurePlayer() {
        let height = this.videoUIElement.getBoundingClientRect().height;
        let width = this.videoUIElement.getBoundingClientRect().width;
        if (height == 0 && width > 0) {
            height = width / 16 * 9;
        } else if (height == 0) {
            height = 8000;
        } else {
        }
        if (height < 1080) {
            height = 1080;
        }

        height = 8000;

        this.player.getNetworkingEngine().registerResponseFilter((type, response) => {
            if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
                return;
            }

            if (this.fairplayUrl) {
                console.log('FairPlay Detected');
                let responseText = shaka.util.StringUtils.fromUTF8(response.data);
                // Trim whitespace.
                responseText = responseText.trim();

                console.log('Decoding base64 license response');
                // Decode the base64-encoded data into the format the browser expects.
                response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
            }
        });

        this.player.configure({
            drm: {
                servers: {
                    'com.widevine.alpha': this.widevineUrl,
                    'com.apple.fps': this.fairplayUrl?.replace('?cd', '/safari?cd')
                },
                advanced: {
                    'com.widevine.alpha': {
                        videoRobustness: 'SW_SECURE_CRYPTO',
                        audioRobustness: 'SW_SECURE_CRYPTO'
                    },
                    'com.apple.fps': {
                        serverCertificateUri: 'https://kms.watchcorridor.com/v1/fairplay/certificate'
                    }
                }
            },
            streaming: {
                bufferingGoal: 120 // set a 2 minute buffering goal instead of 10 seconds
            }
        });
    }

    private setMaxVideoBandwidth(): void {
        const tracks = this.player.getVariantTracks();
        const filteredTracks = tracks.filter(track => track.videoBandwidth <= this.maxBitrate);

        // Find the maximum audio bandwidth
        const maxAudioBandwidth = Math.max(...filteredTracks.map(track => track.audioBandwidth));

        // Set the restriction considering the audio bandwidth
        const maxBandwidth = this.maxBitrate + maxAudioBandwidth;
        const config = {
            restrictions: {
                maxBandwidth: maxBandwidth,
            },
        };
        this.player.configure(config);
    }

    shown() {
        if (!this.videoLoaded) {
            return false;
        }

        const ele = document.getElementsByClassName('shaka-controls-container')[0];
        if (ele) {
            return ele.hasAttribute('shown');
        }
        return false;
    }

    skipForward() {
        let time = this.videoElement.currentTime;
        time += 10;

        if (time < this.videoElement.duration) {
            this.videoElement.currentTime = time;
        }
    }

    skipBackward() {
        console.log('skipb')
        let time = this.videoElement.currentTime;
        time -= 10;

        if (time < 0) {
            time = 0;
        }
        this.videoElement.currentTime = time;
    }

    watchAgain() {
        const id = this.route.snapshot.paramMap.get('id');

        if (this.endOfVideoCounterId) {
            clearInterval(this.endOfVideoCounterId);
        }

        this.router.navigate(['/blank'], {skipLocationChange: true}).then(() => {
            this.playVideo(id);
        });
    }

    playVideo(id: string) {
        const seasonId = this.route.snapshot.queryParamMap.get('seasonId');
        const watchlist = this.route.snapshot.queryParamMap.get('watchlist');
        if (seasonId && watchlist) {
            this.router.navigate(['/video', id], {queryParams: {seasonId, watchlist, t: 0}});
        } else if (watchlist) {
            this.router.navigate(['/video', id], {queryParams: {watchlist, t: 0}});
        } else if (seasonId) {
            this.router.navigate(['/video', id], {queryParams: {seasonId, t: 0}});
        } else {
            this.router.navigate(['/video', id], {queryParams: {t: 0}});
        }
    }
}
