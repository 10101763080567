<div class="subscription-row">
    <h5>Subscription Info:</h5>
    <p>Type: {{getSubscriptionType()}}</p>
    <div class="ml-4" *ngIf="subscription.card">
        <p>Last Four Digits: {{subscription.card.cardEnding}}</p>
        <p>Expiry: {{subscription.card.cardExpiry}}</p>
    </div>
    <div class="row">
        <div class="col-6">
            <p>Start Date: {{subscription.startDate | date:'mediumDate'}}</p>
        </div>
        <div class="col-6">
            <p>End Date: {{subscription.endDate | date:'mediumDate'}}</p>
        </div>
    </div>
    <p *ngIf="getSubscriptionType() == 'Apple IAP'"><b>Apple Subscriptions must be managed through the App Store.</b>
    </p>
    <p *ngIf="subscription.type == 'Patreon'">Renewal: Automatic (Patreon)</p>
    <p *ngIf="(subscription.type == 'Stripe' || subscription.type =='PayPal') && subscription.cancelled">Renewal: <b
            class="red-text">OFF</b></p>
    <p *ngIf="(subscription.type == 'Stripe' || subscription.type =='PayPal') && !subscription.cancelled">Renewal: <b
            class="green-text">ON</b></p>
    <p *ngIf="subscription.type == 'Patreon'">Amount: Included with Patreon</p>
    <p *ngIf="subscription.type == 'Stripe' || subscription.type =='PayPal'">Amount:
        {{subscription.currency.toLowerCase() == 'usd' ? '$' : 'TRIAL - $'}}{{subscription.amount / 100}}</p>
    <p *ngIf="subscription.type == 'Stripe'">Receipt: <a href="{{subscription.receiptUrl}}" target="_blank">Click
            To View</a> </p>
    <p *ngIf="subscription.type == 'PayPal'">Manage your subscription at PayPal.com: <a href="{{subscription.receiptUrl}}"
            target="_blank">Click Here</a> </p>
    <div class="mt-2" *ngIf="endDate > now">
        <button *ngIf="!subscription.cancelled && (subscription.type == 'Stripe' || subscription.type == 'PayPal')" (click)="cancel()"
            class="btn btn-slim btn-outline-light">Cancel</button>
        <button *ngIf="subscription.cancelled && (subscription.type == 'Stripe' || subscription.type == 'PayPal')" (click)="resume()"
            class="btn btn-success btn-block">Resume</button>
    </div>
</div>