import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PointsService } from '../../services/points.service';
import { LocalisationService } from '../../services/localisation.service';

@Component({
    selector: 'app-contribute-points',
    templateUrl: './contribute-points.component.html',
    styleUrls: ['./contribute-points.component.css']
})
export class ContributePointsComponent implements OnInit, OnDestroy {

    shown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    destroy$: Subject<void> = new Subject<void>();
    points: number = 2;
    isMouseInModal = false;
    voteLimit = 10000;

    form = new UntypedFormGroup({
        pointsInputCtrl: new UntypedFormControl('2', [
            Validators.required,
            Validators.pattern('^[0-9]*$'),
            Validators.max(this.voteLimit)
        ])
    });

    @Input() projectId: number;
    @ViewChild('pointsInput') pointsInput: ElementRef;

    @Input() projectTitle: string;

    @Output() contributed = new EventEmitter<{ projectId: number, points: number }>();

    constructor(
        private app: AppComponent,
        private projectsService: ProjectsService,
        private pointsService: PointsService,
        private translate: LocalisationService) {
    }


    ngOnInit(): void {
        this.projectsService.pointContributionHidden
            .pipe(takeUntil(this.destroy$))
            .subscribe(show => {
                this.shown$.next(show);
                if (show) {
                    this.points = 2;

                    setTimeout(() => {
                        this.pointsInput.nativeElement.focus();
                        console.log(this.pointsInput.nativeElement.value)
                        this.pointsInput.nativeElement.type = 'text'; // switch to text to support setSelectionRange
                        this.pointsInput.nativeElement.setSelectionRange(this.pointsInput.nativeElement.value.length, this.pointsInput.nativeElement.value.length);
                        this.pointsInput.nativeElement.type = 'number';
                    });
                }
            });

        this.form.get('pointsInputCtrl').valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(val => {
                this.points = val;
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onClose() {
        if (this.isMouseInModal) {
            this.isMouseInModal = false;
            return;
        }
        this.projectsService.closePointContribution();
    }

    closeButton() {
        this.projectsService.closePointContribution();
    }

    submit() {
        if (this.points === null || this.points === undefined || isNaN(this.points)) {
            this.app.showSimpleAlert({
                title: this.translate.getTranslation('project-vote-error-title'),
                body: this.translate.getTranslation('project-vote-error-no-value')
            });
            return;
        }

        if (this.pointsService.points$.value > 0 && this.points > this.pointsService.points$.value) {
            this.app.showSimpleAlert({
                title: this.translate.getTranslation('project-vote-error-title'),
                body: 'You don’t have enough Producer Points to back this Project'
            });
            return;
        }

        if (this.form.controls['pointsInputCtrl'].errors?.pattern) {
            this.app.showSimpleAlert({
                title: this.translate.getTranslation('project-vote-error-title'),
                body: this.translate.getTranslation('project-vote-error-whole-number')
            });
            return;
        }

        if (this.form.controls['pointsInputCtrl'].errors?.max) {
            this.app.showSimpleAlert({
                title: this.translate.getTranslation('project-vote-error-title'),
                body: this.translate.getTranslationsParams('project-vote-error-maximum', this.voteLimit.toString())
            });
            return;
        }

        this.app.startLoading();
        this.projectsService.contributePoints(this.projectId, this.points).then(
            () => {
                this.pointsService.producerPoints -= this.points;
                this.contributed.emit({projectId: this.projectId, points: this.points});
                this.app.stopLoading();
                this.projectsService.closePointContribution();
                this.app.showSimpleAlert({
                    title: this.translate.getTranslation('project-vote-success-title'),
                    body: this.translate.getTranslationsParams('project-vote-success-body', this.points.toString())
                });
                this.form.controls['pointsInputCtrl'].setValue(2);
            },
            err => {
                this.app.stopLoading();
                this.projectsService.closePointContribution();
                this.app.showSimpleAlert({
                    title: this.translate.getTranslation('project-vote-error-title'),
                    body: err
                });
            });
    }

    nothing(event: Event) {
        event.stopPropagation();
    }

    mouseInModal() {
        this.isMouseInModal = true;
    }

    mouseOutModal() {
        this.isMouseInModal = false;
    }
}
