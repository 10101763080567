<div class="confirm-container">
    <div class="confirm-title">{{confirmObj.title ?? 'CONFIRM'}}</div>
    <div class="confirm-body">{{confirmObj.message ?? 'Are you sure?'}}</div>
    <!-- <div class="confirm-extra-secure" *ngIf="confirmObj.extraSecure">
        <proto-checkbox [(checked)]="extraSecureTicked">{{confirmObj.extraSecureMessage ?? 'Tick to confirm'}}</proto-checkbox>
    </div> -->
    <div class="confirm-buttons">
        <button class="proto-button outline"
                (click)="close()">{{confirmObj.cancelBtnTxt ?? 'CANCEL'}}</button>
        <button class="proto-button outline"
                [disabled]="confirmObj.extraSecure && !extraSecureTicked" (click)="ok()">
            {{confirmObj.okBtnTxt ?? 'OK'}}
        </button>
    </div>
</div>