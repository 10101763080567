
<p>Enter the code from your Smart TV</p>
<form id="signup-form" class="signup-form" (ngSubmit)="submit()" #f="ngForm" novalidate
      class="needs-validation">
    <div class="form-group">
        <input [disabled]="loading" type="text" name="code" [(ngModel)]="code" autoFocus
               class="form-control" placeholder="Enter Code" required *ngIf="!success">
        <p class="text-danger" *ngIf="error">{{error}}</p>
        <p class="text-success" *ngIf="success">{{success}}</p>
    </div>

    <div class="form-group">
        <button type="submit" class="btn btn-outline-primary btn-slim"
                *ngIf="!success">{{ "submit" | translate }}</button>
    </div>
</form>
