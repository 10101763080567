import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../services/subscription.service';
import { AppComponent } from '../../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionCancellation } from '../../../models';
import { lastValueFrom } from 'rxjs';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AccountService } from '../../../services/account.service';

@Component({
    selector: 'app-cancel-membership-reason',
    templateUrl: './cancel-membership-reason.component.html',
    styleUrls: ['./cancel-membership-reason.component.scss']
})
export class CancelMembershipReasonComponent extends AuthenticationOverride implements OnInit {
    loading: boolean = false;
    cancelFailed: boolean;
    reason: SubscriptionCancellation = {
        cancellationId: 0,
        message: '',
        reason: ''
    }

    reasons: SubscriptionCancellation[] = [];
    otherId: number = 0;

    constructor(private subscriptionService: SubscriptionService,
                private app: AppComponent,
                public router: Router,
                route: ActivatedRoute,
                storage: StorageManagerService,
                account: AccountService) {
        super(route, storage, router, account);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.loading = true;
        this.reasons = await lastValueFrom(this.subscriptionService.getSubscriptionCancellationReasons());
        this.otherId = this.reasons.find(x => x.reason.toLocaleLowerCase() === 'other')?.cancellationId;
        this.loading = false;
    }

    async cancel() {
        this.loading = true;
        const reason = this.reason.cancellationId === 0 ? null : this.reason;
        try {
            await lastValueFrom(this.subscriptionService.cancelAllSubscriptions(reason))
            this.app.showSimpleAlert({
                title: 'Success',
                body: 'Your subscription has been cancelled.'
            });
            this.router.navigate(['/account']);
        } catch {
            this.cancelFailed = true;
        } finally {
            this.loading = false;
        }
    }
}
