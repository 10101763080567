import { Component, Input, OnInit } from '@angular/core';
import { MediaSummary } from '../../models';
import { PlaylistService } from '../../services/playlist.service';
import { Utils } from 'src/app/services/utils.service';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-series-view-media-item-v2',
    templateUrl: './series-view-media-item-v2.component.html',
    styleUrls: ['./series-view-media-item-v2.component.scss'],
})

export class SeriesViewMediaItemV2Component implements OnInit {

    @Input() media: MediaSummary;
    @Input() seasonId: number;
    @Input() creatorPageView: boolean = false;

    timeAgo: string;

    addToPlaylistLoading = false;

    constructor(private playlistService: PlaylistService,
                private accountService: AccountService) {
    }

    ngOnInit(): void {
        this.timeAgo = Utils.getTime(this.media.datePublished);
    }

    existsInPlaylist(mediaId: number) {
        return this.playlistService.hasMediaId(mediaId);
    }

    addToPlaylist(media: MediaSummary) {
        if (!this.addToPlaylistLoading) {
            this.addToPlaylistLoading = true;
            this.playlistService.addToPlaylist(media).subscribe({
                next: () => {
                    this.addToPlaylistLoading = false;
                },
                error: () => {
                    this.addToPlaylistLoading = false;
                }
            });
        }
    }

    deleteFromPlaylist(media: MediaSummary) {
        if (!this.addToPlaylistLoading) {
            this.addToPlaylistLoading = true;
            this.playlistService.deleteFromPlaylist(media).subscribe({
                next: () => {
                    this.addToPlaylistLoading = false;
                },
                error: () => {
                    this.addToPlaylistLoading = false;
                }
            });
        }
    }

    isAuthenticated() {
        return this.accountService.isAuthenticated();
    }

}
