import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import {
    SubtitleOption,
    SubtitleOptionColour, SubtitleOptionColourMap,
    SubtitleOptionFont, SubtitleOptionFontMap,
    SubtitleOptions,
    SubtitleOptionShadow, SubtitleOptionShadowMap,
    SubtitleOptionTextSize, SubtitleOptionTextSizeMap
} from '../models/subtitles/subtitle-enums';
import { BehaviorSubject, combineLatest, map, Observable, of, tap } from 'rxjs';
import { SelectOption } from '../pages/account-v2/subtitle-settings/subtitle-settings.component';

@Injectable({
    providedIn: 'root'
})
export class SubtitleService {

    fontOptions: SelectOption[] = [
        { name: 'Typewriter', value: SubtitleOptionFont.Typewriter },
        { name: 'Print', value: SubtitleOptionFont.Print },
        { name: 'Console', value: SubtitleOptionFont.Console },
        { name: 'Block', value: SubtitleOptionFont.Block },
        { name: 'Casual', value: SubtitleOptionFont.Casual },
        { name: 'Cursive', value: SubtitleOptionFont.Cursive },
        { name: 'Small Caps', value: SubtitleOptionFont.SmallCaps },
    ];
    textSizeOptions: SelectOption[] = [
        { name: 'Small', value: SubtitleOptionTextSize.Small },
        { name: 'Medium', value: SubtitleOptionTextSize.Medium },
        { name: 'Large', value: SubtitleOptionTextSize.Large },
    ];
    colourOptions: SelectOption[] = [
        { name: 'transparent', value: SubtitleOptionColour.Transparent },
        { name: 'white', value: SubtitleOptionColour.White },
        { name: 'black', value: SubtitleOptionColour.Black },
        { name: 'red', value: SubtitleOptionColour.Red },
        { name: 'green', value: SubtitleOptionColour.Green },
        { name: 'blue', value: SubtitleOptionColour.Blue },
        { name: 'yellow', value: SubtitleOptionColour.Yellow },
        { name: 'magenta', value: SubtitleOptionColour.Magenta },
        { name: 'lightBlue', value: SubtitleOptionColour.LightBlue },
    ];
    dropShadowOptions: SelectOption[] = [
        { name: 'None', value: SubtitleOptionShadow.None },
        { name: 'Raised', value: SubtitleOptionShadow.Raised },
        { name: 'Depressed', value: SubtitleOptionShadow.Depressed },
        { name: 'Uniform', value: SubtitleOptionShadow.Uniform },
        { name: 'Drop Shadow', value: SubtitleOptionShadow.DropShadow },
    ];

    selectedFont$: BehaviorSubject<SelectOption> = new BehaviorSubject<SelectOption>(this.fontOptions[3]);
    selectedTextSize$: BehaviorSubject<SelectOption> = new BehaviorSubject<SelectOption>(this.textSizeOptions[1]);
    selectedShadow$: BehaviorSubject<SelectOption> = new BehaviorSubject<SelectOption>(this.dropShadowOptions[4]);
    selectedTextColour$: BehaviorSubject<SelectOption> = new BehaviorSubject<SelectOption>(this.colourOptions[1]);
    selectedShadowColour$: BehaviorSubject<SelectOption> = new BehaviorSubject<SelectOption>(this.colourOptions[2]);
    selectedBackgroundColour$: BehaviorSubject<SelectOption> = new BehaviorSubject<SelectOption>(this.colourOptions[2]);
    selectedWindowColour$: BehaviorSubject<SelectOption> = new BehaviorSubject<SelectOption>(this.colourOptions[0]);

    subtitlesRetrieved: boolean = false;

    constructor(private http: HttpClient,
                private env: EnvironmentService) {
    }

    subtitleClass(): Observable<string> {
        return combineLatest([
            this.selectedFont$,
            this.selectedTextSize$,
            this.selectedShadow$,
            this.selectedTextColour$,
            this.selectedShadowColour$,
            this.selectedBackgroundColour$,
            this.selectedWindowColour$,
        ]).pipe(map(([font, size, dropShadow, textColour, dropShadowColour, backgroundColour, windowColour]) => {
            return `${this.fontClass(font.value)} ` +
                `${this.fontSizeClass(size.value)} ` +
                `${this.dropShadowClass(dropShadow.value, dropShadowColour.value)} ` +
                `${this.textColourClass(textColour.value)} ` +
                `${this.backgroundColourClass(backgroundColour.value)}`;
        }));
    }

    fontClass(font: SubtitleOptionFont) {
        return SubtitleOptionFontMap.get(font);
    }

    fontSizeClass(fontSize: SubtitleOptionTextSize): string {
        return SubtitleOptionTextSizeMap.get(fontSize);
    }

    dropShadowClass(shadow: SubtitleOptionShadow, colour: SubtitleOptionColour) {
        let colourStr = SubtitleOptionColourMap.get(colour);
        let shadowStr = SubtitleOptionShadowMap.get(shadow);
        return `${shadowStr}-${colourStr}`;
    }

    textColourClass(value: SubtitleOptionColour) {
        return `font-color-${SubtitleOptionColourMap.get(value)}`;
    }

    backgroundColourClass(value: SubtitleOptionColour) {
        return `background-${SubtitleOptionColourMap.get(value)}`;
    }

    windowColourClass(value: SubtitleOptionColour) {
        return `window-${SubtitleOptionColourMap.get(value)}`;
    }

    buildLocalOptions() {
        return [
            {key: SubtitleOption.Font, value: this.selectedFont$.value.value},
            {key: SubtitleOption.Shadow, value: this.selectedShadow$.value.value},
            {key: SubtitleOption.Background, value: this.selectedBackgroundColour$.value.value},
            {key: SubtitleOption.Window, value: this.selectedWindowColour$.value.value},
            {key: SubtitleOption.TextSize, value: this.selectedTextSize$.value.value},
            {key: SubtitleOption.Colour, value: this.selectedTextColour$.value.value},
            {key: SubtitleOption.ShadowColour, value: this.selectedShadowColour$.value.value}
        ]
    }

    getSubtitleOptions() {
        if (this.subtitlesRetrieved) {
            return of(this.buildLocalOptions());
        }
        return this.http.get<SubtitleOptions[]>(`${this.env.userHost}/v2/subtitleoptions`)
            .pipe(tap(options => {
                for (const option of options) {
                    switch (option.key) {
                        case SubtitleOption.Background:
                            this.selectedBackgroundColour$.next(this.colourOptions.find(x => x.value === option.value));
                            break;
                        case SubtitleOption.Window:
                            this.selectedWindowColour$.next(this.colourOptions.find(x => x.value === option.value));
                            break;
                        case SubtitleOption.Font:
                            this.selectedFont$.next(this.fontOptions.find(x => x.value === option.value));
                            break;
                        case SubtitleOption.Colour:
                            this.selectedTextColour$.next(this.colourOptions.find(x => x.value === option.value));
                            break;
                        case SubtitleOption.Shadow:
                            this.selectedShadow$.next(this.dropShadowOptions.find(x => x.value === option.value));
                            break;
                        case SubtitleOption.ShadowColour:
                            this.selectedShadowColour$.next(this.colourOptions.find(x => x.value === option.value));
                            break;
                        case SubtitleOption.TextSize:
                            this.selectedTextSize$.next(this.textSizeOptions.find(x => x.value === option.value));
                            break;
                    }
                }
                this.subtitlesRetrieved = true;
            }));
    }

    setSubtitleOptions(options: SubtitleOptions[]) {
        return this.http.post(`${this.env.userHost}/v2/subtitleoptions`, options);
    }
}
