<div class="section">
    <img class="d-block d-lg-none w-100" src="{{mobileBackground}}"/>
    <img class="d-none d-lg-block w-100" src="{{webBackground}}"/>
    <div class="d-none d-lg-block section-right-text">
        <h3 class="mb-3">{{title}}</h3>
        <p class="mb-5">{{text}}</p>
        <a *ngIf="url" class="btn btn-primary" href="{{url}}" target="_blank"><b>{{buttonText}}</b></a>
    </div>
</div>

<div class="d-block d-lg-none section px-3 py-5 text-right">
    <h3 class="mb-3">{{title}}</h3>
    <p>{{text}}</p>
    <a *ngIf="url" class="btn btn-primary" href="{{url}}" target="_blank"><b>{{buttonText}}</b></a>
</div>
