import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AvatarService} from 'src/app/services/avatar.service';
import {AppComponent} from 'src/app/app.component';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ActivatedRoute, Router} from '@angular/router';
import {DOC_ORIENTATION, NgxImageCompressService} from 'ngx-image-compress';
import {AuthenticationOverride} from '../../../modules/authentication-override';
import {AccountService} from '../../../services/account.service';
import {StorageManagerService} from '../../../services/storage-manager.service';

declare let Android: any;
declare let window: any;

@Component({
    selector: 'app-profile-picture-v2',
    templateUrl: './profile-picture-v2.component.html',
    styleUrls: ['./profile-picture-v2.component.scss']
})
export class ProfilePictureV2Component extends AuthenticationOverride implements OnInit {

    error: string;
    loading: boolean;
    saveable = false;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];


    constructor(
        private avatarService: AvatarService,
        private ngb: NgbModal,
        private app: AppComponent,
        public router: Router,
        private imageCompress: NgxImageCompressService,
        account: AccountService,
        route: ActivatedRoute,
        storage: StorageManagerService) {
        super(route, storage, router, account);
    }

    fileChangeEvent(event: any): void {
        if (!this.acceptedFileTypes.includes(event.target.files[0].type)) {
            this.error = 'The selected file type is not supported.';
            this.croppedImage = '';
            this.imageChangedEvent = '';
            return;
        }
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.saveable = true;
    }

    imageLoaded() {
        this.error = null;
    }

    loadImageFailed() {
        this.error = 'Failed to load image image.';
    }

    async submit() {
        if (!this.croppedImage) {
            this.error = 'You must select a file for uploading.';
            return;
        }
        this.app.startLoading();
        // Formula for calculating file size from base64 and /1000 for kb
        const originalFileSize = (Math.ceil(this.croppedImage.length / 4) * 3) / 1000;

        // Check if original file size is larger than 2mb
        if (originalFileSize <= 2000) {
            // Check if base64 bloats image above 1mb and if so, compress image quality by 35% (base64 can bloat image up to 33%)
            if (this.croppedImage.length / 1000 > 1000) {
                await this.imageCompress.compressFile(this.croppedImage, DOC_ORIENTATION.Default, 65, 65).then(compressedImage => {
                    this.croppedImage = compressedImage;
                });
            }
            this.uploadImage();
        } else {
            this.error = 'Image size must not exceed 2MB. Image size is ' + (originalFileSize / 1000).toFixed(2) + 'mb';
            this.app.stopLoading();
        }
    }

    uploadImage(): void {
        this.avatarService.upload(this.croppedImage).subscribe({
            next: () => {
                this.app.showSimpleAlert({
                    title: 'Success',
                    body: 'Your profile picture was successfully changed.'
                });
                this.router.navigate(['/account']);

                if (this.isIosApp) {
                    window.webkit.messageHandlers.WVHandler.postMessage('refreshAvatar');
                }

                if (this.isAndroidApp) {
                    Android.refreshAvatar();
                }
            },
            error: () => {
                this.app.stopLoading();
                this.error = 'There was a problem uploading your image.';
            }
        });
    }

}
