import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MediaSummary } from '../models/media-summary';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    search(search, page: number, length = 40): Observable<MediaSummary[]> {
        return this.http.get<MediaSummary[]>(this.envService.contentHost + '/v7/search?s=' + search + '&p=' + page + '&l=' + length)
    }
}
