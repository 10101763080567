<div class="mobile-alert-backdrop">
    <div class="mobile-alert">
        <h4 class="text-center font-weight-bold">Did you know we have an App for a better experience?</h4>
        <div class="d-flex justify-content-between flex-nowrap mt-3">
            <div class="w-50 section-1">
                <a *ngIf="iOS()" [href]='config.remoteConfig.app_store_url' target="_blank" class="d-block w-100">
                    <img class="app-store" alt='Get it on the App Store' src='/assets/apple.svg' class="w-100" />
                </a>
                <a *ngIf="!iOS()" target="_blank" [href]='config.remoteConfig.google_play_url' class="d-block w-100">
                    <img class="app-store" alt='Get it on Google Play' src='/assets/google.svg' class="w-100" />
                </a>
            </div>
            <div class="w-50 section-2">
                <button class="mobile-alert-continue btn btn-outline-primary w-100 no-wrap h-100" (click)="onClose()">
                    Continue On Web
                </button>
            </div>
        </div>
    </div>
</div>
