import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact.service';
import { AppComponent } from 'src/app/app.component';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { AccountService } from '../../../services/account.service';
import { UserInfoService } from '../../../services/user-info.service';
import { Subject, take, takeUntil } from 'rxjs';
import { Utils } from '../../../services/utils.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends AuthenticationOverride implements OnInit, OnDestroy {

    contactForm: UntypedFormGroup;
    submitted = false;
    typeSelected: string;
    selected: string;
    errorMessage: string = '';
    destroy$: Subject<void> = new Subject<void>();

    @ViewChild('emailInput') emailInput;

    constructor(
        private app: AppComponent,
        private contactService: ContactService,
        private formBuilder: FormBuilder,
        public router: Router,
        public config: RemoteConfigService,
        public storage: StorageManagerService,
        private userInfo: UserInfoService,
        route: ActivatedRoute,
        account: AccountService) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            subject: ['', Validators.required],
            message: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]]
        });
        this.userInfo.userInfoUpdated$.pipe(takeUntil(this.destroy$)).subscribe((updated) => {
            console.log(this.userInfo.email);
            if (updated && Utils.isNullOrWhitespace(this.contactForm.value.email)) {
                this.contactForm.setValue({email: this.userInfo.email, name: '', subject: '', message: ''});
            }
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelected(value) {
        this.selected = value;
        if (this.emailInput?.nativeElement) {
            this.emailInput.nativeElement.focus();
        }
    }

    onSubmit() {
        this.submitted = true;

        if (!Utils.isValidEmail(this.contactForm.value.email)) {
            this.errorMessage = 'Please enter a valid email';
            return;
        }

        if (this.contactForm.invalid) {
            return;
        }
        this.app.startLoading();

        this.contactForm.value.selection = this.selected;
        this.contactForm.value.platform = 'Web';
        let httpRequest = this.contactService.contact(this.contactForm.value, this.userInfo.hash);
        if (this.config.remoteConfig.contact_version == 2) {
            this.updateSubject()
            httpRequest = this.contactService.submitTicket(this.contactForm.value);
        }

        httpRequest.subscribe({
            next: () => {
                this.app.showSimpleAlert({
                    title: 'Sent!',
                    body: 'Your support query has been sent, we will get back to you as soon as we can!'
                });

                if (this.isMobile) {
                    this.back('/account');
                    return;
                }

                this.router.navigate(['/']);
            },
            error: err => {
                this.app.stopLoading();
                if (err.status == 400) {
                    console.error(err);
                    this.submitted = false;
                    this.errorMessage = 'Something went wrong submitting the form. Please try again.';
                }
            }
        });
    }

    updateSubject() {
        switch (this.selected) {
            case 'member-feedback':
                this.contactForm.value.subject = 'Feedback - ' + this.contactForm.value.subject;
                break;
            case 'customer-support':
                this.contactForm.value.subject = 'Support - ' + this.contactForm.value.subject;
                break;
            case 'other':
                this.contactForm.value.subject = 'Other - ' + this.contactForm.value.subject;
                break;
        }
    }

    select(value) {
        this.typeSelected = value;
    }
}
