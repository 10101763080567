import { Component, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { UiService } from '../../services/ui.service';

@Component({
	selector: 'app-latest-videos',
	templateUrl: './latest-videos.component.html',
	styleUrls: ['./latest-videos.component.scss']
})
export class LatestVideosComponent implements OnInit {

	channel: any;

	constructor(private ui: UiService,
		private service: ChannelsService) { }

	ngOnInit(): void {
        this.ui.startLoading()
		this.service.getLatestVideosChannel().subscribe(res => {
			this.channel = res;
            this.ui.stopLoading();
		});
	}

}
