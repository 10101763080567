<div class="card-body pt-1 full-width" *ngIf="config.remoteConfig.login_google_enabled || config.remoteConfig.login_apple_enabled">
    <div class="d-flex w-100 justify-content-center position-relative mb-3" *ngIf="config.remoteConfig.login_google_enabled">
        <div *ngIf="disableButtons" class="disabled-overlay"></div>
        <div id="googleBtn" [class.google-btn-disabled]="disableButtons"></div>
    </div>

    <button *ngIf="config.remoteConfig.login_version == 1 && config.remoteConfig.login_google_enabled" [disabled]="disableButtons" class="btn brand-login-button mb-3 btn-outline-light btn-block" (click)="googleLogin()" >
        <svg class="mr-2" width="19" height="19" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg" alt="Google Login">
            <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M16.8433 6.54769C15.6043 5.36599 13.9508 4.72223 12.24 4.74868C9.10936 4.74868 6.45055 6.86074 5.50253 9.70474V9.7048C4.99987 11.1952 4.99987 12.809 5.50253 14.2993H5.50693C6.45934 17.1389 9.11376 19.251 12.2444 19.251C13.8604 19.251 15.2478 18.8376 16.323 18.1076V18.1046C17.5885 17.2668 18.4528 15.9484 18.7217 14.4581H12.24V9.8371H23.5588C23.6999 10.6396 23.766 11.4597 23.766 12.2755C23.766 15.9253 22.4616 19.0111 20.1921 21.1011L20.1944 21.1029C18.2058 22.9372 15.4764 23.9998 12.24 23.9998C7.70278 23.9998 3.55359 21.4424 1.51648 17.3903V17.3902C-0.18551 13.9995 -0.185506 10.0046 1.51649 6.61386H1.51652L1.51648 6.61383C3.55359 2.55725 7.70277 -0.000167897 12.24 -0.000167897C15.2207 -0.0354426 18.1 1.08453 20.2694 3.12164L16.8433 6.54769Z" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
        </svg>
        {{ 'login-continue-google' | translate }}
    </button>
    <button *ngIf="config.remoteConfig.login_apple_enabled" [disabled]="disableButtons" class="btn brand-login-button button-height btn-outline-light btn-block" (click)="appleLogin()">
        <svg class="mr-2" width="19" height="19" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg" alt="Apple Login">
            <g clip-path="url(#clip0)">
                <path
                        d="M21.792 18.7035C21.429 19.542 20.9994 20.3139 20.5016 21.0235C19.8231 21.9908 19.2676 22.6605 18.8395 23.0323C18.1758 23.6427 17.4647 23.9552 16.7032 23.973C16.1566 23.973 15.4973 23.8175 14.73 23.5019C13.9601 23.1878 13.2525 23.0323 12.6056 23.0323C11.9271 23.0323 11.1994 23.1878 10.4211 23.5019C9.64153 23.8175 9.01355 23.9819 8.53342 23.9982C7.80322 24.0293 7.07539 23.7078 6.3489 23.0323C5.88521 22.6279 5.30523 21.9346 4.61043 20.9524C3.86498 19.9035 3.25211 18.6872 2.77198 17.3006C2.25777 15.8029 2 14.3526 2 12.9485C2 11.3401 2.34754 9.95284 3.04367 8.79035C3.59076 7.8566 4.31859 7.12003 5.22953 6.57931C6.14046 6.03858 7.12473 5.76304 8.18469 5.74541C8.76467 5.74541 9.52524 5.92481 10.4704 6.27739C11.4129 6.63116 12.0181 6.81056 12.2834 6.81056C12.4817 6.81056 13.154 6.60079 14.2937 6.18258C15.3714 5.79474 16.281 5.63415 17.0262 5.69741C19.0454 5.86037 20.5624 6.65634 21.5712 8.09037C19.7654 9.18456 18.8721 10.7171 18.8898 12.6831C18.9061 14.2145 19.4617 15.4888 20.5535 16.5006C21.0483 16.9703 21.6009 17.3332 22.2156 17.591C22.0823 17.9776 21.9416 18.348 21.792 18.7035ZM17.161 0.480381C17.161 1.68066 16.7225 2.80135 15.8484 3.83865C14.7937 5.0718 13.5179 5.78437 12.1343 5.67193C12.1167 5.52793 12.1065 5.37638 12.1065 5.21713C12.1065 4.06487 12.6081 2.83172 13.4989 1.82345C13.9436 1.31295 14.5092 0.888472 15.1951 0.54986C15.8796 0.216299 16.5269 0.0318332 17.1358 0.000244141C17.1536 0.160702 17.161 0.32117 17.161 0.480365V0.480381Z" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
        </svg>
        {{ 'login-continue-apple' | translate }}
    </button>
</div>
