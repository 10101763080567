<section class="main main-full" id="signup-section">
    <app-title *ngIf="showPaymentText()" [title]="'create-account' | translate"
        [subtitle]="'payment-type-access-register-text-1' | translate"></app-title>
    <app-title *ngIf="!showPaymentText()" [title]="'create-account' | translate"></app-title>
    <form (ngSubmit)="register()">
        <input [(ngModel)]="viewModel.name" type="text" class="form-control uikit-input" name="name" autofocus
            placeholder="Display Name" tabindex="1" />
        <input [(ngModel)]="viewModel.email" type="email" class="form-control uikit-input" name="email" autofocus
            placeholder="Email" tabindex="2" />
        <app-password-field [(password)]="viewModel.password" [tabIndex]="3"></app-password-field>
        <div *ngIf="mandatoryLegalDocuments.length > 0" class="form-check text-left" id="checkbox-container">
            <div id="legal-list">
                <ng-container *ngFor="let doc of mandatoryLegalDocuments; let i = index">
                    <div class="checkbox-container mb-2" [class.active]="doc.accepted"
                        (click)="doc.accepted = !doc.accepted" (keyup.enter)="doc.accepted = !doc.accepted" [tabindex]="4 + i">
                        <div class="checkbox"></div>
                        <div class="checkbox-label">
                            {{ "i-have-read-and-accept-the" | translate }}
                            <a (click)="viewDocument(doc.typeObj.type, $event)">{{doc.typeObj.name}}</a>
                        </div>
                    </div>
                </ng-container>
                <div class="checkbox-container mb-2" [class.active]="emailPrefsAccepted"
                    (click)="emailPrefsAccepted = !emailPrefsAccepted" (keyup.enter)="emailPrefsAccepted = !emailPrefsAccepted"
                     [tabindex]="4 + mandatoryLegalDocuments?.length">
                    <div class="checkbox"></div>
                    <div class="checkbox-label">{{ "email-special-updates" | translate }}</div>
                </div>
            </div>
        </div>
        <div class="form-group block error" id="error-container">
            <div *ngFor="let errorMessage of errors">
                {{errorMessage}}
            </div>
        </div>
        <button type="submit" id="signup-button" class="btn btn-lg btn-primary btn-block" [tabindex]="5 + mandatoryLegalDocuments?.length">
            {{ 'sign-up' | translate | uppercase }}
        </button>
    </form>
    <or-block *ngIf="config.remoteConfig.login_google_enabled || config.remoteConfig.login_apple_enabled"></or-block>
    <app-brand-buttons [displayName]="viewModel.name" [isTVSignup]="isTVSignup" [disableButtons]="!this.acceptedAllDocuments()" (error)="errors = [$event]"></app-brand-buttons>
    <div id="link-text-container">
        <div class="mt-4 d-flex align-items-center">
            {{ "payment-type-access-register-already-have-account" | translate }}
            <button (click)="changePage('login')" class="btn btn-link p-0 ml-1">{{"payment-type-access-register-login-here" | translate }}</button>
        </div>

        <p class="recaptcha-docs mt-3">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy"><b>Privacy Policy</b></a> and
            <a href="https://policies.google.com/terms"><b>Terms of Service</b></a> apply.
        </p>
    </div>
</section>
