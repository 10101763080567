<div class="channel-header-row">
    <div *ngIf="showBadge">
        <img class="main-channel-header channel-header" *ngIf="image" src="{{image}}" />
    </div>
    <div class="channel-header-row-text">
        <a *ngIf="channel.isShow && channel.nav !== 'none'" [routerLink]="routerLink" [queryParams]="{ref:'sae'}">
            <h3 class="main-channel-header channel-header mr-3"
                *ngIf="channel.name && (!channel.itemType || channel.itemType === 'shows') && channel.type !== 'latest-player'">
                {{channel.name}}
            </h3>
        </a>
        <a *ngIf="channel.type === 'show-list'" [routerLink]="config.remoteConfig.shows_enabled ? ['/shows'] : undefined" [queryParams]="{ref:'sae'}">
            <h3 class="main-channel-header channel-header mr-3"
                *ngIf="channel.name && (!channel.itemType || channel.itemType === 'shows') && channel.type !== 'latest-player'">
                {{channel.name}}
            </h3>
        </a>
        <a *ngIf="channel.nav === 'latest'" [routerLink]="['/latest-videos']" [queryParams]="{ref:'sae'}">
            <h3 class="main-channel-header channel-header mr-3"
                *ngIf="channel.name && (!channel.itemType || channel.itemType === 'shows') && channel.type !== 'latest-player'">
                {{channel.name}}
            </h3>
        </a>
        <h3 class="main-channel-header channel-header mr-3"
            *ngIf="channel.nav === 'none' || (channel.type !== 'show-list' && channel.type !== 'latest-player' && channel.isShow === false) && channel.nav !== 'latest'">
            {{channel.name}}
        </h3>
    </div>
</div>
