<div class="main main-full main-responsive">
	<app-title [title]="'project-points' | translate "></app-title>
	<div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
		<span class="sr-only">{{ "loading" | translate }}</span>
	</div>

	<div *ngIf="!loading">
		<div *ngFor='let point of pointHistory.points' class="mb-2 subscription-row">
			<div><b>{{point.source}}</b></div>
			<div>{{point.date | date:'medium'}}</div>
			<div *ngIf="point.amount >= 0">
				{{ 'point-history-earned' | translate }} {{point.amount}} <img class="icon mb-1"
					src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points" />
			</div>
			<div *ngIf="point.amount < 0">
				{{ 'point-history-spent' | translate }} {{point.amount * -1}} <img class="icon mb-1"
					src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points" />
			</div>
		</div>
		<div *ngIf="!pointHistory.points.length">
			<p>No point history found.</p>
		</div>
        <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
	</div>
</div>
