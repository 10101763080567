export interface ProjectResponse {
    id: number;
    slug: string;
    title: string;
    imageUrl: string;
    target: number | null;
    mediaId: number | null;
    channelId: number | null;
    channelSlug: string;
    points: number | null;
    description: string;
    watchText: string;
}
