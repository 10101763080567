<div class="main-full container-fluid full-width" *ngIf="channelId && channel && (!channel.layout || channel.layout !== 'series-view')">
    <div class="row m-0 justify-content-end">
        <!--<div class="col-9 col-xxl-10 p-0">-->
        <div class="col-12 p-0">
            <app-hero-banner-static-image *ngIf="heroOverride" [hero]="heroOverride"></app-hero-banner-static-image>
            <app-hero-banner *ngIf="heroMedia" [media]="heroMedia"></app-hero-banner>
        </div>
        <div class="col-4 p-0 shift-up-big hide-mobile">
            <!--<h3 id="{{channel.id}}" class="mt-4 title-spacing uppercase text-white d-inline-block">{{channel.name}}</h3>-->
            <button class="btn btn-outline-light btn-slim mt-4 float-right pointer mr-5"
                    (click)="changeOrder()">{{orderType}}</button>
        </div>
    </div>

    <div class="slider-container slider-container-rp pb-3 shift-up mocap-shifted">

        <div class="row">
            <ng-container *ngFor='let m of media; let i = index'>
                <div class="col-12 col-sm-4 p-1 m-0 thumb-margin mb-5 mt-3" *ngIf="i< 3">
                    <app-video-thumb-v2 [media]="m" [hoverOnSmall]="true"></app-video-thumb-v2>
                </div>
                <div class="col-12 col-sm-4 col-md-3 col-xxl-2 p-1 m-0 thumb-margin mb-5 mt-3" *ngIf="i >= 3">
                    <app-video-thumb-v2 [media]="m" [hoverOnSmall]="true"></app-video-thumb-v2>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!-- <app-series-view *ngIf="channelId && channel && channel.layout && channel.layout === 'series-view'" [channel]="channel"></app-series-view> -->
<app-series-view-v2 *ngIf="channelId && channel && channel.layout && channel.layout === 'series-view'" [channel]="channel"></app-series-view-v2>

<div *ngIf="!channelId" class="main-full">
    <app-channel-slider-v2 [type]="'shows'"></app-channel-slider-v2>
</div>
