<!--<div class="main">-->
<!--    <div class="container-fluid">-->
<!--        <div class="row">-->
<!--            <div class="col-12 col-lg-7 col-xl-8 col-xxl-10 pr-lg-1">-->
<!--                <ivs-live *ngIf="url" [url]="url"></ivs-live>-->
<!--            </div>-->
<!--            <div class="col-12 col-lg-5 col-xl-4 col-xxl-2 pl-0">-->
<!--                <app-chat></app-chat>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="main-full">
    <div class="outer-video-container">
        <ivs-live *ngIf="url" [url]="url"></ivs-live>
    </div>
    <div class="chat-container">
        <app-chat></app-chat>
    </div>
</div>
