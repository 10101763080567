<!--<div class="container-fluid full-width mocap">-->
<!--    &lt;!&ndash; <div class="row m-0 justify-content-end">-->

<!--        <div class="col-3 col-xxl-2 p-0 overflow-auto scroll-colour" [style.max-height]="heroHeight">-->
<!--            <div id="shows-container" class="pointer" *ngFor='let c of showChannels' (click)="changeChannel(c.slug)">-->
<!--                <img class="w-100" src="{{c.miniImageUrl}}" alt="{{c.slug}}" />-->
<!--                <img class="w-100 over" *ngIf="c.slug == channelId"-->
<!--                    src="https://cdn.watchcorridor.com/mini/boarder5.png" alt="{{c.slug}}" />-->
<!--            </div>-->
<!--        </div>-->

<!--    </div> &ndash;&gt;-->
<!--	-->
<!--	<h2 class="page-title">LATEST VIDEOS</h2>-->
<!--    <div class="slider-container slider-container-rp pb-3 pt-3">-->

<!--        <div class="row">-->
<!--            <ng-container *ngIf="channel && channel.media">-->
<!--                <ng-container *ngFor='let m of channel.media; let i = index'>-->
<!--                    <div class="col-12 col-sm-4 p-1 m-0 thumb-margin mb-5 mt-3" *ngIf="i< 3">-->
<!--                        <app-video-thumb-v2 [media]="m" [hoverOnSmall]="true"></app-video-thumb-v2>-->
<!--                    </div>-->
<!--                    <div class="col-12 col-sm-4 col-md-3 col-xxl-2 p-1 m-0 thumb-margin mb-5 mt-3" *ngIf="i >= 3">-->
<!--                        <app-video-thumb-v2 [media]="m" [hoverOnSmall]="true"></app-video-thumb-v2>-->
<!--                    </div>-->
<!--                </ng-container>-->
<!--            </ng-container>-->


<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<app-series-view-v2 *ngIf="channel" [channel]="channel"></app-series-view-v2>
