import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-up-next-switch',
    templateUrl: './up-next-switch.component.html',
    styleUrls: ['./up-next-switch.component.scss']
})
export class UpNextSwitchComponent implements OnInit {

    autoplay: boolean = true;
    @Output() autoPlayChanged = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
        if (localStorage.getItem('autoplay')) {
            this.autoplay = JSON.parse(localStorage.getItem('autoplay'));
            this.autoPlayChanged.emit(this.autoplay);
        }
    }

    changed() {
        localStorage.setItem('autoplay', JSON.stringify(this.autoplay));
        this.autoPlayChanged.emit(this.autoplay);
    }

}
