import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { forkJoin, lastValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { RemoteConfig } from '../models/remote-config';
import { Route, Router, Routes } from '@angular/router';
import { RedirectGuard } from '../guards/redirect.guard';
import { MakersplaceComponent } from '../pages/makersplace/makersplace.component';
import { SmushBrethrenComponent } from '../pages/smush-brethren/smush-brethren.component';
import { LearnMoreComponent } from '../pages/learn-more/learn-more.component';
import { LiveComponent } from '../pages/live/live.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { LiveChatComponent } from '../pages/live-chat/live-chat.component';
import { ProjectsVsComponent } from '../pages/projects-vs/projects-vs.component';
import { BrandsComponent } from '../pages/brands/brands.component';
import { BadgesComponent } from '../pages/account-v2/badges/badges.component';
import { AuthGuard } from '../guards/auth.guard';

const authRequired = () => redirectUnauthorizedTo(['signup']);

@Injectable({
    providedIn: 'root'
})
export class RemoteConfigService {

    private _remoteConfig: RemoteConfig;

    get remoteConfig(): RemoteConfig {
        return this._remoteConfig;
    }

    constructor(private http: HttpClient,
                private env: EnvironmentService,
                private router: Router) {

    }

    getConfig(): Observable<RemoteConfig> {
        return this.http.get<RemoteConfig>(`${this.env.contentCdnHost}/v1/remoteconfig`);
    }

    getTheme(): Observable<string> {
        return this.http.get(`${this.env.contentCdnHost}/v1/remoteconfig/css`, { responseType: 'text' });
    }

    init(): Promise<any> {
        return lastValueFrom(
            forkJoin([
                this.getConfig().pipe(map(config => {
                    this._remoteConfig = config;
                    this._remoteConfig.livestream_enabled = true;
                    this.updateRoutes(config);
                    return config;

                })),
                this.getTheme().pipe(map(cssVariables => {
                    const headTag = document.getElementsByTagName('head')[0];
                    const styleTag = document.createElement('style');

                    styleTag.innerHTML = `:root {${cssVariables}}`;
                    headTag.appendChild(styleTag);
                    return cssVariables;
                }))
            ])
        );
    }

    private updateRoutes(config: RemoteConfig) {
        const currentConfig = this.router.config;

        const merchLinks: Routes = [
            { path: 'merch', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: config.store_url } },
            { path: 'store', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: config.store_url } },
            { path: 'faq', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: config.faq_url } }
        ];

        const corridorLinks = [
            { path: 'top10images', loadChildren: () => import('../routes/top10/top10.module').then(m => m.Top10Module) },
            { path: 'toptenimages', loadChildren: () => import('../routes/top10/top10.module').then(m => m.Top10Module) },
            { path: 'makersplace', component: MakersplaceComponent },
            { path: 'crew-member', component: LearnMoreComponent },
            { path: 'brands', component: BrandsComponent },
        ];

        const learnMore: Route = { path: 'learn-more', component: LearnMoreComponent };

        const projects: Routes = [
            { path: 'greenlight', component: ProjectsVsComponent },
            { path: 'greenlight-beta', component: ProjectsVsComponent }
        ];
        const badges: Route = { path: 'account/badges', component: BadgesComponent };

        const live: Routes = [
            { path: 'live/:id', component: LiveComponent, canActivate: [AuthGuard], data: { authGuardPipe: authRequired } },
            { path: 'live-chat/:id', component: LiveChatComponent, canActivate: [AuthGuard], data: { authGuardPipe: authRequired } },
        ];

        currentConfig.unshift(...merchLinks);

        const path = currentConfig.find(x => x.path === '');
        if (config.projectName === 'corridor') {
            path.children.splice(1, 0, ...corridorLinks);
        }
        if (config.learn_more_enabled) {
            path.children.splice(1, 0, learnMore);
        }
        if (config.projects_enabled) {
            path.children.splice(1, 0, ...projects);
        }
        if (config.badges_enabled) {
            path.children.splice(1, 0, badges);
        }
        if (config.livestream_enabled) {
            path.children.splice(1, 0, ...live);
        }
    }
}
