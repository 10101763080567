import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth, createUserWithEmailAndPassword, updateProfile} from '@angular/fire/auth';
import {AppComponent} from '../../../app.component';
import {UiService} from '../../../services/ui.service';
import {LegalDocumentsService} from '../../../services/legal-documents.service';
import {AccountService} from '../../../services/account.service';
import {RemoteConfigService} from 'src/app/services/remote-config.service';
import {lastValueFrom} from 'rxjs';
import { Utils } from '../../../services/utils.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    hidePassword = true;
    errorMessage: string;
    errors: Array<string> = [];

    isTVSignup: boolean = false;

    mandatoryLegalDocuments: any;
    emailPrefsAccepted: boolean = false;
    documentTypes: any;
    uncheckedDocs = '';

    viewModel: { name: string, email: string, password: string } = {
        name: '',
        email: '',
        password: ''
    };

    constructor(
        private afAuth: Auth,
        private app: AppComponent,
        private router: Router,
        private route: ActivatedRoute,
        private legalDocumentsService: LegalDocumentsService,
        private ui: UiService,
        private accountService: AccountService,
        public config: RemoteConfigService) {
    }

    ngOnInit(): void {
        this.loadLegalDocuments();
        this.isTVSignup = this.route.snapshot.data['isTVSignup'] ?? false;
    }

    validateFields(): Array<string> {
        const errors = [];

        if (!this.viewModel.name) {
            errors.push('Display Name is required.');
        }
        if (this.viewModel.name && this.viewModel.name.length < 3) {
            errors.push('Display Name must be at least 3 characters long.');
        }
        if (Utils.isNullOrWhitespace(this.viewModel.email)) {
            errors.push('Email is required.');
        }
        if (Utils.isNullOrWhitespace(this.viewModel.password)) {
            errors.push('Password is required.');
        }
        if (!Utils.isValidEmail(this.viewModel.email)) {
            errors.push('Email is not valid');
        }
        if (!this.acceptedAllDocuments()) {
            errors.push('You must accept the ' + this.uncheckedDocs);
        }
        this.errors = errors;
        return errors;
    }

    async register() {
        if (this.validateFields().length > 0) {
            return;
        }

        this.app.startLoading();

        try {
            await lastValueFrom(this.accountService.checkIp());
        } catch (e) {
            this.errors.push('You have exceeded the maximum number of attempts to register. Please contact support.');
            this.app.stopLoading();
            return;
        }

        try {
            console.log(this.config.remoteConfig);
            if (this.config.remoteConfig.login_version == 1) {
                await this.registerV1();
            }
            if (this.config.remoteConfig.login_version == 2) {
                await this.registerV2();
            }

            this.ui.startLoading();
            this.acceptDocuments();
            this.setEmailPreferences(this.emailPrefsAccepted);
            this.isTVSignup ? this.router.navigate(['/tv']) : (this.config.remoteConfig.payment_flow_enabled ? this.router.navigate(['/payment']) : this.router.navigate(['/']));
        } catch (error) {
            this.errors = [];
            if (error.code && error.code === 'auth/invalid-email') {
                this.errors.push('Email Address invalid.');
                return;
            }
            this.errors.push(this.app.getErrorMessage(error));
        } finally {
            this.app.stopLoading();
        }


    }

    async registerV1() {
        const createRes = await createUserWithEmailAndPassword(this.afAuth, this.viewModel.email, this.viewModel.password);

        updateProfile(createRes.user, {
            displayName: this.viewModel.name,
            photoURL: this.config.remoteConfig.default_avatar
        });

        await this.app.sharedLoginV2(createRes, this.viewModel.name);
    }

    async registerV2() {
        await lastValueFrom(this.accountService.registerV2(this.viewModel.email, this.viewModel.password, this.viewModel.name));
        await this.app.sharedLoginV2();
    }

    setEmailPreferences(accepted: boolean) {
        this.accountService.submitEmailPreferences({
            corridorNewsEmails: accepted,
            corridorStoreEmails: accepted,
            liveStreamEmails: accepted,
            videoNotificationEmails: accepted,
        }).subscribe({
            next: (e) => {
                console.log('Email preferences set.');
            },
            error: (e) => {
                console.log(e);
                console.error('Could not set email preferences.');
            }
        });
    }

    loadLegalDocuments() {
        this.mandatoryLegalDocuments = this.route.snapshot.data.documentData;
        // Remove refund policy as it's present in the payment step.
        const refundIndex = this.mandatoryLegalDocuments.findIndex(x => x.type === 3);
        if (refundIndex > -1) {
            this.mandatoryLegalDocuments.splice(this.mandatoryLegalDocuments.indexOf(x => x.type === 3), 1);
        }
        this.documentTypes = this.route.snapshot.data.documentTypeData;

        if (this.mandatoryLegalDocuments.length > 0) {
            this.mandatoryLegalDocuments.forEach((doc, index) => {
                doc.accepted = false;
                doc.typeObj = this.documentTypes.find(type => type.id === doc.type);
            });
        }
    }

    viewDocument(type, event) {
        event.preventDefault();
        event.stopPropagation();
        window.open(`/legaldocuments/${type}`);
    }

    acceptDocuments() {
        const acceptedDocumentsIds = [];
        this.mandatoryLegalDocuments.forEach(doc => {
            acceptedDocumentsIds.push(doc.id);
        });

        this.legalDocumentsService.acceptLegalDocuments(acceptedDocumentsIds).subscribe(res => {
            if (res === true) {
                this.ui.stopShowingLegalDocumentsModal();
                return true;
            }
        });
        return false;
    }

    acceptedAllDocuments() {
        let verification = true;
        let docs = '';
        this.mandatoryLegalDocuments.forEach(doc => {
            if (!doc.accepted) {
                if (docs !== '') {
                    docs += ' and ';
                }
                docs += doc.typeObj.name;
                verification = false;
            }
        });
        this.uncheckedDocs = docs;
        return verification;
    }

    changePage(page: string) {
        this.accountService.navigateToAccessPage(page);
    }

    showPaymentText() {
        return this.config.remoteConfig.payment_flow_enabled;
    }

    togglePassword() {
        this.hidePassword = !this.hidePassword;
    }
}
