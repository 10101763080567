import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export enum Environment {
    Production = 'production',
    Staging = 'staging',
    Local = 'local',
}

@Injectable({ providedIn: 'root' })
export class EnvironmentService {

    private _localContentApi: boolean = false;
    private _localUserApi: boolean = false;
    private _bypassCache: boolean = false;
    private _connectToProdLocal: boolean = false;

    private _environment: Environment;
    private _host: string = '';
    private _domain = environment.domain;

    // private _hasStaging: string[] = ['Corridor Digital'];
    private _hasStaging: string[] = [];

    get env(): Environment {
        return this._environment;
    }

    get imageHost(): string {
        return 'https://images' + this._host + this._domain;
    }

    get userHost(): string {
        if (this._localUserApi) {
            return 'http://localhost:52902';
        }
        return 'https://user' + this._host + this._domain;
    }

    get userCdnHost(): string {
        if (this._localUserApi) {
            return 'http://localhost:52902';
        }
        return 'https://user-cache' + this._host + this._domain;
    }

    get contentHost(): string {
        if (this._localContentApi) {
            return 'http://localhost:52902';
        }
        return 'https://content' + this._host + this._domain;
    }

    get contentCdnHost(): string {
        if (this._localContentApi) {
            return 'http://localhost:52902';
        }
        return 'https://content' + (this._bypassCache ? '' : '-cache') + this._host + this._domain;
    }

    get websocketCorridor(): string {
        return 'wss://ws' + this._host + this._domain;
    }

    get stripePk(): string {
        if (this._environment === Environment.Staging || this._environment === Environment.Local || this._environment === undefined) {
            return environment.stripe.pk_test;
        }
        return environment.stripe.pk_live;
    }

    constructor() {
    }

    init(): Promise<void> {
        return new Promise(resolve => {
            this.setEnvironmentVariables();
            resolve();
        });
    }

    private setEnvironmentVariables(): void {
        let hostname = window && window.location && window.location.hostname;

        if (hostname.includes('localhost')) {
            // so we don't have to keep commenting this out for pepperbox etc.
            if (!this._connectToProdLocal && this._hasStaging.includes(environment.name)) {
                hostname = Environment.Staging;
                this._host = '-staging';
            }
        } else {
            this._localContentApi = false; // don't let non-local connect to local api ever!
            this._localUserApi = false; // don't let non-local connect to local api ever!
            this._bypassCache = false;
            this._connectToProdLocal = false;
        }

        if (hostname.includes('staging')) {
            this._environment = Environment.Staging;
            this._host = '-staging';
        } else {
            this._environment = Environment.Production;
            this._host = '';
        }
    }
}
