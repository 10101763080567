<form id="signup-form" class="signup-form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate
	class="needs-validation">
	<div class="form-group">
		<label for="voucherCode">{{ "account-voucher-code" | translate }}</label>
		<input type="text" name="voucherCode" class="form-control" autoFocus [(ngModel)]="code"
			placeholder="{{ 'account-enter-voucher-code' | translate }}" required #voucherCode="ngModel">
	</div>
	<p class="text-danger" *ngIf="error">{{error}}</p>
	<div class="form-group">
		<button type="submit" class="btn btn-outline-primary btn-slim">{{ 'account-voucher-submit' | translate
			}}</button>
	</div>
</form>