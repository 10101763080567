import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-purchase-cancelled',
    templateUrl: './purchase-cancelled.component.html',
    styleUrls: ['./purchase-cancelled.component.css']
})
export class PurchaseCancelledComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
