import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Utils } from '../../services/utils.service';
import { MetadataService } from '../../services/metadata.service';
import { Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CreatorInfoResponse, MetadataDataResponseV3 } from '../../models/metadata-response-v3';
import { CreatorTeamService } from '../../services/creator-team.service';
import { RemoteConfigService } from '../../services/remote-config.service';

@Component({
    selector: 'app-video-description',
    templateUrl: './video-description.component.html',
    styleUrls: ['./video-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoDescriptionComponent implements OnInit {

    @Input() mediaId: number;
    @Input() videoLoaded: boolean = false;
    @Input() safariHls: boolean = false;

    @Output() reportClicked = new EventEmitter<void>();

    metadata$: Observable<MetadataDataResponseV3>;
    showShort$ = new BehaviorSubject<boolean>(true);
    creatorDetails$: Observable<CreatorInfoResponse>;
    followDone$ = new BehaviorSubject<number>(0);

    destroy$: Subject<void> = new Subject<void>();

    constructor(private metadataService: MetadataService,
                private titleService: Title,
                private router: Router,
                private creatorTeamService: CreatorTeamService,
                public config: RemoteConfigService) {
    }

    ngOnInit(): void {
        this.metadata$ = this.metadataService.getMetadataV3(this.mediaId)
            .pipe(
                map(res => {
                    this.titleService.setTitle(res.metadata.title + ' - ' + environment.name);

                    res.metadata.datePublished = Utils.getTime(res.metadata.datePublished);
                    if (res.metadata.creatorTeamId) {
                        this.creatorDetails$ = this.creatorTeamService.getVideoDetails(res.metadata.creatorTeamId);
                    }
                    return res.metadata;
                }),
                catchError(err => {
                    if (err.status === 404) {
                        this.router.navigate(['/not-found']);
                    } else {
                        this.router.navigate(['/']);
                    }
                    throw err;
                })
            );
    }

    toggleDescription() {
        this.showShort$.next(!this.showShort$.value);
    }

    toggleFollow(isFollowing: boolean) {
        if (isFollowing) {
            this.followDone$.next(this.followDone$.value + 1);
        } else {
            this.followDone$.next(this.followDone$.value - 1);
        }
    }
}
