import { Component, OnInit } from '@angular/core';
import { SmushService } from 'src/app/services/smush.service';
import { SmushResponse } from '../../../models';

@Component({
    selector: 'app-smush-download',
    templateUrl: './smush-download.component.html',
    styleUrls: ['./smush-download.component.css']
})
export class SmushDownloadComponent implements OnInit {

    message = 'loading...';
    smush: SmushResponse;

    constructor(private smushService: SmushService) {
    }

    ngOnInit(): void {
        this.smushService.getSmush().subscribe(res => {
            this.smush = res;
        }, err => {
            this.message = 'An active subscription is required to download.';
        });
    }

}
