import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

	httpSubscription: Subscription;
	searchTerm: string;
	media: any;
	timer: any;
	message: string;
	page: number = 0;
	noMoreSearch: boolean = false;
	additionalSearching: boolean = false;
    searchLength = 40;

	constructor(private searchService: SearchService,
		private route: ActivatedRoute,
                private router: Router
	) {
	}

	ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.search?.length > 0) {
                this.onSearchChange(params.search);
            }
        })

	}

	ngOnDestroy() {
		this.httpSubscription?.unsubscribe();
        clearTimeout(this.timer);
	}

	onSearchChange(searchValue: string) {
        if (searchValue === this.searchTerm) {
            return;
        }

		this.message = 'Searching...';
		this.page = 0;
		this.noMoreSearch = false;
		this.media = null;
		if (this.httpSubscription) {
			this.httpSubscription.unsubscribe();
		}
		this.searchTerm = searchValue;

		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.httpSubscription = this.searchService.search(this.searchTerm, 0).subscribe(res => {
                this.router.navigate([], {  relativeTo: this.route, queryParams: { search: this.searchTerm } });
				this.media = res;
				this.message = null;
				if (this.media.length === 0) {
					this.message = 'No results.';
				}
			});
		}, 500);
	}

	@HostListener("window:scroll", ["$event"])
	onWindowScroll() {
		let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
		let max = document.documentElement.scrollHeight;
		if (pos > max - 20) {
			if (this.noMoreSearch || this.additionalSearching) {
				return;
			}

			this.additionalSearching = true;
			this.page++;
			this.searchService.search(this.searchTerm, this.page, this.searchLength).subscribe(res => {
				this.additionalSearching = false;
				if (!this.media) {
					return;
				}
				if (res.length > 0) {
					this.media = this.media.concat(res);

                    if (res.length < this.searchLength) {
                        this.noMoreSearch = true;
                        this.message = "No more results."
                    }
				}
				else {
					this.noMoreSearch = true;
					this.message = "No more results."
				}
			})
		}
	}
}
