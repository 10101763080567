import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { PaymentLine } from '../../../models/payment-line';
import { AccountService } from '../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';

@Component({
  selector: 'app-payment-history-v2',
  templateUrl: './payment-history-v2.component.html',
  styleUrls: ['./payment-history-v2.component.scss']
})
export class PaymentHistoryV2Component extends AuthenticationOverride implements OnInit {

	loading: boolean;
    payments: PaymentLine[];

    constructor(private subscriptionService: SubscriptionService,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loading = true;
        this.subscriptionService.getPayments().subscribe(res => {
            this.payments = res;
            this.loading = false;
        });
    }
}
