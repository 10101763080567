import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { MediaSummary } from '../models';

@Injectable({
    providedIn: 'root'
})
export class RecommendationsService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    // getContentRecommendations(mediaId: number): Observable<MediaSummary[]> {
    //     return this.http
    //         .get<MediaSummary[]>(this.envService.contentCdnHost + '/v4/recommendations/' + mediaId)
    //         .pipe(
    //             catchError(error => {
    //                 if (!environment.production)
    //                     console.error('RecommendationsService::Error::GET', error);
    //                 return throwError(error);
    //             }));
    // }

    getContentRecommendationsV5(mediaId: number, seasonId: number, order: string): Observable<MediaSummary[]> {
        return this.http
            .get<MediaSummary[]>(this.envService.contentCdnHost + '/v5/recommendations/' + mediaId + "?seasonId=" + seasonId + "&order=" + order)
            .pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('RecommendationsService::Error::GET', error);
                    return throwError(error);
                }));
    }
}
