import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LegalDocument } from 'src/app/models/legal-document';
import { LegalDocumentsService } from '../legal-documents.service';

@Injectable({
	providedIn: 'root'
})
export class LegalDocumentResolver implements Resolve<any> {

	constructor(private legalDocumentsService: LegalDocumentsService) { }

	resolve(route: ActivatedRouteSnapshot): Observable<LegalDocument> {
		return this.legalDocumentsService.getLatestDocumentByType(route.paramMap.get('type'));
	}
}
