<div class="main main-full main-responsive">
	<app-title [title]="'account-payment-method' | translate "></app-title>
	<div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
		<span class="sr-only">{{ "loading" | translate }}</span>
	</div>

	<div *ngIf="!loading">
		<ng-container *ngFor='let subscription of subscriptions'>
			<app-subscription-row-v2 [subscription]="subscription" [showConvert]="showConvert()">
			</app-subscription-row-v2>
		</ng-container>
		<div *ngIf="subscriptions && subscriptions.length === 0">{{ "account-payment-method-no-subs" | translate }}
		</div>
		<small>{{ "account-payment-method-old-methods" | translate }}</small>

		<h4 class="mt-4">{{ "account-payment-method-cards" | translate }}</h4>
		<div *ngIf="cardsLoading" class="spinner-border text-light ml-4" role="status">
			<span class="sr-only">{{ "loading" | translate }}</span>
		</div>
		<ng-container *ngIf="!cardsLoading">
			<ng-container *ngFor='let card of cards'>
				<app-card-row-v2 [card]="card" (removed)="onCardRemoved($event)"></app-card-row-v2>
			</ng-container>
			<ng-container *ngIf="cards && cards.length === 0">
				<div>{{ "account-payment-method-no-cards" | translate }}</div>
				<button (click)="addCard()" class="btn btn-slim btn-outline-primary mt-2">{{
					"account-payment-method-add-card" | translate }}</button>
			</ng-container>
		</ng-container>
		<div class="d-block"></div>
        <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
	</div>
</div>
