import { Component, Input, OnInit } from '@angular/core';
import { ChannelsService } from '../../services/channels.service';
import { MetadataService } from '../../services/metadata.service';
import { PlaylistService } from '../../services/playlist.service';
import { Utils } from '../../services/utils.service';
import { Subscription } from 'rxjs';
import { RemoteConfigService } from '../../services/remote-config.service';
import { WatchHistoryService } from '../../services/watch-history.service';

@Component({
    selector: 'app-video-thumb-open',
    templateUrl: './video-thumb-open.component.html',
    styleUrls: ['./video-thumb-open.component.scss']
})
export class VideoThumbOpenComponent implements OnInit {

    @Input() media: any;
    @Input() lazyLoadOff: boolean;

    loadImages = false;
    progress: number;
    timeAgo: string;
    newVideo: boolean = false;
    watchSubscription: Subscription;
    watched: Boolean = false;

    constructor(private channelService: ChannelsService,
        private metadataService: MetadataService,
                public config: RemoteConfigService,
                private watchHistoryService: WatchHistoryService) {
    }

    ngOnInit(): void {
        if (this.lazyLoadOff) {
            this.load();
        }

        if (this.media.previewImage?.indexOf('?a=') > 0) {
            this.media.previewImage = this.media.previewImage + '&w=600'
        }

        if (!this.media.title && this.media.mediaId) {
            this.metadataService.getMetadata(this.media.mediaId).subscribe(res => {
                this.media.title = res.metadata.title;
                this.media.shortDescription = res.metadata.shortDescription;
                this.timeAgo = Utils.getTime(this.media.datePublished);
            });
        } else {
            if (this.media.images) {
                this.media.image = this.media.images.find(x => x.type === 'thumbnail')?.url;
                this.media.channelBadgeUrl = this.media.images.find(x => x.type === 'channel-badge')?.url;
                this.media.previewImage = this.media.images.find(x => x.type === 'media-preview')?.url;
            }
            this.timeAgo = Utils.getTime(this.media.datePublished);
        }

        this.watchSubscription = this.watchHistoryService.watchHistory.subscribe(res => {
            this.watchHistory(res);
        });
    }

    ngOnDestroy(): void {
        this.watchSubscription.unsubscribe();
    }

    checkNew() {
        this.newVideo = false;
        const timestamp = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
        const releaseTime = new Date(this.media.datePublished).getTime();

        if (releaseTime > timestamp && (!this.progress || this.progress <= 0)) {
            this.newVideo = true;
        }
    }

    watchHistory(history: any[]) {
        if (history && history.length > 0) {
            let result = history.find(f => f.mediaId == this.media.mediaId);
            if (result) {
                this.progress = result.percentage;
            }

            if (!this.progress || this.progress <= 0) {
                this.checkNew();
            } else {
                this.watched = this.config.remoteConfig.watched_tick_enabled && true;
            }
        }
    }

    load() {
        this.loadImages = true;
    }
}
