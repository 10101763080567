import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlaylistService } from 'src/app/services/playlist.service';
import { MediaSummary } from '../../models';
import { RemoteConfigService } from '../../services/remote-config.service';
import { WatchHistoryService } from '../../services/watch-history.service';
import { AccountService } from '../../services/account.service';
import { UiService } from '../../services/ui.service';

@Component({
	selector: 'app-hero-banner',
	templateUrl: './hero-banner.component.html',
	styleUrls: ['./hero-banner.component.scss']
})
export class HeroBannerComponent implements OnInit, OnDestroy {

	watchSubscription: Subscription;
	newVideo: boolean = false;
    watched: boolean = false;
	progress: number;

	addToPlaylistLoading: boolean = false;

	@Input() media: MediaSummary;
	@Input() seriesView: boolean;
	@Input() heroWeb: string;
	@Input() heroMobile: string;
    @Input() logo: string;
    @Input() showNew: boolean;


	heroMobileImage: string;
	heroWebImage: string;

	//@ViewChild('heroBanner') private heroBanner: ElementRef;

	constructor(
		private playlistService: PlaylistService,
        public config: RemoteConfigService,
        private watchHistoryService: WatchHistoryService,
        private account: AccountService,
        private ui: UiService) {
	}

	ngOnInit(): void {
		this.watchSubscription = this.watchHistoryService.watchHistory.subscribe(res => {
			this.watchHistory(res);
		});

		this.heroMobileImage = this.media?.images.find(x => x.type === 'hero-mobile')?.url;
		this.heroWebImage = this.media?.images.find(x => x.type === 'hero-web')?.url;
	}

	ngOnDestroy(): void {
		this.watchSubscription.unsubscribe();
	}

	addToPlaylist(media: MediaSummary) {
		this.ui.startLoading();
		if (!this.addToPlaylistLoading) {
			this.addToPlaylistLoading = true;
			this.playlistService.addToPlaylist(media).subscribe({
				next: () => {
					this.addToPlaylistLoading = false;
					this.ui.stopLoading();
				},
				error: () => {
					this.addToPlaylistLoading = false;
					this.ui.stopLoading();
				}
			});
		}
	}

	deleteFromPlaylist(media: MediaSummary) {
		this.ui.startLoading();
		if (!this.addToPlaylistLoading) {
			this.addToPlaylistLoading = true;
			this.playlistService.deleteFromPlaylist(media).subscribe({
				next: () => {
					this.addToPlaylistLoading = false;
					this.ui.stopLoading();
				},
				error: () => {
					this.addToPlaylistLoading = false;
					this.ui.stopLoading();
				}
			});
		}
	}

	existsInPlaylist(mediaId: number) {
		return this.playlistService.hasMediaId(mediaId);
	}

	watchHistory(history: any[]) {
		if (history && history.length > 0) {
			let result = history.find(f => f.mediaId == this.media.mediaId);
			if (result) {
				this.progress = result.percentage;
			}
            if (!this.progress || this.progress <= 0) {
                this.checkNew();
            } else {
                this.watched = this.config.remoteConfig.watched_tick_enabled && true;
            }
		}
	}

	checkNew() {
		this.newVideo = false;
		const timestamp = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
		const releaseTime = new Date(this.media.datePublished).getTime();

		if (releaseTime > timestamp && (!this.progress || this.progress <= 0)) {
			this.newVideo = true;
		}
	}

	isAuthenticated() {
		return this.account.isAuthenticated();
	}
}
