import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { ChannelResponse, MediaSummary } from '../../models';
import { Subject, takeUntil } from 'rxjs';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-continue-watching',
    templateUrl: './continue-watching.component.html',
    styleUrls: ['./continue-watching.component.css']
})
export class ContinueWatchingComponent implements OnInit, OnDestroy {

    @Input() channel: ChannelResponse;
    mediaList: MediaSummary[];
    mediaList2: MediaSummary[] = [{}, {}, {}, {}, {}, {}, {}];

    loaded: boolean = false;
    destroy = new Subject<void>();
    isMobileView = false;

    constructor(private channelService: ChannelsService,
                private ui: UiService) {
        this.ui.breakpointObservable
            .pipe(takeUntil(this.destroy))
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize < ScreenSizeEnum.Large;
            });
    }

    ngOnInit(): void {
        this.channelService.userChannels()
            .pipe(takeUntil(this.destroy))
            .subscribe(res => {
                if (res) {
                    this.mediaList = null;
                    setTimeout(() => {
                        this.mediaList = res.find(f => f.type === 'continue-watching').media;
                    });
                    this.mediaList2 = null;
                }
            });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

}
