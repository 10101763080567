<div class="main main-full config-background-2">
  <div *ngIf="!error" class="loader-content">
    <div class="loading">
      <p>processing payment</p>
      <span></span>
    </div>
  </div>
  <div *ngIf="error" class="container h-100">
    <div class="row h-100">
      <div class="col-12">
        <div class="entry-content">
          <h2 class="form-title">An error has occurred!</h2>
          <p>We couldn't find a successful payment, please contact support so we can resolve your issue.</p>
          <a routerLink="/home/feedback" [queryParams]="{ref:'payment-page'}">
            GET HELP
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
