import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';
import { EnvironmentService } from './environment.service';
import { ProjectPage, ProjectResponse, ProjectResponseV5 } from '../models';
import { ProjectVsResponse } from '../models/project-page/v5/project-vs-response';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

    pointContributionHidden: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private projectId: number;

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient,
        private messenger: MessageService) {
    }

    getActiveProjectsV5(): Observable<ProjectVsResponse> {
        return this.http
            .get<ProjectVsResponse>(this.envService.contentHost + '/v5/projects/active');
    }

    getPastProjectsV5(): Observable<ProjectResponseV5[]> {
        return this.http
            .get<ProjectResponseV5[]>(this.envService.contentHost + '/v5/projects/past');
    }

    getActiveProjects(): Observable<ProjectResponse[]> {
        return this.http
            .get<any>(this.envService.contentCdnHost + '/v3/projects/active')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('ProjectsService::Error::GET', error);
                return throwError(error);
            }));
    }

    getProjects(type: string): Observable<ProjectResponse[]> {
        return this.http
            .get<ProjectResponse[]>(this.envService.contentCdnHost + '/v3/projects/' + type)
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('ProjectsService::Error::GET', error);
                return throwError(error);
            }));
    }

    getCompleteProjects(): Observable<ProjectResponse[]> {
        return this.http
            .get<ProjectResponse[]>(this.envService.contentCdnHost + '/v3/projects/complete')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('ProjectsService::Error::GET', error);
                return throwError(error);
            }));
    }

    getCompleteProjectsV3(): Observable<ProjectResponse[]> {
        return this.http
            .get<ProjectResponse[]>(this.envService.contentCdnHost + '/v3/projects/released')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('ProjectsService::Error::GET', error);
                return throwError(error);
            }));
    }

    getActiveProjectsV3(): Observable<ProjectResponse[]> {
        return this.http
            .get<ProjectResponse[]>(this.envService.contentCdnHost + '/v3/projects/active')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('ProjectsService::Error::GET', error);
                return throwError(error);
            }));
    }

    getProject(projectSlug: string): Observable<ProjectResponse> {
        return this.http
            .get<ProjectResponse>(this.envService.contentCdnHost + '/v3/projects/' + projectSlug)
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('ProjectsService::Error::GET', error);
                return throwError(error);
            }));
    }

    getProjectPage(projectSlug: string): Observable<ProjectPage> {
        return this.http
            .get<ProjectPage>(this.envService.contentCdnHost + '/v1/projectpage/' + projectSlug)
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('ProjectsService::Error::GET', error);
                return throwError(error);
            }));
    }

    contributePoints(projectId: number, amount: number): Promise<string> {
        return new Promise((resolve, reject) => {
            // this.app.startLoading()
            this.contributeToProject(projectId, amount).subscribe({
                next: () => {
                    this.closePointContribution();
                    this.messenger.sendMessage('getUserInfo');
                    resolve('Complete');
                },
                error: error => {
                    reject(error.error.Amount[0]);
                }
            });
        });
    }

    private contributeToProject(id: number, amount: number): Observable<any> {
        return this.http
            .post<any>(this.envService.contentHost + '/v5/projects/' + id + '/' + amount, {});
    }


    closePointContribution() {
        this.pointContributionHidden.next(false);
    }

    openPointContribution(projectId: number) {
        this.projectId = projectId;
        this.pointContributionHidden.next(true);
    }
}
