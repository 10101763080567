import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { RateResponseModel } from '../models/rate-response';

@Injectable({
    providedIn: 'root'
})
export class RateMediaService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getRating(mediaId: number): Observable<RateResponseModel> {
        return this.http.get<any>(this.envService.userHost + '/v1/rateMedia/' + mediaId)
    }

    getRatingAnonymous(mediaId: number): Observable<RateResponseModel> {
        return this.http.get<any>(this.envService.userHost + '/v1/rateMedia/' + mediaId + '/anonymous')
    }

    setRating(mediaId: number, liked: boolean): Observable<any> {
        return this.http.post<any>(this.envService.userHost + '/v1/rateMedia/' + mediaId, { liked: liked })
    }
}
