import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { ChannelResponse } from '../../models';
import { Subject, takeUntil } from 'rxjs';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-user-recommendations',
    templateUrl: './user-recommendations.component.html',
    styleUrls: ['./user-recommendations.component.css']
})
export class UserRecommendationsComponent implements OnInit, OnDestroy {

    @Input() channel: ChannelResponse;

    mediaList: any[];
    mediaList2: any[] = [{}, {}, {}, {}, {}, {}, {}];

    loaded: boolean = false;

    destroy = new Subject<void>()
    isMobileView = false;

    constructor(
        private channelService: ChannelsService,
        private ui: UiService) {
        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize < ScreenSizeEnum.Large;
            });
    }

    ngOnInit(): void {
        this.channelService.userChannels().subscribe(res => {
            if (res) {
                this.mediaList = res.find(f => f.type === 'user-recommendations').media;
                this.mediaList2 = null;
            }
        });
    }

    ngOnDestroy() {
        this.destroy.next();
    }

}
