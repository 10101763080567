<div class="main main-full">
    <section class="entry">
        <div class="container">
            <div class="entry-content-message mocap">
                <h2 class="mb-4 text-center entry-content-message-title">{{title}}</h2>

                <p class="text-center">{{message}}</p>
            </div>
        </div>
    </section>
</div>

