import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {RemoteConfigService} from '../../services/remote-config.service';
import {environment} from 'src/environments/environment';
import { WatchHistoryService } from '../../services/watch-history.service';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {

    mediaId: number;
    autoPlay = true;
    safariHls = false;

    destroy$: Subject<void> = new Subject<void>();
    showReportReasons: boolean = false;
    videoLoaded: boolean = false;

    constructor(
        router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        public config: RemoteConfigService,
        private watchHistoryService: WatchHistoryService,) {

        this.mediaId = +this.route.snapshot.paramMap.get('id');

        router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }


    ngOnDestroy() {
        this.titleService.setTitle(environment.name);
        this.watchHistoryService.refreshWatchHistory();
    }

    ngOnInit() {
    }
}
