import { Component, OnInit } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { AccountService } from '../../../services/account.service';
import { AppComponent } from '../../../app.component';
import { Router } from '@angular/router';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { Utils } from '../../../services/utils.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
    email: string;
    errorMessage: string;
    errors: any[];

    constructor(
        private accountService: AccountService,
        private afAuth: Auth,
        private app: AppComponent,
        private router: Router,
        private config: RemoteConfigService
    ) {}

    ngOnInit(): void {
    }

    resetPassword() {
        if (!this.email) {
            this.errorMessage = 'Email is required.';
            return;
        }
        this.app.startLoading();

        if (this.config.remoteConfig.login_version == 1) {
            this.sendResetPasswordV1();
        }

        if (this.config.remoteConfig.login_version == 2) {
            this.sendResetPasswordV2();
        }

    }

    sendResetPasswordV1() {
        sendPasswordResetEmail(this.afAuth, this.email)
            .then(() => {
                this.handleSuccess();
            })
            .catch(error => {
                if (error.code) {
                    this.handleFirebaseError(error);
                    return;
                }

                this.errorMessage = error.message;
            })
            .finally(() => {
                this.app.stopLoading();
            });
    }

    sendResetPasswordV2() {
        if (!Utils.isValidEmail(this.email)) {
            this.app.stopLoading();
            this.errorMessage = 'Email is not valid.';
            return;
        }

        this.accountService.forgottenPasswordSendV2(this.email).subscribe({
            next: () => this.handleSuccess(),
            error: (error) => {
                this.app.stopLoading();
                this.errorMessage = error.error;
            }
        })
    }

    handleSuccess() {
        this.app.showSimpleAlert({
            title: 'Success',
            body: 'Please check your email for recovery instructions.'
        });
        this.app.stopLoading();
        this.router.navigate(['/login']);
    }

    handleFirebaseError(error: { code: string }) {
        switch (error.code) {
            case 'auth/invalid-email':
                this.errorMessage = 'Email Address invalid.';
                break;
            case 'auth/user-not-found':
                this.handleSuccess();
                break;
            default:
                this.errorMessage = 'Something went wrong. Please try again';
                break;
        }
    }

    changePage(page: string) {
        this.accountService.navigateToAccessPage(page);
    }

}
