import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AppComponent } from 'src/app/app.component';
import { StorageManagerService } from '../../services/storage-manager.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

    attemptCount: number = 0;
    error: boolean;

    constructor(
        private app: AppComponent,
        private router: Router,
        private storage: StorageManagerService,
        private userInfo: UserInfoService,
        private account: AccountService) {
    }

    ngOnInit() {
        this.retryRequest();
    }

    retryRequest() {
        let intervalId = setInterval(() => {
            clearInterval(intervalId);
            this.account.updateToken().then(
                () => {
                    this.userInfo.getUserInfoQuery('subscription').subscribe(res => {
                        this.attemptCount++;

                        if (this.attemptCount > 5) {
                            this.error = true;
                            return;
                        }
                        if (res.hasSubscription) {

                            this.storage.setSubscription(res.hasSubscription);
                            this.app.showSimpleAlert({
                                title: 'Success',
                                body: 'Your payment has gone through successfully, we hope you enjoy our content.'
                            });

                            this.router.navigate(['/']);
                        } else {
                            this.retryRequest();
                        }
                    });
                },
                error => {
                });
        }, 2000);
    }
}
