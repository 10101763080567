<div class="main main-full main-responsive">
    <app-title [title]="'account-change-password' | translate"></app-title>
    <form id="signup-form" class="signup-form mt-3" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

        <div class="form-group mb-4">
            <label>Current Password</label>
            <app-password-field placeholder="Current Password" [(password)]="viewModel.oldPassword" [tabIndex]="1"></app-password-field>
        </div>

        <div class="form-group mb-4">
            <label>{{ "account-change-password-new" | translate }}</label>
            <app-password-field placeholder="{{ 'account-change-password-new' | translate }}" [(password)]="viewModel.newPassword" [tabIndex]="2"></app-password-field>
        </div>

        <div class="form-group mb-4">
            <label>{{ "account-change-password-confirm" | translate }}</label>
            <app-password-field placeholder="{{ 'account-change-password-confirm' | translate }}" [(password)]="viewModel.confirmPassword" [tabIndex]="3"></app-password-field>
        </div>

        <p class="text-danger" *ngIf="error">{{error}}</p>

        <div class="form-group">
            <button type="submit" class="btn btn-slim btn-outline-primary" tabindex="4">{{ "account-change-password" | translate }}</button>
        </div>
    </form>

    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>

    <div class="mt-3">
        Don't remember your Password? <strong class="pointer hover-accent" (click)="logout()">Sign Out</strong> and select Forgotten Password on the Sign In page.
    </div>
</div>
