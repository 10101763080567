<div class="thumb" #thumb  tabindex="0">
    <img *ngIf="newVideo && !watched" class="thumb-new" src="{{config.remoteConfig.new_badge}}" alt="New Video" />
    <img *ngIf="watched" class="thumb-watched" [class.large]="thumbnailType === 'large'" src="{{config.remoteConfig.watched}}" alt="New Video" />
    <div class="thumb-container p-1 p-lg-2">
        <a class="thumb-link" [routerLink]="routerLink" [queryParams]="{seasonId:seasonId, watchlist: fromWatchList}">
            <picture (deferLoad)="load()">
                <source *ngIf="loadImages && this.media && this.media.image"
                    srcset="{{this.media.image + '?w=' + imageWidth}}">
                <img class="thumb-image h-100 w-100" src="{{isVertical ? config.remoteConfig.loading_packshot_vertical : config.remoteConfig.loading_packshot}}"
                    alt="Loading Image" />
            </picture>
            <img class="channelBadge mobile" *ngIf="media.channelBadgeUrl"
                 src="{{media.channelBadgeUrl}}" alt="Channel Badge" />
            <div class="thumb-text m-1 m-lg-2 p-1 p-lg-2" *ngIf="!media.seasonId">
                <img class="mr-2 channelBadge desktop" *ngIf="media.channelBadgeUrl"
                    src="{{media.channelBadgeUrl}}" alt="Channel Badge" /> <!-- TODO: ACCESIBILITY -->
                <div>{{media.title}}</div>
            </div>

            <div class="media-duration" *ngIf="media.duration" [ngClass]="{ 'media-padding': progress > 0 }">
                {{media.duration}}
            </div>
        </a>
    </div>

    <div class="thumb-hover p-1 p-lg-2"   [class.large-preview]="isVertical && this.media.previewImage">
        <a class="a-route" [routerLink]="routerLink" [queryParams]="{seasonId:seasonId, watchlist: fromWatchList}">
            <picture (deferLoad)="load()">
                <source *ngIf="loadImages && this.media && this.media.previewImage"
                        srcset="{{this.media.previewImage}}"
                        type="image/webp"
                      >
                <source *ngIf="loadImages && this.media && this.media.image"
                    srcset="{{this.media.image + '?w=' + imageWidth}}">
                <img class="thumb-image h-100 w-100"   [class.large-preview-image]="isVertical && this.media.previewImage" src="{{isVertical ? config.remoteConfig.loading_packshot_vertical : config.remoteConfig.loading_packshot}}"
                    alt="{{media.title}} Image" />
            </picture>
        </a>

        <div class="thumb-text-hover d-flex flex-column">
            <div class="media-progress" *ngIf="progress > 0 && !media.seasonId">
                <ngb-progressbar class="watch_progress_colour" type="primary" [value]="progress" [max]="100" height="6px">
                </ngb-progressbar>
            </div>
            <div class="d-flex flex-row p-2 p-lg-3">
                <div class="flex-grow-1">
                    <a class="a-route" [routerLink]="routerLink" [queryParams]="{seasonId:seasonId}">
                        <h5 class="pr-1 media-title">{{media.title}}</h5>
                    </a>
                    <small class="text-left d-block text-gray media-description" *ngIf="media.mediaId">{{timeAgo}}</small>
                    <small class="text-left d-block text-gray media-description" *ngIf="media.seasonId && media.shortDescription && isSearch">{{media.shortDescription}}</small>
                </div>
                <div *ngIf="media.mediaId" class="d-flex" [class.flex-column]="stack" [class.justify-content-end]="!stack">
                    <a class="a-route" [class.mr-2]="!stack" [class.mb-2]="stack" [routerLink]="['/video', media.mediaId]" [queryParams]="{seasonId:seasonId}">
                        <div class="thumb-icon" >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path class="svg-icon" fill-rule="evenodd" clip-rule="evenodd" d="M15 12L22.5341 16.2157L30 20.4995L22.5341 24.7161L15 29V12Z"/>
                                <circle cx="20" cy="20" r="19.5"/>
                            </svg>
                        </div>
                    </a>

                    <div class="tt" *ngIf="!existsInPlaylist(media.mediaId) && !addToPlaylistLoading && isAuthenticated()">
                        <div class="thumb-icon" (click)="addToPlaylist(this.media)" >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="19.5"/>
                                <path class="svg-icon" fill-rule="evenodd" clip-rule="evenodd" d="M29 18.7838H21.2162V11H18.7838V18.7838H11V21.2162H18.7838V29H21.2162V21.2162H29V18.7838Z"/>
                            </svg>
                        </div>
                        <span class="tttext">Add to Watchlist</span>
                    </div>

                    <div class="tt" *ngIf="existsInPlaylist(media.mediaId) && !addToPlaylistLoading && isAuthenticated()">
                        <div class="thumb-icon" (click)="deleteFromPlaylist(this.media)" >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="19.5"/>
                                <path class="svg-icon" fill-rule="evenodd" clip-rule="evenodd" d="M29 18.7838H11V21.2162H29V18.7838Z"/>
                            </svg>
                        </div>
                        <span class="tttext">Remove from Watchlist</span>
                    </div>

                    <div *ngIf="addToPlaylistLoading" class="thumb-icon" (click)="deleteFromPlaylist(this.media)" >
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="19.5"/>
                            <path d="M14.0611 28.5L19.5 20.742L24.9389 28.5H14.0611Z" stroke-width="2"/>
                            <rect  class="svg-icon" x="16" y="26" width="7" height="2" />
                            <path d="M24.9389 11.5L19.5 19.258L14.0611 11.5L24.9389 11.5Z" stroke-width="2"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="media-duration" *ngIf="media.duration" [ngClass]="{ 'media-padding': progress > 0 }">
            {{media.duration}}
        </div>
    </div>
</div>
