<div class="section">
    <img class="d-block d-lg-none w-100" src="{{mobileBackground}}" />
    <img class="d-none d-lg-block w-100" src="{{webBackground}}" />
    <div class="d-none d-lg-block section-left-text">
        <img class="mb-3 mb-xl-5 w-75 w-xl-100" src="{{logo.url}}" />
        <h3 class="mb-3">{{title}}</h3>
        <p class="mb-5">{{text}}</p>
        <button *ngIf="pointsToGo > 0" class="btn btn-greenlight font-weight-bold w-100 w-md-75 w-xl-50 mt-5"
            (click)="openContribute()">CONTRIBUTE</button>
        <div *ngIf="pointsToGo === 0">
            <button *ngIf="mediaId" class="btn btn-primary btn-block" [routerLink]="['/shows', project.mediaId]">WATCH
                NOW</button>
            <button *ngIf="!mediaId" class="btn btn-primary btn-block">COMING SOON</button>
        </div>
    </div>
    <img *ngIf="videoUrl" class="play-greenlight pointer" (click)="openVideo()"
        src="https://cdn.watchcorridor.com/assets/play.svg" alt="Play Greenlight Video" />
</div>
<div class="d-block d-lg-none section px-3 py-5">
    <h3 class="mb-3">{{title}}</h3>
    <p class="my-0">{{text}}</p>
    <button class="btn btn-greenlight font-weight-bold w-100 mt-5" (click)="openContribute()">CONTRIBUTE</button>
</div>
<app-modal-simple-shaka *ngIf="showModal && videoUrl" (closeEvent)="closeVideo()" [url]="videoUrl">
</app-modal-simple-shaka>