import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { BehaviorSubject, interval, Observable, Subject, takeUntil } from 'rxjs';
import {EnvironmentService} from './environment.service';
import {LiveVideoResponse} from '../models';
import { SocketService } from './socket.service';
import { LivestreamStatus } from '../models/socket-messages/livestream-status';
import { RemoteConfigService } from './remote-config.service';

@Injectable({
    providedIn: 'root'
})
export class LiveService {

    private _status$ = new BehaviorSubject<LivestreamStatus>({isLive: false, streams: null, nextStream: null});
    get status$(): Observable<LivestreamStatus> {
        return this._status$.asObservable();
    }

    private readonly statusIntervalMinutes = 10;
    private readonly disconnect$ = new Subject<void>();

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient,
        private socket: SocketService,
        private config: RemoteConfigService,) {

        if (!this.config.remoteConfig.livestream_enabled) {
            return;
        }

        this.socket.connected$.subscribe(connected => {
            if (connected) {
                this.init();
            } else {
                this.disconnect();
            }
        });


    }

    init() {
        this.socket.onMessage('livestream').pipe(takeUntil(this.disconnect$)).subscribe(data => {
            const parsed = JSON.parse(data);

            switch(parsed.type) {
                case 'status':
                    this.handleStatus(parsed.body);
                    console.log('[Livestream] Status: ', parsed.body);
                    break;
            }
        });

        this.requestStatus()
        interval(this.statusIntervalMinutes * 60 * 1000).pipe(takeUntil(this.disconnect$)).subscribe(() => this.requestStatus())
    }

    disconnect() {
        this.disconnect$.next();
    }


    handleStatus(message: LivestreamStatus) {
        this._status$.next(message);
    }

    requestStatus() {
        this.socket.sendMessage('livestream', {type: 'status'})
    }

    sendPing(livestreamId: number) {
        this.socket.sendMessage('livestream', { type: 'ping', livestreamId: livestreamId });
    }

    sendChatMessage(livestreamId: number, message: string) {
        this.socket.sendMessage('livestream', {
            type: 'message',
            livestreamId: livestreamId,
            message: message
        });
    }

    getLiveVideo(): Observable<LiveVideoResponse> {
        return this.http.get<any>(this.envService.contentHost + '/v1/live')
    }

    getLiveMedia() {
        return this.http.get<any>(this.envService.contentHost + '/v1/live/media')
    }

    getLiveMediaV2() {
        return this.http.get<any>(this.envService.contentHost + '/v2/live/media')
    }

    getChat(livestreamId: number) {
        return this.http.get<any>(this.envService.userHost + '/v2/livestream/chat/' + livestreamId);
    }
}
