<div class="main main-full">
    <section class="entry">
        <div class="container">
            <div class="entry-content">
                <h2 class="mb-4 text-center">Down for Maintenance</h2>

                <p>Sorry, we're down for some maintenance right now and will be back shortly!</p>
            </div>
        </div>
    </section>
</div>
