import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { LocalisationService } from 'src/app/services/localisation.service';
import { AuthenticationOverride } from '../../../../modules/authentication-override';
import { AccountService } from '../../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../../services/storage-manager.service';

@Component({
    selector: 'app-change-language',
    templateUrl: './change-language.component.html',
    styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent extends AuthenticationOverride implements OnInit {
    loading: boolean = false;
    selectedLanguage: number;

    constructor(public app: AppComponent,
                public localisationService: LocalisationService,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectedLanguage = this.app.languages.find(lg => lg.id == this.localisationService.getSelectedLanguageId()).id;
    }

    async onLanguageChange(): Promise<void> {
        if (confirm(this.localisationService.getTranslation('account-change-language-confirm'))) {
            this.app.storeLanguageInLocalStorage(this.selectedLanguage);
            await this.changeLanguage(this.selectedLanguage);
        }
    }

    private async changeLanguage(languageId: number): Promise<void> {
        let targetLanguage = this.app.languages.find(lg => lg.id == languageId);
        if (targetLanguage && this.localisationService.getSelectedLanguageId() != languageId) {
            await this.localisationService.setSelectedLanguageId(languageId);
        }
    }

}
