import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';
import { VersionResponse } from '../models/version-response';
import { EnvironmentService } from './environment.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceService {

    isMaintenanceMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    hasUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    updateMaintentanceMode(): Promise<boolean> {
        if (environment.forceMaintenance) {
            this.isMaintenanceMode.next(true);
            this.hasUpdated.next(true);
            return Promise.resolve(true);
        }
        this.isMaintenanceMode.next(false);
        this.hasUpdated.next(true);
        return Promise.resolve(false);
    }

}
