<div class="subscription-row" *ngIf="card">
    <div *ngIf="!card || loading" class="text-center">
        <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="d-flex justify-content-between" *ngIf="card && !loading">
        <div>
            <div>
                <img *ngIf="card.brand.toLowerCase() === 'mastercard'" src="assets/subscriptions/mastercard.svg"
                    width="28px" />
                <img *ngIf="card.brand.toLowerCase() === 'visa'" src="assets/subscriptions/visa.svg" width="28px" />
                <b class="mx-2">{{card.brand}}</b> •••• {{card.cardEnding}}
            </div>
            <div>
                <span class="mt-2 status-padding">Expires {{card.cardExpiry}}</span>
            </div>
        </div>
        <div class="my-auto">
            <button class="btn btn-slim btn-outline-danger ml-3" (click)="remove()">Remove</button>
        </div>
    </div>
</div>