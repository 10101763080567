import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-final',
    templateUrl: './final.component.html',
    styleUrls: ['./final.component.css']
})
export class FinalComponent implements OnInit {

    @Input() pointsToGo: number;
    @Input() section: any;

    logo: string;
    mobileBackground: string;
    webBackground: string;

    title: string;
    text: string;
    text2: string;

    constructor() {
    }

    ngOnInit(): void {
        this.logo = this.section.images.find(f => f.orientation === 'all')?.url;
        this.mobileBackground = this.section.images.find(f => f.orientation === 'vertical' || f.orientation === 'narrow')?.url;
        this.webBackground = this.section.images.find(f => f.orientation === 'horizontal' || f.orientation === 'wide')?.url;

        this.title = this.section.text.find(f => f.type === 'title')?.text;
        this.text = this.section.text.find(f => f.type === 'text')?.text;
        this.text2 = this.section.text.find(f => f.type === 'text2')?.text;
    }

}
