import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AccountService } from './account.service';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { WatchHistory } from '../models/watch-history';

@Injectable({
    providedIn: 'root'
})
export class WatchHistoryService {

    private historySubject = new BehaviorSubject<WatchHistory[]>(null);

    get watchHistory() {
        return this.historySubject.asObservable();
    }

    constructor(private accountService: AccountService,
                private envService: EnvironmentService,
                private http: HttpClient) {
    }

    async refreshWatchHistory(): Promise<void> {
        if (!this.accountService.isAuthenticated()) {
            return;
        }

        try {
            await this.accountService.updateToken();
            this.getWatchHistory().subscribe(res => {
                this.historySubject.next(res);
            });
        } catch {}
    }

    getWatchHistory(): Observable<WatchHistory[]> {
        return this.http
            .get<WatchHistory[]>(this.envService.userHost + '/v1/watchHistory');
    }
}
