import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public simpleEventEmitter(
    eventCategory: string,
    eventAction: string,
    eventLabel: string) {
    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }

  public navigateToPage(page: string) {
    if (environment.googleAnalyticsEnabled) {
      gtag('config', environment.googleAnalyticsId, { 'page_path': page })
    }
  }
}
