import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { LegalDocument } from 'src/app/models/legal-document';
import { AccountService } from 'src/app/services/account.service';
import { LegalDocumentsService } from 'src/app/services/legal-documents.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { UiService } from 'src/app/services/ui.service';
import { LegalDocumentTypes } from 'src/app/models/legal-document-types';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-modal-legal-documents',
	templateUrl: './modal-legal-documents.component.html',
	styleUrls: ['./modal-legal-documents.component.css']
})
export class ModalLegalDocumentsComponent implements OnInit {
	@Output() onHide = new EventEmitter<boolean>();
	setHide() {
		this.onHide.emit(true);
	}

	errorMessage: string;
	missingDocuments: LegalDocument[];
	acceptedDocumentsIds: number[];

	documentTypes: LegalDocumentTypes[];

	documentsLoaded: boolean = false;
	documentTypesLoaded: boolean = false;

	constructor(
		private app: AppComponent,
		private legalDocumentsService: LegalDocumentsService,
		private accountService: AccountService,
		private router: Router,
		private ui: UiService,
		private cdr: ChangeDetectorRef) {

	}

	async ngOnInit() {
		await this.loadMissingDocuments();
	}

	async loadMissingDocuments(): Promise<void> {
		forkJoin(
			[
				await this.legalDocumentsService.getLegalDocumentTypes(),
				await this.legalDocumentsService.getMissingRequiredDocuments()
			]).subscribe(([types, docs]) => {
				docs.forEach(doc => {
					doc.typeObj = types.find(type => type.id == doc.type);
				})
				this.missingDocuments = docs;
				this.documentsLoaded = true;
				this.documentTypesLoaded = true;
			})
	}

	onDecline() {
		if (confirm("Would you like to decline? Doing so will log you out of your account.")) {
			this.app.stopShowingLegalDocumentPopup();
			this.accountService.logoutUser();
			this.router.navigate(['/login'])
		};
	}

	acceptDocuments() {
		this.acceptedDocumentsIds = [];
		this.missingDocuments.forEach(doc => {
			if (doc.accepted) {
				this.acceptedDocumentsIds.push(doc.id);
			} else {
				this.errorMessage = 'You must accept all legal documents before proceeding.'
				return;
			}
		});
		if (this.acceptedDocumentsIds.length == this.missingDocuments.length) {
			this.legalDocumentsService.acceptLegalDocuments(this.acceptedDocumentsIds).subscribe(res => {
				this.ui.stopShowingLegalDocumentsModal();
			});
		}
	}

	viewDocument(type) {
		//Returns url-safe version of the document type by getting the key corresponding to the value of type from the 'documentTypes' object declared above.
		window.open(`/legaldocuments/${type}`);
	}

	nothing(event: Event) {
		event.stopPropagation()
	}
}
