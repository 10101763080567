<div class="main main-full mocap-bg">
    <div class="container account-management wide-content">
        <h2 class="d-inline">{{ 'account-enter-voucher-code' | translate }}</h2>
        <div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
            <span class="sr-only">{{ 'loading' | translate }}</span>
        </div>
        <div class="row border-top py-3 my-3">
            <div class="col-12">

                <label>{{ 'voucher-code' | translate }}</label>
                <app-enter-voucher-code (complete)="navigateComplete()" (loadingEvent)="loading = $event" [slim]="true"></app-enter-voucher-code>

                <a [routerLink]="['/account']" class="btn btn-outline-light btn-slim mt-3">{{ 'account-back' | translate }}</a>
            </div>
        </div>
    </div>
</div>
