<div class="main main-full config-background-2">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12">
                <div class="entry-content">
                    <h2 class="form-title">Payment Cancelled!</h2>
                    <p>The payment process was cancelled, no money has been taken.</p>

                    <a routerLink="/">Go back to Channels.</a>
                </div>
            </div>
        </div>
    </div>
</div>
