import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-season-banner',
    templateUrl: './season-banner.component.html',
    styleUrls: ['./season-banner.component.scss']
})
export class SeasonBannerComponent implements OnInit, OnDestroy {

    @Input() channel: any;
    @Input() isFirst: boolean = false;

    logo: string;
    background: string;

    @ViewChild('seasonBanner')
    seasonBanner: ElementRef;

    @ViewChild('slider')
    slider: ElementRef;
    isMobileView: boolean = false;

    destroy = new Subject<void>();

    constructor(private ui: UiService) {
        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView = screenSize < ScreenSizeEnum.Large;
            });
    }

    ngOnInit(): void {
        const {images} = this.channel;
        this.logo = images.find(x => x.type === 'logo')?.url;
        this.background = images.find(x => x.type === 'hero-web-slim')?.url ?? images.find(x => x.type === 'hero-web')?.url;
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

}
