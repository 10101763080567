import { Component, Input, OnInit } from '@angular/core';
import { ConfirmModalObject } from '../../models/confirm-modal-object';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'proto-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

    @Input() confirmObj: ConfirmModalObject;

    extraSecureTicked = false;

    constructor(private ui: UiService) {
    }

    ngOnInit(): void {
    }

    ok() {
        this.confirmObj.okCallback();
        this.close();
    }

    close() {
        this.ui.closeConfirm();
    }
}
