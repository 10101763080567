<div class="proto-modal-background" *ngIf="documentsLoaded && documentTypesLoaded">
	<div class="proto-modal proto-modal-big" (click)="nothing($event)">
		<div class="modal-container" style="display:block">
			<div class="modal-dialog modal-md" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">You have new legal documents to review</h5>
					</div>
					<div class="modal-body">
						<ul *ngFor="let doc of missingDocuments">
							<li>&nbsp;<input [(ngModel)]="doc.accepted" type="checkbox" required/> I have read and accept the <a (click)="viewDocument(doc.typeObj.type)">{{doc.typeObj.name}}</a></li>
						</ul>
					</div>
						<span class="form-group block error text-center pr-4" *ngIf="errorMessage">{{errorMessage}}</span>
					<div class="modal-footer">
						<div class="btn-group w-100" role="group" aria-label="Buttongroup">
							<button class="btn mr-1 btn-outline-info" (click)="acceptDocuments()">Accept</button>
							<button class="btn ml-1 btn-outline-danger" (click)="onDecline()">Decline</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>