<div class="show-thumb mx-1 mx-lg-2 pointer" >
    <a [routerLink]="routerLink">
        <div class="show-thumb-image-wrapper">
            <div>
                <img class="w-100" src="{{item.imageUrl}}" alt="Show Thumbnail" /> <!-- TODO: ACCESSIBILITY -->
            </div>
        </div>
    </a>

</div>
