import { Component, Input, OnInit } from '@angular/core';
import { ChannelResponse, ProjectPageSection } from '../../../models';

@Component({
    selector: 'app-playlist',
    templateUrl: './playlist.component.html',
    styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit {

    @Input() section: ProjectPageSection;
    @Input() channel: ChannelResponse;

    title = '';

    constructor() {
    }

    ngOnInit(): void {
        this.title = this.section.text.find(f => f.type === 'title' || f.name === 'title')?.text;
    }

}
