import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';

@Component({
    selector: 'app-signup-email-preferences',
    templateUrl: './signup-email-preferences.component.html',
    styleUrls: ['./signup-email-preferences.component.scss']
})
export class SignupEmailPreferencesComponent extends AuthenticationOverride implements OnInit {

    constructor(account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router) {
        super(route, storage, router, account);
    }
}
