import { Injectable } from '@angular/core';
import { UserInfo } from '../models';
import { BehaviorSubject, Observable, of, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { RemoteConfigService } from './remote-config.service';
import { DeleteRequest } from '../models/delete-request';
import { PointsService } from './points.service';

@Injectable({
    providedIn: 'root'
})
export class UserInfoService extends UserInfo {
    constructor(private http: HttpClient,
                private envService: EnvironmentService,
                private config: RemoteConfigService,
                private pointsService: PointsService) {
        super();
    }

    userInfoUpdated$ = new BehaviorSubject<boolean>(false);

    getUserInfoQuery(querySelect: string): Observable<UserInfo> {
        const query = querySelect.split(',');

        if (!this.config.remoteConfig.points_enabled && query.includes('points')) {
            query.splice(query.indexOf('points'), 1);
        }

        if (!this.config.remoteConfig.patreon_enabled && query.includes('patreon')) {
            query.splice(query.indexOf('patreon'), 1);
        }

        if (query.length < 1) {
            return of(undefined);
        }

        return this.http
            .get<UserInfo>(this.envService.userHost + '/v1/userinfo/query?select=' + query.join(','))
            .pipe(tap(x => {
                this.updateUserInfo(x);
            }))
    }

    private updateUserInfo(newValue: UserInfo) {
        if (!newValue) {
            return;
        }

        const keys = Object.keys(newValue);
        for (let key of keys) {
            if (key in this) {
                this[key] = newValue[key];

                if (key === 'pointsAvailable') {
                    this.pointsService.producerPoints = this[key];
                }
            }
        }
        this.userInfoUpdated$.next(true);
    }
}
