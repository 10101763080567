import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UiService } from '../../../services/ui.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { SubscriptionOption } from '../../../models/subscription-option';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../../../services/user-info.service';
import { Environment, EnvironmentService } from '../../../services/environment.service';
import { AppComponent } from '../../../app.component';
import { AccountService } from '../../../services/account.service';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';

@Component({
    selector: 'app-change-plan',
    templateUrl: './change-plan.component.html',
    styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent extends AuthenticationOverride implements OnInit {

    planId: string;
    subscriptionId: string;
    paths: SubscriptionOption[];

    selectedPlanId: string;

    constructor(private app: AppComponent,
                private ui: UiService,
                private subscriptionService: SubscriptionService,
                private userInfoService: UserInfoService,
                public router: Router,
                private envService: EnvironmentService,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService) {
        super(route, storage, router, account);
    }

    async ngOnInit() {
        super.ngOnInit();
        this.ui.startLoading();
        if (!this.userInfoService.subscriptionId) {
            await lastValueFrom(this.userInfoService.getUserInfoQuery('subscription-extended'));
        }

        if (!this.userInfoService.canUpdatePlan || !this.userInfoService.subscriptionId) {
            this.router.navigate(['/account']);
        }

        this.planId = this.userInfoService.planId;
        this.selectedPlanId = this.planId;
        this.subscriptionId = this.userInfoService.subscriptionId;


        this.paths = await lastValueFrom(this.subscriptionService.getSubscriptionPaths(this.subscriptionId));

        this.ui.stopLoading();
    }

    isStaging() {
        return this.envService.env !== Environment.Production;
    }

    getPrice(path: SubscriptionOption): number {
        return path.prices.find(x => x.currency.toLowerCase() === path.defaultCurrency.toLowerCase()).price;
    }

    updateConfirmation() {
        if (this.selectedPlanId === this.planId) {
            return;
        }

        this.ui.confirm({
            title: 'Update Subscription Plan',
            message: 'Are you sure you want update your subscription plan?',
            okCallback: () => this.updateSubscription(),
            okBtnTxt: 'Yes',
            cancelBtnTxt: 'No'
        })
    }

    async updateSubscription() {
        if (this.selectedPlanId === this.planId) {
            return;
        }

        this.ui.startLoading();
        try {
            await lastValueFrom(this.subscriptionService.updateSubscription(this.subscriptionId, this.selectedPlanId));
            this.app.showSimpleAlert({
                title: 'Success',
                body: 'Your subscription has been updated.'
            });
            this.router.navigate(['/']);
            this.ui.stopLoading();
        }
        catch (e) {
            this.ui.stopLoading();
        }
    }

    async endTrial() {
        this.ui.startLoading();
        try {
            await lastValueFrom(this.subscriptionService.endTrial(this.subscriptionId))
        }
        catch (e) {

        }
        finally {
            this.ui.stopLoading();
        }
    }
}
