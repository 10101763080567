import { ImageModel } from './image-model';

export interface PromoResponse {
    id: string;
    redirectUrl: string;
    active: boolean;
    authentication: number;
    subscription: number;
    images: ImageModel[];
}
