<div class="comment-grid" [class.top-comment-mobile]="mobileTopComment">

    <div class="comment-avatar">
        <img src="{{comment.avatarUrl}}" alt="{{comment.name ?? comment.displayName}}" />
    </div>

    <div class="comment-header">
        <span class="comment-name">
            {{comment.name ?? comment.displayName}}
        </span>

        <div class="v2-badges" *ngIf="showBadges() && config.remoteConfig.comments_version == '2'">
            <img *ngIf="comment.accountType === AccountType.Official" class="chat-badge ml-2" alt="Official" title="Official" src="{{env.imageHost + '/badges/official.png'}}" />
            <img *ngIf="comment.accountType === AccountType.Creator" class="chat-badge ml-2" alt="Creator" title="Creator" src="{{env.imageHost + '/badges/creator.png'}}" />
            <img class="mx-1 chat-badge" *ngFor="let badge of comment.badges" [src]="env.imageHost + '/badges/' + badge + '.png'" />
        </div>

        <span class="comment-age ml-1">
            {{getTime()}}
        </span>
    </div>

    <div class="comment-message">
        <span [innerHtml]="comment.message | chat : mediaId : false | async | safeHtml"></span>
    </div>

    <div class="comment-actions">
        <ng-container *ngIf="!mobileTopComment">
            <button (click)="toggleLike()" class="btn btn-link inline like-btn" [class.liked]="comment.liked" [disabled]="likeLoading$ | async">
                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px">
                    <path class="fill" d="M720-144H264v-480l288-288 32 22q18 13 26.5 33t3.5 41l-38 192h264q30 0 51 21t21 51v57q0 8-1.5 14.5T906-467L787-188q-9 20-27 32t-40 12ZM192-624v480H48v-480h144Z"/>
                    <path class="outline" d="M720-144H264v-480l288-288 32 22q17 12 26 30.5t5 38.5l-1 5-38 192h264q30 0 51 21t21 51v57q0 8-1.5 14.5T906-467L786.93-187.8Q778-168 760-156t-40 12Zm-384-72h384l120-279v-57H488l49-243-201 201v378Zm0-378v378-378Zm-72-30v72H120v336h144v72H48v-480h216Z"/>
                </svg>
                <span>{{comment.likes}}</span>
            </button>
            <button *ngIf="(chatService.replyingComment$ | async)?.id !== comment.id" (click)="replyChat(comment)" type="button" class="btn btn-link inline">Reply</button>
            <button (click)="deleteChat()" *ngIf="ownComment()" type="button" class="btn btn-link inline">Delete</button>
            <button (click)="reportChat()" *ngIf="!ownComment()" type="button" class="btn btn-link inline report-btn">Report</button>
            <button title="Emojis" class="btn inline emoji-button" type="button" (click)='emoji(comment)'>
                <img src="/assets/smiley.png" />
            </button>
        </ng-container>
    </div>

    <div class="comments-reply" *ngIf="!mobileTopComment">
        <div *ngFor="let subComment of replies">
            <app-comment-read [comment]="subComment" [mediaId]="mediaId" [subComment]="true"
                [originalCommentId]="comment.id" (replyClicked)="replyChat($event)">
            </app-comment-read>
        </div>
        <ng-container *ngIf="chatService.replyingComment$ | async as replyingComment">
            <app-comment-write *ngIf="(replyingComment.id === comment.id || replyingComment.originalCommentId == comment.id) && !subComment" [mediaId]="mediaId" [originalChatId]="comment.id"
                               (close)="closeReply()" [subComment]="true">
            </app-comment-write>
        </ng-container>

    </div>

</div>

