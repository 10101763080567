import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfirmModalObject } from '../models/confirm-modal-object';
import { ScreenSizeEnum } from '../models/screen-size.enum';
import { BootstrapBreakpoints } from '../models';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({
    providedIn: 'root'
})
export class UiService {

    private _loading = new BehaviorSubject<boolean>(false);
    private _showLegalDocumentsModal = new BehaviorSubject<boolean>(false);

    get loading(): Observable<boolean> {
        return this._loading.asObservable();
    }

    get isLoading(): boolean {
        return this._loading.getValue();
    }

	get legalDocumentsModal(): Observable<boolean> {
        return this._showLegalDocumentsModal.asObservable();
    }

	get showingLegalDocumentsModal(): boolean {
		return this._showLegalDocumentsModal.getValue();
	}

	private _confirm = new BehaviorSubject<ConfirmModalObject>(undefined);

    get confirmOpen() {
        return this._confirm.getValue();
    }

    get confirmChanged() {
        return this._confirm.asObservable();
    }

    private _currentScreenSize
    public get screenSize() {
        return this._currentScreenSize;
    }

    private _breakpointSubject = new BehaviorSubject<ScreenSizeEnum>(ScreenSizeEnum.XSmall)
    public get breakpointObservable() {
        return this._breakpointSubject.asObservable();
    }

    constructor(private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe([
                BootstrapBreakpoints.XSmall,
                BootstrapBreakpoints.Small,
                BootstrapBreakpoints.Medium,
                BootstrapBreakpoints.Large,
                BootstrapBreakpoints.XLarge,
                BootstrapBreakpoints.XXLarge

            ])
            .subscribe((state: BreakpointState) => {
                if (state.breakpoints[BootstrapBreakpoints.XSmall]) {
                    this._currentScreenSize = ScreenSizeEnum.XSmall;
                }
                if (state.breakpoints[BootstrapBreakpoints.Small]) {
                    this._currentScreenSize = ScreenSizeEnum.Small;
                }
                if (state.breakpoints[BootstrapBreakpoints.Medium]) {
                    this._currentScreenSize = ScreenSizeEnum.Medium;
                }
                if (state.breakpoints[BootstrapBreakpoints.Large]) {
                    this._currentScreenSize = ScreenSizeEnum.Large;
                }
                if (state.breakpoints[BootstrapBreakpoints.XLarge]) {
                    this._currentScreenSize = ScreenSizeEnum.XLarge;
                }
                if (state.breakpoints[BootstrapBreakpoints.XXLarge]) {
                    this._currentScreenSize = ScreenSizeEnum.XXLarge;
                }

                this._breakpointSubject.next(this._currentScreenSize);
            })
    }

    startLoading(): void {
        this._loading.next(true);
    }

    stopLoading(): void {
        this._loading.next(false);
    }

	showLegalDocumentsModal(): void {
		this._showLegalDocumentsModal.next(true);
	}

	stopShowingLegalDocumentsModal(): void {
		this._showLegalDocumentsModal.next(false);
	}

	/**
     Pass in undefined to close the modal
     */
	 confirm(confirmObj: ConfirmModalObject) {
        this._confirm.next(confirmObj);
    }

    closeConfirm() {
        this._confirm.next(undefined);
    }
}
