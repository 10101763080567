<div class="chat-container" *ngIf="!(deleted$ | async)">
    <div>

        <span *ngIf="comment.name" [class.text-crew]="comment.accountType > AccountType.Free" class="chat-name">
            <img *ngIf="comment.accountType === AccountType.Official" class="chat-badge" alt="Official" title="Official" src="{{env.imageHost + '/badges/official.png'}}"/>
            <img *ngIf="comment.accountType === AccountType.Creator" class="chat-badge" alt="Creator" title="Creator" src="{{env.imageHost + '/badges/creator.png'}}"/>
            <img class="chat-badge" *ngIf="comment.badge && !comment.official" [src]="env.imageHost + '/badges/' + comment.badge + '.png'"/>{{ comment.name }}:
        </span>
        <span class="chat-message" [innerHtml]="comment.message | chat : 0 : true | async"></span>
    </div>
</div>
