import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-custom-message',
    templateUrl: './custom-message.component.html',
    styleUrls: ['./custom-message.component.scss']
})
export class CustomMessageComponent implements OnInit {

    title: string = '';
    message: string = '';

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.title = this.route.snapshot.data['title'];
        this.message = this.route.snapshot.data['message'];
    }

}
