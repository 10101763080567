import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { HeroResponse } from '../models';

@Injectable({
    providedIn: 'root'
})
export class HeroService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getHero(): Observable<HeroResponse> {
        return this.http.get<HeroResponse>(this.envService.contentHost + '/v4/hero?supportVersion=5&platform=web')
    }

    getHeroV5Auth(): Observable<HeroResponse> {
        return this.http.get<HeroResponse>(this.envService.contentHost + '/v5/hero/auth?supportVersion=5&platform=web')
    }

    getHeroV5Anon(): Observable<HeroResponse> {
        return this.http.get<HeroResponse>(this.envService.contentHost + '/v5/hero/anon?supportVersion=5&platform=web')
    }
}
