<ng-container *ngIf="media">
	<div class="series-view-thumb">
		<app-video-thumb-simple [showPlayButton]="true" [media]="media" [hoverOnSmall]="true" [seasonId]="seasonId"></app-video-thumb-simple>
	</div>
	<div class="series-view-separator d-none d-md-flex">

    </div>
	<div [ngClass]="creatorPageView ? 'center-text': ''" class="series-view-text ml-3 ml-md-0">
		<h4 class="series-view-text-title mt-1 mb-4">
			{{media.title}}
		</h4>
		<div class="series-view-text-description">
			{{media.shortDescription}}
		</div>
		<div class="series-view-time-ago d-md-block d-lg-none mt-2">
			{{timeAgo}}
		</div>
		<div class="series-view-add-to-watchlist d-none d-md-block d-lg-block" *ngIf="isAuthenticated() && !existsInPlaylist(media.mediaId)" (click)="addToPlaylist(media)">
            <span *ngIf="!addToPlaylistLoading">+ Watchlist</span>
            <span *ngIf="addToPlaylistLoading">Removing...</span>
		</div>
		<div class="series-view-add-to-watchlist d-none d-md-block d-lg-block" *ngIf="isAuthenticated() && existsInPlaylist(media.mediaId)" (click)="deleteFromPlaylist(media)">
            <span *ngIf="!addToPlaylistLoading">- Watchlist</span>
            <span *ngIf="addToPlaylistLoading">Adding...</span>
		</div>
	</div>
</ng-container>
