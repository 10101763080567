import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LegalDocument } from '../models/legal-document';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LegalDocumentTypes } from '../models/legal-document-types';


@Injectable({
	providedIn: 'root'
})
export class LegalDocumentsService {

	constructor(
		private envService: EnvironmentService,
		private http: HttpClient) { }

	getMissingRequiredDocuments(): Observable<LegalDocument[]> {
		return this.http
			.get<LegalDocument[]>(this.envService.userHost + '/v1/legaldocuments')
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('LegalDocumentsService::Error::GET', error);
				return throwError(error);
			}))
	}

	getLegalDocumentTypes(): Observable<LegalDocumentTypes[]> {
		return this.http
			.get<LegalDocumentTypes[]>(`${this.envService.userHost}/v1/legaldocuments/types`)
			.pipe(catchError(error => {
				console.error('LegalDocumentsService::Error::GET', error);
				return throwError(error);
			}))
	}

	getLatestLegalDocuments(): Observable<LegalDocument[]> {
		return this.http
			.get<LegalDocument[]>(`${this.envService.userHost}/v1/legaldocuments/latest`)
			.pipe(catchError(error => {
				console.error('LegalDocumentsService::Error::GET', error);
				return throwError(error);
			}))
	}

	getLatestDocumentByType(type: string): Observable<LegalDocument> {
		return this.http
			.get<LegalDocument>(this.envService.userHost + '/v1/legaldocuments/' + type)
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('LegalDocumentsService::Error::GET', error);
				return throwError(error);
			}))
	}

	acceptLegalDocuments(legalDocumentIds: number[]): Observable<boolean> {
		return this.http
			.post<any>(this.envService.userHost + '/v1/legaldocuments/accept', legalDocumentIds)
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('LegalDocumentsService::Error::POST', error);
				return throwError(error);
			}))
	}
}
