import { Component, OnInit } from '@angular/core';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AccountService } from '../../../services/account.service';
import { SmartTvService } from '../../../services/smart-tv.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UiService } from '../../../services/ui.service';
import { Utils } from '../../../services/utils.service';

@Component({
    selector: 'app-smart-tv-code',
    templateUrl: './smart-tv-code.component.html',
    styleUrls: ['./smart-tv-code.component.scss']
})
export class SmartTvCodeComponent extends AuthenticationOverride implements OnInit {
    loading: boolean = false;
    error: string;
    success: string;
    code: string = '';

    constructor(protected route: ActivatedRoute,
                protected storageManager: StorageManagerService,
                protected router: Router,
                protected account: AccountService,
                private smartTvService: SmartTvService,
                private ui: UiService) {
        super(route, storageManager, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const code = this.route.snapshot.paramMap.get('code');
        if (code) {
            this.code = code;
            this.submit();
        }
    }


    submit() {
        this.error = undefined;
        this.success = undefined;
        if (Utils.isNullOrWhitespace(this.code)) {
            this.error = 'Please enter a code';
            return;
        }

        this.ui.startLoading();

        this.smartTvService.sendCodeV2(this.code)
            .subscribe({
                next: (x) => {
                    if (x.status === 200) {
                        this.success = 'Login Successful!';
                    } else {
                        this.error = 'Code not recognized. Please try again.';
                    }

                    console.log(x.status);
                    this.ui.stopLoading();
                },
                error: (err: HttpErrorResponse) => {
                    this.error = 'Code not recognized. Please try again.';
                    this.account.checkUnauthorized(err);
                    this.ui.stopLoading();
                }
            });
    }
}
