<div class="main main-full main-responsive">
    <app-title [title]="cancelFailed ? 'Error' : 'Cancel Membership'"></app-title>
    <div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="row">
        <div class="col-12">
            <ng-container *ngIf="!cancelFailed">
                <p>Select one of the reasons below:</p>
                <ng-container *ngFor="let r of reasons; let i = index">
                    <input type="radio" id="{{'reason-' + i}}" name="reason" [value]="r.cancellationId" [(ngModel)]="reason.cancellationId">
                    <label for="{{'reason-' + i}}" class="ml-2">{{r.reason}}</label><br>
                </ng-container>

                <textarea [(ngModel)]="reason.message" class="reason-message mb-2" [disabled]="reason.cancellationId !== otherId"></textarea>

                <button class="btn btn-outline-primary btn-slim mt-2 mr-2" (click)="cancel()">
                    Finish Cancellation
                </button>
            </ng-container>
            <ng-container *ngIf="cancelFailed">
                <p>Something went wrong when trying to cancel your subscription! Please contact support if you continue to have this issue.</p>
                <a [routerLink]="['/home/feeback']" class="btn btn-outline-primary btn-slim yellow reason mt-2 mr-2">Contact Support</a>
            </ng-container>
            <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
        </div>
    </div>
</div>
