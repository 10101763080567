import { Component, OnInit } from '@angular/core';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { AccountService } from '../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';

@Component({
    selector: 'app-email-preferences-v2',
    templateUrl: './email-preferences-v2.component.html',
    styleUrls: ['./email-preferences-v2.component.scss']
})
export class EmailPreferencesV2Component extends AuthenticationOverride implements OnInit {

    constructor(account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService,
                router: Router) {
        super(route, storage, router, account);
    }

}
