import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
    @Input() widthHeightPx: number = 160;
    @Input() overrideSize = false;

    get fontSize(): string {
        if (this.overrideSize) {
            return 'inherit';
        }

        return Math.floor(this.widthHeightPx / 10).toString();
    }

    @HostBinding('style.padding-bottom.px') paddingBottom = 160;

    constructor() {
    }

    ngOnInit(): void {
        this.paddingBottom = this.widthHeightPx;
    }

}
