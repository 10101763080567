<div class="simple-alert" *ngIf="simpleAlert">
    <span class="simple-alert-close" (click)="close()"><b>X</b></span>

    <div class="simple-alert-container">
        <h3>{{simpleAlert.title}}</h3>
        <p [innerHtml]="simpleAlert.body"></p>

        <a *ngIf="simpleAlert.buttonLink" class="btn btn-outline-secondary btn-block text-black" (click)="close()"
            routerLink="{{simpleAlert.buttonLink}}">
            {{simpleAlert.buttonText}}
        </a>

        <a *ngIf="simpleAlert.buttonUrl" class="btn btn-outline-secondary btn-block text-black"
            href="{{simpleAlert.buttonUrl}}" target="_blank">
            {{simpleAlert.buttonText}}
        </a>
    </div>
</div>
