<div class="section">
    <img class="d-block d-lg-none w-100" src="{{mobileBackground}}"/>
    <img class="d-none d-lg-block w-100" src="{{webBackground}}"/>
    <div class="section-left-text">
        <img class="w-75 mb-5" src="{{logo}}"/>
        <h3 class="mb-3">{{title}}</h3>
        <p class="my-0">{{text}}</p>
        <p class="mb-0 mt-3" *ngIf="text2">{{text2}}</p>
    </div>
    <div class="section-right-text">
        <div *ngIf="pointsToGo === 0">
            <button *ngIf="mediaId" class="btn btn-primary btn-block" [routerLink]="['/shows', project.mediaId]">WATCH
                NOW
            </button>
            <button *ngIf="!mediaId" class="btn btn-primary btn-block">COMING SOON</button>
        </div>
        <div *ngIf="pointsToGo > 0">
            <h3 class="mb-3">{{pointsToGo}} points to go!</h3>
            <button class="btn btn-greenlight font-weight-bold w-100 w-md-75">CONTRIBUTE</button>
        </div>
    </div>
</div>
