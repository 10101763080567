<nav class="mobile" [class.mobile-open]="mobileMenuOpen$ | async">
    <div class="top-bar">
        <img class="nav-brand pointer" src="{{config.remoteConfig.logo_square}}" alt="Logo" routerLink="/" [queryParams]="{ref:'menu'}" (click)="mobileMenuOpen = false"/>
        <div class="pointer px-3" (click)="mobileMenuOpen = !mobileMenuOpen">
            <img class="avatar" width="46px" height="46px" src="{{getAvatarUrl()}}" clickOutside (clickOutside)="mobileMenuOpen = false"/>
        </div>
    </div>
    <div class="nav-items">
        <a class="nav-item nav-link" routerLink="/" [queryParams]="{ref:'menu'}" [class.active]="activeUrl === '/' || activeUrl.startsWith('/?')">
            {{ "home" | translate }}
        </a>
        <a class="nav-item nav-link" *ngIf="config.remoteConfig.shows_enabled" [class.active]="activeUrl.startsWith('/shows')" routerLink="/shows" [queryParams]="{ref:'menu'}">
            {{ "shows" | translate }}
        </a>
        <a class="nav-item nav-link" *ngIf="config.remoteConfig.menu_creators" [class.active]="activeUrl.startsWith('/creators')" routerLink="/creators" [queryParams]="{ref:'menu'}">
            Creators
        </a>
        <a *ngIf="config.remoteConfig.projects_enabled" class="nav-item nav-link" [class.active]="activeUrl.startsWith('/greenlight')" routerLink="/greenlight" [queryParams]="{ref:'menu'}">
            {{ "projects" | translate }}
        </a>
        <a *ngIf="config.remoteConfig.store_url && config.remoteConfig.store_url.length > 0" class="nav-item nav-link" href="{{config.remoteConfig.store_url}}" target="_blank">
            {{ "merch" | translate }}
        </a>
        <a class="nav-item nav-link" [class.active]="activeUrl === '/learn-more'" *ngIf="showLearnMore()" routerLink="/learn-more" [queryParams]="{ref:'menu'}">
            LEARN MORE
        </a>
        <ng-container *ngIf="isAuthenticated()">
            <a routerLink="/search" [class.active]="activeUrl.startsWith('/search')" [queryParams]="{ref:'menu'}" class="nav-item nav-link">
                {{ "search" | translate }}
            </a>
            <a routerLink="/account" [class.active]="activeUrl.startsWith('/account')" [queryParams]="{ref:'menu'}" class="nav-item nav-link">
                {{ "account" | translate }}
            </a>
            <a routerLink="/watchlist" [class.active]="activeUrl.startsWith('/watchlist')" [queryParams]="{ref:'menu'}" class="nav-item nav-link">
                {{ "watchlist" | translate }}
            </a>
            <a routerLink="/home/feedback" [class.active]="activeUrl.startsWith('/home/feedback')" [queryParams]="{ref:'menu'}" class="nav-item nav-link">
                {{ "need-help" | translate }}
            </a>
        </ng-container>
        <div class="live-padding" *ngIf="config.remoteConfig.livestream_enabled">
            <app-live-menu></app-live-menu>
        </div>

    </div>
    <div class="bottom-bar mt-4 mb-3 px-2">
        <div>
            <a target="_blank" href="{{config.remoteConfig.google_play_url}}">
                <img class="app-store my-2 mx-2" height="45px" alt='Get it on Google Play' src='/assets/google.svg'/>
            </a>
            <a href="{{config.remoteConfig.app_store_url}}" target="_blank">
                <img class="app-store my-2 mx-2" height="45px" alt='Get it on the App Store' src='/assets/apple.svg'/>
            </a>
        </div>
        <ng-container *ngIf="isAuthenticated()">
            <button class="btn btn-slim btn-outline-light btn-spacing my-2 w-100" type="submit" (click)="signout()">
                {{ "sign-out" | translate }}
            </button>
        </ng-container>
        <ng-container *ngIf="!isAuthenticated()">
            <button (click)="login()"
                    class="btn btn-slim btn-block btn-outline-light btn-no-outline btn-no-outline-lgs my-2 my-lg-0 menu-button">
                {{ "log-in" | translate }}
            </button>

            <button (click)="register()"
                    class="btn btn-slim btn-block btn-outline-light my-2 my-lg-0 menu-button">
                {{ "sign-up" | translate }}
            </button>
        </ng-container>
    </div>
</nav>

<nav class="desktop">
    <div class="side left" #left>
        <div class="nav-items">
            <img class="nav-brand pointer" src="{{config.remoteConfig.logo_square}}" alt="Logo" routerLink="/" [queryParams]="{ref:'menu'}"/>
            <a class="nav-item nav-link" routerLink="/" [queryParams]="{ref:'menu'}" [class.active]="activeUrl === '/' || activeUrl.startsWith('/?')">
                {{ "home" | translate }}
            </a>
            <a class="nav-item nav-link" *ngIf="config.remoteConfig.shows_enabled" [class.active]="activeUrl.startsWith('/shows')" routerLink="/shows" [queryParams]="{ref:'menu'}">
                {{ "shows" | translate }}
            </a>
            <a class="nav-item nav-link" *ngIf="config.remoteConfig.menu_creators" [class.active]="activeUrl.startsWith('/creators')" routerLink="/creators" [queryParams]="{ref:'menu'}">
                Creators
            </a>
            <a *ngIf="config.remoteConfig.projects_enabled" class="nav-item nav-link" [class.active]="activeUrl.startsWith('/greenlight')" routerLink="/greenlight" [queryParams]="{ref:'menu'}">
                {{ "projects" | translate }}
            </a>
            <a *ngIf="config.remoteConfig.store_url && config.remoteConfig.store_url.length > 0" class="nav-item nav-link" href="{{config.remoteConfig.store_url}}" target="_blank">
                {{ "merch" | translate }}
            </a>
            <a class="nav-item nav-link" [class.active]="activeUrl === '/learn-more'" *ngIf="showLearnMore()" routerLink="/learn-more" [queryParams]="{ref:'menu'}">
                LEARN MORE
            </a>
        </div>
    </div>

    <div class="search-centered" *ngIf="isAuthenticated()" #center>
        <div class="search-bar">
            <input (keyup.enter)="doSearch()" [(ngModel)]="searchTerm" type="text" class="search-bar-input"
                   placeholder="Search">
            <button class="search-icon-button" (click)="doSearch()">
                <svg class="search-icon pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 21.0008L15.8 15.8008" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    </div>

    <div class="side right" #right>
        <div *ngIf="!isAuthenticated()" class="noauth-menu mr-3">
            <app-live-menu  *ngIf="config.remoteConfig.livestream_enabled"></app-live-menu>
            <button (click)="login()"
                    class="btn btn-slim btn-block btn-outline-light btn-no-outline btn-no-outline-lgs my-2 my-lg-0 mx-1 mx-lg-1 menu-button">
                {{ "log-in" | translate }}
            </button>

            <button (click)="register()"
                    class="btn btn-slim btn-block btn-outline-light my-2 my-lg-0 mx-1 mx-lg-1 menu-button">
                {{ "sign-up" | translate }}
            </button>
        </div>
        <div *ngIf="isAuthenticated()" class="auth-menu">

            <app-live-menu  *ngIf="config.remoteConfig.livestream_enabled"></app-live-menu>

            <a *ngIf="config.remoteConfig.points_enabled" [routerLink]="config.remoteConfig.projects_enabled ? '/greenlight' : undefined" [queryParams]="{ref:'menu'}"
               class="my-3 my-sm-0 mx-3 pp-points" [class.pp-disabled]="!config.remoteConfig.projects_enabled">
                <span class="text mr-1">{{ points$ | async }}</span>
                <img class="icon" src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points"/>
            </a>

            <a *ngIf="userInfo() && userInfo().streamsRemaining > 0 && hasSubscription()" class="nav-link whitelink trial-remaining"
               routerLink="/account" [queryParams]="{ref:'menu'}">
                {{ trialString() }}
            </a>
            <div class="mx-3 dropdown-menu-selector">
                <img class="avatar" width="46px" height="46px" src="{{getAvatarUrl()}}" (click)="showDesktopDropdown = !showDesktopDropdown" clickOutside (clickOutside)="closeNav()"/>

                <div class="desktop-dropdown" [class.d-none]="!(showDesktopDropdown$ | async)">
                    <a routerLink="/account" [queryParams]="{ref:'menu'}" class="btn btn-slim btn-outline-light btn-spacing btn-no-outline my-2 mx-2">
                        {{ "account" | translate }}
                    </a>
                    <a routerLink="/watchlist" [queryParams]="{ref:'menu'}" class="btn btn-slim btn-outline-light btn-spacing btn-no-outline my-2 mx-2 no-wrap">
                        {{ "watchlist" | translate }}
                    </a>
                    <a routerLink="/home/feedback" [queryParams]="{ref:'menu'}" class="btn btn-slim btn-outline-light btn-spacing btn-no-outline my-2 mx-2">
                        {{ "need-help" | translate }}
                    </a>
                    <button class="btn btn-slim btn-outline-light btn-spacing my-2 mx-2" type="submit" (click)="signout()">
                        {{ "sign-out" | translate }}
                    </button>

                    <a target="_blank" href="{{config.remoteConfig.google_play_url}}">
                        <img class="app-store my-2 mx-2" height="45px" alt='Get it on Google Play' src='/assets/google.svg'/>
                    </a>
                    <a href="{{config.remoteConfig.app_store_url}}" target="_blank">
                        <img class="app-store my-2 mx-2" height="45px" alt='Get it on the App Store' src='/assets/apple.svg'/>
                    </a>
                </div>

            </div>
        </div>
    </div>

</nav>
