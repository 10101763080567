<div class="main-full" *ngIf="!notFound">
    <div class="page-container">
        <div *ngIf="creatorTeam" class="hero">
            <app-hero-banner-static-image *ngIf="checkHeroImages()" [hero]="creatorTeam" [roundedBorder]="true"></app-hero-banner-static-image>
            <h1 *ngIf="!checkHeroImages()">{{creatorTeam.displayName}}</h1>
        </div>
        <div *ngIf="creatorTeam" class="latest-header">
            <h3>Latest Videos</h3>
            <div>
                <button class="view-all-button" (click)="toggleViewAll()">{{ viewAllButtonText }}</button>
                <app-follow-btn [creatorTeamId]="creatorTeam.id"></app-follow-btn>
            </div>
        </div>

        <div class="margin-top" *ngIf="latestMedia">
            <app-series-view-media-item-v2 *ngFor="let a of viewAll"
                [media]="this.latestMedia[a]" [creatorPageView]="true"></app-series-view-media-item-v2>
        </div>
        <div class="margin-top" *ngIf="!latestMedia">
            <app-loading-spinner widthHeightPx="80"></app-loading-spinner>
        </div>

        <div class="latest-header margin-top">
            <h3>Popular</h3>
        </div>
    </div>
    <div *ngIf="popularMedia" #slider class="slider">
        <app-image-slider [type]="'media-list'" [items]="popularMedia" *ngIf="popularMedia"
                          [thumbnailType]="'horizontal'"></app-image-slider>
    </div>
    <div class="margin-top margin-bottom" *ngIf="!popularMedia">
        <app-loading-spinner widthHeightPx="80"></app-loading-spinner>
    </div>
</div>


<app-not-found *ngIf="notFound"></app-not-found>
