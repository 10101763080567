import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AccountService } from 'src/app/services/account.service';
import { UserInfoService } from '../../../services/user-info.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { StorageManagerService } from '../../../services/storage-manager.service';

@Component({
    selector: 'app-change-display-name-v2',
    templateUrl: './change-display-name-v2.component.html',
    styleUrls: ['./change-display-name-v2.component.scss']
})
export class ChangeDisplayNameV2Component extends AuthenticationOverride implements OnInit {

    error: string;
    loading: boolean;
    viewModel: any = {};

    constructor(
        private accountService: AccountService,
        private app: AppComponent,
        public router: Router,
        private userInfoService: UserInfoService,
        route: ActivatedRoute,
        storage: StorageManagerService,
        account: AccountService) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loading = true;
        this.userInfoService.getUserInfoQuery('display-name').subscribe(res => {
            this.viewModel.displayName = res.displayName;
            this.loading = false;
        });
    }

    submit() {
		this.error = undefined;

        if (!this.viewModel.displayName) {
            this.error = 'Display name must be at least 3 characters long.';
        }

        if (this.viewModel.displayName && this.viewModel.displayName.length < 3) {
            this.error = 'Display name must be at least 3 characters long.';
        }

		if(this.viewModel.displayName && this.viewModel.displayName.length > 28) {
            this.error = 'Display name cannot be longer than 28 characters.';
		}

		if(this.error) {
			return;
		}

        this.app.startLoading();
        this.accountService.changeDisplayName(this.viewModel.displayName).subscribe({
            next: () => {
                this.app.showSimpleAlert({
                    title: 'Success',
                    body: 'Your display name was successfully changed.'
                });
                this.router.navigate(['/account']);
            },
            error: error => {
                this.app.stopLoading();
                this.error = 'Invalid Display Name.';
            }
        });
    }
}
