<div class="main main-full main-responsive">
	<app-title [title]="'account-change-display-name' | translate"></app-title>
	<div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
		<span class="sr-only">{{ "loading" | translate }}</span>
	</div>
	<form id="signup-form" class="signup-form" (ngSubmit)="submit()" #f="ngForm" novalidate class="needs-validation">
		<div class="form-group">
			<label for="displayName">{{ "display-name" | translate }}</label>
			<input [disabled]="loading" type="text" name="displayName" [(ngModel)]="viewModel.displayName" autoFocus
				class="form-control" placeholder="{{ 'display-name' | translate }}" required>
			<p class="text-danger" *ngIf="error">{{error}}</p>
		</div>

		<div class="form-group">
			<button type="submit" class="btn btn-outline-primary btn-slim">{{ "account-change-display-name" | translate }}</button>
		</div>
	</form>

    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>
