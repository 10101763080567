import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreatorTeamService } from '../../services/creator-team.service';
import { Observable, of, tap } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'app-follow-btn',
    templateUrl: './follow-btn.component.html',
    styleUrls: ['./follow-btn.component.scss']
})
export class FollowBtnComponent implements OnInit {

    canToggleFollow = true
    follows$: Observable<number[]>;
    isAuthenticated$: Observable<boolean>;

    @Input() creatorTeamId: number;

    @Output() onToggleFollow = new EventEmitter<boolean>();

    constructor(private creatorTeamService: CreatorTeamService,
                private account: AccountService) {
        this.isAuthenticated$ = this.account.isAuthenticated$.pipe(tap(authed => {
            if (authed) {
                this.follows$ = this.creatorTeamService.getMyFollows();
            } else {
                this.follows$ = undefined;
            }
        }));
    }

    ngOnInit(): void {
    }

    toggleFollow(isFollowing: boolean, teamId: number) {
        if (!this.canToggleFollow) {
            return;
        }

        this.canToggleFollow = false;
        setTimeout(() => this.canToggleFollow = true, 1000);

        if (isFollowing) {
            this.creatorTeamService.unfollowTeam(teamId).subscribe();
            this.onToggleFollow.emit(false);
        } else {
            this.creatorTeamService.followTeam(teamId).subscribe();
            this.onToggleFollow.emit(true);
        }
    }

}
