<div class="container-fluid full-width d-flex flex-column overflow-hidden" *ngIf='channels'>
    <h2 *ngIf="header" class="page-title">{{header}}</h2>

    <div class="hero-container-ar" *ngIf="type !== 'shows'">
        <app-hero-carousel *ngIf="heroResponse" [heroResponse]="heroResponse" [class.round-indicators]="heroResponse.heroes.length > 5"></app-hero-carousel>
    </div>


    <div class="padding-bot-main" [class.shift-up]="type !== 'shows' && !isMobileView">
        <div *ngFor="let channel of channels; let i = index" [style.z-index]="100 - i" class="position-relative mt-3 mt-lg-0">
            <div class="flex-column" *ngIf="((channel.media != null && channel.media.length > 0)
            || (channel.itemIds != null && channel.itemIds.length > 0)
            || (channel.items != null && channel.items.length > 0)
            || (channel.type == 'user-recommendations' && isAuthenticated())
            || (channel.type.startsWith('playlist') && isAuthenticated())
            || (channel.type == 'continue-watching' && isAuthenticated()))
            && channel.type !== 'latest-player'">

                <app-channel-header [channel]="channel" [showBadge]="channel.type !== 'media-list-channel'"
                                    *ngIf="channel.type !== 'season-banner' && channel.type !== 'continue-watching' && channel.type !== 'user-recommendations'">
                </app-channel-header>

                <ng-container *ngIf="channel.type == 'latest-player' || channel.itemType === 'hero'">
                    <div class="pb-3">
                        <app-hero-banner [media]="channel.media[0]"></app-hero-banner>
                    </div>
                </ng-container>

                <app-image-slider
                        *ngIf="(channel.type == 'media-list' || channel.type === 'media-list-channel') && !channel.itemType"
                        [type]="channel.type" [channelId]="channel.id.toString()"
                        [thumbnailType]="isMobileView ? channel.thumbnailTypeResponsive ?? channel.thumbnailType : channel.thumbnailType" [items]="channel.media">
                </app-image-slider>

                <!-- Media List Double ONLY -->
                <app-image-slider *ngIf="(channel.type == 'media-list-double') && !channel.itemType"
                                  [type]="channel.type" [channelId]="channel.id.toString()" [items]="channel.media"
                                  [rows]="2"
                                  [disableNextRow]="true">
                </app-image-slider>

                <app-image-slider *ngIf="channel.itemType" [type]="channel.type" [channelId]="channel.id.toString()"
                                  [disableNextRow]="channel.type !== 'show-list'" [items]="channel.items">
                </app-image-slider>

                <app-season-banner [isFirst]="i === 0 && type === 'shows'"
                                   *ngIf="channel.type == 'season-banner' && channel.media" [channel]="channel">
                </app-season-banner>

                <app-user-playlist *ngIf="channel.type.startsWith('playlist')" [type]="channel.slug">
                </app-user-playlist>
                <app-user-recommendations *ngIf="channel.type == 'user-recommendations'" [channel]="channel"></app-user-recommendations>
                <app-continue-watching *ngIf="channel.type == 'continue-watching'" [channel]="channel"></app-continue-watching>
            </div>
        </div>
    </div>
</div>
