import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LegalDocumentTypes } from 'src/app/models/legal-document-types';
import { LegalDocumentsService } from '../legal-documents.service';

@Injectable({
	providedIn: 'root'
})
export class LegalDocumentsTypesResolver implements Resolve<any> {

	constructor(private legalDocumentsService: LegalDocumentsService) { }

	resolve(): Observable<LegalDocumentTypes[]> {
		return this.legalDocumentsService.getLegalDocumentTypes();
	}
}
