import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CreatorInfoResponse } from '../models/metadata-response-v3';
import { CreatorTeamResponse } from '../models/creator-team-response';
import { CreatorTeamMediaResponse } from '../models/creator-team-media';

@Injectable({
    providedIn: 'root'
})
export class CreatorTeamService {

    private cachedFollows$: BehaviorSubject<number[]> = new BehaviorSubject(null);
    private myFollows$: Observable<number[]>;

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getTeams(): Observable<CreatorTeamResponse[]> {
        return this.http.get<CreatorTeamResponse[]>(`${this.envService.contentHost}/v2/creatorteam`)
    }

    getVideoDetails(creatorTeamId: number): Observable<CreatorInfoResponse> {
        return this.http.get<CreatorInfoResponse>(`${this.envService.contentHost}/v2/creatorteam/${creatorTeamId}/video-details`)
    }

    getMyFollows(): Observable<number[]> {

        if (this.myFollows$ === undefined) {
            this.myFollows$ = this.http.get<number[]>(`${this.envService.contentHost}/v2/creatorteam/my-follows`).pipe(shareReplay(1));
        }

        this.myFollows$.subscribe(x => this.cachedFollows$.next(x));

        return this.cachedFollows$.asObservable();
    }

    followTeam(teamId: number): Observable<any> {
        const currentArray = this.cachedFollows$.value;
        let arrayUpdated = false;
        if (!currentArray.includes(teamId)) {
            const updatedArray = [...currentArray, teamId];
            this.cachedFollows$.next(updatedArray);
            arrayUpdated = true;
        }
        return this.http.post<any>(`${this.envService.contentHost}/v2/creatorteam/${teamId}/follow`, {})
            .pipe(catchError(x => {
                if (arrayUpdated) {
                    this.cachedFollows$.next(currentArray);
                }
                throw x
            }));
    }

    unfollowTeam(teamId: number): Observable<any> {
        const currentArray = this.cachedFollows$.value;
        const updatedArray = currentArray.filter(number => number !== teamId);
        const arrayUpdated = currentArray.length !== updatedArray.length;
        if (arrayUpdated) {
            this.cachedFollows$.next(updatedArray);
        }


        return this.http.post<any>(`${this.envService.contentHost}/v2/creatorteam/${teamId}/unfollow`, {})
            .pipe(catchError(x => {
                if (arrayUpdated) {
                    this.cachedFollows$.next(currentArray);
                }
                throw x
            }));
    }

    getTeam(teamId: number): Observable<CreatorTeamResponse> {
        return this.http.get<CreatorTeamResponse>(`${this.envService.contentHost}/v2/creatorteam/${teamId}`)
    }

    getTeamVideos(teamId: number): Observable<CreatorTeamMediaResponse> {
        return this.http.get<CreatorTeamMediaResponse>(`${this.envService.contentHost}/v2/creatorteam/${teamId}/media`);
    }
}
