<div class="report-modal-container" (click)="cancelEvent($event)">
    <div class="report-modal-body" *ngIf="reasons">
        <h2 class="mb-3">{{'report-video-playback' | translate | titlecase}}</h2>

        <div *ngFor="let key of keys" class="ui-checkbox-container my-2" [class.active]="selectedReason === key" (click)="selectedReason = key">
            <div class="ui-checkbox"></div>
            <div class="ui-checkbox-label">{{'video-report-' + key | translate}}</div>
        </div>

        <div class="d-flex justify-content-between mt-4">
            <button class="btn btn-outline-light mr-2 w-50" [disabled]="loading" (click)="cancel()">{{'cancel' | translate}}</button>
            <button class="btn btn-primary w-50" [disabled]="!selectedReason || loading" (click)="submitReport()">{{'submit' | translate}}</button>
        </div>
    </div>

    <app-modal-loading-component *ngIf="loading"></app-modal-loading-component>

</div>
