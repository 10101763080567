<div class="section">
    <img class="d-block d-lg-none w-100" src="https://cdn.watchcorridor.com/assets/learn-more/hero-bg.jpg" />
    <img class="d-none d-lg-block w-100" src="https://cdn.watchcorridor.com/assets/learn-more/hero-bg.jpg" />
    <div class="section-left-text">
        <h1 class="mb-3">JOIN THE CREW</h1>
        <div class="mb-5 d-none d-lg-block ">
            <ul>
                <li>Watch exclusive content ad-free</li>
                <li>YOU decide what shows get made</li>
                <li>15% off on ALL merch</li>
                <li>Watch on ANY device</li>
            </ul>
        </div>
        <div class="text-center d-none d-lg-block ">
            <button class="btn btn-primary btn-block font-weight-bold mt-5 mb-3" (click)="signup()">START WITH A FREE
                TRIAL!</button>
            <div>Join in minutes. Cancel any time.</div>
        </div>
    </div>
</div>

<div class="learn-more-membership">
    <div class="row w-75 mx-auto justify-content-center membership-row">
        <div class="membership-card membership-card-left col-12 col-lg text-center py-3 py-lg-5 px-3">
            <div>
                <h2 class="mb-4 text-black">MONTHLY MEMBERSHIP</h2>
                <hr class="grey w-25" />
                <p class="mt-4">Flexible. Earn 2 Producer Points per month by going monthly.</p>
                <div><b>$3.99 / month</b></div>
                <button class="btn btn-outline-secondary mt-4" (click)="handleRedirect()"><b>GO MONTHLY</b></button>
            </div>
        </div>
        <div class="membership-card membership-card-center col-12 col-lg-5 text-center py-3 py-lg-5 px-3">
            <div class="membership-card-text">
                <svg class="card-logo-small mb-4" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.009 24L24 39.009V46L46 24H39.009Z" fill="black" />
                    <path d="M24 6.99244L39.009 22H46L24 0V6.99244Z" fill="black" />
                    <path d="M6.99244 22L22 6.99244V0L0 22H6.99244Z" fill="black" />
                    <path d="M28.3186 24L24 28.3186V35L35 24H28.3186Z" fill="black" />
                    <path d="M17.6814 22L22 17.6814V11L11 22H17.6814Z" fill="black" />
                    <path d="M22 28.3186L17.6814 24H11L22 35V28.3186Z" fill="black" />
                </svg>
                <h2 class="mb-4 text-black">ANNUAL MEMBERSHIP</h2>
                <hr class="grey w-25" />
                <p class="mt-4">Best Value. Earn 30 Producer Points and exclusive profile badge upon signup!</p>
                <div><b>$39.99 / year</b></div>
                <button class="btn btn-primary mt-4" (click)="handleRedirect()"><b>SAVE WITH ANNUAL</b></button>
            </div>
        </div>
        <div class="membership-card membership-card-right col-12 col-lg text-center py-3 py-lg-5 px-3">
            <div class="mc-text">
                <h2 class="mb-4 text-black">FREE 14-DAY TRIAL</h2>
                <hr class="grey w-25" />
                <p class="mt-4">Every subscription gets a 14-day free trial!</p>
            </div>
        </div>
    </div>
</div>


<div class="learn-more-membership">
    <div class="row w-75 mx-auto">

        <div class="col-4 skew-test"></div>
    </div>
</div>

<div class="angled-spacer">
    <svg class="w-100" height="125" viewBox="0 0 1429 205" preserveAspectRatio="none" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M1429 0L1429 205H0L1429 0Z" class="spacer-path" />
    </svg>
</div>

<div class="section dark-bg-lm">
    <img class="d-block d-lg-none w-75" src="https://cdn.watchcorridor.com/assets/learn-more/devices.png" alt="Available devices" />
    <img class="d-none d-lg-block w-75" src="https://cdn.watchcorridor.com/assets/learn-more/devices.png" alt="Available devices" />
    <div class="d-none d-lg-block section-upper-right-text">
        <h3 class="mb-3">VIEW ON ANY DEVICE</h3>
        <p class="mb-5">Every episode ad-free, including new exclusive shows. Higher quality than YouTube, and just
            as
            fast.</p>

        <a target="_blank"
            href='https://play.google.com/store/apps/details?id=com.corridordigital.watchcorridor&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img class="app-store my-2 mx-2" height="60px" alt='Get it on Google Play' src='/assets/google.svg'
                alt="Google Playstore" />
        </a>
        <a href='https://apps.apple.com/gb/app/corridor-digital/id1488548152' target="_blank">
            <img class="app-store my-2 mx-2" height="60px" alt='Get it on the App Store' src='/assets/apple.svg'
                alt="Apple Appstore" />
        </a>
    </div>
</div>

<div class="d-block d-lg-none section px-3 py-5 text-right">
    <h3 class="mb-3">VIEW ON ANY DEVICE</h3>
    <p>Every episode ad-free, including new exclusive shows. Higher quality than YouTube, and just as fast.</p>
</div>

<app-section-left-text [section]="greenlight"></app-section-left-text>
<app-section-right-text [section]="merch"></app-section-right-text>
