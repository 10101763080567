export enum UserPreferenceKey {
    CorridorNewsEmails = 'CorridorNewsEmails',
    CorridorStoreEmails = 'CorridorStoreEmails',
    LiveStreamEmails = 'LiveStreamEmails',
    VideoNotificationEmails = 'VideoNotificationEmails',
    SUBTITLE_Font = 'SUBTITLE_Font',
    SUBTITLE_Shadow = 'SUBTITLE_Shadow',
    SUBTITLE_Background = 'SUBTITLE_Background',
    SUBTITLE_Window = 'SUBTITLE_Window',
    SUBTITLE_TextSize = 'SUBTITLE_TextSize',
    SUBTITLE_Colour = 'SUBTITLE_Colour',
    SUBTITLE_ShadowColour = 'SUBTITLE_ShadowColour',
    VIDEOQUALITY_MobileData = 'VIDEOQUALITY_MobileData',
    VIDEOQUALITY_Other = 'VIDEOQUALITY_Other',
    VIDEOQUALITY_MobileWifi = 'VIDEOQUALITY_MobileWifi',
    DOWNLOADS_WifiOnly = 'DOWNLOADS_WifiOnly',
    NOTIFICATIONS_UserSetting = 'NOTIFICATIONS_UserSetting'
}

export class UserPreferenceDefaults {
    static VIDEOQUALITY_MobileData: number = 4000000;
    static VIDEOQUALITY_MobileWifi: number = 12000000;
    static VIDEOQUALITY_Other: number = 12000000;
    static DOWNLOADS_WifiOnly: boolean = true;
}
