<div *ngIf="heroItem" (click)="navigate()" [class.pointer]="canNavigate()" class="hero-container-ar">
    <div>
        <img class="w-100 d-none d-lg-block loading-image" [src]="heroLoadingWeb">
        <img class="w-100 d-block d-lg-none loading-image" [src]="heroLoadingMobile">
        <img class="w-100 d-none d-lg-block" [src]="heroWeb">
        <img class="w-100 d-block d-lg-none" [src]="heroMobile">
    </div>
    <div class="carousel-caption">
        <img [src]="logo" class="pb-2 pb-xl-3" />
        <p>{{titleText}}</p>
        <div class="carousel-buttons" *ngIf="heroItem.type === heroTypes.LatestPlayer || heroItem.type === heroTypes.Media">
            <button class="btn btn-outline-light play-btn" [routerLink]="['/video', heroItem.media?.mediaId]">
                PLAY
            </button>
            <a class="watchlist-btn" *ngIf="isAuthenticated() && !existsInPlaylist(heroItem.media?.mediaId)"
               (click)="addToPlaylist(heroItem.media, $event)">
                <b *ngIf="!addToPlaylistLoading" class="align-middle">+Watchlist</b>
                <b *ngIf="addToPlaylistLoading" class="align-middle">Removing...</b>
            </a>
            <a class="watchlist-btn" *ngIf="isAuthenticated() && existsInPlaylist(heroItem.media?.mediaId)"
               (click)="deleteFromPlaylist(heroItem.media, $event)">
                <b *ngIf="!addToPlaylistLoading" class="align-middle">-Watchlist</b>
                <b *ngIf="addToPlaylistLoading" class="align-middle">Adding...</b>
            </a>
        </div>
    </div>
    <div class="gradient"></div>
</div>
