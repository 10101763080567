<div class="row">
    <div class="col-12 mb-4">
        <h4>Subscriptions</h4>
        <div *ngIf="subscriptions" class="mh-500 scroll-colour pr-2">
            <div *ngIf="subscriptions.length === 0">
                No Subscriptions.
            </div>
            <ng-container *ngFor='let subscription of subscriptions'>
                <app-subscription-row-v2 [subscription]="subscription"></app-subscription-row-v2>
            </ng-container>
        </div>
        <div *ngIf="!subscriptions" class="text-center">
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>

    <div class="col-12 mb-4">
        <h4>Cards</h4>
        <div *ngIf="cards" class="mh-500 scroll-colour pr-2">
            <div *ngIf="cards.length === 0">
                No cards.
            </div>
            <ng-container *ngFor='let card of cards'>
                <app-card-row-v2 [card]="card"></app-card-row-v2>
            </ng-container>
        </div>
        <div *ngIf="!cards" class="text-center">
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <button (click)="changeCard()" class="btn btn-slim btn-outline-primary mt-2">Add Card</button>
    </div>

    <div class="col-12 mb-4">
        <h4>Payments</h4>
        <div *ngIf="payments" class="mh-500-scroll scroll-colour pr-2">
            <div *ngIf="payments.length === 0">
                No payments.
            </div>
            <ng-container *ngFor='let payment of payments'>
                <app-payment-row-v2 [isMobile]="isMobile" [payment]="payment"></app-payment-row-v2>
            </ng-container>
        </div>
        <div *ngIf="!payments" class="text-center">
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
