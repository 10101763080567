import {Component, OnInit} from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import {AccountService} from 'src/app/services/account.service';

// Sign in methods
// Auth Providers
import {
    Auth,
    signInWithEmailAndPassword,
    updateProfile
} from '@angular/fire/auth';
import {StorageManagerService} from '../../../services/storage-manager.service';
import {RemoteConfigService} from '../../../services/remote-config.service';
import { Utils } from '../../../services/utils.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    hidePassword = true;
    errors: any[];
    nextRoute: string;
    viewModel: { email: string, password: string } = {
        email: '',
        password: ''
    };
    email: any;
    password: any;
    invalidFields = true;

    constructor(
        private afAuth: Auth,
        private app: AppComponent,
        private accountService: AccountService,
        private storageService: StorageManagerService,
        public config: RemoteConfigService) {
        this.app.stopLoading();
    }

    ngOnInit(): void {
    }

    validateFields(): Array<string> {
        const errors = [];
        if (!this.viewModel.email || !Utils.isValidEmail(this.viewModel.email)) {
            errors.push('Please enter a valid email address.');
        }
        if (!this.viewModel.password) {
            errors.push('Password is required.');
        }
        this.errors = errors;
        return errors;
    }

    emailLogin() {
        if (this.validateFields().length > 0) {
            return;
        }

        const afterLogin = this.storageService.getAfterLoginUrl();
        this.nextRoute = afterLogin ?? this.accountService.nextRoute;
        this.app.startLoading();
        if (this.config.remoteConfig.login_version == 1) {
            this.emailLoginV1();
            return;
        }
        if (this.config.remoteConfig.login_version == 2) {
            this.emailLoginV2();
            return;
        }
    }

    emailLoginV1() {
        signInWithEmailAndPassword(this.afAuth, this.viewModel.email, this.viewModel.password)
            .then(result => {
                if (!result.user.photoURL) {
                    updateProfile(result.user, {
                        photoURL: this.config.remoteConfig.default_avatar
                    });
                }
                this.app.sharedLogin(result, this.nextRoute);
            })
            .catch(error => {
                this.errors = [];
                this.errors.push(this.app.getErrorMessage(error));
                this.app.stopLoading();
            });
    }

    emailLoginV2() {
        this.accountService.loginV2(this.viewModel.email, this.viewModel.password)
            .subscribe({
                next: x => {
                    this.app.sharedLogin(undefined, this.nextRoute);
                },
                error: error => {
                    this.errors = [];
                    this.errors.push(this.app.getErrorMessage(error));
                    this.app.stopLoading();
                }
            });
    }

    changePage(page: string) {
        this.accountService.navigateToAccessPage(page);
    }
}
