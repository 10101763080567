import { Component, OnInit } from '@angular/core';
import { LegalDocument } from 'src/app/models/legal-document';
import { Router, ActivatedRoute } from '@angular/router';
import { LegalDocumentsService } from 'src/app/services/legal-documents.service';
import { LegalDocumentConstants } from 'src/app/models/legal-document-constants';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { AppComponent } from 'src/app/app.component';
import { UiService } from 'src/app/services/ui.service';
import { LegalDocumentTypes } from 'src/app/models/legal-document-types';

@Component({
	selector: 'app-legal-document',
	templateUrl: './legal-document.component.html',
	styleUrls: ['./legal-document.component.css']
})
export class LegalDocumentComponent implements OnInit {

	legalDocument: LegalDocument = {
		id: undefined,
		text: undefined,
		typeObj: undefined,
		type: undefined,
		accepted: undefined
	};

	invalidDocumentType: boolean = false;
	fetchingDocument: boolean = true;

	documentTypes: LegalDocumentTypes[];

	constructor(
		private app: AppComponent,
		private legalDocumentsService: LegalDocumentsService,
		private route: ActivatedRoute,
		private router: Router,
		private ui: UiService
	) {
	}

	ngOnInit() {
		if (this.ui.showingLegalDocumentsModal) {
			this.ui.stopShowingLegalDocumentsModal();
		}
		this.ui.startLoading();
		this.loadDocument();

		MarkdownModule.forRoot();

		if (this.legalDocument.id === undefined) {
			this.fetchingDocument = false;
			this.invalidDocumentType = true;
		}

		this.ui.stopLoading();
	}

	loadDocument() {
		this.legalDocument = this.route.snapshot.data.documentData;
		this.documentTypes = this.route.snapshot.data.documentTypeData
		this.fetchingDocument = false;
	}
}