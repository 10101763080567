<div class="main main-full main-responsive">
    <app-title [title]="'account-change-plan' | translate "></app-title>

    <ng-container *ngFor="let path of paths">
        <div class="plan-item" [class.active]="path.priceId === selectedPlanId" (click)="selectedPlanId = path.priceId">
            <div class="plan-title">
                <div class="current-plan" *ngIf="planId === path.priceId">{{'current-plan' | translate}}</div>
                <div>{{(path.period === 'year' ? 'annual' : 'monthly') | translate}}</div>
            </div>
            <div class="plan-details">
                <span *ngIf="path.period === 'month'">
                    {{'payment-type-access-sub-text-monthly-text-1' | translate}}
                    <img src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points" class="icon-small">
                    {{'payment-type-access-sub-text-monthly-text-2' | translate}}.
                </span>
                <span *ngIf="path.period === 'year'">
                    {{'payment-type-access-sub-text-annual-text-1' | translate}}
                    <img src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points" class="icon-small">
                    {{'payment-type-access-sub-text-annual-text-2' | translate}}
                </span>
            </div>
            <div class="plan-price">
                {{getPrice(path) | currency : (path.defaultCurrency | uppercase)}}
            </div>
        </div>
    </ng-container>

    <div class="plan-buttons">
        <button (click)="updateConfirmation()" class="btn btn-primary mt-3">{{ 'account-change-plan' | translate }}</button>
        <button (click)="endTrial()" class="btn btn-primary mt-3" *ngIf="isStaging()">TEST - END TRIAL</button>

        <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
    </div>

</div>
