import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HeroResponse } from '../../models';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-hero-carousel',
    templateUrl: './hero-carousel.component.html',
    styleUrls: ['./hero-carousel.component.scss'],
    providers: [NgbCarouselConfig]
})
export class HeroCarouselComponent implements OnInit, OnDestroy {

    @Input() heroResponse: HeroResponse;
    destroy = new Subject<void>();

    @ViewChild('carousel') carousel : NgbCarousel;

    constructor(private config: NgbCarouselConfig) {
    }

    ngOnInit(): void {

        if (this.heroResponse.autoPlay) {
            this.config.interval = this.heroResponse.autoPlayTime;
        } else {
            this.config.interval = 0;
        }
        this.config.wrap = true;
        this.config.keyboard = false;
        this.config.pauseOnHover = true;
        this.config.showNavigationArrows = false;
        this.config.showNavigationIndicators = this.heroResponse.heroes.length > 1;
    }

    next() {
        this.carousel.next();
    }

    prev() {
        this.carousel.prev();
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
