<div class="ml-5 mt-4 country-blocked">
	<h3>Sorry, this service isn't available in your region.</h3>

	This service is no longer available in the following regions:
	<ul>
		<li>Russia</li>
		<li>Belarus</li>
	</ul>
	To manage payment and subscription, Account pages will continue to be accessible.
	<br>
	If you are a current Subscriber and are having trouble managing your account, please contact support:
	website@corridordigital.com
	<br>
	<br>
	The Team
</div>