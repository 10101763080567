<div class="thumb h-100">
  <img class="thumb-image h-100 w-100 schedule-image" *ngIf="!imageUrl"
    src="https://cdn.watchcorridor.com/assets/loading-packshot-vertical.jpg" />
  <img class="thumb-image h-100 w-100 schedule-image" *ngIf="imageUrl" src="{{imageUrl}}" />
  <div class="schedule-overlay">
    <h4 class="smaller mb-0"><b>{{date}}</b></h4>
    <h4 class="smaller">{{time}}</h4>
    <div class="pt-2 pb-2"></div>
    <h4 class="h4-schedule"><b>{{title}}</b></h4>
  </div>
</div>