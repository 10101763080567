<div class="main main-full main-responsive wide">
    <app-title class="d-block mb-3" [title]="'account-payment-history' | translate "></app-title>
    <div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
        <span class="sr-only">{{ "loading" | translate }}</span>
    </div>

    <div *ngIf="!loading">
        <div class="overflow-auto">
            <div class="d-wide">
                <table class="payment-table">
                    <thead>
                    <tr>
                        <th>Subscription</th>
                        <th>Payment Method</th>
                        <th>Amount</th>
                        <th>Service Period</th>
                        <th></th>
                        <th></th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor='let payment of payments'>
                        <td>{{payment.subscriptionId}}</td>
                        <td>
                            <div class="my-auto">
                                <img *ngIf="payment.type === 'Patreon'" src="assets/subscriptions/patreon.svg"
                                     height="24px"/>
                                <img *ngIf="payment.type === 'Apple'" src="assets/subscriptions/apple.svg"
                                     height="24px"/>
                                <img *ngIf="payment.type === 'Stripe'" src="assets/subscriptions/stripe.svg"
                                     height="24px"/>
                                <img *ngIf="payment.type === 'PayPal'" src="assets/subscriptions/paypal.svg"
                                     height="24px"/>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="payment.type === 'Voucher'">
                                <span>VOUCHER</span>
                            </ng-container>
                            <ng-container *ngIf="payment.type !== 'Voucher'">
                                <span *ngIf="payment.currency !== 'trial'">${{payment.amount ?? 0 / 100}}</span>
                                <span *ngIf="payment.currency === 'trial'">TRIAL</span>
                            </ng-container>
                        </td>
                        <td>{{ payment.startDate | date}} - {{ payment.endDate | date}}</td>
                        <td>
                            <a *ngIf="payment.receiptUrl" href="{{payment.receiptUrl}}" [target]="isMobile ? '_self' : '_blank'"
                               class="btn btn-link">Receipt</a>
                        </td>
                        <td>
                            <span *ngIf="payment.status" class="mr-3"
                                  [class.text-warning]="payment.status === 'REFUNDED'"
                                  [class.text-success]="payment.status === 'COMPLETED'">{{payment.status}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-slim">
                <table class="payment-table">
                    <tbody>
                    <tr *ngFor='let payment of payments'>
                        <td>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <ng-container *ngIf="payment.type === 'Voucher'">
                                        <span>VOUCHER</span>
                                    </ng-container>
                                    <ng-container *ngIf="payment.type !== 'Voucher'">
                                        <span *ngIf="payment.currency !== 'trial'">${{payment.amount ?? 0 / 100}}</span>
                                        <span *ngIf="payment.currency === 'trial'">TRIAL</span>
                                    </ng-container>
                                </div>
                                <div>
                                    <img *ngIf="payment.type === 'Patreon'" src="assets/subscriptions/patreon.svg"
                                         height="16px"/>
                                    <img *ngIf="payment.type === 'Apple'" src="assets/subscriptions/apple.svg"
                                         height="16px"/>
                                    <img *ngIf="payment.type === 'Stripe'" src="assets/subscriptions/stripe.svg"
                                         height="16px"/>
                                    <img *ngIf="payment.type === 'PayPal'" src="assets/subscriptions/paypal.svg"
                                         height="16px"/>
                                </div>
                            </div>
                            <div>
                                {{payment.subscriptionId}}
                            </div>
                            <div>
                                {{ payment.startDate | date}} - {{ payment.endDate | date}}
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <a *ngIf="payment.receiptUrl" href="{{payment.receiptUrl}}" [target]="isMobile ? '_self' : '_blank'"
                                       class="btn-link">Receipt</a>
                                </div>

                                <div>
                                    <span *ngIf="payment.status"
                                          [class.text-warning]="payment.status === 'REFUNDED'"
                                          [class.text-success]="payment.status === 'COMPLETED'">
                                        {{payment.status}}
                                    </span>
                                </div>
                            </div>

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div *ngIf="payments && payments.length === 0">{{ 'account-payment-history-none' | translate }}</div>

        <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
    </div>
</div>
