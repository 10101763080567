<div id="recommendation-list">
    <ng-container *ngFor='let m of media; let index = index'>
        <div class="mb-2">
            <app-video-thumb-v2 [fromWatchList]="watchList" [media]="m" [seasonId]="seasonId" [hoverOnSmall]="false"></app-video-thumb-v2>
        </div>
    </ng-container>

    <a routerLink="/">
        <img class="w-100" *ngIf="media && media.length == 0"
            src="{{config.remoteConfig.loading_packshot}}" alt="Up Next - Channels" />
    </a>
</div>
