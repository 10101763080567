export interface ItemSummary {
    title: string;
    subtitle: string;
    imageUrl: string;
    images: ItemImage[];
    releaseDate: string;
    channelId: number;
    slug: string;
}

export interface ItemImage {
    url: string;
    type: string;
    dateCreated: string;
}
