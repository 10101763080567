import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { NotificationMessage } from '../models';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    sendPushDeviceId(pushDeviceId: string): Observable<any> {
        return this.http.put<any>(this.envService.userHost + '/v2/pushnotification/', {pushDeviceId: pushDeviceId, platform: 'web'})
    }

    getNotifications(): Observable<NotificationMessage[]> {
        return this.http.get<NotificationMessage[]>(this.envService.userHost + '/v2/notification/')
    }
}
