import { Component, Input, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
    selector: 'app-subscription-row-v2',
    templateUrl: './subscription-row-v2.component.html',
    styleUrls: ['./subscription-row-v2.component.css']
})
export class SubscriptionRowV2Component implements OnInit {

    error: string;
    loading: boolean = false;
    @Input() subscription: any;
    @Input() showConvert: boolean = false;

    constructor(
        private app: AppComponent,
        private subscriptionService: SubscriptionService) {
    }

    ngOnInit(): void {
    }

    refresh() {
        this.error = null;
        this.loading = true;
        this.subscriptionService.getSubscription(this.subscription.subscriptionId).subscribe(res => {
            this.subscription = res;
            this.loading = false;
        });
    }

    cancel() {
        this.error = null;
        this.loading = true;
        this.subscriptionService.cancelSubscription(this.subscription.subscriptionId).subscribe(
            res => {
                this.subscription = res;
                this.loading = false;
            },
            err => {
                this.loading = false;
                this.error = 'Failed to cancel subscription.';
                this.app.showSimpleAlert({
                    title: 'Failed to cancel subscription',
                    body: 'We were unable to cancel your subscription, please try again.<br />If the problem persists please contact support.'
                });
            });
    }

    resume() {
        this.error = null;
        this.loading = true;
        this.subscriptionService.resumeSubscription(this.subscription.subscriptionId).subscribe(
            res => {
                this.subscription = res;
                this.loading = false;
            },
            err => {
                this.error = err.error;
                this.loading = false;
            });
    }
}
