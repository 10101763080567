import { Component, OnInit } from '@angular/core';
import { CreatorTeamService } from '../../services/creator-team.service';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, tap } from 'rxjs';
import { ImageModel } from '../../models';
import { Router } from '@angular/router';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-creators',
    templateUrl: './creators.component.html',
    styleUrls: ['./creators.component.scss']
})
export class CreatorsComponent implements OnInit {

    private reverseSubject = new BehaviorSubject<boolean>(false);
    reverse$ = this.reverseSubject.asObservable();
    creatorTeams$: Observable<CreatorTeamGridModel[]>;

    constructor(private service: CreatorTeamService,
                private router: Router,
                private ui: UiService) {
    }

    ngOnInit(): void {
        this.ui.startLoading();
        const teams$ =  this.service.getTeams().pipe(
            shareReplay(1),
            map(creatorTeamResponses => {
                const teams: CreatorTeamGridModel[] = [];
                for (let team of creatorTeamResponses) {
                    if (team.id && team.images && team.images.length > 0) {
                        teams.push({
                            id: team.id,
                            displayName: team.displayName,
                            shortDescription: team.shortDescription,
                            created: team.created,
                            image: team.images.find(x => x.type === 'thumb-logo')?.url
                        });
                    }
                }
                this.ui.stopLoading();
                return teams;
            })
        );

        this.creatorTeams$ = combineLatest([teams$, this.reverse$]).pipe(
            map(([teams, reverse]) => (reverse ? [...teams].reverse() : teams))
        );
    }

    viewProfile(teamId: number) {
        this.router.navigate(['/creators', teamId]);
    }

    toggleSort() {
        this.reverseSubject.next(!this.reverseSubject.value);
    }

}

export interface CreatorTeamGridModel {
    id: number;
    displayName: string;
    shortDescription: string;
    created: string;
    image: string;
}
