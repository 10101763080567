<div class="main main-full main-responsive">
    <app-title [title]="'Cancel Membership'"></app-title>
    <div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="row">
        <div class="col-12">
            <p>
                Thanks for supporting us and our efforts to bring fresh new content outside of the
                YouTube ecosystem.
            </p>

            <p>Your membership will continue until the end of your current billing period on <b
                    class="text-primary">{{subscriptionEndDate | date:'medium'}}</b></p>

            <p class="text-danger" *ngIf="error">{{error}}</p>

            <a *ngIf="appleSubscriptionType !== true && patreonSubscriptionType !== true"
                class="btn btn-outline-primary btn-slim mt-2 mr-2 yellow hover-black" [routerLink]="['/account/cancel-membership/reason']">Continue With Cancellation</a>

            <div *ngIf="appleSubscriptionType === true">
                <h4>Cancel a subscription from Apple</h4>
                <p>You can cancel a subscription in the Settings app on your iPhone or iPad, or in the App Store on
                    your Mac.</p>
                <a class="btn btn-outline-primary btn-slim mr-2 yellow hover-black" href="https://apple.co/2Th4vqI"
                   [target]="isMobile ? '_self' : '_blank'">Cancel an Apple subscription</a>
            </div>

            <div *ngIf="patreonSubscriptionType === true">
                <h4>Cancel a subscription from Patreon</h4>
                <p>You can only cancel a Patreon subscription at Patreon.com.</p>
                <a class="btn btn-outline-primary btn-slim mr-2 yellow hover-black" href="https://www.patreon.com/pledges"
                   [target]="isMobile ? '_self' : '_blank'">Cancel an Patreon subscription</a>
            </div>

            <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>

            <div class="mt-3">
                <small *ngIf="appleSubscriptionType === true" class="d-block">
                    Need help cancelling your Apple Subscription, check the Apple support article:
                    <a class="blue" [target]="isMobile ? '_self' : '_blank'" href="https://support.apple.com/en-gb/HT202039">Apple Support
                        Article: Cancel a subscription</a>
                </small>
                <small *ngIf="patreonSubscriptionType === true" class="d-block">
                    Need help cancelling your Patreon Subscription, check the Patreon support article:
                    <a class="blue" [target]="isMobile ? '_self' : '_blank'"
                        href="https://support.patreon.com/hc/en-gb/articles/360000126286-Editing-your-membership#h_01EPWHKBX8XBBDQ125YJVJNSTP">Patreon
                        Support Article: Cancel a subscription</a>
                </small>
                <small class="d-block">
                    Having problems cancelling your membership? Contact our customer service:
                    <a class="blue" [routerLink]="['/home/feedback']">Contact Support</a>
                </small>
                <small class="d-block">
                    Please check out payments and refund policies if you have queries regarding refunds:
                    <a class="blue" [target]="isMobile ? '_self' : '_blank'"
                        [routerLink]="['/legaldocuments/refund-policy']">Refund
                        Policy</a>
                </small>
            </div>
        </div>
    </div>
</div>
