import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { ContactRequest } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    contact(form: ContactRequest, hash: string): Observable<any> {
        form.hash = hash;
        return this.http.post<any>(this.envService.userHost + '/v1/contact', form);
    }

    contactv2(form: ContactRequest, hash: string): Observable<any> {
        form.hash = hash;
        return this.http.post<any>(this.envService.userHost + '/v2/contact', form);
    }

    submitTicket(request: ContactRequest): Observable<any> {
        return this.http.post<any>(this.envService.userHost + '/v2/tickets/support-ticket/anonymous',
            {
                name: request.name,
                email: request.email,
                platform: request.platform,
                subject: request.subject,
                message: request.message
            });
    }
}
