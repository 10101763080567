export interface ContactRequest {
    email: string;
    name: string;
    hash: string;
    platform: string;
    subject: string;
    message: string;
    selection: string;
    formattedMessage: string;
}
