import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { CreatorTeamResponse } from 'src/app/models/creator-team-response';
import { ChannelsService } from 'src/app/services/channels.service';
import { CreatorTeamService } from 'src/app/services/creator-team.service';

@Component({
    selector: 'app-creator-profile',
    templateUrl: './creator-profile.component.html',
    styleUrls: ['./creator-profile.component.scss']
})
export class CreatorProfileComponent implements OnInit, OnDestroy {
    creatorId: number;
    creatorTeam: CreatorTeamResponse;
    viewAll: number[] = [0, 1];
    viewAllButtonText: string = 'View all';
    latestMedia: any[];
    popularMedia: any[];
    notFound: boolean = false;

    destroy$ = new Subject<void>();

    constructor(
        private teamService: CreatorTeamService,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.creatorId = +this.route.snapshot.paramMap.get('creatorId');

        if (this.creatorId) {
            this.teamService.getTeam(this.creatorId).pipe(takeUntil(this.destroy$)).subscribe({
                next: (data) => {
                    this.creatorTeam = data;
                    console.log(data);
                },
                error: (err) => {
                    this.notFound = true;
                    console.error('Failed to load creator data:', err);
                },
                complete: () => console.log('Creator data fetched successfully.')
            });

            this.teamService.getTeamVideos(this.creatorId).pipe(takeUntil(this.destroy$)).subscribe({ // 9 needs to be changed to this.creatorId
                next: (data) => {
                    this.latestMedia = data.latest;
                    this.popularMedia = data.popular;
                },
                error: (err) => console.error('Failed to load creator data:', err),
                complete: () => console.log('Creator data fetched successfully.')
            });
        }
    }

    /**
     * Alters view all array length to display either 2 or all of the latest videos.
     */
    toggleViewAll() {
        if (this.viewAll.length > 2 || (this.viewAll.length === 2 && (this.viewAll[0] !== 0 || this.viewAll[1] !== 1))) {
            this.viewAll = [0, 1];
            this.viewAllButtonText = 'View all';
        } else {
            this.viewAll = Array.from({ length: this.latestMedia.length }, (_, i) => i);
            this.viewAllButtonText = 'View less';
        }
    }

    checkHeroImages(): boolean {
        if (this.creatorTeam) {
            return this.creatorTeam.images.some(image =>
                image.type === 'hero-mobile' || image.type === 'hero-desktop'
            );
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
