import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../../services/payments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { AccountService } from '../../../services/account.service';
import { StorageManagerService } from '../../../services/storage-manager.service';

@Component({
    selector: 'app-voucher-v2',
    templateUrl: './voucher-v2.component.html',
    styleUrls: ['./voucher-v2.component.scss']
})
export class VoucherV2Component extends AuthenticationOverride implements OnInit {
    code: string = '';
    error: string;
    loading: boolean = false;

    constructor(private app: AppComponent,
                public router: Router,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService) {
        super(route, storage, router, account);
    }

    async navigateComplete() {
        await this.app.getUserInfo();
        this.router.navigate(['account']);
    }
}
