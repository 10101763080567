	import { Component, Input, OnInit } from '@angular/core';
	import { ChannelResponse } from '../../models';
    import { RemoteConfigService } from '../../services/remote-config.service';

	@Component({
		selector: 'app-channel-header',
		templateUrl: './channel-header.component.html',
		styleUrls: ['./channel-header.component.scss']
	})
	export class ChannelHeaderComponent implements OnInit {

		@Input() channel: ChannelResponse;
		@Input() showBadge: boolean = true;

		get routerLink(): string[] {
			if (this.channel.seasonId !== undefined) {
				return ['/season', this.channel.seasonId.toString()];
			}
			return ['/shows', this.channel.slug]
		}

		image: any;

		constructor(public config: RemoteConfigService) {
		}

		ngOnInit(): void {
			this.image = this.channel.images?.find(i => i.type === 'badge')?.url;
		}

	}
