import { MediaSummary } from './media-summary';

export class Channel {
    constructor(
        public id: string,
        public channels: Channel[],
        public images: any[],
        public name: string,
        public itemType: string,
        public slug: string,
        public miniImageUrl: string,
        public type: string,
        public mediaIds: string[],
        public media: MediaSummary[],
        public isShow?: boolean,
        public items?: any,
        public itemIds?: any) {
    }
}
