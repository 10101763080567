import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ui-kit',
    templateUrl: './ui-kit.component.html',
    styleUrls: ['./ui-kit.component.css']
})
export class UiKitComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
