import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { MetadataResponseV2 } from '../models';
import { MetadataResponseV3 } from '../models/metadata-response-v3';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getMetadata(mediaId: number): Observable<MetadataResponseV2> {
        return this.http
            .get<MetadataResponseV2>(this.envService.contentCdnHost + '/v2/metadata/' + mediaId)
    }

    getMetadataV3(mediaId: number): Observable<MetadataResponseV3> {
        return this.http
            .get<MetadataResponseV3>(this.envService.contentCdnHost + '/v3/metadata/' + mediaId)
    }
}
