<div class="main main-full main-responsive">
    <app-title [title]="'account-store-discount-code' | translate"></app-title>
    <div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
        <span class="sr-only">{{ "loading" | tranlsate }}</span>
    </div>
    <ng-container *ngIf="loading">
        <p>{{ "account-code-checking" | translate }}</p>
    </ng-container>

    <ng-container *ngIf="!loading && promo && promo.code">
        <p>{{ "account-store-code-your-discount-code" | translate}} </p>
        <h3>{{promo.code}}</h3>
    </ng-container>

    <ng-container *ngIf="!loading && !promo">
        <a [routerLink]="['/payment']">{{ "account-store-code-subscribe" | translate }}</a>
    </ng-container>

    <div class="d-block"></div>
    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>
