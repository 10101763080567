import { ProjectVersusVideo } from './project-versus-video';
import { ProjectVersusImage } from './project-versus-image';

export interface ProjectResponseV5 {
    id: number;
    title: string;
    points: number | null;
    description: string;
    videos: ProjectVersusVideo[];
    images: ProjectVersusImage[];
    mediaId: number | undefined;
}
