import { DeleteRequest } from "./delete-request";

export class UserInfo {
    queryList?: string = undefined;
    automaticRenewal?: boolean | null = undefined;
    avatarUrl?: string = undefined;
    badgeUrl?: string = undefined;
    hash?: string = undefined;
    uid?: string = undefined;
    hasPatreon?: boolean | null = undefined;
    hasSubscription?: boolean | null = undefined;
    notificationsEnabled?: boolean | null = undefined;
    pointsAvailable?: number | null = undefined;
    pointsGained?: number | null = undefined;
    pointsSpent?: number | null = undefined;
    streamLimit?: number | null = undefined;
    streamsRemaining?: number | null = undefined;
    nameRequired?: boolean | null = undefined;
    subscriptionEndDate?: string | null = undefined;
    displayName?: string = undefined;
    badges?: string[] = undefined;
    preferencesSet?: boolean | null = undefined;
    userCreated?: string | null = undefined;
    email?: string = undefined;
    plan?: string = undefined;
    subscriptionType?: string = undefined;
    subscriptionCancelled?: boolean = undefined;
    userId?: number = undefined;
	deleteRequest?: DeleteRequest = undefined;
    subscriptionId?: string = undefined;
    planId?: string = undefined;
    isInTrial?: boolean = undefined;
    canUpdatePlan?: boolean = undefined;
    verified?: boolean = undefined;
}
