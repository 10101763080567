import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollTrackerService {
  private scrollPositions: Map<string, number> = new Map();

  setScrollPosition(url: string, position: number): void {
    this.scrollPositions.set(url, position);
  }

  getScrollPosition(url: string): number | undefined {
    return this.scrollPositions.get(url);
  }
}
