import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../services/payments.service';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-voucher',
    templateUrl: './voucher.component.html',
    styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {
    code: string = '';
    error: string;
    loading: boolean = false;

    constructor(private paymentService: PaymentsService,
                private app: AppComponent,
                private router: Router) {
    }

    ngOnInit(): void {
    }

    async navigateComplete() {
        await this.app.getUserInfo();
        this.router.navigate(['account']);
    }
}
