import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionService } from '../../services/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { AuthenticationOverride } from '../../modules/authentication-override';
import { StorageManagerService } from '../../services/storage-manager.service';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-paypal-payment-pending',
    templateUrl: './paypal-payment-pending.component.html',
    styleUrls: ['./paypal-payment-pending.component.scss']
})
export class PaypalPaymentPendingComponent extends AuthenticationOverride implements OnInit {

    interval: any;
    loading: boolean = false;
    paypalUrl: string;

    paymentDuration;

    constructor(private app: AppComponent,
                private subscriptionService: SubscriptionService,
                public router: Router,
                route: ActivatedRoute,
                storageManager: StorageManagerService,
                account: AccountService) {
        super(route, storageManager, router, account);

        this.paymentDuration = window.history.state.duration;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.paymentDuration) {
            this.paypalPay();
        } else {
            this.router.navigate(['/payment'], {queryParamsHandling: 'merge'});
        }
    }

    paypalPay() {
        let annual = this.paymentDuration === 'annual';
        this.loading = true;

        this.subscriptionService.createPayPalSubscription(annual).subscribe({
            next: res => {
                this.paypalUrl = res.approvalUrl;
                const paypalWindow = window.open(res.approvalUrl, 'popup', 'width=600,height=860');

                this.interval = setInterval(() => {
                    this.subscriptionService.checkPayPalSubscriptionStatus(res.subscriptionId).subscribe({
                        next: res => {
                            if (res.status === 200) {
                                clearInterval(this.interval);
                                this.handleComplete();
                            } else if (paypalWindow.closed !== false) {
                                clearInterval(this.interval);
                                this.handleError('PayPal window closed without payment');
                            }
                        },
                        error: error => {
                            this.handleError(error.error.message ?? error.error.error);
                            clearInterval(this.interval);
                        }
                    });
                }, 5000);
            },
            error: error => {
                this.handleError(error.error.message);
            }
        });
    }

    handleComplete() {
        this.onComplete();

        setTimeout(() => {
            this.account.updateSubscriptionStatus();
        }, 3000);

        this.app.showSimpleAlert({
            title: 'Subscription Successful!'
        });
        this.router.navigate(['/']);
    }

    handleError(error: string) {
        this.router.navigate(['/payment'], {queryParamsHandling: 'merge', state: {paypalError: error}});
    }
}
