<div class="main main-full config-background-2">
    <div class="container-fluid h-100">
        <div class="row">
            <div class="col-12 pb-5">
                <h1>This is a heading for h1</h1>
                <h2>This is a heading for h2</h2>
                <h3>This is a heading for h3</h3>
                <h4>This is a heading for h4</h4>
                <h5>This is a heading for h5</h5>
            </div>
        </div>
        <div class="row h-100 ml-5">
            <div>
                <button class="btn btn-primary mr-2">Button Label</button>
                <button class="btn btn-outline-primary mr-2">Button Label</button>
            </div>
            <div class="bg-info p-5">
                <button class="btn btn-secondary mr-2">Button Label</button>
                <button class="btn btn-outline-secondary" mr-2>Button Label</button>
            </div>
            <div class="p-5 mb-2">
                <button class="btn btn-light mr-2">Button Label</button>
                <button class="btn btn-outline-light" mr-2>Button Label</button>
            </div>
            <div>
                <button class="btn btn-primary mr-2">Learn about Producer Points</button>
                <button class="btn btn-outline-primary mr-2">Learn about Producer Points</button>
            </div>
            <div class="bg-info p-5">
                <button class="btn btn-secondary mr-2">Learn about Producer Points</button>
                <button class="btn btn-outline-secondary" mr-2>Learn about Producer Points</button>
            </div>
            <div class="p-5">
                <button class="btn btn-light mr-2">Check out merch store</button>
                <button class="btn btn-outline-light" mr-2>Check out merch Store</button>
            </div>

            <div class="bg-primary p-5">
                <div>
                    <button class="btn btn-outline-primary mr-2">Learn about Producer Points</button>
                </div>
            </div>
        </div>
        <div class="row h-100 ml-5 mt-5 bg-white p-5">
            <div class="col-4 pr-0">
                <div class="card card-left">
                    <div class="card-title">Monthly
                        Membership
                    </div>
                    <div class="card-body">
                        <hr style="width:40%">
                        <p>Want flexibility? No worries, start out with a monthly subscription. But if you want real
                            savings, go for the annual.</p>
                        <p><b>$39.99/annual<br />30 Points</b></p>

                        <button class="btn btn-outline-secondary">Save with annual</button>
                    </div>
                </div>
            </div>
            <div class="col-4 pr-0 pl-0">
                <div class="card">
                    <div class="card-title">
                        <div>
                            <svg class="card-logo" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M39.009 24L24 39.009V46L46 24H39.009Z" fill="black" />
                                <path d="M24 6.99244L39.009 22H46L24 0V6.99244Z" fill="black" />
                                <path d="M6.99244 22L22 6.99244V0L0 22H6.99244Z" fill="black" />
                                <path d="M28.3186 24L24 28.3186V35L35 24H28.3186Z" fill="black" />
                                <path d="M17.6814 22L22 17.6814V11L11 22H17.6814Z" fill="black" />
                                <path d="M22 28.3186L17.6814 24H11L22 35V28.3186Z" fill="black" />
                            </svg>
                        </div>
                        Monthly
                        Membership
                    </div>
                    <div class="card-body">
                        <hr style="width:40%">
                        <p>Want flexibility? No worries, start out with a monthly subscription. But if you want real
                            savings, go for the annual.</p>
                        <p><b>$39.99/annual<br />30 Points</b></p>

                        <button class="btn btn-outline-primary">Save with annual</button>
                    </div>
                </div>
            </div>
            <div class="col-4 pl-0">
                <div class="card card-right">
                    <div class="card-title">Monthly
                        Membership
                    </div>
                    <div class="card-body">
                        <hr style="width:40%">
                        <p>Want flexibility? No worries, start out with a monthly subscription. But if you want real
                            savings, go for the annual.</p>
                        <p><b>$39.99/annual<br />30 Points</b></p>

                        <button class="btn btn-outline-secondary">Save with annual</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row h-100 ml-5 mt-5 bg-secondary p-5">
            <div class="col-5 pr-0">
                <div class="card card-modal">
                    <div class="card-title">
                        <div>
                            <svg class="card-logo-small" viewBox="0 0 46 46" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M39.009 24L24 39.009V46L46 24H39.009Z" fill="black" />
                                <path d="M24 6.99244L39.009 22H46L24 0V6.99244Z" fill="black" />
                                <path d="M6.99244 22L22 6.99244V0L0 22H6.99244Z" fill="black" />
                                <path d="M28.3186 24L24 28.3186V35L35 24H28.3186Z" fill="black" />
                                <path d="M17.6814 22L22 17.6814V11L11 22H17.6814Z" fill="black" />
                                <path d="M22 28.3186L17.6814 24H11L22 35V28.3186Z" fill="black" />
                            </svg>
                        </div>
                        Sign up to Corridor Digital
                    </div>
                    <div class="card-body">
                        <p>Getting started is easy, just a few steps and you can immerse yourself with the latest
                            Corridor content!</p>

                        <button class="btn btn-secondary btn-block">Sign up with email</button>
                        <or-block></or-block>
                        <button class="btn btn-outline-secondary btn-block">Continue with Google</button>
                        <button class="btn btn-outline-secondary btn-block">Continue with Apple</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
