import { Component, Input, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
    selector: 'app-section-video-header-right',
    templateUrl: './video-header-right.component.html',
    styleUrls: ['./video-header-right.component.css']
})
export class VideoHeaderRightComponent implements OnInit {

    @Input() pointsToGo: number;
    @Input() projectId: number;
    @Input() section: any;

    showModal: boolean = false;

    logo: any;
    mobileBackground: string;
    webBackground: string;
    videoUrl: string;

    title: string;
    text: string;
    text2: string;

    constructor(
        private app: AppComponent,
        private projectsService: ProjectsService) {
    }

    ngOnInit(): void {
        this.logo = this.section.images.find(f => f.orientation === 'all')?.url;
        this.mobileBackground = this.section.images.find(f => f.orientation === 'vertical' || f.orientation === 'narrow')?.url;
        this.webBackground = this.section.images.find(f => f.orientation === 'horizontal' || f.orientation === 'wide')?.url;

        this.title = this.section.text.find(f => f.type === 'title')?.text;
        this.text = this.section.text.find(f => f.type === 'text')?.text;
        this.text2 = this.section.text.find(f => f.type === 'text2')?.text;

        this.videoUrl = this.section.videos.find(f => f.format === 'dash')?.url;
    }

    openContribute() {
        this.projectsService.openPointContribution(this.projectId);
    }

    openVideo() {
        this.showModal = true;
    }

    closeVideo() {
        this.showModal = false;
    }
}
