<div class="main-full p-0">
    <div class="projects-vs-container">
        <ng-container *ngIf="projects$ | async as projects; else loading">
            <div class="projects-vs-videos">
                <div class="w-50">
                    <div class="projects-vs-video left">
                        <div class="video-container">
                            <shaka-player-simple [autoplay]="false" [url]="getVideo(projects.leftProject)"
                                                 [hlsUrl]="getVideo(projects.leftProject, 'hls')"
                                                 [side]="'left'"
                                                 [poster]="getImage(projects.leftProject, (isMobileView$ | async) ? 'packshot-vertical' : 'packshot')">
                            </shaka-player-simple>
                        </div>
                    </div>

                </div>
                <div class="w-50">
                    <div class="projects-vs-video right">
                        <div class="video-container">
                            <shaka-player-simple [autoplay]="false" [url]="getVideo(projects.rightProject)"
                                                 [hlsUrl]="getVideo(projects.rightProject, 'hls')"
                                                 [side]="'right'"
                                                 [poster]="getImage(projects.rightProject, (isMobileView$ | async) ? 'packshot-vertical' : 'packshot')">
                            </shaka-player-simple>
                        </div>
                    </div>
                </div>

                <div class="projects-vs-battle-bars">

                    <div class="projects-vs-battle-bar left">
                        <div class="projects-vs-battle-bar-bar" *ngIf="(pointsLeft$ | async)?.points as pointsLeft"
                             [style.width.%]="percentage(pointsLeft)">
                            <span class="projects-vs-battle-bar-bar-points">{{pointsLeft}}PTS</span>
                        </div>
                    </div>

                    <div class="projects-vs-battle-bar-logo">
                        <div>
                            VS
                        </div>
                    </div>

                    <div class="projects-vs-battle-bar right">
                        <div class="projects-vs-battle-bar-bar" *ngIf="(pointsRight$ | async)?.points as pointsRight"
                             [style.width.%]="percentage(pointsRight)">
                            <span class="projects-vs-battle-bar-bar-points">{{pointsRight}}PTS</span>
                        </div>

                    </div>

                </div>

            </div>

            <div class="projects-vs-timer font-weight-bold" *ngIf="isMobileView$ | async">
                <ng-container *ngIf="countdown$ | async as countdown">
                    <div class="text-center">
                        {{countdown.label}} {{countdown.time}}
                    </div>
                </ng-container>
            </div>

            <div class="projects-vs-votes">

                <div class="projects-vs-vote left">
                    <div class="projects-vs-vote-title">
                        {{projects.leftProject.title}}
                    </div>
                    <div class="projects-vs-vote-description">
                        {{projects.leftProject.description}}
                    </div>
                    <button class="btn btn-outline-primary projects-vs-vote-button"
                            [disabled]="!(active$ | async)"
                            (click)="openContribute(projects.leftProject)">
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="!(active$ | async)">
                                <span class="d-none d-lg-block">
                                    Voting Closed
                                </span>
                                <span class="d-block d-lg-none">
                                    Closed
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="isAuthenticated() && (active$ | async)">
                                <span class="d-none d-lg-block">
                                    Vote to Greenlight
                                </span>
                                <span class="d-block d-lg-none">
                                    Vote
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="!isAuthenticated() && (active$ | async)">
                                <span class="d-none d-lg-block">
                                    Login to Contribute
                                </span>
                                <span class="d-block d-lg-none">
                                    Login
                                </span>
                            </ng-container>
                        </ng-container>
                    </button>
                </div>

                <div class="projects-vs-timer font-weight-bold" *ngIf="!(isMobileView$ | async)">
                    <ng-container *ngIf="countdown$ | async as countdown">
                        <div class="title text-center">
                            {{countdown.label}}
                        </div>
                        <div class="text-center">
                            {{countdown.time}}
                        </div>
                    </ng-container>
                </div>

                <div class="projects-vs-vote right">
                    <div class="projects-vs-vote-title">
                        {{projects.rightProject.title}}
                    </div>
                    <div class="projects-vs-vote-description">
                        {{projects.rightProject.description}}
                    </div>
                    <button class="btn btn-outline-primary projects-vs-vote-button"
                            [disabled]="!(active$ | async)"
                            (click)="openContribute(projects.rightProject)">
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="!(active$ | async)">
                                <span class="d-none d-lg-block">
                                    Voting Closed
                                </span>
                                <span class="d-block d-lg-none">
                                    Closed
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="isAuthenticated() && (active$ | async)">
                                <span class="d-none d-lg-block">
                                    Vote to Greenlight
                                </span>
                                <span class="d-block d-lg-none">
                                    Vote
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="!isAuthenticated() && (active$ | async)">
                                <span class="d-none d-lg-block">
                                    Login to Contribute
                                </span>
                                <span class="d-block d-lg-none">
                                    Login
                                </span>
                            </ng-container>
                        </ng-container>
                    </button>
                </div>

            </div>

            <div class="d-flex justify-content-center font-weight-bold mt-3" *ngIf="isMobile">
                    <span class="text mr-1">{{producterPoints$ | async}}</span>
                    <img class="icon" src="https://cdn.watchcorridor.com/assets/pp.gif" alt="Producer Points" />
            </div>

            <div class="project-vs-how-it-works mocap-grad mt-5" [class.mt-3]="isMobile" [class.mt-5]="!isMobile">

                <div class="project-vs-hiw-section">
                    <div class="project-vs-hiw-section-title">
                        <span class="project-vs-hiw-section-title-number">01</span> EARN POINTS
                    </div>
                    <div class="project-vs-hiw-section-text">
                        Every member automatically earns Producer Points based on their subscription.
                    </div>
                </div>
                <div class="project-vs-hiw-section">
                    <div class="project-vs-hiw-section-title">
                        <span class="project-vs-hiw-section-title-number">02</span> BROWSE BATTLES
                    </div>
                    <div class="project-vs-hiw-section-text">
                        Every Idea must Battle head-to-head with another idea; whichever gets the most points wins.
                    </div>
                </div>
                <div class="project-vs-hiw-section">
                    <div class="project-vs-hiw-section-title">
                        <span class="project-vs-hiw-section-title-number">03</span> CONTRIBUTE POINTS
                    </div>
                    <div class="project-vs-hiw-section-text">
                        Contribute Your Points towards your favorite idea before time runs out.
                    </div>
                </div>
                <div class="project-vs-hiw-section">
                    <div class="project-vs-hiw-section-title">
                        <span class="project-vs-hiw-section-title-number">04</span> WATCH WINNERS
                    </div>
                    <div class="project-vs-hiw-section-text">
                        whichever idea wins the point battle, we make.
                    </div>
                </div>
            </div>

        </ng-container>
        <ng-template #loading>
            <div class="loading-container">
                <app-loading-spinner [widthHeightPx]="100"></app-loading-spinner>
            </div>
        </ng-template>
    </div>

    <ng-container *ngIf="pastProjects$ | async as pastProjects">
        <div class="project-vs-past-winners" *ngIf="pastProjects.length > 0">
            <div class="project-vs-past-winners-title">Past Winners</div>
            <div class="transform-container" appSwipe (swipeLeft)="navRight()" (swipeRight)="navLeft()">
                <div class="project-vs-past-winners-winners"
                     [style.transform]="'translateX(' + (-100 * (pastWinnerTransformIndex$ | async)) + '%)'">
                    <a class="project-vs-past-winners-winner" *ngFor="let winner of pastProjects" (click)="pastWinnerClicked(winner)" [class.pointer]="pointer(winner)">
                        <img [src]="winner.url" class="w-100" alt=""/>
                    </a>

                </div>
                <button *ngIf="left" type="button" class="is-nav is-prev" (click)="navLeft()"
                        aria-label="Scroll Left">
                    <div class="is-nav-bg"></div>
                    <img height="42" class="slider-arrow-left"
                         src="https://cdn.watchcorridor.com/assets/LEFT.svg" alt="Scroll Left">
                </button>
                <button *ngIf="right" type="button" class="is-nav is-next"
                        (click)="navRight()" aria-label="Scroll Right">
                    <div class="is-nav-bg"></div>
                    <img height="42" class="slider-arrow-right"
                         src="https://cdn.watchcorridor.com/assets/RIGHT.svg" alt="Scroll Right">
                </button>
            </div>
        </div>
    </ng-container>
</div>

<app-contribute-points [projectId]="voteId$ | async" [projectTitle]="(voteTitle$ | async) | uppercase"></app-contribute-points>
