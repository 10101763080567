import { ProjectPageSectionText } from './project-page-section-text';
import { ProjectPageSectionImage } from './project-page-section-image';
import { ProjectPageSectionVideo } from './project-page-section-video';
import { ProjectPageSectionPlaylist } from './project-page-section-playlist';

export interface ProjectPageSection {
    images?: ProjectPageSectionImage[];
    text?: ProjectPageSectionText[];
    type: string;
    videos?: ProjectPageSectionVideo[];
    playlists?: ProjectPageSectionPlaylist[];
}
