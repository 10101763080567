import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SubscriptionService } from '../../../../services/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../../../../services/ui.service';
import { AccountService } from '../../../../services/account.service';
import { Environment, EnvironmentService } from '../../../../services/environment.service';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { AppComponent } from '../../../../app.component';
import { getCurrencySymbol } from '@angular/common';
import { AuthenticationOverride } from '../../../../modules/authentication-override';
import { PaymentsService } from '../../../../services/payments.service';
import { RemoteConfigService } from '../../../../services/remote-config.service';
import { UserInfoService } from '../../../../services/user-info.service';

declare function Stripe(pk: string): any;

@Component({
    selector: 'app-stripe-checkout',
    templateUrl: './stripe-checkout.component.html',
    styleUrls: ['./stripe-checkout.component.scss']
})
export class StripeCheckoutComponent extends AuthenticationOverride implements OnInit {

    init = false;
    stripe: any;
    stripeElements: any;
    product: string = 'month';
    priceId: string = '';
    selectedPrice: number;
    email: string;
    availableProducts: any;
    hasPreviousSub: boolean = false;
    errorMessage: string = '';
    trialEndDate: any;
    paymentElement: any;
    currency: string = 'usd';
    currencies: string[] = [];
    session: any;

    annualPrice = 0;
    monthlyPrice = 0;
    refundAccepted: boolean = false;
    hasPatreon = false;
    voucherCode: string;
    voucherError: string = '';

    loading = true;

    constructor(private subService: SubscriptionService,
                route: ActivatedRoute,
                storageManager: StorageManagerService,
                router: Router,
                private ui: UiService,
                account: AccountService,
                private envService: EnvironmentService,
                private app: AppComponent,
                private paymentService: PaymentsService,
                public config: RemoteConfigService,
                private userInfo: UserInfoService) {
        super(route, storageManager, router, account);
    }

    async ngOnInit() {
        super.ngOnInit();
        this.ui.startLoading();

        const error = window.history.state.paypalError;
        if (error) {
            this.errorMessage = error;
        }

        try {
            const [availableProducts, session, trialPeriod, hasPrevious] = await Promise.all([
                lastValueFrom(this.subService.getStripePrices()),
                lastValueFrom(this.subService.getSecret()),
                lastValueFrom(this.subService.getTrialPeriod()),
                lastValueFrom(this.subService.hasPreviousSubscription()),
            ]);

            this.trialEndDate = this.addDays(new Date(), trialPeriod);
            this.stripe = Stripe(this.envService.stripePk);

            this.availableProducts = availableProducts;
            this.session = session;
            this.hasPreviousSub = hasPrevious;

            this.getCurrencies();
            this.updatePrices();
            this.monthly();

            this.stripeElements = this.stripe.elements({clientSecret: this.session.clientSecret, appearance: this.appearance()});
            this.paymentElement = this.stripeElements.create('payment');
            this.paymentElement.mount('#payment-element');
        } catch (e) {
            if (e.error?.message === 'You already have an Active Subscription.') {
                await this.account.updateSubscriptionStatus();
                this.router.navigate([this.account.nextRoute ?? '/']);
                this.handleComplete()
            }
            console.log(e);
        } finally {
            this.ui.stopLoading();
            this.loading = false;
        }
    }

    viewDocument(type, event) {
        event.preventDefault();
        event.stopPropagation();
        window.open(`/legaldocuments/${type}`);
    }

    getCurrencySymbol(code: string) {
        return getCurrencySymbol(code.toUpperCase(), 'narrow');
    }

    addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    getCurrencies() {
        this.availableProducts.find(x => x !== undefined)?.prices.forEach(x => this.currencies.push(x.currency));
    }

    updatePrices() {
        this.annualPrice = this.availableProducts?.find(x => x.period === 'year')?.prices.find(x => x.currency === this.currency)?.price;
        this.monthlyPrice = this.availableProducts?.find(x => x.period === 'month')?.prices.find(x => x.currency === this.currency)?.price;
        this.selectedPrice = this.product === 'month' ? this.monthlyPrice : this.annualPrice;
    }

    annual() {
        this.product = 'year';
        this.priceId = this.availableProducts?.find(x => x.period === 'year')?.priceId;
        this.selectedPrice = this.annualPrice;
    }

    monthly() {
        this.product = 'month';
        this.priceId = this.availableProducts?.find(x => x.period === 'month')?.priceId;
        this.selectedPrice = this.monthlyPrice;
    }

    appearance() {
        const styles = getComputedStyle(document.body);
        return {
            theme: 'none',
            labels: 'floating',
            variables: {
                colorText: styles.getPropertyValue('--primary_text_colour'),
                colorBackground: styles.getPropertyValue('--plan_button_background')
            },
            rules: {
                '.Input': {
                    borderRadius: '0',
                    borderBottom: '2px solid ' + styles.getPropertyValue('--plan_button_background'),
                },
                '.Input:focus': {
                    borderRadius: '0',
                    borderBottom: '2px solid ' + styles.getPropertyValue('--button_highlight_colour'),
                    outline: '0'
                }
            }
        };
    }

    async confirmStripeSetup() {
        this.ui.startLoading();
        try {
            const {setupIntent, error} = await this.stripe.confirmSetup({
                elements: this.stripeElements,
                confirmParams: {
                    return_url: `${location.origin}/complete-payment/${this.priceId}`,
                },
                redirect: 'if_required'
            });

            if (setupIntent && setupIntent.status === 'succeeded') {
                console.log('SETUP INTENT: ', setupIntent);

                try {
                    if (this.hasPatreon) {
                        await lastValueFrom(this.subService.convertPatreon(this.priceId, setupIntent.id));
                    } else {
                        await lastValueFrom(this.subService.createStripeSubscription(this.priceId, setupIntent.id));
                    }
                } catch (e) {
                    if (e.error.message === 'You already have an Active Subscription.') {
                        await this.account.updateSubscriptionStatus();
                        this.router.navigate([this.account.nextRoute ?? '/']);
                    }
                }

                this.handleComplete();
                return;
            }

            if (error) {
                this.errorMessage = error.message;
            }
        } finally {
            this.ui.stopLoading();
        }

    }

    async redeemVoucher() {
        this.ui.startLoading();
        try {
            await lastValueFrom(this.paymentService.redeemVoucher(this.voucherCode));
            this.handleComplete();
            return;
        } catch (e) {
            this.voucherError = e.error;
        } finally {
            this.ui.stopLoading();
        }
    }

    isStaging() {
        return this.envService.env !== Environment.Production;
    }

    handleComplete() {
        this.onComplete();

        setTimeout(() => {
            this.account.updateSubscriptionStatus();
        }, 3000);

        this.app.showSimpleAlert({
            title: 'Subscription Successful!'
        });
        this.router.navigate(['/']);
    }

    payPal() {
        if (!this.refundAccepted) {
            return;
        }
        this.router.navigate(['/payment/paypal'], {
            state: {duration: this.product === 'month' ? 'monthly' : 'annual'},
            queryParamsHandling: 'merge'
        });
    }

    paypalError(error: string) {
        this.errorMessage = error;
    }
}
