<div class="up-next-container">
    <div [class.hidden]="cancelled || endOfVideoTime <= -1" class="up-next-countdown">
        <h4 class="text-dull d-inline-block">Next video in&nbsp;&nbsp;</h4>
        <h3 class="d-inline-block">{{ endOfVideoTime }}</h3>
    </div>
    <div class="up-next-media">
        <div class="up-next-video-img-placeholder">
            <img class="up-next-video-img hidden" src="/assets/end-card-placeholder.png">
            <img class="up-next-video-img actual-image" src="{{endOfVideoImage}}" alt="Next Video" onload="this.style.display='block'; this.previousElementSibling.style.display='none';" />
        </div>
        <div class="up-next-video-text">
            <div class="video-title">{{ endOfVideoTitle }}</div>
            <div class="video-description">{{ endOfVideoDescription }}</div>
        </div>
    </div>
    <div class="video-end-controls">
        <button *ngIf="cancelled && !(endOfVideoTime > -1)" class="btn btn-outline-light w-100 btn-up-next"
            (click)="watchAgain.emit()">WATCH AGAIN</button>
        <button *ngIf="!cancelled && endOfVideoTime > -1" class="btn btn-outline-light w-100 btn-up-next"
            (click)="upNextCancelled.emit()">CANCEL
        </button>
        <button class="btn-primary w-100" (click)="playNextVideo.emit()">PLAY
        </button>
    </div>
</div>