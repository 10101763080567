<div class="main-full">
    <div class="container-fluid search-container mb-5">

        <div class="row">
            <a routerLink="/">
                <h3 class="main-channel-header mt-3 mb-3">{{ "home" | translate }}</h3>
            </a>
            <h3 class="main-channel-header pl-2 pr-2 mt-3 mb-3">/</h3>
            <h3 class="main-channel-header mt-3 mb-3 pl-0">{{ "search" | translate }}</h3>
        </div>

        <div class="row ml-2 mr-2 mb-3">
            <input type="text" name="search" class="form-control text-input-dark"
                placeholder="{{ 'search-message' | translate }}" required autoFocus tabindex="1" autocomplete="off"
                [ngModel]="searchTerm" (input)="onSearchChange($event.target.value)">
        </div>

        <div class="row ml-2 mr-2 mb-3">
            <div class="col-12 col-sm-4 col-md-3 col-xxl-2 p-1 m-0" *ngFor='let m of media'>
                <app-video-thumb-v2 [media]="m" [hoverOnSmall]="true" [isSearch]="true"></app-video-thumb-v2>
            </div>
        </div>

        <div *ngIf="additionalSearching" class="position-relative div-loader">
        </div>

        <div class="ml-2 mr-2 mb-3 mt-3 text-center" *ngIf="message">
            {{message}}
        </div>
    </div>
</div>
