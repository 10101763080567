import { Component, OnInit } from '@angular/core';
import { BadgeService } from 'src/app/services/badge.service';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserBadge } from '../../../models';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AccountService } from '../../../services/account.service';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
    selector: 'app-badges',
    templateUrl: './badges.component.html',
    styleUrls: ['./badges.component.scss']
})
export class BadgesComponent extends AuthenticationOverride implements OnInit {

    message: string;
    unorderedBadges: UserBadge[];
    orderedBadges: UserBadge[];

    constructor(
        private app: AppComponent,
        private badgeService: BadgeService,
        public router: Router,
        public config: RemoteConfigService,
        public env: EnvironmentService,
        storage: StorageManagerService,
        account: AccountService,
        route: ActivatedRoute) {
        super(route, storage, router, account)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.badgeService.getBadges().subscribe(res => {
            let unorderedBadges = res.filter(f => !f.order || f.order === null);
            this.orderedBadges = res.filter(f => f.order && f.order !== null);
            this.unorderedBadges = [...this.orderedBadges, ...unorderedBadges];
        });
    }

    toggleBadge(badge: any): void {
        const index = this.orderedBadges.indexOf(badge);
        if (index > -1) {
            this.orderedBadges.splice(index, 1);
        } else {
            if (this.orderedBadges.length < 3) {
                this.orderedBadges.push(badge);
            }
        }
    }
    

    isBadgeSelected(badge: any): boolean {
        return this.orderedBadges.includes(badge);
    }

    submit() {
        this.app.startLoading();
        this.badgeService.updateBadges(this.orderedBadges?.map(m => m.type)).subscribe(res => {
            this.app.showSimpleAlert({
                title: 'Success',
                body: 'Your badges were successfully changed.'
            });
            this.router.navigate(['/account']);
        });
    }
}
