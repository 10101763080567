export class Subscription {
    constructor(
        public amount: number,
        public cancelled: boolean,
        public currency: string,
        public endDate: Date,
        public id: string,
        public card: any,
        public planId: string,
        public receiptUrl: string,
        public startDate: Date,
        public subscriptionId: string,
        public type: string,
        public userId: string) {
    }
}