import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-share-button',
    templateUrl: './share-button.component.html',
    styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {

    constructor(private app: AppComponent) {
    }

    ngOnInit(): void {
    }

    async sharePage() {
        const url = this.getUrl();
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share this video!',
                    text: 'Check out this video!',
                    url: url,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            await this.copyToClipboard(url)
            this.app.showSimpleAlert({
                title: 'Share Video',
                body: 'A link has been copied!'
            });
        }
    }

    async copyToClipboard(text: string) {
        console.log(navigator.clipboard, text);
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(text)
            return;
        }

        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }

    getUrl() {
        let url = window.location.href;
        if (url.indexOf('?') > -1) {
            return url.substring(0, window.location.href.indexOf('?'));
        }

        return url;
    }
}
