import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { UiService } from '../../../services/ui.service';
import { BehaviorSubject, tap } from 'rxjs';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss',],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyEmailComponent implements OnInit {

    code: string = '';
    status$ = new BehaviorSubject(VerificationStatus.None);

    VerificationStatus = VerificationStatus;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private accountService: AccountService,
                private ui: UiService) {
    }

    ngOnInit(): void {
        this.code = this.route.snapshot.paramMap.get('code');
        if (!this.code) {
            return;
        }

        this.submitCode();
    }

    submitCode() {
        if (!this.code || this.code.length <= 0) {
            return;
        }

        this.ui.startLoading();

        this.accountService.verifyEmail(this.code).subscribe({
            next: () => {
                this.status$.next(VerificationStatus.Verified);
                this.ui.stopLoading();
            },
            error: (error) => {
                if (error.status === 400) {
                    this.status$.next(VerificationStatus.Invalid);
                }
                this.ui.stopLoading();
            }});
    }
}

enum VerificationStatus {
    None = 1,
    Verified,
    Invalid,
}
