<div class="main">
    <div class="container-fluid" *ngIf="channel">

        <div class="row">
            <a routerLink="/">
                <h3 class="main-channel-header mt-3 mb-3">Channels</h3>
            </a>
            <h3 class="main-channel-header pl-2 pr-2 mt-3 mb-3">/</h3>
            <h3 id="{{channel.id}}" class="main-channel-header mt-3 mb-3 pl-0">{{channel.name}}</h3>
        </div>

        <div class="row ml-2 mr-2">
            <input type="text" name="search" class="form-control text-input-dark" placeholder="Type to search..."
                required ngbAutofocus autofocus tabindex="1" autocomplete="off"
                (input)="onSearchChange($event.target.value)">
        </div>

        <div class="row ml-2 mr-2 mb-3">
            <div class="col-12 col-sm-4 col-md-3 col-xxl-2 m-0 thumb-margin mb-5 mt-3" *ngFor='let m of media'>
                <app-video-thumb-v2 [media]="m" [hoverOnSmall]="true"></app-video-thumb-v2>
            </div>
        </div>

        <div class="ml-2 mr-2 mb-3 mt-3" *ngIf="!media || media.length == 0">
            No results
        </div>
    </div>
</div>
