import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MetadataService } from 'src/app/services/metadata.service';
import { Utils } from 'src/app/services/utils.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { PlaylistService } from 'src/app/services/playlist.service';
import { RemoteConfigService } from '../../services/remote-config.service';
import { WatchHistoryService } from '../../services/watch-history.service';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-video-thumb-v2',
    templateUrl: './video-thumb-v2.component.html',
    styleUrls: ['./video-thumb-v2.component.scss']
})
export class VideoThumbV2Component implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() media: any;
    @Input() hoverOnSmall: boolean;
    @Input() channelImage: string;
    @Input() lazyLoadOff: boolean;
    @Input() seasonId: number;
    @Input() thumbnailType: string = 'horizontal';
    @Input() fromWatchList: boolean = false;
    @Input() isSearch: boolean = false;

    watchSubscription: Subscription;

    addToPlaylistLoading: boolean = false;
    largeThumb: boolean = false;
    loadImages: boolean;
    progress: number;
    timeAgo: string;
    newVideo: boolean = false;
    watched: boolean = false;
    stack: boolean = true;
    routerLink: string[];
    imageWidth = 400;


    @ViewChild('thumb')
    thumb: ElementRef;


    get isVertical() {
        return this.thumbnailType && this.thumbnailType.includes('vertical');
    }

    constructor(
        private metadataService: MetadataService,
        private playlistService: PlaylistService,
        private changeDetector: ChangeDetectorRef,
        public config: RemoteConfigService,
        public watchHistoryService: WatchHistoryService,
        private account: AccountService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.thumbnailType) {
            this.refreshThumbImage();
        }
    }

    ngAfterViewInit() {
        if (this.thumb.nativeElement.offsetWidth > 330) {
            this.largeThumb = true;
            this.stack = false;
            this.changeDetector.detectChanges();
        }
    }

    async ngOnInit() {
        if (this.lazyLoadOff) {
            this.load();
        }

        if (!this.media.title && this.media.mediaId) {
            const res = await lastValueFrom(this.metadataService.getMetadata(this.media.mediaId));
            this.media.title = res.metadata.title;
            // this.media.duration = res.media.duration
            this.media.shortDescription = res.metadata.shortDescription;
            // this.media.image = res.images.find(x => x.type === "thumbnail")?.url
            // this.media.channelBadgeUrl = res.images.find(x => x.type === "channel-badge")?.url
            // this.media.previewImage = res.images.find(x => x.type === "media-preview")?.url
            this.timeAgo = Utils.getTime(this.media.datePublished);
        } else {
            if (this.media.images) {
                this.refreshThumbImage();
                this.media.channelBadgeUrl = this.media.images.find(x => x.type === 'channel-badge')?.url;
                this.media.previewImage = this.media.images.find(x => x.type === 'media-preview')?.url;
            }
            this.timeAgo = Utils.getTime(this.media.datePublished);
        }

        this.imageWidth = this.thumbnailType === 'large' ? 800 : 400;

        if (this.media.previewImage?.indexOf('?a=') > 0) {
            this.media.previewImage = this.media.previewImage + '&w=' + this.imageWidth;
        }

        this.routerLink = this.getRouterLink();

        this.watchSubscription = this.watchHistoryService.watchHistory.subscribe(res => {
            this.watchHistory(res);
        });
    }

    refreshThumbImage() {
        this.media.image = this.thumbnailType.includes('vertical') ? this.media.images?.find(x => x.type === 'packshot')?.url : this.media.images?.find(x => x.type === 'thumbnail')?.url;
    }

    ngOnDestroy(): void {
        this.watchSubscription.unsubscribe();
    }

    checkNew() {
        this.newVideo = false;
        const timestamp = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
        const releaseTime = new Date(this.media.datePublished).getTime();

        if (releaseTime > timestamp && (!this.progress || this.progress <= 0)) {
            this.newVideo = true;
        }
    }

    watchHistory(history: any[]) {
        if (history && history.length > 0) {
            let result = history.find(f => f.mediaId == this.media.mediaId);
            if (result) {
                this.progress = result.percentage;
            }
        }

        if (!this.progress || this.progress <= 0) {
            this.checkNew();
        } else {
            this.watched = this.config.remoteConfig.watched_tick_enabled && true;
        }
    }

    load() {
        this.loadImages = true;
    }

    addToPlaylist(media: any) {
        if (!this.addToPlaylistLoading) {
            this.addToPlaylistLoading = true;
            this.playlistService.addToPlaylist(media).subscribe({
                next: async () => {
                    this.addToPlaylistLoading = false;
                },
                error: () => {
                    this.addToPlaylistLoading = false;
                }
            });
        }
    }

    deleteFromPlaylist(media: any) {
        if (!this.addToPlaylistLoading) {
            this.addToPlaylistLoading = true;
            this.playlistService.deleteFromPlaylist(media).subscribe({
                next: async () => {
                    this.addToPlaylistLoading = false;
                },
                error: () => {
                    this.addToPlaylistLoading = false;
                }
            });
        }
    }

    existsInPlaylist(mediaId: number) {
        return this.playlistService.hasMediaId(mediaId);
    }

    isAuthenticated() {
        return this.account.isAuthenticated();
    }

    private getRouterLink(): string[] {
        if (this.media.seasonId !== undefined) {
            return ['/season', this.media.seasonId];
        }
        if (this.media.livestreamId !== undefined) {
            return ['/live', this.media.livestreamId];
        }
        return ['/video', this.media.mediaId];
    }
}
