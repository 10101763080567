import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {

    isMaintenance = false;

    constructor(private maintenanceService: MaintenanceService,
                private router: Router) {
        this.maintenanceService.isMaintenanceMode.subscribe(x => {
            this.isMaintenance = x;
        });
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return new Promise((resolve) => {
            this.maintenanceService.hasUpdated.subscribe(x => {
                if (x) {

                    if (this.isMaintenance && !this.isMaintenancePath(route)) {
                        this.router.navigate(['/maintenance']);
                        resolve(false);
                        return;
                    }

                    if (!this.isMaintenance && this.isMaintenancePath(route)) {
                        this.router.navigate(['/']);
                        resolve(false);
                        return;
                    }
                    resolve(true);
                }
            });
        });
    }

    private isMaintenancePath(route: ActivatedRouteSnapshot): boolean {
        return route.url.some(x => x.path === 'maintenance');
    }

}
