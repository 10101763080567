import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ScreenSizeEnum } from '../../models/screen-size.enum';
import { AuthenticationOverride } from '../../modules/authentication-override';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { StorageManagerService } from '../../services/storage-manager.service';
import { UiService } from '../../services/ui.service';

declare var shaka: any;

@Component({
    selector: 'shaka-player-simple',
    templateUrl: './shaka-player-simple.component.html',
    styleUrls: ['./shaka-player-simple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShakaPlayerSimpleComponent extends AuthenticationOverride implements AfterViewInit, OnDestroy {

    @ViewChild('videoPlayer') videoElementRef: ElementRef;
    videoElement: HTMLVideoElement;
    @ViewChild('videoUI') videoUIElementRef: ElementRef;
    videoUIElement: HTMLDivElement;

    @Input() autoplay: boolean;
    @Input() poster: string;
    @Input() url: string;
    @Input() hlsUrl: string;
    @Input() side: string;

    @Output() ended = new EventEmitter<void>();

    buffering = true;
    loaded = false;
    player: any;
    playing$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    canPlay$ = new BehaviorSubject<boolean>(false);
    isMobileView$ = new BehaviorSubject<boolean>(false)
    destroy$ = new Subject<void>();

    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    constructor(private ui: UiService,
                route: ActivatedRoute,
                account: AccountService,
                router: Router,
                storage: StorageManagerService) {
        super(route, storage, router, account);

        this.ui.breakpointObservable
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((screenSize: ScreenSizeEnum) => {
                this.isMobileView$.next(screenSize <= ScreenSizeEnum.Medium) ;
            });
    }

    ngAfterViewInit(): void {
        if (this.isMobile) {
            return;
        }

        // Install built-in polyfills to patch browser incompatibilities.
        shaka.polyfill.installAll();

        // Check to see if the browser supports the basic APIs Shaka needs.
        if (shaka.Player.isBrowserSupported()) {
            // Everything looks good!
            this.videoElement = this.videoElementRef.nativeElement;
            this.videoUIElement = this.videoUIElementRef.nativeElement;

            this.initPlayer();
        } else {
            // This browser does not have the minimum set of APIs we need.
            console.error('Browser not supported!');
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initPlayer() {

        let volume = +localStorage.getItem('volume');
        if (volume) {
            this.videoElement.volume = volume;
        }

        if (this.autoplay === true) {
            this.videoElement.autoplay = this.autoplay;
        }

        let localPlayer = new shaka.Player(this.videoElement);
        const ui = new shaka.ui.Overlay(localPlayer, this.videoUIElement, this.videoElement);

        const controls = ui.getControls();

        this.player = ui.getControls().getPlayer();

        const controlPanelElements = this.side === 'left' ?
            ['fullscreen', 'mute', 'volume', 'spacer'] :
            ['spacer', 'mute', 'volume', 'fullscreen']

        ui.configure({
            'seekBarColors': {
                base: 'rgba(255, 255, 255, 0.3)',
                buffered: 'rgba(0, 154, 218, 0.7)',
                played: 'rgb(0, 154, 218)',
            },
            'controlPanelElements': controlPanelElements,
            'addBigPlayButton': true
        });

        this.player.addEventListener('loaded', (e) => {
            this.loaded = true;
            this.canPlay$.next(!this.buffering && this.loaded);
        });

        this.player.addEventListener('buffering', (e) => {
            this.buffering = e.buffering;
            this.canPlay$.next(!this.buffering && this.loaded);
        });

        this.videoElement.addEventListener('playing', () => {
            this.playing$.next(true);
        });

        this.videoElement.addEventListener('pause', () => {
            this.playing$.next(false);
        });

        this.player.addEventListener('error', this.onErrorEvent);
        this.player.addEventListener('adaptation', (e) => {
            this.player.getVariantTracks().forEach(function (track) {
                if (track.active) {
                    let autoElement = document.getElementsByClassName('shaka-resolution-button')[0].children[1].children[1];
                    if (autoElement) {
                        autoElement.innerHTML = 'Auto (' + track.height + 'p)';
                    }
                }
            });
        });

        this.videoElement.addEventListener('ended', () => {
            this.ended.emit();
        });

        if (this.isSafari && this.hlsUrl) {
            this.url = this.hlsUrl;
        }

        this.player.load(
            this.url
        ).catch(this.onError);
    }

    togglePausePlay() {
        if (this.videoElement.paused) {
            this.videoElement.currentTime = 0;
            this.videoElement.play();
        } else {
            this.videoElement.currentTime = 0;
            this.videoElement.pause();
        }
    }

    posterClicked() {
        if (this.isMobile) {
            this.playVideoMobile(this.url, this.hlsUrl);
            return;
        }
        this.togglePausePlay();
    }

    private onErrorEvent(event) {
        // Extract the shaka.util.Error object from the event.
        this.onError(event.detail);
    }

    private onError(error) {
        // Log the error.
        console.error('Error code', error.code, 'object', error);
    }
}
