import {ChangeDetectorRef, Component, HostListener, Inject, NgZone, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, Router, RouterEvent} from '@angular/router';
import {AccountService} from './services/account.service';
import {StorageManagerService} from './services/storage-manager.service';
import {filter} from 'rxjs/operators';
import {LegalDocumentsService} from './services/legal-documents.service';
import {MessagingService} from './services/messaging.service';
import {MessageService} from './services/message.service';
import {lastValueFrom, Subject, Subscription} from 'rxjs';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {ChannelsService} from './services/channels.service';
import {UserCredential} from '@angular/fire/auth';
import {UiService} from './services/ui.service';
import {LocalisationService} from './services/localisation.service';
import {Language} from './models/localisation/Language';
import {DOCUMENT} from '@angular/common';
import {ConfirmModalObject} from './models/confirm-modal-object';
import {MaintenanceService} from './services/maintenance.service';
import {UserInfoService} from './services/user-info.service';
import {environment} from 'src/environments/environment';
import {ScrollTrackerService} from './services/scroll-tracker.service';
import {RemoteConfigService} from './services/remote-config.service';
import { HttpParams } from '@angular/common/http';

declare let window: any;
declare let Cookiebot: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

    title = environment.name;
    version = "608abb07"; // automated version number

    contributeModal: boolean = false;
    loading: boolean = false;
    simpleAlert: any;
    userHold: boolean = false;
    showMobileApps: boolean = false;
    showMobileAppsChecked: boolean = false;
    pleaseSubscribeShown: boolean = false;
    showLegalDocumentsModal: boolean = false;
    allDocumentsAccepted: boolean = false;
    blockAccess: boolean;
    confirmObj: ConfirmModalObject = undefined;
    private scrollSubscription: Subscription = new Subscription();

    public hideLanguageSettings: boolean = true;

    heroLoaded = new Subject<string>();
    public languages: Language[];
    hideMenus: boolean = false;

    constructor(
        private gaService: GoogleAnalyticsService,
        public localisationService: LocalisationService,
        private router: Router,
        private ngZone: NgZone,
        private legalDocumentsService: LegalDocumentsService,
        private messagingService: MessagingService,
        private channelService: ChannelsService,
        private storageManager: StorageManagerService,
        private messenger: MessageService,
        private accountService: AccountService,
        private changeDetector: ChangeDetectorRef,
        private ui: UiService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private maintenanceService: MaintenanceService,
        private userInfoService: UserInfoService,
        private scrollTracker: ScrollTrackerService,
        private config: RemoteConfigService) {
    }

    async ngOnInit(): Promise<void> {

        this.ui.loading.subscribe(x => x ? this.startLoading() : this.stopLoading());

        const isMaintenanceMode = await this.maintenanceService.updateMaintentanceMode();
        if (isMaintenanceMode) {
            this.hideMenus = true;
            this.localisationService.translationsLoaded = true;
            this.ui.stopLoading();
            return;
        }

        if (this.accountService.isAuthenticated()) {
            setTimeout(() => {
                this.getUserInfo(true);
                this.messagingService.requestPermission();
                this.messagingService.receiveMessage();
            }, 150);
        }

        this.router.events.subscribe((routerEvent: RouterEvent) => {
            this.checkRouterEvent(routerEvent);
        });

        this.messenger.getMessage().subscribe(message => {
            if (message && message == 'getUserInfo') {
                this.getUserInfo();
            }
        });

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(
                (event: any) => {
                    this.gaService.navigateToPage(event.urlAfterRedirects);
                }
            );
        await this.loadLanguages();

        if (this.hideLanguageSettings) {
            this.storeLanguageInLocalStorage(1);
            await this.setWebsiteLanguage();
        } else {
            await this.setWebsiteLanguage();
        }

        this.ui.confirmChanged.subscribe((obj: ConfirmModalObject) => {
            this.confirmObj = obj;
        });

        this.ui.legalDocumentsModal.subscribe(showLegalDocumentsModal => showLegalDocumentsModal ? this.showLegalDocumentPopup() : this.stopShowingLegalDocumentPopup());

        this.scrollSubscription.add(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    const savedPosition = this.scrollTracker.getScrollPosition(event.urlAfterRedirects || event.url);
                    if (savedPosition !== undefined) {
                        setTimeout(() => {
                            window.scrollTo(0, savedPosition);
                        }, 50);
                    }
                })
        );
    }

    ngOnDestroy() {
        this.scrollSubscription.unsubscribe();
    }

    @HostListener('window:scroll', ['$event'])
    trackScroll(event: any): void {
        this.scrollTracker.setScrollPosition(this.router.url, window.scrollY);
    }

    private async loadLanguages(): Promise<void> {
        this.languages = await lastValueFrom(this.localisationService.getAllLanguages());
    }

    private async setWebsiteLanguage(): Promise<void> {
        if (this.userHasLanguagePreference()) {
            this.localisationService.setSelectedLanguageId(this.getLanguageInLocalStorage());
            return;
        }
        let language = await this.resolveLanguage();

        if (language) {
            this.localisationService.setSelectedLanguageId(language.id);
        } else {
            this.localisationService.refreshTranslations();
        }
    }

    public storeLanguageInLocalStorage(languageId: number): void {
        localStorage.setItem('selectedLanguageId', JSON.stringify(languageId));
    }

    private getLanguageInLocalStorage(): number {
        return JSON.parse(localStorage.getItem('selectedLanguageId'));
    }

    private userHasLanguagePreference(): boolean {
        return localStorage.getItem('selectedLanguageId') !== null;
    }

    private async resolveLanguage(): Promise<Language> {

        let currentLanguageId = this.localisationService.getSelectedLanguageId();
        let userBrowserLanguage = navigator.language.toLowerCase().split('-');
        let languageFamily = this.languages.filter(lg => lg.code.split('-')[0] == userBrowserLanguage[0]);

        if (languageFamily.length > 1) {
            let language = languageFamily.find(lg => lg.code == userBrowserLanguage.join('-') || lg.altCodes?.split(',').includes(userBrowserLanguage.join('-')));
            return language ?? null;
        } else if (languageFamily.length == 1 && languageFamily[0].id != currentLanguageId) {
            return languageFamily[0];
        }
    }

    checkRouterEvent(routerEvent: RouterEvent): void {
        if (routerEvent instanceof NavigationEnd) {
            // make sure it's only set once
            if (!this.hideMenus) {
                this.hideMenus = routerEvent.url.toLowerCase().includes('hidemenus') || routerEvent.url.toLowerCase().includes('iosapp') || routerEvent.url.toLowerCase().includes('androidapp');
                if (this.hideMenus) {
                    if (Cookiebot) {
                        try {
                            Cookiebot.hide();
                        } catch {
                        }
                    }
                    this.renderer.addClass(this.document.body, 'hidemenus');
                    this.showMobileApps = false;
                }
            }

            if (routerEvent.url.toLowerCase().includes('iosapp')) {
                this.storageManager.setDevice('ios');
            }

            if (routerEvent.url.toLowerCase().includes('androidapp')) {
                this.storageManager.setDevice('android');
            }

            this.mobileAndTabletCheck(routerEvent.url);

        }

        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.stopLoading();
        }
    }

    mobileAndTabletCheck(url: string): boolean {
        if (this.showMobileAppsChecked) {
            return;
        }

        this.showMobileAppsChecked = true;

        const mobileIgnore = ['signup', 'forgotten', 'legaldocuments']
        if (mobileIgnore.some(x => url.toLowerCase().includes(x))) {
            this.showMobileApps = false;
            return;
        }

        if (this.hideMenus) {
            this.showMobileApps = false;
            return;
        }
        const lastClosed = localStorage.getItem('closed-mobile');
        if (lastClosed && lastClosed !== 'null') {
            const weekAgo = Date.now() - 604800000; // 1000 * 60 * 60 * 24 * 7
            if (+lastClosed > weekAgo) {
                this.showMobileApps = false;
                return;
            }
        }

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        this.showMobileApps = /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b|Silk|Kindle|Opera Mini|Opera Mobi/.test(userAgent);
    }

    public startLoading() {
        this.loading = true;
        this.changeDetector.detectChanges();
    }

    public stopLoading() {
        this.loading = false;
        this.changeDetector.detectChanges();
    }

    public showLegalDocumentPopup() {
        this.showLegalDocumentsModal = true;
    }

    public stopShowingLegalDocumentPopup() {
        this.showLegalDocumentsModal = false;
    }

    public checkForPendingLegalDocuments() {

        if (!this.accountService.isAuthenticated()) {
            return;
        }
        if (this.storageManager.getDevice() === 'ios') {
            return;
        }

        if (!window.location.pathname.includes('/legaldocuments/') && this.allDocumentsAccepted == false) {
            this.legalDocumentsService.getMissingRequiredDocuments().subscribe(res => {
                if (res.length > 0) {
                    this.showLegalDocumentPopup();
                } else {
                    this.allDocumentsAccepted = true;
                    this.stopShowingLegalDocumentPopup();
                }
            });
        }
    }

    public hasSubscription() {
        const sub = this.storageManager.getSubscription();
        if ((sub + '') !== 'null') {
            return sub;
        }
        return false;
    }

    public async getUserInfo(firstLoad: boolean = false): Promise<void> {
        try {
            await this.accountService.updateToken();

            if (this.userHold) {
                return;
            }

            const res = await lastValueFrom(this.userInfoService.getUserInfoQuery('avatar,email,hash,preferences,points,subscription,userid'));

            if (!res.hasSubscription && new Date(res.userCreated) > new Date('2021-03-01')) {
                this.accountService.navigateToAccessPage('payment');
            } else if (!res.hasSubscription && !this.pleaseSubscribeShown && firstLoad
                && !this.router.url.includes('payment') && !this.router.url.includes('legaldocuments') && !this.hideMenus) {

                if (this.storageManager.getDevice() !== 'ios') {
                    this.pleaseSubscribeShown = true;
                    this.showSimpleAlert({
                        title: 'Don\'t forget to subscribe!',
                        buttonText: 'Support ' + environment.name,
                        buttonLink: '/payment',
                        body: 'Hey there! We\'ve noticed you haven\'t subscribed yet.<br\>If you would like to support us here at ' + environment.name + ' click the button below.'
                    });
                }
            }
            this.storageManager.setSubscription(res.hasSubscription);
            this.checkForPendingLegalDocuments();

        } catch (e) {
            this.accountService.checkUnauthorized(e);
            console.log('getUserInfo', e);
            console.log(e);
        }
    }

    async sharedLogin(result: any, nextRoute: string) {
        this.userHold = true;

        if (this.config.remoteConfig.login_version == 1 && result) {
            const tokenData = await result.user.getIdToken();
            await lastValueFrom(this.accountService.verifyAccount(tokenData));
            this.storageManager.setToken(tokenData);
        }
        this.userHold = false;
        this.accountService.setAuthenticated(true);
        await Promise.all([
            this.channelService.refreshUserChannels(),
            this.getUserInfo()
        ]);
        if (!nextRoute) {
            await this.ngZone.run(() => this.router.navigate(['/']));
        } else {
            await this.ngZone.run(() => {
                this.accountService.nextRoute = undefined;
                this.storageManager.removeAfterLoginUrl();
                return this.navigateToNext(nextRoute);
            });
        }
        this.checkForPendingLegalDocuments();
        this.stopLoading();
    }

    sharedLoginV2(result?: UserCredential, displayName?: string): Promise<any> {
        if (this.config.remoteConfig.login_version == 1 && result) {
            return new Promise((resolve, reject) => {
                this.userHold = true;
                result.user.getIdToken().then(tokenData => {
                    this.accountService.verifyAccount(tokenData, displayName).subscribe(res => {
                        this.userHold = false;
                        this.storageManager.setToken(tokenData);
                        this.accountService.setAuthenticated(true);
                        this.channelService.refreshUserChannels();
                        this.getUserInfo().then(res => {
                            this.checkForPendingLegalDocuments();
                            this.stopLoading();
                            resolve('User Registered');
                        });
                    });
                }, err => {
                });
            });
        }

        if (this.config.remoteConfig.login_version == 2) {
            this.userHold = false;
            this.channelService.refreshUserChannels();
            return this.getUserInfo().then(res => {
                this.checkForPendingLegalDocuments();
                this.stopLoading();
            });
        }
    }

    navigateToNext(nextRoute: string): Promise<boolean> {
        const split = nextRoute.split('?');
        const url = split[0];

        if (split.length > 1) {
            const paramUrl = new URL(nextRoute, window.location.origin);
            const queryParams = {};
            paramUrl.searchParams.forEach((value, key) => {
                queryParams[key] = value;
            });
            return this.router.navigate(['/' + url], {queryParams});
        }

        return this.router.navigate(['/' + url]);
    }

    getErrorMessage(error: any) {
        console.log(error);

        if (error.status === 401) {
            return 'Invalid Email or Password.';
        }
        if (error.status === 409) {
            return 'This account already exists.';
        }
        if (error.error) {
            return error.error;
        }

        if (error.code) {
            switch (error.code) {
                case 'auth/invalid-email':
                    return 'Invalid Email or Password.';
                case 'auth/wrong-password':
                    return 'Invalid Email or Password.';
                case 'auth/user-not-found':
                    return 'Invalid Email or Password.';
                case 'auth/email-already-in-use':
                    return 'Email address is already in use.';
                case 'auth/too-many-requests':
                    return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later';
                case 'auth/weak-password':
                    return 'Password should be at least 6 characters';
            }
        }
        return error.error ?? error.message;
    }

    showContribute() {
        this.contributeModal = true;
    }

    closeSimpleAlert() {
        this.simpleAlert = null;
        //console.log("IS THIS DELETED?")
        //console.log(this.simpleAlert)
    }

    showSimpleAlert(alert: any) {
        //console.log("EXISTING")
        //console.log(this.simpleAlert)
        this.simpleAlert = alert;
        //console.log("NEW")
        //console.log(this.simpleAlert)
    }
}
