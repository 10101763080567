import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
    selector: 'app-success-card-update',
    templateUrl: './success-card-update.component.html',
    styleUrls: ['./success-card-update.component.css']
})
export class SuccessCardUpdateComponent implements OnInit {

    constructor(
        private app: AppComponent,
        private route: ActivatedRoute,
        private subscriptionService: SubscriptionService) {

        this.app.startLoading();
        this.route.queryParams.subscribe(params => {
            let session = params['session_id'];
            this.subscriptionService.changeCardSubscription(session).subscribe(() => {
                this.app.stopLoading();
            });
        });
    }

    ngOnInit(): void {
    }
}
