import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../../services/account.service';

@Component({
    selector: 'app-change-email-complete',
    templateUrl: './change-email-complete.component.html',
    styleUrls: ['./change-email-complete.component.scss']
})
export class ChangeEmailCompleteComponent implements OnInit {

    code: string;
    email: string;
    loading: boolean = true;

    message: string = 'Updating Email. Please wait...';

    constructor(private route: ActivatedRoute,
                private account: AccountService) {
        this.route.queryParams.subscribe(params => {
            this.code =  params['c'];
            this.email = params['e'];
        });
    }

    ngOnInit(): void {
        this.account.changeEmailCompleteV2(this.code).subscribe({
            next: x => {
                this.message = 'You have successfully updated your email to: ' + this.email;
                this.loading = false;
            },
            error: () => {
                this.message = 'Something went wrong!\nPlease contact support if this continues'
                this.loading = false;
            }
        })
    }

}
