import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HeroTypes, HeroV2, MediaSummary } from '../../models';
import { PlaylistService } from '../../services/playlist.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RemoteConfigService } from '../../services/remote-config.service';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-hero-carousel-banner',
    templateUrl: './hero-carousel-banner.component.html',
    styleUrls: ['./hero-carousel-banner.component.scss']
})
export class HeroCarouselBannerComponent implements OnInit, OnDestroy {

    heroWeb: string;
    heroMobile: string;
    logo: string;
    titleText: string;
    addToPlaylistLoading = false;
    heroLoadingMobile: string;
    heroLoadingWeb: string;

    heroTypes = HeroTypes;
    destroy = new Subject<void>();

    @Input() heroItem: HeroV2;


    constructor(private playlistService: PlaylistService,
                private router: Router,
                private configService: RemoteConfigService,
                private account: AccountService) {
    }

    ngOnInit(): void {
        this.heroLoadingWeb = this.configService.remoteConfig.loading_hero_web;
        this.heroLoadingMobile = this.configService.remoteConfig.loading_hero_mobile;
        if (this.heroItem.images) {
            this.heroWeb = this.heroItem.images.find(f => f.type === 'hero-web')?.url ?? this.heroWeb;
            this.heroMobile = this.heroItem.images.find(f => f.type === 'hero-mobile')?.url ?? this.heroMobile;
            this.logo = this.heroItem.images.find(f => f.type === 'logo')?.url ?? this.logo;
        }

        if (this.heroItem.text) {
            this.titleText = this.heroItem.text.find(f => f.type === 'title')?.text ?? this.titleText;
        }
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    addToPlaylist(media: MediaSummary, event: any) {
        event.stopPropagation();
        if (!this.addToPlaylistLoading) {
            this.addToPlaylistLoading = true;
            this.playlistService.addToPlaylist(media).subscribe({
                next: async () => {
                    this.addToPlaylistLoading = false;
                },
                error: () => {
                    this.addToPlaylistLoading = false;
                }
            });
        }
    }

    deleteFromPlaylist(media: MediaSummary, event: any) {
        event.stopPropagation();
        if (!this.addToPlaylistLoading) {
            this.addToPlaylistLoading = true;
            this.playlistService.deleteFromPlaylist(media).subscribe({
                next: async () => {
                    this.addToPlaylistLoading = false;
                },
                error: () => {
                    this.addToPlaylistLoading = false;
                }
            });
        }
    }

    existsInPlaylist(mediaId: number) {
        return this.playlistService.hasMediaId(mediaId);
    }

    isAuthenticated() {
        return this.account.isAuthenticated();
    }

    canNavigate() {
        if (this.heroItem) {
            switch (this.heroItem.type) {
                case HeroTypes.LatestPlayer:
                case HeroTypes.ExternalLink:
                case HeroTypes.InternalLink:
                case HeroTypes.Show:
                case HeroTypes.Media:
                    return true;
            }
        }
        return false;
    }

    navigate() {
        switch (this.heroItem.type) {
            case HeroTypes.LatestPlayer:
                this.router.navigate(['/video', this.heroItem.media.mediaId]);
                break;
            case HeroTypes.ExternalLink:
                window.open(this.heroItem.redirectUrl, '_blank').focus();
                break;
            case HeroTypes.InternalLink:
                this.router.navigate([this.heroItem.redirectUrl]);
                break;
            case HeroTypes.Show:
                if (this.heroItem.seasonId) {
                    this.router.navigate(['/season', this.heroItem.seasonId]);
                } else {
                    this.router.navigate(['/shows', this.heroItem.channelId]);
                }
                break;
            case HeroTypes.Media:
                this.router.navigate(['/video', this.heroItem.mediaId]);
                break;
        }
    }
}
