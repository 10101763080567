<div class="main main-full main-responsive">
    <ng-container *ngIf="preferences$ | async">
        <app-title class="d-block mb-3" [title]="'Video Quality'" [subtitle]="'This sets the maximum quality that the app will attempt to maintain'"></app-title>

        <div class="mt-2">
            Phone Data
            <select [(ngModel)]="selections.mobileData.value">
                <option *ngFor="let opt of videoQualityOptionsMap" [ngValue]="opt.bitrate">{{opt.quality}}</option>
            </select>
        </div>
        <div class="mt-2">
            Phone WiFi
            <select [(ngModel)]="selections.mobileWifi.value">
                <option *ngFor="let opt of videoQualityOptionsMap" [ngValue]="opt.bitrate">{{opt.quality}}</option>
            </select>
        </div>
        <div class="mt-2">
            Default (everything else)
            <select [(ngModel)]="selections.other.value">
                <option *ngFor="let opt of videoQualityOptionsMap" [ngValue]="opt.bitrate">{{opt.quality}}</option>
            </select>
        </div>

        <div class="mt-3">
            <button class="btn btn-primary save-btn" style="width: 150px; height: 46px;" (click)="save()" [disabled]="(saving$ | async)">
                <app-loading-spinner [widthHeightPx]="25" *ngIf="saving$ | async"></app-loading-spinner>
                <ng-container *ngIf="!(saving$ | async)">
                    Save
                </ng-container>
            </button>
        </div>


        <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
    </ng-container>
</div>
