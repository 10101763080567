import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-base-reroute',
    templateUrl: './base-reroute.component.html',
    styleUrls: ['./base-reroute.component.scss']
})
export class BaseRerouteComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router) {
    }

    ngOnInit(): void {
        let id = this.route.snapshot.paramMap.get('id');
        this.router.navigate(['/shows', id]);
    }

}
