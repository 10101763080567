import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-password-field',
    templateUrl: './password-field.component.html',
    styleUrls: ['./password-field.component.scss']
})
export class PasswordFieldComponent implements OnInit {
    hidePassword: boolean = true;
    @Input() password: string = '';
    @Output() passwordChange = new EventEmitter<string>();

    @Input() placeholder: string = 'Password';
    @Input() tabIndex: number;

    constructor() {
    }

    ngOnInit(): void {
    }

    togglePassword() {
        this.hidePassword = !this.hidePassword;
    }
}
