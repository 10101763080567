import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class AvatarService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    delete() {
        return this.http
            .delete(this.envService.userHost + '/v1/avatar/')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('AvatarService::Error::DELETE', error);
                return throwError(error);
            }));
    }

    upload(base64: string) {
        return this.http
            .post(this.envService.userHost + '/v1/avatar/base64', {
                base64: base64
            })
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('AvatarService::Error::DELETE', error);
                return throwError(error);
            }));
    }
}
