<div class="main main-full main-responsive">

    <div class="d-flex flex-column w-100">
        <app-title [title]="'email-verification' | translate" [subtitle]="(status$ | async) === VerificationStatus.None ? ('enter-verification-code' | translate) : undefined">
        </app-title>

        <ng-container *ngIf="status$ | async as status">
            <ng-container [ngSwitch]="status">

                <ng-container *ngSwitchCase="VerificationStatus.Verified">
                    <div class="my-3">{{'email-verified' | translate}}</div>

                    <a [routerLink]="['/']" class="btn btn-outline-light btn-slim mt-3">{{ 'back-to-home' | translate | titlecase }}</a>
                </ng-container>

                <ng-container *ngSwitchCase="VerificationStatus.Invalid">
                    <div class="my-3">{{'verification-invalid' | translate}}</div>
                    <a [routerLink]="['/account']" class="btn btn-outline-light btn-slim mt-3">{{ 'account-go-to' | translate }}</a>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <input class="form-control uikit-input w-100 my-4" [(ngModel)]="code" [placeholder]="'verification-code' | translate">
                    <button [disabled]="!code" (click)="submitCode()" class="btn btn-lg btn-primary btn-block">{{ 'submit' | translate | uppercase}}</button>
                </ng-container>

            </ng-container>
        </ng-container>


    </div>

</div>
