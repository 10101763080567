<div class="main main-full config-background-2">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12">
          <div class="entry-content">
            <h2 class="form-title">Purchase Complete</h2>
            <p [innerHtml]="successMessage"></p>
            <p class="red-text">{{errorMessage}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
