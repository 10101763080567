import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class PromoCodeService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) { }

    getPromo(): Observable<any[]> {
        return this.http
            .get<any>(this.envService.userHost + '/v1/promocode')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('PromoService::Error::GET', error);
                return throwError(error);
            }))
    }
}
