import { HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { StorageManagerService } from '../storage-manager.service';
import { RemoteConfigService } from '../remote-config.service';
import { AccountService } from '../account.service';
import { from, Observable, switchMap, take } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    recaptchaEndpoints = [
        'v2/account/login',
        'v2/account/register',
        'v2/account/integrity',
        'v2/account/forgotten-password/send'
    ];

    constructor(private injector: Injector) {
    }

    intercept(req, next): Observable<any> {
        const headers = {'x-platform': 'web'};

        if (req.url.includes('remoteconfig')) {
            return next.handle(req.clone({
                setHeaders: headers
            }));
        }

        let addAuth = false;
        const url = req.url;

        if (this.doAddAuth(url, req)) {
            addAuth = true;
        }

        let config = this.injector.get(RemoteConfigService);
        let account = this.injector.get(AccountService);



        if (addAuth) {
            let storageManager = this.injector.get(StorageManagerService);

            if (config.remoteConfig.login_version == 1) {
                let accessToken = storageManager.getOverrideToken();
                accessToken = (!accessToken || accessToken === 'null') ? storageManager.getToken() : accessToken;
                headers['Authorization'] = `bearer ${accessToken}`
                let tokenizedReq = req.clone({
                    setHeaders: headers
                });
                return next.handle(tokenizedReq);
            }

            if (config.remoteConfig.login_version == 2) {
                let account = this.injector.get(AccountService);
                return account.updateTokenV2()
                    .pipe(
                        take(1),
                        switchMap(token => {
                            headers['Authorization'] = `bearer ${token}`
                            let tokenizedReq = req.clone({
                                setHeaders: headers
                            });
                            return next.handle(tokenizedReq);
                        }),
                        catchError(err => {
                            console.log('interceptor', err);
                            if (err && err.status === 401) {
                                const dontLogOut = ['change/password'];
                                if (!dontLogOut.some(x => url.includes(x))) {
                                    account.logoutUser();
                                }
                            }
                            throw err;
                        })
                    );
            }
        }

        if (config.remoteConfig.recaptcha_enabled && this.recaptchaEndpoints.some(x => url.includes(x))) {

            let action = this.getAction(url);

            return from(account.getRecaptchaToken(action)).pipe(
                switchMap(recaptchaToken => {
                    headers['x-api-security-token'] = recaptchaToken
                    return next.handle(req.clone({
                        setHeaders: headers
                    }));
                }));
        }

        return next.handle(req.clone({
            setHeaders: headers
        }));
    }

    private getAction(url: string): string {
        if (url.includes('v2/account/integrity')) {
            return 'integrity';
        }
        if (url.includes('v2/account/login')) {
            return 'login';
        }
        if (url.includes('v2/account/register')) {
            return 'register';
        }
        if (url.includes('v2/account/forgotten-password/send')) {
            return 'forgotten_password';
        }
        return 'API';
    }

    private doAddAuth(url: string, req: any) {
        return url.includes('v1/avatar') ||
            url.includes('v1/badges') ||
            url.includes('v1/chat') ||
            url.includes('v1/changePassword') ||
            url.includes('v1/comments') ||
            url.includes('v2/continueWatching') ||
            url.includes('v3/continueWatching') ||
            url.includes('v3/userchannels') ||
            url.includes('v4/userchannels') ||
            url.includes('v1/displayName') ||
            url.includes('v1/emailPreferences') ||
            url.includes('v1/legaldocuments') ||
            url.includes('v1/key') ||
            url.includes('v1/notification') ||
            url.includes('v2/notification') ||
            url.includes('v2/pushNotification') ||
            url.includes('v2/pushnotification') ||
            url.includes('v1/rateMedia') ||
            url.includes('v1/recommendations') ||
            url.includes('v2/recommendations') ||
            url.includes('v3/recommendations') ||
            url.includes('v1/payPalSubscription') ||
            url.includes('v2/payPalSubscription') ||
            url.includes('v1/purchaseHistory') ||
            url.includes('v1/stripePurchase') ||
            url.includes('v1/videoReport') ||
            url.includes('v1/patreon') ||
            url.includes('v1/points') ||
            url.includes('v1/email') ||
            url.includes('v2/playlist') ||
            url.includes('v3/playlist') ||
            url.includes('ugw') ||
            url.includes('v1/promocode') ||
            url.includes('v1/subscription') ||
            url.includes('v2/subscription') ||
            url.includes('v3/subscription') ||
            url.includes('v4/subscription') ||
            url.includes('v5/subscription') ||
            url.includes('v6/subscription') ||
            url.includes('v1/stripe') ||
            url.includes('v2/stripe') ||
            url.includes('v1/user') ||
            url.includes('v1/live') ||
            url.includes('v1/smartlogin') ||
            url.includes('v2/smartlogin') ||
            url.includes('v1/userdevice') ||
            url.includes('v1/voucher') ||
            url.includes('v1/watchHistory') ||
            url.includes('v1/smush') ||
            url.includes('v2/video') ||
            url.includes('v3/video') ||
            url.includes('v4/video') ||
            url.includes('v5/video') ||
            (url.includes('v2/projects') && req.method == 'POST') ||
            (url.includes('v3/projects') && req.method == 'POST') ||
            (url.includes('v5/projects') && req.method == 'POST') ||
            url.includes('/v2/account/change/password') ||
            url.includes('/v2/account/change/email') ||
            url.includes('/v2/subtitleoptions') ||
            url.includes('/v5/hero/auth') ||
            url.includes('/v1/preferences') ||
            url.includes('/v3/comments') ||
            url.includes('/v2/creatorteam');
    }
}
