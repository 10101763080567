import { Component, OnInit } from '@angular/core';
import {
    SubtitleOption
} from '../../../models/subtitles/subtitle-enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubtitleService } from '../../../services/subtitle.service';
import { UiService } from '../../../services/ui.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AppComponent } from '../../../app.component';

@Component({
    selector: 'app-subtitle-settings',
    templateUrl: './subtitle-settings.component.html',
    styleUrls: ['./subtitle-settings.component.scss']
})
export class SubtitleSettingsComponent extends AuthenticationOverride implements OnInit {

    subtitleClass$: Observable<string>;

    fontOptionsOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dropShadowOptionsOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    textColourOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    shadowColourOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    backgroundColourOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    windowColourOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public service: SubtitleService,
                private ui: UiService,
                private app: AppComponent,
                router: Router,
                account: AccountService,
                route: ActivatedRoute,
                storage: StorageManagerService) {
        super(route, storage, router, account);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.ui.startLoading();
        this.service.getSubtitleOptions().subscribe(() => {
            this.subtitleClass$ = this.service.subtitleClass();
            this.ui.stopLoading();
        });
    }

    optionClicked($event: MouseEvent, o: SelectOption, type: string) {
        $event.stopPropagation();
        $event.preventDefault();

        switch (type) {
            case 'font':
                this.service.selectedFont$.next(o);
                this.fontOptionsOpen$.next(false);
                break;
            case 'drop-shadow':
                this.service.selectedShadow$.next(o);
                this.dropShadowOptionsOpen$.next(false);
                break;
            case 'textColour':
                this.service.selectedTextColour$.next(o);
                this.textColourOpen$.next(false);
                break;
            case 'shadowColour':
                this.service.selectedShadowColour$.next(o);
                this.shadowColourOpen$.next(false);
                break;
            case 'backgroundColour':
                this.service.selectedBackgroundColour$.next(o);
                this.backgroundColourOpen$.next(false);
                break;
            case 'windowColour':
                this.service.selectedWindowColour$.next(o);
                this.windowColourOpen$.next(false);
                break;
        }
    }

    openDropdown(type: string) {
        switch (type) {
            case 'font':
                this.fontOptionsOpen$.next(true);
                break;
            case 'drop-shadow':
                this.dropShadowOptionsOpen$.next(true);
                break;
        }
    }

    save() {
        this.saving$.next(true);
        const body = [
            {key: SubtitleOption.Font, value: this.service.selectedFont$.value.value},
            {key: SubtitleOption.Shadow, value: this.service.selectedShadow$.value.value},
            {key: SubtitleOption.Background, value: this.service.selectedBackgroundColour$.value.value},
            {key: SubtitleOption.Window, value: this.service.selectedWindowColour$.value.value},
            {key: SubtitleOption.TextSize, value: this.service.selectedTextSize$.value.value},
            {key: SubtitleOption.Colour, value: this.service.selectedTextColour$.value.value},
            {key: SubtitleOption.ShadowColour, value: this.service.selectedShadowColour$.value.value}
        ];
        this.service.setSubtitleOptions(body).subscribe({
            next: () => {
                this.saving$.next(false);
                this.app.showSimpleAlert({
                    title: 'Success!',
                    body: 'Subtitle options saved!'
                });
            },
            error: () => this.saving$.next(false)
        });
    }
}

export interface SelectOption {
    name: string;
    value: any;
}
