import { Component, OnInit } from '@angular/core';
import { Auth, signInWithEmailAndPassword, updatePassword } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { AccountService } from '../../../services/account.service';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { UserInfoService } from '../../../services/user-info.service';
import { UiService } from '../../../services/ui.service';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { Utils } from '../../../services/utils.service';

@Component({
    selector: 'app-change-password-v2',
    templateUrl: './change-password-v2.component.html',
    styleUrls: ['./change-password-v2.component.scss']
})
export class ChangePasswordV2Component extends AuthenticationOverride implements OnInit {

    error: string;
    viewModel: {oldPassword: string, newPassword: string, confirmPassword: string} = {oldPassword: '', newPassword: '', confirmPassword: ''};

    constructor(
        private afAuth: Auth,
        private app: AppComponent,
        private userInfo: UserInfoService,
        public router: Router,
        private config: RemoteConfigService,
        public account: AccountService,
        route: ActivatedRoute,
        storage: StorageManagerService) {
        super(route, storage, router, account);
    }

    async submit() {
        this.app.startLoading();
        if (this.validatePassword()) {
            if (this.config.remoteConfig.login_version == 1) {
                await this.changePasswordV1();
            }
            if (this.config.remoteConfig.login_version == 2) {
                this.changePasswordV2();
            }
        } else {
            this.app.stopLoading();
        }
    }

    validatePassword(): boolean {
        if (Utils.isNullOrWhitespace(this.viewModel.newPassword) || Utils.isNullOrWhitespace(this.viewModel.oldPassword) || Utils.isNullOrWhitespace(this.viewModel.confirmPassword)) {
            this.error = 'Please make sure you\'ve filled in all fields';
            return false;
        }

        if (this.viewModel.newPassword !== this.viewModel.confirmPassword) {
            this.error = 'Passwords do not match.';
            return false;
        }

        return true;
    }


    async changePasswordV1() {
        try {
            await signInWithEmailAndPassword(this.afAuth, this.userInfo.email, this.viewModel.oldPassword)
            await updatePassword(this.afAuth.currentUser, this.viewModel.newPassword);

            this.app.showSimpleAlert({
                title: 'Success',
                body: 'Your password was successfully changed.'
            });
            this.back('/account');
            this.app.stopLoading();
        } catch (error) {
            console.log(error);
            if (error.code === 'auth/weak-password') {
                this.error = 'New password too weak. Your password must be at least 6 characters long.';
            }else if (error.code === 'auth/wrong-password') {
                this.error = 'Incorrect Password.';
            } else {
                this.error = 'Session too old, please log out and back in again to change your password.';
            }
            this.app.stopLoading();
        }
    }

    changePasswordV2() {
        this.account.changePasswordV2(this.userInfo.email, this.viewModel.oldPassword, this.viewModel.newPassword).subscribe({
            next: () => {
                this.app.showSimpleAlert({
                    title: 'Success',
                    body: 'Your password was successfully changed.'
                });
                this.back('/account');
                this.app.stopLoading();
            },
            error: (error) => {
                this.error = error.error;

                if (error.status === 401) {
                    this.error = 'Current password incorrect.'
                }

                this.app.stopLoading();
            }
        })
    }

}
