import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RemoteConfigService } from '../services/remote-config.service';
import { AccountService } from '../services/account.service';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { StorageManagerService } from '../services/storage-manager.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {


    constructor(private router: Router,
                private config: RemoteConfigService,
                private account: AccountService,
                private fireGuard: AngularFireAuthGuard,
                private storage: StorageManagerService) {
    }

    canActivate = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> => {

        let redirect = ['/'];

        if (route.data.type === 'no-auth') {
            return this.account.isAuthenticated() ? of(this.router.createUrlTree(redirect)) : of(true);
        }

        if (!this.account.isAuthenticated()) {
            redirect = ['/login'];
            this.storage.setAfterLoginUrl(state.url);
        }

        if (route.data.type === 'override') {
            return this.account.isAuthenticated() ? of(true) : of(this.router.createUrlTree(redirect));
        }

        if (this.config.remoteConfig.login_version == 1) {
            return this.fireGuard.canActivate(route, state);
        }

        return this.account.isAuthenticated() ? of(true) : of(this.router.createUrlTree(redirect));
    };

}
