import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RateMediaService } from 'src/app/services/rate-media.service';
import { AccountService } from '../../services/account.service';
import { BehaviorSubject } from 'rxjs';
import { RemoteConfigService } from '../../services/remote-config.service';

@Component({
    selector: 'app-rate',
    templateUrl: './rate.component.html',
    styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {

    @Input() mediaId: number;

    likes$: BehaviorSubject<number> = new BehaviorSubject(0);

    liked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    disliked$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    canUpdate = true;

    constructor(
        private account: AccountService,
        private rateMediaServices: RateMediaService,
        public config: RemoteConfigService) {
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.liked$.next(false);
        this.disliked$.next(false);
        this.account.updateToken().then(
            () => {
                if (this.account.isAuthenticated()) {
                    this.rateMediaServices.getRating(this.mediaId).subscribe(res => {
                        this.likes$.next(res.likes);
                        if (res.liked === true) {
                            this.liked$.next(true);
                        } else if (res.liked === false) {
                            this.disliked$.next(true);
                        }
                    });
                } else {
                    this.rateMediaServices.getRatingAnonymous(this.mediaId).subscribe(res => {
                        this.likes$.next(res.likes);
                    });
                }
            },
            () => {
            });
    }

    like() {

        if (!this.account.isAuthenticated() || !this.canUpdate) {
            return;
        }

        this.preventUpdate();

        if (this.disliked$.value) {
        }
        if (this.liked$.value) {
            this.liked$.next(false);
            this.likes$.next(this.likes$.value - 1);
            this.rateMediaServices.setRating(this.mediaId, null).subscribe();
        } else {
            this.liked$.next(true);
            this.disliked$.next(false);
            this.likes$.next(this.likes$.value + 1);
            this.rateMediaServices.setRating(this.mediaId, true).subscribe();
        }
    }

    dislike() {
        if (!this.account.isAuthenticated() || !this.canUpdate) {
            return;
        }

        this.preventUpdate();

        if (this.liked$.value) {
            this.likes$.next(this.likes$.value - 1);
        }
        if (this.disliked$.value) {
            this.disliked$.next(false);
            this.rateMediaServices.setRating(this.mediaId, null).subscribe();
        } else {
            this.disliked$.next(true);
            this.liked$.next(false);
            this.rateMediaServices.setRating(this.mediaId, false).subscribe();
        }
    }

    private preventUpdate() {
        this.canUpdate = false;

        setTimeout(() => this.canUpdate = true, 500);
    }
}
