import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { EmailPreferences } from '../../models';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'app-email-preferences',
    templateUrl: './email-preferences.component.html',
    styleUrls: ['./email-preferences.component.css']
})
export class EmailPreferencesComponent implements OnInit {

    preferences$: Observable<EmailPreferences>;
    liveStreamEmails: boolean;
    corridorNewsEmails: boolean;
    corridorStoreEmails: boolean;
    videoNotificationEmails: boolean;
    @Input() route: string = '/subscribe';

    constructor(
        private app: AppComponent,
        private accountService: AccountService,
        private router: Router) {

        const route = window.history.state.route;
        if (route) {
            this.route = route;
        }
    }

    ngOnInit(): void {
        this.app.startLoading();
        this.preferences$ = this.accountService.getEmailPreferences().pipe(
            tap(res => {
                this.liveStreamEmails = res.liveStreamEmails;
                this.corridorNewsEmails = res.corridorNewsEmails;
                this.corridorStoreEmails = res.corridorStoreEmails;
                this.videoNotificationEmails = res.videoNotificationEmails;
                this.app.stopLoading();
            }),
            catchError((err) => {
                this.app.stopLoading();
                return of(null);
            })
        )
    }

    onSave() {
        this.app.startLoading();
        this.accountService.submitEmailPreferences({
            corridorNewsEmails: this.corridorNewsEmails,
            corridorStoreEmails: this.corridorStoreEmails,
            liveStreamEmails: this.liveStreamEmails,
            videoNotificationEmails: this.videoNotificationEmails
        }).subscribe({
            next: () => {
                if (this.route !== '/subscribe') {
                    this.app.showSimpleAlert({
                        title: 'Success',
                        body: 'Your email preferences were successfully changed.'
                    });
                }

                this.app.stopLoading();
                this.router.navigate([this.route]);
            },
            error: () => {
                this.app.showSimpleAlert({
                    title: 'Error',
                    body: 'Your email preferences failed to save.'
                });
                this.app.stopLoading();
            }
        });
    }
}
