import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AccountService } from 'src/app/services/account.service';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { UserInfoService } from '../../../services/user-info.service';
import { RemoteConfigService } from '../../../services/remote-config.service';
import { Utils } from '../../../services/utils.service';

@Component({
    selector: 'app-change-email-v2',
    templateUrl: './change-email-v2.component.html',
    styleUrls: ['./change-email-v2.component.scss']
})
export class ChangeEmailV2Component extends AuthenticationOverride implements OnInit {

    error: string;
    loading: boolean;
    viewModel: any = {};

    constructor(
        private app: AppComponent,
        public accountService: AccountService,
        private userInfo: UserInfoService,
        public config: RemoteConfigService,
        route: ActivatedRoute,
        storage: StorageManagerService,
        router: Router) {
        super(route, storage, router, accountService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loading = true;
        this.userInfo.getUserInfoQuery('email').subscribe(res => {
            this.viewModel.email = res.email;
            this.loading = false;
        });
    }

    submit() {
        this.app.startLoading();
        this.error = undefined;
        if (this.config.remoteConfig.login_version == 1) {
            this.changeEmailV1();
        }

        if (this.config.remoteConfig.login_version == 2) {
            this.changeEmailV2();
        }
    }

    changeEmailV1() {
        if (!this.viewModel.email || this.viewModel.email.length < 1 || !Utils.isValidEmail(this.viewModel.email)) {
            this.error = 'Please enter a valid email address.';
            this.app.stopLoading();
            return;
        }

        this.accountService.changeEmail(this.viewModel.email).subscribe({
            next: () => {
                this.app.showSimpleAlert({
                    title: 'Success',
                    body: 'Your email was successfully changed.'
                });
                this.logout();
            },
            error: err => {
                this.error = "Something went wrong! Please try again later or contact an administrator.";

                if(err.error) {
                    this.error = err.error;
                    try {
                        this.error = JSON.parse(err.error.substring(err.error.indexOf("\n")+1)).error.message.toLowerCase().split('_').join(' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                    } catch {}
                }
                this.app.stopLoading();
            }
        });
    }

    changeEmailV2() {
        if (!this.viewModel.email || this.viewModel.email.length < 1) {
            this.error = 'Please enter a valid email address.';
            this.app.stopLoading();
            return;
        }

        this.accountService.changeEmailV2(this.viewModel.email).subscribe({
            next: () => {
                this.viewModel.email = '';
                this.app.showSimpleAlert({
                    title: 'Success',
                    body: 'Please check your emails at your old email address to verify'
                });
                this.app.stopLoading();
            },
            error: err => {
                this.error = 'Something went wrong! Please try again later or contact an administrator';

                if(err.error) {
                    this.error = err.error;
                }
                this.app.stopLoading();
            }
        });
    }
}
