import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, of, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { ChannelsService } from './channels.service';
import { MediaSummary } from '../models';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root'
})
export class PlaylistService {

    mediaList: any[] = [];

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient,
        private channelsService: ChannelsService) {
        this.channelsService.userChannels().subscribe(x => {
            if (x) {
                this.mediaList = x?.find(f => f.playlistId === 'my-watchlist')?.media;
            }
        })
    }

    hasMediaId(mediaId) {
        return this.mediaList && this.mediaList.length > 0 && this.mediaList.filter(f => f.mediaId === mediaId).length > 0;

    }

    getWatchlist(): Observable<any> {
        return this.http
            .get<any>(this.envService.contentHost + '/v3/playlist/my-watchlist/');
    }

    addToPlaylist(media: MediaSummary): Observable<any> {
        if (!this.mediaList) {
            this.mediaList = [];
        }
        this.mediaList.push(media);
        return this.http
            .post<any>(this.envService.contentHost + '/v3/playlist/my-watchlist/' + media.mediaId, {})
            .pipe(catchError(error => {
                if (!environment.production) {
                    console.error('PlaylistService::Error::post', error);
                }
                return throwError(error);
            }));
    }

    deleteFromPlaylist(media: MediaSummary): Observable<any> {
        if (!this.mediaList) {
            this.mediaList = [];
        }
        const index = this.mediaList.findIndex(f => f.mediaId == media.mediaId);
        if (index > -1) {
            if (index > -1) {
                this.mediaList.splice(index, 1);
            }
        }
        return this.http
            .delete<any>(this.envService.contentHost + '/v3/playlist/my-watchlist/' + media.mediaId, {})
            .pipe(catchError(error => {
                if (!environment.production) {
                    console.error('PlaylistService::Error::post', error);
                }
                return throwError(error);
            }));
    }
}
