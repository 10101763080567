<div class="main main-full main-responsive wide" *ngIf="subtitleClass$ | async">
    <app-title class="d-block mb-3" [title]="'Subtitle Settings'"></app-title>
    <p>Change the way subtitles appear on all supported devices.</p>
    <div class="d-flex justify-content-center align-items-center">
        <section class="subtitle-preview">
            <div class="subtitle-preview-text">
                <span [ngClass]="subtitleClass$ | async" class="settings">These settings affect subtitles on all supported devices.</span>
            </div>
        </section>
    </div>


    <section class="subtitle-options-row z-index-2">
        <div class="subtitle-option mr-lg-3">
            <div class="subtitle-option-title">Font</div>
            <div class="d-flex">
                <div class="w-100">
                    <div class="dropdown-select dropdown-toggle bordered" (click)="openDropdown('font')" clickOutside (clickOutside)="fontOptionsOpen$.next(false)">
                        <span class="settings" [ngClass]="service.fontClass((service.selectedFont$ | async).value)">{{(service.selectedFont$ | async).name}}</span>
                        <div class="dropdown" *ngIf="fontOptionsOpen$ | async">
                            <div class="dropdown-option settings" *ngFor="let o of service.fontOptions" (click)="optionClicked($event, o, 'font')" [ngClass]="service.fontClass(o.value)">
                                {{o.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-2">
                    <div class="position-relative" clickOutside (click)="textColourOpen$.next(true)" (clickOutside)="textColourOpen$.next(false)">
                        <div class="colour-picker settings" [ngClass]="service.windowColourClass((service.selectedTextColour$ | async)?.value)"></div>
                        <div class="colour-picker-dropdown left" *ngIf="textColourOpen$ | async">
                            <ng-container *ngFor="let colour of service.colourOptions">
                                <div class="colour-picker settings" *ngIf="colour.value !== 'Transparent'"
                                     [ngClass]="service.windowColourClass(colour.value)"
                                     (click)="optionClicked($event, colour, 'textColour')">
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="subtitle-option mt-4 mt-lg-0 ml-lg-3">
            <div class="subtitle-option-title">Text Size: {{(service.selectedTextSize$ | async).name}}</div>
            <div class="font-size-options">
                <div class="font-size-option font-size-small settings" (click)="service.selectedTextSize$.next(service.textSizeOptions[0])" [class.active]="(service.selectedTextSize$ | async).value === 'Small'">
                    A
                </div>
                <div class="font-size-option font-size-medium settings" (click)="service.selectedTextSize$.next(service.textSizeOptions[1])" [class.active]="(service.selectedTextSize$ | async).value === 'Medium'">
                    A
                </div>
                <div class="font-size-option font-size-large settings" (click)="service.selectedTextSize$.next(service.textSizeOptions[2])" [class.active]="(service.selectedTextSize$ | async).value === 'Large'">
                    A
                </div>
            </div>
        </div>
    </section>
    <section class="subtitle-options-row z-index-1">
        <div class="subtitle-option mr-lg-3">
            <div class="subtitle-option-title">Shadow</div>
            <div class="d-flex">
                <div class="w-100">
                    <div class="dropdown-select dropdown-toggle bordered settings" (click)="openDropdown('drop-shadow')" clickOutside (clickOutside)="dropShadowOptionsOpen$.next(false)">
                        <span [ngClass]="service.dropShadowClass((service.selectedShadow$ | async).value, (service.selectedShadowColour$ | async)?.value)">{{(service.selectedShadow$ | async).name}}</span>
                        <div class="dropdown" *ngIf="dropShadowOptionsOpen$ | async">
                            <div class="dropdown-option settings" *ngFor="let o of service.dropShadowOptions" (click)="optionClicked($event, o, 'drop-shadow')"
                                 [ngClass]="service.dropShadowClass(o.value, (service.selectedShadowColour$ | async)?.value)" [class.active]="(service.selectedShadow$ | async).value === o.value">
                                {{o.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-2">
                    <div class="position-relative" clickOutside (click)="shadowColourOpen$.next(true)" (clickOutside)="shadowColourOpen$.next(false)">
                        <div class="colour-picker settings" [ngClass]="service.windowColourClass((service.selectedShadowColour$ | async)?.value)"></div>
                        <div class="colour-picker-dropdown left" *ngIf="shadowColourOpen$ | async">
                            <ng-container *ngFor="let colour of service.colourOptions">
                                <div class="colour-picker settings" *ngIf="colour.value !== 'Transparent'"
                                     [ngClass]="service.windowColourClass(colour.value)"
                                     (click)="optionClicked($event, colour, 'shadowColour')">
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="subtitle-option mt-4 mt-lg-0 ml-lg-3 d-flex">
            <div class="subtitle-background w-50">
                <div class="subtitle-option-title">Background</div>
                <div class="position-relative">
                    <div class="colour-picker none settings" [ngClass]="service.windowColourClass((service.selectedBackgroundColour$ | async)?.value)" clickOutside (click)="backgroundColourOpen$.next(true)" (clickOutside)="backgroundColourOpen$.next(false)"></div>
                    <div class="colour-picker-dropdown right" *ngIf="backgroundColourOpen$ | async">
                        <ng-container *ngFor="let colour of service.colourOptions">
                            <div class="colour-picker-none" *ngIf="colour.value === 'Transparent'" (click)="optionClicked($event, colour, 'backgroundColour')">
                                None
                            </div>
                            <div class="colour-picker settings" *ngIf="colour.value !== 'Transparent'"
                                 [ngClass]="service.windowColourClass(colour.value)"
                                 (click)="optionClicked($event, colour, 'backgroundColour')">
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
<!--            <div class="subtitle-window">-->
<!--                <div class="subtitle-option-title">Window</div>-->
<!--                <div class="position-relative" clickOutside (click)="windowColourOpen$.next(true)" (clickOutside)="windowColourOpen$.next(false)">-->
<!--                    <div class="colour-picker none" [ngClass]="windowColourClass((selectedWindowColour$ | async)?.value)"></div>-->
<!--                    <div class="colour-picker-dropdown center" *ngIf="windowColourOpen$ | async">-->
<!--                        <ng-container *ngFor="let colour of colourOptions">-->
<!--                            <div class="colour-picker-none" *ngIf="colour.value === 0" (click)="optionClicked($event, colour, 'windowColour')">-->
<!--                                None-->
<!--                            </div>-->
<!--                            <div class="colour-picker" *ngIf="colour.value !== 0"-->
<!--                                 [ngClass]="windowColourClass(colour.value)"-->
<!--                                 (click)="optionClicked($event, colour, 'windowColour')">-->
<!--                            </div>-->
<!--                        </ng-container>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </section>

    <section class="subtitle-option-buttons">
        <button class="btn btn-primary save-btn" (click)="save()" [disabled]="(saving$ | async)">
            <app-loading-spinner [widthHeightPx]="25" *ngIf="saving$ | async"></app-loading-spinner>
            <ng-container *ngIf="!(saving$ | async)">
                Save
            </ng-container>
        </button>
    </section>
    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>
