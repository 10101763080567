<div>
    <div class="comments-row" [class.mt-2]="originalChatId">
        <div class="comments-avatar">
            <img class="float-right avatar avatar-small" *ngIf="avatarUrl" src="{{avatarUrl}}" />
        </div>
        <div class="comments-main">

            <textarea #comment type="text" name="comment" [(ngModel)]="chat.message" #message="ngModel"
                class="text-input-dark hide-resize inline-block emoji-padding" placeholder="Something friendly to say?"
                tabindex="1" autocomplete="off" autosize (keydown)="ctrlEnter($event)">
                </textarea>
            <div class="floating-images">
                <img class="pointer" (click)="cancel()" src="/assets/x.png" height="20" />
                <img class="pointer" (click)="emoji()" src="/assets/smiley.png" height="20" />

                <svg class="pointer send-svg" [class.active]="!this.isEmptyOrSpaces(chat.message)" (click)="send()" width="20" height="20" viewBox="0 0 481 481" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M420.32 84.4261L215.453 289.205L290.795 425.286L420.32 84.4261ZM191.662 265.424L396.529 60.6454L55.5224 190.114L191.662 265.424ZM458.66 1.19121C471.871 -4.08767 485.102 9.11844 479.802 22.3246L309.292 470.205C305.326 483.411 286.815 484.745 280.218 472.854L182.418 298.449L9.27565 202.004C-3.93613 195.391 -2.62077 176.906 10.5912 171.609L458.664 1.17188L458.66 1.19121Z"/>
                </svg>
            </div>

        </div>
    </div>
    <div class="comments-row position-relative" [class.mt-2]="originalChatId">
        <div class="comments-submit">
            <div *ngIf="customEmojis">
                <emoji-mart (emojiClick)="addEmoji($event)" [darkMode]="true" [custom]="customEmojis" [include]="includes"
                    [i18n]="translations" [style]="{ position: 'absolute', right: '0px' }" [hidden]="hideEmoji">
                </emoji-mart>
            </div>
        </div>
    </div>
</div>
