import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-left-text',
    templateUrl: './left-text.component.html',
    styleUrls: ['./left-text.component.css']
})
export class LeftTextComponent implements OnInit {

    @Input() section: any;

    mobileBackground: string;
    webBackground: string;

    title: string;
    text: string;

    buttonText: string;
    url: string;

    img: string;

    constructor() {
    }

    ngOnInit(): void {
        this.mobileBackground = this.section.images.find(f => f.orientation === 'vertical' || f.orientation === 'narrow')?.url;
        this.webBackground = this.section.images.find(f => f.orientation === 'horizontal' || f.orientation === 'wide')?.url;
        this.img = this.section.images.find(f => f.type === 'img')?.url;

        this.title = this.section.text.find(f => f.type === 'title')?.text;
        this.text = this.section.text.find(f => f.type === 'text')?.text;

        this.buttonText = this.section.text.find(f => f.type === 'button')?.text;
        this.url = this.section.urls[0]?.url;
    }

}
