<div class="main main-full main-responsive">
    <app-title [title]="'Delete Account Request'"></app-title>

    <ng-container *ngIf="deleteRequest; else noRequest">
        <div>
            {{deleteRequest.daysLeft-1}} Days Left To Cancel Request
        </div>
        <div>
            Requested {{deleteRequest.requestDate | date:"mediumDate"}}
        </div>
        <div>

        </div>
        <button class="btn btn-outline-primary btn-slim mt-3" (click)="cancelAccountDeleteRequestClicked()">
            {{"account-cancel-delete-request" | translate }}
        </button>
    </ng-container>
    <ng-template #noRequest>
        <div>
            Click the button below to request your account to be deleted. You will then have 60 days to change your mind before your account is removed from our system.
        </div>
        <div class="mt-2">
            You can come back to this page at any time within 60 days of the request to cancel your request or check how long until your account is removed.
        </div>
        <div class="mt-2">
            <div class="form-group">
                <label>Why would you like to delete your account? (optional)</label>
                <input type="text" name="reason" [(ngModel)]="reason" autoFocus
                       class="form-control" placeholder="Reason">
            </div>
        </div>
        <div>
            <button class="btn btn-outline-primary btn-slim mt-2" (click)="requestDeleteClicked()">Request To Delete Account</button>
        </div>
    </ng-template>

    <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
</div>
