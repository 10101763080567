<ng-container *ngIf="(mediaList || mediaList2) && (mediaList?.length > 0 || mediaList2?.length > 0)">
    <app-channel-header *ngIf="(mediaList || mediaList2) && channel" [channel]="channel" [showBadge]="false"></app-channel-header>
    <ng-container *ngIf="mediaList">
        <app-image-slider [type]="'thumbs'" [items]="mediaList" [thumbnailType]="isMobileView ? channel.thumbnailTypeResponsive ?? channel.thumbnailType : channel.thumbnailType">
        </app-image-slider>
    </ng-container>
    <ng-container *ngIf="mediaList2">
        <app-image-slider [type]="'thumbs'" [items]="mediaList2" [thumbnailType]="isMobileView ? channel.thumbnailTypeResponsive ?? channel.thumbnailType : channel.thumbnailType">
        </app-image-slider>
    </ng-container>
</ng-container>

