import { Component, OnInit } from '@angular/core';
import { AuthenticationOverride } from '../../../modules/authentication-override';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from '../../../services/storage-manager.service';
import { AccountService } from '../../../services/account.service';
import { UserInfoService } from '../../../services/user-info.service';
import { DeleteRequest } from '../../../models/delete-request';
import { UiService } from '../../../services/ui.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-delete-account',
    templateUrl: './delete-account.component.html',
    styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent extends AuthenticationOverride implements OnInit {

    deleteRequest: DeleteRequest;
    userId: number;
    reason: string = '';

    constructor(route: ActivatedRoute,
                storageManager: StorageManagerService,
                router: Router,
                public account: AccountService,
                private userInfoService: UserInfoService,
                private ui: UiService) {
        super(route, storageManager, router, account)
    }

    ngOnInit() {
        super.ngOnInit();
        this.ui.startLoading();
        this.userInfoService.getUserInfoQuery('delete-request,userid')
            .subscribe(x => {
                this.deleteRequest = x.deleteRequest;
                this.userId = x.userId;
                this.ui.stopLoading();
            })

    }

    cancelAccountDeleteRequestClicked() {
        this.ui.confirm({
            title: 'Cancel Account Deletion Request',
            message: 'Are you sure you want to cancel this request?',
            okCallback: () => this.cancelAccountDeleteRequest(),
            okBtnTxt: 'Yes',
            cancelBtnTxt: 'No'
        })
    }

    async cancelAccountDeleteRequest() {
        this.ui.startLoading();
        try {
            await lastValueFrom(this.account.cancelDeleteRequest(this.userId))
            this.deleteRequest = undefined;
        } catch {
        } finally {
            this.ui.stopLoading();
        }
    }

    requestDeleteClicked() {
        this.ui.confirm({
            title: 'Account Deletion Request',
            message: 'Are you sure you want to delete your account?',
            okCallback: () => this.requestDelete(),
            okBtnTxt: 'Yes',
            cancelBtnTxt: 'No'
        })
    }

    async requestDelete() {
        this.ui.startLoading();
        try {
            this.deleteRequest = await lastValueFrom(this.account.requestDelete(this.reason));
        } catch {
        } finally {
            this.ui.stopLoading();
        }
    }
}
