import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
    selector: 'app-purchase-success',
    templateUrl: './purchase-success.component.html',
    styleUrls: ['./purchase-success.component.css']
})
export class PurchaseSuccessComponent implements OnInit {

    purchaseId: string;
    sessionId: string;

    errorMessage: string;
    successMessage: string;

    constructor(
        private app: AppComponent,
        private route: ActivatedRoute,
        private paymentsService: PaymentsService) {

        this.route.queryParamMap
            .subscribe((params) => {
                this.purchaseId = params.get('purchase_id');
                this.sessionId = params.get('session_id');
            });
    }

    ngOnInit(): void {
        this.app.startLoading();
        this.paymentsService.confirmPurchasePayment(this.sessionId, +this.purchaseId).subscribe({
            next: res => {
                this.successMessage = res.successMessage;
                this.app.getUserInfo().then(res => {
                    this.app.stopLoading();
                });
            },
            error: err => {
                this.errorMessage = err.error;
                this.app.getUserInfo().then(res => {
                    this.app.stopLoading();
                });
            }
        });
    }

}
