<div class="main main-full main-responsive">
    <app-title [title]="'account-email-preferences' | translate"></app-title>
    <div class="row py-3 my-3">
        <div class="col-12">
            <app-email-preferences [route]="'/account'"></app-email-preferences>

            <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
        </div>
    </div>
</div>
