import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { SubscriptionCancellation } from '../models';
import { SubscriptionOption } from '../models/subscription-option';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    createPayPalSubscription(annual: boolean): Observable<any> {
        return this.http
            .post<string>(this.envService.userHost + '/v2/payPalSubscription?annual=' + annual, {}).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    checkPayPalSubscriptionStatus(subscriptionId: string): Observable<any> {
        return this.http
            .get<string>(this.envService.userHost + '/v2/payPalSubscription/status/' + subscriptionId, {
                observe: 'response'
            }).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    createSubscription(cardToken: string, productId: string, planId: string): Observable<string> {
        return this.http
            .post<string>(this.envService.userHost + '/v6/subscription', {
                cardToken: cardToken,
                productId: productId,
                planId: planId
            }).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    createSubscriptionSession(planId: string): Observable<any> {
        return this.http
            .get<string>(this.envService.userHost + '/v1/stripeSession/' + planId + '?trial=true').pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    getCardChangeSession(): Observable<any> {
        return this.http
            .get<string>(this.envService.userHost + '/v1/stripeCard/session').pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    getCards(): Observable<any[]> {
        return this.http
            .get<any[]>(this.envService.userHost + '/v1/stripeCard').pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    deleteCard(id: string): Observable<any> {
        return this.http
            .delete<string>(this.envService.userHost + '/v1/stripeCard/' + id, {}).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    changeCardSubscription(session: string): Observable<any> {
        return this.http
            .post<string>(this.envService.userHost + '/v1/stripeCard/' + session, {}).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::post::error', error);
                    return throwError(error);
                })
            );
    }

    getActiveSubscriptions(): Observable<any> {
        return this.http.get<any[]>(this.envService.userHost + '/v6/subscription').pipe(
            catchError(error => {
                if (!environment.production)
                    console.error('SubscriptionService::get::error', error);
                return throwError(error);
            }));
    }

    getSubscriptions(): Observable<any> {
        return this.http.get<any[]>(this.envService.userHost + '/v6/subscription/list').pipe(
            catchError(error => {
                if (!environment.production)
                    console.error('SubscriptionService::get::error', error);
                return throwError(error);
            }));
    }

    getPayments(): Observable<any> {
        return this.http.get<any[]>(this.envService.userHost + '/v6/subscription/payments').pipe(
            catchError(error => {
                if (!environment.production)
                    console.error('SubscriptionService::get::error', error);
                return throwError(error);
            }));
    }

    getSubscription(subscriptionId: string): Observable<any> {
        return this.http.get<any>(this.envService.userHost + '/v6/subscription/' + subscriptionId).pipe(
            catchError(error => {
                if (!environment.production)
                    console.error('SubscriptionService::get::error', error);
                return throwError(error);
            }));
    }

    checkPatreon(): Observable<any> {
        return this.http.get<any[]>(this.envService.userHost + '/v1/patreon').pipe(
            catchError(error => {
                if (!environment.production)
                    console.error('SubscriptionService::get::error', error);
                return throwError(error);
            }));
    }

    cancelSubscription(id: string): Observable<any> {
        return this.http
            .delete(this.envService.userHost + '/v6/subscription/' + id).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::delete::error', error);
                    return throwError(error);
                }));
    }

    cancelAllSubscriptions(cancellation: SubscriptionCancellation): Observable<any> {
        return this.http
            .delete(this.envService.userHost + '/v6/subscription/cancelAll', {body: cancellation}).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::delete::error', error);
                    return throwError(error);
                }));
    }

    getSubscriptionCancellationReasons(): Observable<SubscriptionCancellation[]> {
        return this.http
            .get<SubscriptionCancellation[]>(this.envService.userHost + '/v6/subscription/cancel-reasons').pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::get::error', error);
                    return throwError(error);
                }));
    }

    resumeSubscription(id: string): Observable<any> {
        return this.http
            .patch(this.envService.userHost + '/v6/subscription/' + id, {}).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::resume::error', error);
                    return throwError(error);
                }));
    }

    resumeLatestSubscription(): Observable<any> {
        return this.http
            .patch(this.envService.userHost + '/v6/subscription/resume/latest', {}).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('SubscriptionService::resume::error', error);
                    return throwError(error);
                }));
    }

    hasPreviousSubscription(): Observable<boolean> {
        return this.http.get<boolean>(this.envService.userHost + '/v6/subscription/hasprevious');
    }

    getStripePrices() {
        return this.http.get(`${this.envService.userHost}/v2/stripe/prices`);
    }

    getSecret() {
        return this.http.get<any>(`${this.envService.userHost}/v2/stripe/payment-method`);
    }

    getTrialPeriod() {
        return this.http.get<any>(`${this.envService.userHost}/v2/stripe/trial-period`);
    }

    createStripeSubscription(priceId: string, setupIntentId: string) {
        return this.http.post<any>(`${this.envService.userHost}/v2/stripe/subscription`, { priceId, setupIntentId })
    }

    convertPatreon(priceId: string, setupIntentId: string) {
        return this.http.post<any>(`${this.envService.userHost}/v2/stripe/convert-patreon`, { priceId, setupIntentId })
    }

    updateSubscription(subscriptionId: string, planId: string) {
        return this.http.patch<any>(`${this.envService.userHost}/v6/subscription/update-subscription/${ subscriptionId }`, { planId })
    }
    endTrial(subscriptionId: string) {
        return this.http.get<any>(`${this.envService.userHost}/v6/subscription/update-subscription/${ subscriptionId }/end-trial`)
    }

    getSubscriptionPaths(subscriptionId: string): Observable<SubscriptionOption[]> {
        return this.http
            .get<SubscriptionOption[]>(`${this.envService.userHost}/v6/subscription/subscription-path/${subscriptionId}`)
    }
}
