<h3 class="section-title">
    {{title}}
</h3>
<div class="playlist-slider-container">
    <div class="playlist-slider">
        <app-image-slider
                [type]="'media-list-greenlight'"
                [channelId]="channel.id"
                [items]="channel.media"
                [disableNextRow]="false">
        </app-image-slider>
    </div>
</div>

