import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../services/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { LocalisationService } from '../../../services/localisation.service';
import { AccountService } from '../../../services/account.service';
import { environment } from '../../../../environments/environment';
import { EnvironmentService } from '../../../services/environment.service';

declare function Stripe(pk: string): any;
declare let Android: any;

@Component({
    selector: 'app-complete-payment',
    templateUrl: './complete-payment.component.html',
    styleUrls: ['./complete-payment.component.scss']
})
export class CompletePaymentComponent implements OnInit, OnDestroy {
    message: string = '';
    seconds: number = 10;
    stripe: any;
    clientSecret: string;
    priceId: string;
    countDownInterval: any;

    get isMobile() {
        try {
            return Android !== undefined && Android !== null;
        } catch {
            return false
        }
    }


    constructor(private subService: SubscriptionService,
                private route: ActivatedRoute,
                private router: Router,
                private localisation: LocalisationService,
                private account: AccountService,
                private env: EnvironmentService) {
    }

    async ngOnInit(): Promise<void> {
        this.stripe = Stripe(this.env.stripePk);
        this.clientSecret = this.route.snapshot.queryParamMap.get('setup_intent_client_secret');
        this.priceId = this.route.snapshot.paramMap.get('priceId');

        // Retrieve the PaymentIntent
        this.refreshStatus();

    }

    ngOnDestroy() {
        clearInterval(this.countDownInterval);
    }

    async refreshStatus() {
        const {setupIntent} = await this.stripe.retrieveSetupIntent(this.clientSecret);

        switch (setupIntent.status) {
            case 'succeeded':
                this.message = 'Success! Payment received.';
                await lastValueFrom(this.subService.createStripeSubscription(this.priceId, setupIntent.id));

                if (this.isMobile) {
                    Android.subscriptionComplete(true)
                    return;
                }

                setTimeout(() => {
                    this.account.updateSubscriptionStatus();
                }, 3000);
                this.countdown();
                break;
            case 'processing':
                this.message = 'Payment processing. We\'ll update you when payment is received.';
                this.countdown('', true);
                break;

            case 'requires_payment_method':
                this.message = 'Payment failed. Please try another payment method.';
                this.countdown('/payment');
                break;

            default:
                this.message = 'Something went wrong.';
        }
    }

    countdown(route: string = '/', reload: boolean = false) {
        this.countDownInterval = setInterval(() => {
            this.seconds--;
            if (this.seconds === 0) {
                clearInterval(this.countDownInterval);
                if (reload) {
                    this.refreshStatus();
                    return;
                }
                this.router.navigate([route]);
            }
        }, 1000)
    }

}
