import { ImageModel } from './image-model';
import { ItemSummary } from './item-summary';
import { MediaSummary } from './media-summary';
import { Hero } from './hero';

export interface ChannelResponse {
    id?: number;
    name?: string;
    type?: string;
    slug?: string;
    itemType?: string;
    isShow?: boolean;
    images?: ImageModel[];
    items?: ItemSummary[];
    itemIds?: number[];
    media?: MediaSummary[];
    hero?: Hero;
    layout?: string;
    series?: SeriesViewSeries;
    displayOrder?: string;
    seasonId?: number;
    nav?: 'none' | 'show' | 'latest';
    thumbnailType?: string;
    thumbnailTypeResponsive?: string;
}

export interface SeriesViewSeries {
    seasons: SeriesViewSeason[];
}

export interface SeriesViewSeason {
    name: string;
    seasonId: number;
}
