import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';

@Component({
	selector: 'app-learn-more',
	templateUrl: './learn-more.component.html',
	styleUrls: ['./learn-more.component.scss']
})
export class LearnMoreComponent implements OnInit {

	merch: any = {
		'text-type': 'overlay',
		images: [
			{
				orientation: 'horizontal',
				url: 'https://cdn.watchcorridor.com/assets/learn-more/merch-web.jpg'
			},
			{
				orientation: 'vertical',
				url: 'https://cdn.watchcorridor.com/assets/learn-more/merch-mobile.jpg'
			}
		],
		text: [
			{
				type: 'title',
				text: 'ALL MERCH 15% OFF & FIRST DIBS'
			},
			{
				type: 'text',
				text: 'Site members get 15% off all product and the first opportunity to buy new product before the general public.'
			},
			{
				type: 'button',
				text: 'CHECK OUT MERCH'
			}
		],
		urls: [
			{
				url: 'https://corridordigital.store/'
			}
		]
	};

	greenlight: any = {
		'text-type': 'overlay',
		images: [
			{
				orientation: 'horizontal',
				url: 'https://cdn.watchcorridor.com/assets/learn-more/cd-bg.jpg'
			},
			{
				orientation: 'vertical',
				url: 'https://cdn.watchcorridor.com/assets/learn-more/cd-bg-mobile.jpg'
			},
			{
				type: 'img',
				url: 'https://cdn.watchcorridor.com/assets/learn-more/greenlight.png'
			}
		],
		text: [
			{
				type: 'title',
				text: 'BE A PRODUCER'
			},
			{
				type: 'text',
				text: 'Directly control what your subscription dollars are spent on by using your Producer Points'
			},
			{
				type: 'button',
				text: 'SEE IT IN ACTION'
			}
		],
		urls: [
			{
				url: 'https://corridordigital.com/greenlight'
			}
		]
	};

	constructor(private accountService: AccountService) {
	}

	ngOnInit(): void {
	}

	handleRedirect() {
		this.accountService.isAuthenticated() ? this.accountService.navigateToAccessPage('subscribe') : this.accountService.navigateToAccessPage('signup');
	}

	signup() {
		this.accountService.navigateToAccessPage('signup');
	}
}
