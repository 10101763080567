import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChannelsService } from '../../services/channels.service';
import { ProjectResponse } from '../../models';
import { PlaylistService } from '../../services/playlist.service';
import { firstValueFrom, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-watchlist',
    templateUrl: './watchlist.component.html',
    styleUrls: ['./watchlist.component.scss']
})
export class WatchlistComponent implements OnInit, OnDestroy {
    media: Array<ProjectResponse> = [];

    destroy$ = new Subject<void>();

    @ViewChild('imageSlider') containerRef: ElementRef;
    isLoading: boolean = true;

    constructor(private channelService: ChannelsService,
                private playlistService: PlaylistService,
                private ui: UiService) {

    }

    async ngOnInit() {
        this.ui.startLoading()
        const userChannels = await firstValueFrom(this.channelService.userChannels());
        console.log(userChannels, this.playlistService.mediaList)
        if (this.playlistService.mediaList || (userChannels && userChannels.some(f => f.playlistId === 'my-watchlist'))) {
            this.media = this.playlistService.mediaList;
            this.isLoading = false;
            this.ui.stopLoading();
            return;
        }

        this.media = (await lastValueFrom(this.playlistService.getWatchlist()))?.media;
        this.playlistService.mediaList = this.media;
        this.isLoading = false;
        this.ui.stopLoading();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
