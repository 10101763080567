import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable, Observer, of, switchMap, take, throwError } from 'rxjs';
import io from 'socket.io-client';
import {environment} from 'src/environments/environment';
import {StorageManagerService} from './storage-manager.service';
import {RemoteConfigService} from './remote-config.service';
import {SocketIoCommentMessage} from '../models/socket-io.models';
import {AccountService} from './account.service';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private socket: any;
    private url: string = environment.socketUrl;

    private _connected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    get connected$(): Observable<boolean> {
        return this._connected$.asObservable();
    }

    set connected(value: boolean) {
        if (this._connected$.value === value) {
            return;
        }
        this._connected$.next(value);
    }

    constructor(
        private accountService: AccountService
    ) {
        this.accountService.isAuthenticated$.subscribe(isAuthenticated => {
            if (isAuthenticated) {
                this.connect();
            } else {
                this.disconnect();
            }
        })
    }

    sendMessage(route: string, message: any): void {
        console.log("SocketIO::SENT::", message)
        this.socket.emit(route, JSON.stringify(message));
    }

    sendComment(message: string, mediaId: number, originalChatId: number = undefined): void {
        const ioMessage: SocketIoCommentMessage = {
            message: message,
            mediaId: mediaId,
            type: 'message'
        };
        if (originalChatId) {
            ioMessage.originalCommentId = originalChatId;
        }
        this.sendMessage('comment', ioMessage);
    }

    addLike(commentId: number, mediaId: number) {
        const message = {type: 'add-like', commentId, mediaId};
        this.sendMessage('comment', message);
    }

    removeLike(commentId: number, mediaId: number) {
        const message = {type: 'remove-like', commentId, mediaId};
        this.sendMessage('comment', message);
    }

    get(route: string, mediaId: number, lowestCommentId: number = undefined): void {
        const message: any = {
            type: 'get', mediaId: mediaId
        };

        if (lowestCommentId !== undefined) {
            message.lowestCommentId = lowestCommentId;
        }

        this.socket.emit(route, JSON.stringify(message));
    }

    deleteMessage(route: string, mediaId: number, commentId: number): void {
        this.socket.emit(route, JSON.stringify({type: 'delete', mediaId: mediaId, commentId}));
    }

    onMessage(route: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.socket.on(route, (data: any) => {
                observer.next(data);
            });
        });
    }

    connect(): void {
        this.accountService.updateTokenV2().pipe(
            take(1),
            switchMap(token => {
                if (!token) {
                    return null;
                }

                if (!this.socket || !this.socket.connected) {
                    this.socket = io(`${this.url}?token=${token}`);
                    console.log('SocketIO::CONNECT');
                }

                this.connected = true;
                return of(this.socket);
            }),
            catchError(err => {
                return throwError(err);
            })
        ).subscribe();
    }

    disconnect(): void {
        if (this.socket && this.socket.connected) {
            console.log('SocketIO::DISCONNECT');
            this.socket.disconnect();
            this.connected = false;
        }
    }
}
