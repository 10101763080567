import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { EnvironmentService } from '../../services/environment.service';

declare function Stripe(publicKey): any

@Component({
    selector: 'app-subscription-management-v2',
    templateUrl: './subscription-management-v2.component.html',
    styleUrls: ['./subscription-management-v2.component.css']
})
export class SubscriptionManagementV2Component implements OnInit {

    cards: any[];
    payments: any[];
    subscriptions: any[];
    isMobile = false;

    constructor(private subscriptionService: SubscriptionService,
                private environmentService: EnvironmentService) {
    }

    ngOnInit(): void {
        this.subscriptionService.getSubscriptions().subscribe(res => {
            this.subscriptions = res;
        });

        this.subscriptionService.getCards().subscribe(res => {
            this.cards = res;
            if (!res) {
                this.cards = [];
            }
        });

        this.subscriptionService.getPayments().subscribe(res => {
            this.payments = res;
        });
    }

    changeCard() {
        this.subscriptionService.getCardChangeSession().subscribe(res => {
            const stripe = Stripe(this.environmentService.stripePk);

            stripe.redirectToCheckout({
                sessionId: res.id
            }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            });
        });
    }
}
