import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[disableControl]'
})
export class DisableControlDirective {

    @Input() set disableControl(isDisabled: boolean) {
        this.elementRef.nativeElement.disabled = isDisabled;
    }

    constructor(private elementRef: ElementRef) {
    }
}
