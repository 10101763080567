<div class="card-title">
    <div>
        <svg class="card-logo-small" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.009 24L24 39.009V46L46 24H39.009Z" fill="black" />
            <path d="M24 6.99244L39.009 22H46L24 0V6.99244Z" fill="black" />
            <path d="M6.99244 22L22 6.99244V0L0 22H6.99244Z" fill="black" />
            <path d="M28.3186 24L24 28.3186V35L35 24H28.3186Z" fill="black" />
            <path d="M17.6814 22L22 17.6814V11L11 22H17.6814Z" fill="black" />
            <path d="M22 28.3186L17.6814 24H11L22 35V28.3186Z" fill="black" />
        </svg>
    </div>
    Download Super Smush Brethren now
</div>
<div class="card-body card-body-slim">
    <div *ngIf="smush">
        <p>This is an alpha version so as Jake says “mind the jank”.</p>
        <a href="{{smush.torrentLink}}" class="btn btn-primary text-black">DOWNLOAD TORRENT</a>
        <p>
            (Preferred)
        </p>
        <a href="{{smush.directLink}}" class="btn btn-outline-secondary text-black">DIRECT DOWNLOAD</a>
        <p class="mt-4"><i>A Windows PC is required to play this game.</i></p>
    </div>
    <div *ngIf="!smush">
        {{message}}
    </div>
</div>