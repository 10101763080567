import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[appSwipe]'
})
export class SwipeDirective {

    startX: number;
    startY: number;

    @Input() thresholdPx = 50;

    @Output() swipeLeft = new EventEmitter<void>();
    @Output() swipeRight = new EventEmitter<void>();
    @Output() swipeUp = new EventEmitter<void>();
    @Output() swipeDown = new EventEmitter<void>();

    @HostListener('touchstart', ['$event'])
    touchstart(event: TouchEvent) {
        this.startX = event.touches[0].clientX;
        this.startY = event.touches[0].clientY;
    }

    @HostListener('touchend', ['$event'])
    touchend(event: TouchEvent) {
        let changeX = event.changedTouches[0].clientX - this.startX;
        let changeY = event.changedTouches[0].clientY - this.startY;
        if (Math.abs(changeX) > Math.abs(changeY)) {
            if (changeX > this.thresholdPx) {
                this.swipeRight.emit();
            } else if (changeX < -this.thresholdPx) {
                this.swipeLeft.emit();
            }
        } else {
            if (changeY > this.thresholdPx) {
                this.swipeDown.emit();
            } else if (changeY < -this.thresholdPx) {
                this.swipeUp.emit();
            }
        }
    }

}
