<div class="main-full container-fluid full-width d-flex flex-column overflow-hidden series-view side-padding pt-0" *ngIf="channel">
	<div class="row mr-0 w-100" *ngIf="imageWeb && imageMobile">
		<app-hero-banner class="series-hero w-100" [seriesView]="true" [heroWeb]="imageWeb" [heroMobile]="imageMobile"
			[logo]="logo" [media]="channel.media[0]" [showNew]="false"></app-hero-banner>
	</div>
	<div class="series-view-media-container container-fluid d-flex justify-content-between">
		<div class="dropdown-row mb-4">
			<div class="col-sm-4 col-md-6 col-lg-6">
				<div class="series-tabs">
					<div class="series-tab" (click)="selectedTab = 1" [class.active]="selectedTab === 1">
						EPISODES
					</div>
					<div class="series-tab" (click)="selectedTab = 2" [class.active]="selectedTab === 2" *ngIf="false">
						BTS
					</div>
				</div>
			</div>
			<div class="d-flex"
				[class.filterOptions-SingleButton]="!showHasMultipleSeasons()"
				[class.filterOptions-MultipleButtons]="showHasMultipleSeasons()">
				<div class="series-view-dropdown-container">
					<div>
						<div class="dropdown-toggle series-view-dropdown-select season-select mr-2"
							(click)="openSeasons($event)"
							*ngIf="channel.series?.seasons && channel.series?.seasons.length > 1">
							{{selectedSeason}}
							<div class="series-view-dropdown seasons" *ngIf="seasonsOpen">
								<div class="series-view-dropdown-option" (click)="optionClicked($event, s)"
									*ngFor="let s of channel.series.seasons">
									{{s.name}}
								</div>
							</div>
						</div>
					</div>
                    <div class="d-flex">
                        <div class="mr-1 sort-by-text">Sort By:</div>
                        <div class="dropdown-toggle series-view-dropdown-select sort-select"
                            (click)="openSortBy($event)">
                            {{sortByOption}}
                            <div class="series-view-dropdown sort-by" *ngIf="sortByOpen">
                                <div class="series-view-dropdown-option" *ngFor="let o of sortOptions"
                                    (click)="o.cb($event)">
                                    {{o.title}}
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
		<app-series-view-media-item-v2 *ngFor="let m of channel.media" [media]="m" [seasonId]="seasonId"></app-series-view-media-item-v2>
	</div>
</div>
