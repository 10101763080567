<div class="corridor-modal" *ngIf="shown$ | async" (click)="onClose();">
    <div class="central-modal-content contribute-modal"
         (click)="nothing($event)"
         (mousedown)="mouseInModal()"
         (mouseup)="mouseOutModal()"
    >
        <div class="corridor-modal-close no-select" (click)="onClose()">
            <img src="https://cdn.watchcorridor.com/assets/close-yellow.png" height="16px"/>
        </div>
        <div>
            <h3 class="no-select">{{projectTitle}}</h3>
            <p class="no-select">{{'contribute-modal-subtitle' | translate}}</p>

            <div class="flex-center mb-4 mt-3">
                <form [formGroup]="form" novalidate>
                    <input class="form-control text-center points-input remove-arrows"
                           type="number"
                           value="2"
                           formControlName="pointsInputCtrl"
                           #pointsInput>
                </form>

            </div>


            <div class="form-group no-select">
                <button type="submit"
                        tabindex="3"
                        class="btn btn-lg btn-outline-light btn-spacing w-100 text-uppercase"
                        (click)="submit()"
                >
                    {{'vote' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
