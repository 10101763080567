<div class="subscription-row" *ngIf="payment">
    <div *ngIf="!payment || loading" class="text-center">
        <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="d-flex justify-content-between flex-column overflow-auto" *ngIf="payment && !loading">
        <div>
            <div>
                <b class="mx-2">Corridor Subscription</b>
            </div>
            <div class="mx-2">
                {{payment.subscriptionId}}
            </div>
            <div>
                <span class="mt-2 mx-2">
                    <b>
                        <span *ngIf="payment.currency !== 'trial'">${{payment.amount / 100}}</span>
                        <span *ngIf="payment.currency === 'trial'">TRIAL</span>
                    </b>
                    <div class="ml-2 d-inline">
                        {{payment.startDate | date}} -
                        {{payment.endDate | date}}
                    </div>
                </span>
            </div>
        </div>
        <div class="my-auto">
            <span *ngIf="payment.status" [class.text-warning]="payment.status === 'REFUNDED'"
                [class.text-success]="payment.status === 'COMPLETED'">{{payment.status}}</span>
            <img *ngIf="payment.type === 'Patreon'" src="assets/subscriptions/patreon.svg" height="28px" />
            <img *ngIf="payment.type === 'Apple'" src="assets/subscriptions/apple.svg" height="28px" />
            <img *ngIf="payment.type === 'Stripe'" src="assets/subscriptions/stripe.svg" height="28px" />
            <img *ngIf="payment.type === 'PayPal'" src="assets/subscriptions/paypal.svg" height="28px" />
            <a *ngIf="payment.receiptUrl" href="{{payment.receiptUrl}}" [target]="isMobile ? '_self' : '_blank'" class="btn btn-link">Receipt</a>
        </div>
    </div>
</div>
