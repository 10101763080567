import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
    selector: 'app-card-row-v2',
    templateUrl: './card-row-v2.component.html',
    styleUrls: ['./card-row-v2.component.css']
})
export class CardRowV2Component implements OnInit {

    error: string;
    loading: boolean = false;

    @Input() card: any;
    @Output() removed = new EventEmitter<boolean>();

    constructor(private subscriptionService: SubscriptionService) {
    }

    ngOnInit(): void {
    }

    remove() {
        this.loading = true;
        this.subscriptionService.deleteCard(this.card.id).subscribe(res => {
            this.loading = false;
            this.card = null;
            this.removed.emit(true);
        });
    }

}
