export interface Menu {
    android?: boolean;
    authentication?: number;
    iOS?: boolean;
    id?: string;
    name?: string;
    order?: number;
    subscription?: number;
    type?: string;
    url?: string;
    web?: boolean;
    subMenus?: Menu[];
    channelId?: number;
}
