import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-schedule-thumb',
    templateUrl: './schedule-thumb.component.html',
    styleUrls: ['./schedule-thumb.component.css']
})
export class ScheduleThumbComponent implements OnInit {

    weekday = new Array(7);

    imageUrl: string;
    title: string;
    subtitle: string;
    date: string;
    time: string;

    @Input() item: any;

    constructor() {
        this.weekday[0] = 'Sunday';
        this.weekday[1] = 'Monday';
        this.weekday[2] = 'Tuesday';
        this.weekday[3] = 'Wednesday';
        this.weekday[4] = 'Thursday';
        this.weekday[5] = 'Friday';
        this.weekday[6] = 'Saturday';
    }

    ngOnInit() {
        this.imageUrl = this.item.imageUrl;
        this.title = this.item.title;
        this.subtitle = this.item.subtitle;

        let itemDate: Date = new Date(this.item.releaseDate);

        this.date = this.weekday[itemDate.getDay()];

        if (new Date().toLocaleDateString() == itemDate.toLocaleDateString()) {
            this.date = 'Today';
        } else {
            this.date = this.date + ' ' + itemDate.toLocaleDateString().replace('/2020', '');
        }
        const zone = itemDate.toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
        this.time = itemDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) + ' ' + zone;

        if (zone != 'PST' && zone != 'PDT') {
            const zoneLA = itemDate.toLocaleTimeString('en-us', {
                timeZoneName: 'short',
                timeZone: 'America/Los_Angeles'
            }).split(' ')[2];
            let la = itemDate.toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'America/Los_Angeles'
            });
            this.time = this.time + ' / ' + la + ' ' + zoneLA;
        }
    }

}
