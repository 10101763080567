import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from './account.service';
import { EnvironmentService } from './environment.service';
import { ChannelResponse, Channel } from '../models';

@Injectable({
	providedIn: 'root'
})
export class ChannelsService {

	private historySubject = new BehaviorSubject<any[]>(null);

	userChannelSubject = new BehaviorSubject<any[]>(null);

	constructor(
		private envService: EnvironmentService,
		private http: HttpClient,
        private accountService: AccountService) {
        this.accountService.loggingOut.subscribe(() => this.clearHistorySubject());
	}

	clearHistorySubject() {
		this.historySubject.next(null);
	}

	getChannels(hero: boolean, showFree: boolean): Observable<ChannelResponse[]> {
		return this.http
			.get<ChannelResponse[]>(this.envService.contentCdnHost + '/channels/v11?hero=' + hero + '&showFree=' + showFree)
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('ChannelsService::Error::GET', error);
				return throwError(error);
			}));
	}

	getLatestVideosChannel(): Observable<ChannelResponse> {
		return this.http
			.get<ChannelResponse>(this.envService.contentCdnHost + '/channels/v11/latest')
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('ChannelsService::Error::GET', error);
				return throwError(error);
			}));
	}

	getChannelById(channelId: number): Observable<ChannelResponse> {
		return this.http
			.get<ChannelResponse>(this.envService.contentCdnHost + '/channels/v11/' + channelId)
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('ChannelsService::Error::GET', error);
				return throwError(error);
			}));
	}

	getChannelBySeasonId(seasonId: number): Observable<ChannelResponse> {
		return this.http
			.get<ChannelResponse>(this.envService.contentCdnHost + '/channels/v11/season/' + seasonId)
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('ChannelsService::Error::GET', error);
				return throwError(error);
			}));
	}

	getChannelsShows(): Observable<ChannelResponse[]> {
		return this.http
			.get<ChannelResponse[]>(this.envService.contentCdnHost + '/channels/v11/shows')
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('ChannelsService::Error::GET', error);
				return throwError(error);
			}));
	}

	getChannelsShow(channelId: number): Observable<ChannelResponse> {
		return this.http
			.get<ChannelResponse>(this.envService.contentCdnHost + '/channels/v11/' + channelId)
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('ChannelsService::Error::GET', error);
				return throwError(error);
			}));
	}

	getChannelBySlug(slug: string): Observable<ChannelResponse> {
		return this.http
			.get<ChannelResponse>(this.envService.contentCdnHost + '/channels/v11/slug/' + slug)
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('ChannelsService::Error::GET', error);
				return throwError(error);
			}));
	}

	getUserChannels(): Observable<any> {
		return this.http
			.get<any>(this.envService.contentHost + '/v4/userchannels/')
			.pipe(catchError(error => {
				if (!environment.production)
					console.error('RecommendationsService::Error::GET', error);
				return throwError(error);
			}));
	}

	userChannels(): Observable<any[]> {
		return this.userChannelSubject.asObservable();
	}

	async refreshUserChannels() {
        if (!this.accountService.isAuthenticated()) {
            return;
        }
		await this.accountService.updateToken().then(
			() => {
				this.getUserChannels().subscribe(res => {
					this.userChannelSubject.next(res);
				});
			},
			() => {
			});
	}
}
