import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';
import { UserBadge } from '../models';

@Injectable({
    providedIn: 'root'
})
export class BadgeService {

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) {
    }

    getBadges(): Observable<UserBadge[]> {
        return this.http
            .get<any[]>(this.envService.userHost + '/v1/badges/all')
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('BadgeService::Error::GET', error);
                return throwError(error);
            }));
    }

    updateBadges(badges: any[]): Observable<any[]> {
        return this.http
            .post<any[]>(this.envService.userHost + '/v1/badges', badges)
            .pipe(catchError(error => {
                if (!environment.production)
                    console.error('BadgeService::Error::POST', error);
                return throwError(error);
            }));
    }
}
