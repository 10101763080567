import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-payment-row-v2',
    templateUrl: './payment-row-v2.component.html',
    styleUrls: ['./payment-row-v2.component.css']
})
export class PaymentRowV2Component implements OnInit {

    error: string;
    loading: boolean = false;
    @Input() payment: any;
    @Input() isMobile: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
