import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RemoteConfigService } from '../../services/remote-config.service';

@Component({
    selector: 'app-mobile-apps',
    templateUrl: './mobile-apps.component.html',
    styleUrls: ['./mobile-apps.component.scss']
})
export class MobileAppsComponent implements OnInit {

    @Output() close = new EventEmitter<void>();

    constructor(public config: RemoteConfigService) {
    }

    ngOnInit(): void {
    }

    iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    onClose() {
        localStorage.setItem('closed-mobile', ''+Date.now());
        this.close.emit()
    }
}
