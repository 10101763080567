export interface VideoResponse extends VideoV3Response{
    id?: number;
    url?: string;
}

export interface VideoV3Response {
    dashVideoId: number;
    dashUrl: string;
    hlsVideoId: number;
    hlsUrl: string;
    fpsHlsUrl: string;
    widevineUrl: string;
    uid: string;
    poster: string;
    type: string;
    startTimeMs: number;
    previewsUrl: string;
    fairPlayUrl: string;
    videoId?: number;
    subtitles?: any;
}
