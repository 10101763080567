import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChannelsService } from 'src/app/services/channels.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { AppComponent } from 'src/app/app.component';
import { ChannelResponse } from '../../models';
import { lastValueFrom } from 'rxjs';
import { LocalisationService } from 'src/app/services/localisation.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-shows',
	templateUrl: './shows.component.html',
	styleUrls: ['./shows.component.css']
})
export class ShowsComponent implements OnInit, OnDestroy {

	channelId: string;
	channel: ChannelResponse;
	media: any[];
	heroMedia: any;
	orderType: string = 'Newest';
	heroHeight: string = '40vh';
	heroOverride: any;

	order: string;

	showsHeaderTranslation: string;

	constructor(
		private route: ActivatedRoute,
		private localisationService: LocalisationService,
		private app: AppComponent,
		private channelService: ChannelsService,
		private storageManager: StorageManagerService,
		private router: Router,
		private titleService: Title) {
	}

	ngOnInit(): void {
		this.showsHeaderTranslation = this.localisationService.getTranslation("shows");
		this.route.params.subscribe(() => {
			this.channelLoad(null);
		});

		this.app.heroLoaded.subscribe(res => {
			if (this.heroHeight !== res) {
				this.heroHeight = res;
			}
		});
	}

	async channelLoad(channelIdOverride) {
		if (channelIdOverride) {
			this.channelId = channelIdOverride;
		} else {
			this.channelId = this.route.snapshot.paramMap.get('id');
		}

		if (this.channelId) {

            try {
                if (Number.isInteger(Number(this.channelId))) {
                    this.channel = await lastValueFrom(this.channelService.getChannelById(Number(this.channelId)));
                } else {
                    this.channel = await lastValueFrom(this.channelService.getChannelBySlug(this.channelId));
                }
            } catch {}

			if (!this.channel) {
				this.router.navigate(['/not-found']);
			}

			if (!this.channel.hero) {
				this.heroMedia = this.channel.media[0];
				if (this.channel.images) {
					this.heroMedia.channelImageUrl = this.channel.images.find(f => f.type === 'standard')?.url;
				}
				this.media = this.channel.media.slice(1);
			} else {
				this.heroOverride = this.channel.hero;
				this.media = this.channel.media;
			}

			this.order = this.storageManager.playlistOrder();

			if (this.storageManager.playlistOrder() == 'asc') {
				this.media.reverse();
				this.orderType = 'Oldest';
			} else {
				this.orderType = 'Newest';
			}

			this.titleService.setTitle(this.channel.name);
		}
	}

	ngOnDestroy() {
		this.titleService.setTitle(environment.name);
	}

	changeOrder() {
		if (this.storageManager.playlistOrder() == 'asc') {
			this.storageManager.setPlaylistOrder('desc');
			this.media.reverse();
			this.orderType = 'Newest';
		} else {
			this.storageManager.setPlaylistOrder('asc');
			this.media.reverse();
			this.orderType = 'Oldest';
		}
		//this.storageManager.setCookie("channel-" + this.channelId, this.channel)
		this.order = this.storageManager.playlistOrder();
	}
}
