<ng-container *ngIf="config.remoteConfig.comments_enabled">

    <div [class.d-none]="showComments$ | async" class="d-lg-none top-comment-mobile" *ngIf="comments">
        <div class="no-click-through" (click)="showComments$.next(true)"></div>

        <div class="comments-title">
            Comments <span class="comment-count">{{ totalComments$ | async }}</span>
        </div>

        <div *ngIf="!authed">
            <p>Sign in for Comments</p>
        </div>

        <div *ngIf="authed && comments.length > 0">
            <app-comment-read [mobileTopComment]="true" [comment]="comments[0]" [mediaId]="mediaId"></app-comment-read>
        </div>

    </div>


    <div [class.d-none]="!(showComments$ | async)" class="d-lg-block">
        <div class="hr-bottom hide-hr-mobile">
            <div class="comments-title">
                <h3>
                    Comments
                </h3>

                <div *ngIf="authed" class="comment-sort-buttons">
                    <button class="btn-v2" [class.active]="topComments$ | async" (click)="sortByTop()">Top</button>
                    <button class="btn-v2" [class.active]="!(topComments$ | async)" (click)="sortByNewest()">Newest</button>
                </div>

                <button class="comment-close-button d-lg-none" (click)="showComments$.next(false)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>

            </div>


        </div>

        <div *ngIf="!authed">
            <p>Sign in for Comments</p>
        </div>

        <div id="comments" *ngIf="authed">
            <app-comment-write [mediaId]="mediaId"></app-comment-write>
            <div class="mt-3">
                <div *ngFor="let comment of comments">
                    <app-comment-read [comment]="comment" [mediaId]="mediaId"></app-comment-read>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm">
                    <p *ngIf='!comments'>
                        Loading comments...
                    </p>
                    <p *ngIf='comments && noComments'>
                        No comments yet!
                    </p>
                </div>
            </div>
            <div #bottomOfComments></div>
            <div *ngIf="lastComment" class="position-relative div-loader">
            </div>
        </div>

    </div>
</ng-container>
