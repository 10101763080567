import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { PurchaseHistory } from '../models';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {

    paymentTypeSelected = ""

    constructor(
        private envService: EnvironmentService,
        private http: HttpClient) { }

    createPaymentSession(priceId: string): Observable<any> {
        return this.http
            .get<string>(this.envService.userHost + '/v1/stripePurchase/' + priceId).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('PaymentsService::post::error', error);
                    return throwError(error);
                })
            );
    }

    getProducts(): Observable<any> {
        return this.http
            .get<string>(this.envService.contentCdnHost + '/v1/shop/').pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('PaymentsService::post::error', error);
                    return throwError(error);
                })
            );
    }

    getPurchaseHistory(): Observable<PurchaseHistory[]> {
        return this.http
            .get<PurchaseHistory[]>(this.envService.userHost + '/v1/purchaseHistory/').pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('PaymentsService::post::error', error);
                    return throwError(error);
                })
            );
    }

    redeemVoucher(voucher: string): Observable<any> {
        return this.http
            .post<any>(this.envService.userHost + '/v1/voucher/', { code: voucher }).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('PaymentsService::post::error', error);
                    return throwError(error);
                })
            );
    }

    confirmPurchasePayment(session: string, purchaseId: number): Observable<any> {
        return this.http
            .post<any>(this.envService.userHost + '/v1/stripePurchase/', { sessionId: session, purchaseId: purchaseId }).pipe(
                catchError(error => {
                    if (!environment.production)
                        console.error('PaymentsService::post::error', error);
                    return throwError(error);
                })
            );
    }
}
