import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RemoteConfigService } from '../../services/remote-config.service';
import { AccountService } from '../../services/account.service';
import { BehaviorSubject, debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ChatService } from '../../services/chat.service';
import { SocketService } from '../../services/socket.service';
import { StorageManagerService } from '../../services/storage-manager.service';

@Component({
    selector: 'app-video-comments',
    templateUrl: './video-comments.component.html',
    styleUrls: ['./video-comments.component.scss']
})
export class VideoCommentsComponent implements OnInit, OnDestroy {

    @Input() mediaId: number;

    authed = this.account.isAuthenticated();
    comments: any[] = [];
    totalComments$ = new BehaviorSubject<number>(0);

    set totalComments(newVal: number) {
        this.totalComments$.next(newVal);
    }

    noComments: boolean = false;
    gettingComments = false;
    commentBatchSize = 10;
    lastComment: string | undefined;
    initialised: boolean = false;

    @ViewChild('bottomOfComments') bottomOfCommentsEl: ElementRef;

    destroy$: Subject<void> = new Subject<void>();
    cancel$: Subject<void> = new Subject<void>();
    topComments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.storageService.topComments ?? this.config.remoteConfig.video_default_comment_order === 'top');
    showComments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    constructor(public config: RemoteConfigService,
                private account: AccountService,
                private db: AngularFireDatabase,
                private chatService: ChatService,
                private socketService: SocketService,
                private storageService: StorageManagerService,) {
    }

    ngOnInit(): void {
        fromEvent(window, 'scroll')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.onWindowScroll());

        this.initializeComments();

        this.topComments$.subscribe(topComments => {
            this.storageService.topComments = topComments;
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    initializeComments() {
        if (this.config.remoteConfig.comments_version === '1') {
            this.initV1();
        } else {
            this.initV2();
        }
    }

    initV1() {
        let newSub = true;
        // Get initial comments and listen for changes/new comments
        this.db.list('chat-' + this.mediaId, qr => qr.orderByKey().limitToLast(this.commentBatchSize))
            .snapshotChanges()
            .pipe(debounceTime(10), takeUntil(this.destroy$))
            .subscribe({
                next: data => {
                    this.initialised = true;
                    if (data && this.chatService.replying === false) {
                        if (newSub && data.length === this.commentBatchSize) {
                            this.lastComment = data[0].key;
                        }
                        newSub = false;
                        data.map(dataAction => {
                            let chat = <any> dataAction.payload.val();
                            chat.id = dataAction.key;

                            const index = this.comments.findIndex(f => f.id === chat.id);
                            if (index < 0) {
                                this.comments.unshift(chat);
                            } else {
                                this.comments[index] = chat;
                            }
                        });
                    }
                }
            });
    }

    initV2() {
        this.socketService.onMessage('comment-' + this.mediaId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            const parsed = JSON.parse(data);
            if (parsed.type === 'comment') {
                this.addCommentV2(parsed);
            } else if (parsed.type === 'delete') {
                this.deleteCommentV2(parsed);
            }
        });

        this.getComments();
    }

    getComments() {
        let observable = this.topComments$.value ? this.chatService.getTopComments(this.mediaId) : this.chatService.getComments(this.mediaId);

        observable.pipe(takeUntil(this.destroy$), takeUntil(this.cancel$)).subscribe(x => {
            this.comments = x.comments;
            this.initialised = true;
            this.totalComments = x.totalComments;
            if (x.lastPage) {
                this.lastComment = undefined;
                return;
            }
            this.lastComment = x.comments[x.comments.length - 1].id;

        });
    }

    addCommentV2(parsed: any) {
        // Process comments without an originalCommentId
        const comments = parsed.comments.filter(comment => !comment.originalCommentId);
        comments.forEach(comment => {
            console.log(comment);
            const index = this.comments.findIndex(f => f.id === comment.id);
            if (index < 0) {
                const insertIndex = this.comments.findIndex(f => f.id < comment.id);
                if (insertIndex < 0) {
                    this.comments.push(comment);
                } else {
                    this.comments.splice(insertIndex, 0, comment);
                }
            } else {
                this.comments[index] = comment;
            }
        });
        if (parsed.comments.length > 1 || (parsed.comments.length === 1 && parsed.lastPage)) {
            this.gettingComments = false;
        }

        // Process comments with an originalCommentId
        parsed.comments.filter(comment => comment.originalCommentId).forEach(comment => {
            const parentIndex = this.comments.findIndex(f => f.id === comment.originalCommentId);
            if (parentIndex >= 0) {
                // Initialize replies array if it doesn't exist
                this.comments[parentIndex].comments = this.comments[parentIndex].comments || [];

                // Find the correct position to insert the new reply in ascending order
                const insertReplyIndex = this.comments[parentIndex].comments.findIndex(f => f.id > comment.id);
                if (insertReplyIndex < 0) {
                    // Add to the end if no larger id is found
                    this.comments[parentIndex].comments.push(comment);
                } else {
                    // Insert before the first larger id
                    this.comments[parentIndex].comments.splice(insertReplyIndex, 0, comment);
                }
            }
        });

        this.totalComments++;
    }

    deleteCommentV2(parsed: any) {
        this.comments = this.comments.filter(x => x.id !== parsed.commentId).map(comment => {
            if (comment.comments && comment.comments.length > 0) {
                comment.comments = comment.comments.filter(reply => reply.id !== parsed.commentId);
            }
            return comment;
        });
        this.totalComments--;
    }

    onWindowScroll() {
        if (this.gettingComments || !this.bottomOfCommentsEl) {
            return;
        }
        const distanceFromBottom = this.bottomOfCommentsEl.nativeElement.getBoundingClientRect().top - window.innerHeight;
        if (distanceFromBottom < 300) {
            this.getMoreComments();
        }
    }

    async getMoreComments() {
        if (this.gettingComments || !this.initialised) {
            return;
        }

        if (!this.lastComment) {
            return;
        }
        this.gettingComments = true;

        if (this.config.remoteConfig.comments_version === '1') {
            this.getMoreCommentsV1();
        } else {
            this.getMoreCommentsV2();
        }
    }

    getMoreCommentsV1() {
        const query = qr => qr.orderByKey().endBefore(this.lastComment).limitToLast(this.commentBatchSize);
        let newSub = true;
        this.db.list('chat-' + this.mediaId, query)
            .snapshotChanges()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                if (newSub) {
                    if (data.length === 0) {
                        this.lastComment = undefined;
                    } else {
                        this.lastComment = data[0].key;
                    }
                }

                if (data) {
                    let newComments = [];
                    data.map(dataAction => {
                        let chat = <any> dataAction.payload.val();
                        chat.id = dataAction.key;
                        const index = this.comments.findIndex(f => f.id === chat.id);
                        if (index < 0) {
                            newComments = [chat].concat(newComments);
                        } else {
                            this.comments[index] = chat;
                        }
                    });
                    this.comments = this.comments.concat(newComments);
                }
                this.gettingComments = false;
                newSub = false;
            });
    }

    getMoreCommentsV2() {
        let observable = this.topComments$.value ? this.chatService.getTopComments(this.mediaId, this.lastComment) : this.chatService.getComments(this.mediaId, this.lastComment);
        observable.pipe(takeUntil(this.destroy$), takeUntil(this.cancel$)).subscribe(x => {
            this.comments.push(...x.comments);
            this.gettingComments = false;
            this.totalComments = x.totalComments;
            if (x.lastPage) {
                this.lastComment = undefined;
                return;
            }

            this.lastComment = x.comments[x.comments.length - 1].id;
        });
    }

    sortByTop() {
        if (this.topComments$.value) {
            return;
        }
        this.cancel$.next();
        this.topComments$.next(true);
        this.getComments();
    }

    sortByNewest() {
        if (!this.topComments$.value) {
            return;
        }
        this.cancel$.next();
        this.topComments$.next(false);
        this.getComments();
    }
}
