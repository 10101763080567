<div class="main main-full mocap-bg">
	<div class="container account-management wide-content">
		<h2 class="d-inline">{{ 'account-change-language' | translate }}</h2>
		<div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
			<span class="sr-only">{{ 'loading' | translate }}</span>
		</div>
		<div class="row border-top py-3 my-3">
			<div class="col-12">

				<label>{{ 'voucher-code' | translate }}</label>
				<p>{{ 'change-language-select' | translate }}</p>
				<div class="form-group mb-3">
					<select class="form-control" [(ngModel)]="selectedLanguage" name="selectedLanguage" #linkSelect (change)="onLanguageChange()">
						<option *ngFor="let language of app.languages" [ngValue]="language.id" [selected]="language.selected == true">
							{{language.name}}</option>
					</select>
				</div>
                <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
			</div>
		</div>
	</div>
</div>
