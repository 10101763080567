<div class="main main-full main-responsive">
	<app-title [title]="'account-change-profile-picture' | translate "></app-title>
	<div *ngIf="loading" class="spinner-border text-light ml-4" role="status">
		<span class="sr-only">{{ "loading" | translate }}</span>
	</div>

	<div *ngIf="!loading">
		<div class="mb-3">
			<p>{{ "account-change-profile-picture-file-types" | translate }}: jpg, png</p>
			<p>{{ "account-change-profile-picture-max-size" | translate }}: 1MB</p>

			<input type="file" accept="image/*" (change)="fileChangeEvent($event)" />
			<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
				[aspectRatio]="1 / 1" format="jpg" (imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded()"
				(loadImageFailed)="loadImageFailed()"></image-cropper>

			<div *ngIf="croppedImage" class="avatar-container mb-3 mx-auto">
				<img class="avatar-big" alt="Avatar Preview" [src]="croppedImage" />
			</div>
		</div>

		<p class="text-danger" *ngIf="error">{{error}}</p>

		<div class="form-group">
			<button type="submit" class="btn btn-slim btn-outline-primary" (click)="submit()">Upload</button>
		</div>

		<div class="d-block"></div>
        <a (click)="back('/account')" class="btn btn-outline-light btn-slim mt-3">{{ "account-back" | translate}}</a>
	</div>
</div>
