export const environment = {
    production: true,
    forceMaintenance: false,
    webSocketEnabled: false,
    googleAnalyticsEnabled: false,
    googleAnalyticsId: '',
    projectsEnabled: false,
    name: 'Pepperbox',
    domain: '.pepperbox.tv',
    project: 'velocity-portal',
    socketUrl: 'https://socket.pepperbox.tv',
    cookiebotId: 'bc2b4d5a-2d25-4c0b-bdb0-957ac5a04163',
    recaptchaKey: '6Le9MhgqAAAAANsgEdwnPNxicOKNdqiixE2L0P7k',
    recaptchaDebug: '43FB4F6F-D8FD-4FEB-9F39-CBAFC566814B',
    assets: {
        logo: 'https://assets.pepperbox.tv/logo_short.svg',
    },
    google: {
        client_id: '761565242808-4ffa736via2475frqvp57ognk7nfcjl0.apps.googleusercontent.com',
        project_id: 'watch-velocity',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
        client_secret: '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',
    },
    apple: {
        client_id: 'tv.pepperbox.web',
        scope: 'name email',
        state: '12345',
        nonce: '12345',
        redirectUri: 'https://www.pepperbox.tv/'
    },
    firebaseConfig: {
        apiKey: 'AIzaSyDse-e2RY7WasRtABaWPDnU6ifOiaUFRZs',
        authDomain: 'watch-velocity.firebaseapp.com',
        projectId: 'watch-velocity',
        storageBucket: 'watch-velocity.appspot.com',
        messagingSenderId: '761565242808',
        appId: '1:761565242808:web:df2239675a494030ea1b4d',
        measurementId: 'G-QVC543G4BP'
    },
    stripe: {
        monthlyPlan: '',
        annualPlan: '',
        buyMonthlySub: '',
        buyAnnualSub: '',
        pk_test: 'pk_test_51NyLHYEi0GQ5Rf84lh0oYafRamslauoBo953nLWIvsjIJYKgo2hCAYYgZUUHfgn2htQfk4lGBEv4tf3hfkwKqcCo00bfkIc0V8',
        pk_live: 'pk_live_51NyLHYEi0GQ5Rf84CcXh5yt9TWzkEfvh2rwkPFukdaOwgtdWHYj87bpRhd1xZNzQS3AAGlkDqJwez8VfNegXLWOk00tIWHxyVQ'
    }

};
